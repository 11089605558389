import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Select } from "antd";
import { generalFields } from "@/components/Reports/variables";
import type { SimpleField } from "@/utils/dynamic-fields/types";

type Props = {
  value?: string;
  onChange?: (key: string, variable: SimpleField) => void;
};

export const VariablePicker = ({ value, onChange }: Props) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      options={generalFields}
      suffixIcon={<Icon component={DropdownArrow} />}
      fieldNames={{ label: "label", value: "name" }}
      placeholder="Variable"
    />
  );
};
