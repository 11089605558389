import type { SelectProps } from "antd";
import { Cascader, Select } from "antd";
import Icon, { ArrowRightOutlined } from "@ant-design/icons";
import "./MultiSelect.less";
import { DropdownArrow } from "../Icons";
import { BaseTag } from "../BaseTag/BaseTag";

const defaultTagRender = ({ closable, onClose, label }) => (
  <BaseTag closable={closable} onClose={onClose}>
    {label}
  </BaseTag>
);

export interface MultiSelectProps<TValue extends any[] = string[], TOption extends any = any>
  extends Pick<
    SelectProps<TValue, TOption>,
    | "fieldNames"
    | "value"
    | "onChange"
    | "options"
    | "tagRender"
    | "placeholder"
    | "notFoundContent"
    | "disabled"
    | "mode"
    | "tokenSeparators"
    | "onBlur"
    | "dropdownRender"
    | "loading"
  > {}

export const MultiSelect = ({
  value,
  onChange,
  options,
  tagRender = defaultTagRender,
  placeholder,
  fieldNames,
  notFoundContent,
  disabled,
  mode = "multiple",
  tokenSeparators,
  onBlur,
  dropdownRender,
  loading,
}: MultiSelectProps) => {
  const filterOption = (inputValue, option) =>
    option[fieldNames?.label ?? "label"].toLowerCase().includes(inputValue.toLowerCase());

  return (
    <Select
      value={value}
      onChange={onChange}
      className="multi-select"
      mode={mode}
      options={options}
      filterOption={filterOption}
      tagRender={tagRender}
      placeholder={placeholder}
      fieldNames={fieldNames}
      notFoundContent={notFoundContent}
      disabled={disabled}
      suffixIcon={<Icon component={DropdownArrow} />}
      tokenSeparators={tokenSeparators}
      onBlur={onBlur}
      dropdownRender={dropdownRender}
      loading={loading}
    />
  );
};

export const MultiCascader = ({ value, options, onChange, tagRender, dropdownFooter, fieldNames }) => (
  <Cascader
    value={value}
    options={options}
    multiple
    onChange={onChange}
    tagRender={tagRender}
    allowClear={false}
    className="multi-select"
    expandIcon={<ArrowRightOutlined />}
    fieldNames={fieldNames}
    dropdownRender={(menus) => (
      <div className="multi-select__dropdown">
        {menus}
        {dropdownFooter}
      </div>
    )}
  />
);
