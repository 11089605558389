import React from "react";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../Icons";
import { Select, type SelectProps } from "antd";
import { getAllTimezones } from "@/utils/timezones";

const options = getAllTimezones().map((tz) => ({
  value: tz.name,
  label: `${tz.name.replaceAll("_", " ")} (${tz.utcOffsetStr})`,
}));

interface TimezoneSelectProps extends Pick<SelectProps, 'value' | 'onChange' | 'placeholder' | 'disabled' | 'allowClear'> {}

export const TimezoneSelect = ({ value, onChange, placeholder, disabled, allowClear }: TimezoneSelectProps) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      showSearch
      disabled={disabled}
      suffixIcon={<Icon component={DropdownArrow} />}
      options={options}
      allowClear={allowClear}
    />
  );
};
