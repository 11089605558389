import { SalesforceObject } from "@/types/salesforce";
import { OpenplayObjectType } from "@/types/common";

export const getOpenPlayEquivalent = (obj: string) => {
  if (!obj) {
    return null;
  }
  const lookup: Partial<Record<SalesforceObject, OpenplayObjectType>> = {
    [SalesforceObject.Planner]: OpenplayObjectType.Release,
    [SalesforceObject.PlannerProject]: OpenplayObjectType.Project,
    [SalesforceObject.AssociatedRelease]: OpenplayObjectType.Release,
  };
  return lookup[obj] ?? null;
};
