import { getSuggestionOptions } from "./getSuggestionOptions";
import { Node } from "@tiptap/core";
import { Suggestion } from "@tiptap/suggestion";
import type { VariableOptions } from "./types";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeView } from "./NodeView";

export const Variable = Node.create<VariableOptions>({
  name: "variable",
  addOptions() {
    return {
      variables: [],
    };
  },
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,
  parseHTML: () => [{ tag: "variable" }],
  addAttributes() {
    return {
      name: {
        default: null,
        parseHTML: (element) => element.textContent || element.getAttribute("data-name"),
      },
    };
  },
  renderHTML({ node }) {
    return ["variable", {}, node.attrs.name];
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isVariable = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isVariable = true;
              tr.insertText("{", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isVariable;
        }),
    };
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...getSuggestionOptions(this.options.variables),
      }),
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(NodeView, { className: "variable" });
  },
});
