import { SalesforceObject } from "@/types/salesforce";
import { OpenplayObjectType } from "@/types/common";

export const getSalesforceEquivalent = (obj: OpenplayObjectType): SalesforceObject => {
  if (!obj) {
    return null;
  }
  const lookup: Record<OpenplayObjectType, SalesforceObject> = {
    [OpenplayObjectType.Release]: SalesforceObject.Planner,
    [OpenplayObjectType.Project]: SalesforceObject.PlannerProject,
  };
  return lookup[obj] ?? null;
};
