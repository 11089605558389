import { Button, Form, Modal } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { SpreadsheetColumn } from "../SpreadsheetColumn";
import "./SpreadsheetColumnList.less";
import { useFieldPath } from "@/components/common/Form/hooks";
import { CopyFromTemplate } from "@/components/common/CopyFromTemplate/CopyFromTemplate";
import type { TabSource } from "@/utils";
import { TemplateType, useAuth } from "@/utils";
import type { SpreadsheetColumnSetTemplateData, Template } from "@/api/templates/types";
import cn from "classnames";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { atLeastOne } from "@/utils/validation";
import type { ComponentProps } from "react";
import { memo, useCallback } from "react";
import { SaveAsColumnSetTemplate } from "@/components/Spreadsheets/SpreadsheetColumnList/SaveAsColumnSetTemplate";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type TemplateActionsProps = {
  sourceObject: string;
  source: TabSource;
};

const TemplateActions = memo(({ sourceObject, source }: TemplateActionsProps) => {
  const form = Form.useFormInstance();

  const { getAbsolutePath } = useFieldPath();

  const applyTemplate = (template: Template<SpreadsheetColumnSetTemplateData>) => {
    form.setFieldValue(getAbsolutePath("columns"), template.data.columns);
  };

  const handleCopyFromTemplate = (template: Template<SpreadsheetColumnSetTemplateData>) => {
    const hasColumns = form.getFieldValue(getAbsolutePath("columns"))?.length > 0;
    if (hasColumns) {
      Modal.confirm({
        title: "Copy from a custom template?",
        content: "Are you sure you want to override current column values?",
        onOk: () => {
          applyTemplate(template);
        },
      });
    } else {
      applyTemplate(template);
    }
  };

  const { ability } = useAuth();

  return (
    <div className="spreadsheet-column-list__template-actions">
      {ability.can(PermissionAction.Create, PermissionSubject.Template) && (
        <SaveAsColumnSetTemplate sourceObject={sourceObject} source={source} />
      )}
      <CopyFromTemplate
        onCopy={handleCopyFromTemplate}
        filter={source ? { type: TemplateType.SpreadsheetColumnSet, dataSource: source, sourceObject } : null}
        buttonType="text"
      />
    </div>
  );
});

type Props = {
  showTemplateActions?: boolean;
  sourceObject: string;
  source: TabSource;
  canAdd?: boolean;
  disabled?: boolean;
  openplayQuery?: OpenplayQuery;
};

const rules = [atLeastOne("At least one column should be configured")];

export const SpreadsheetColumnList = memo(
  ({
    showTemplateActions = true,
    sourceObject,
    source,
    canAdd = true,
    disabled = false,
    openplayQuery,
  }: Props) => {
    const renderFields: ComponentProps<typeof FieldList>["children"] = useCallback(
      (fields, { add, remove, move }, { errors }) => (
        <div className={cn("spreadsheet-column-list", disabled && "spreadsheet-column-list--disabled")}>
          <div className="spreadsheet-column-list__actions">
            <Button
              className="spreadsheet-column-list__add"
              size="small"
              icon={<Icon component={Plus} />}
              onClick={() => add({ name: "" })}
              disabled={!canAdd}
            >
              Add Column
            </Button>
            {showTemplateActions && <TemplateActions sourceObject={sourceObject} source={source} />}
          </div>
          <SortableList onMove={move}>
            {fields.map((field) => (
              <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                <FieldListItem name={field.name}>
                  <SpreadsheetColumn
                    source={source}
                    sourceObject={sourceObject}
                    openplayQuery={openplayQuery}
                  />
                </FieldListItem>
              </SortableListItem>
            ))}
          </SortableList>
          {errors.length > 0 && <Form.ErrorList errors={errors} />}
        </div>
      ),
      [disabled, canAdd, showTemplateActions, sourceObject, source, openplayQuery]
    );

    return (
      <FieldList name="columns" rules={rules}>
        {renderFields}
      </FieldList>
    );
  }
);
