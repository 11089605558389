import Highlighter from "react-highlight-words";
import { Dropdown } from "antd";
import cn from "classnames";

const getSearchString = (token, caret) => {
  if (token.enclosed) {
    return token.variable;
  } else {
    return token.match.slice(1, caret);
  }
};

const Wrapper = ({ focused, invalid = false, children }) => {
  const className = cn(
    "dynamic-path-field__var",
    focused && "dynamic-path-field__var--focused",
    invalid && "dynamic-path-field__var--invalid"
  );
  return <span className={className}>{children}</span>;
};

const DropdownRender = ({ children }) => {
  const handleMouseDown = (e) => {
    e.preventDefault();
  };
  return <div onMouseDown={handleMouseDown}>{children}</div>;
};

const Content = ({ token, focused, valid, caret }) => {
  const { enclosed, match } = token;
  const invalid = enclosed && !valid;

  if (focused) {
    return enclosed ? (
      <Wrapper focused={focused} invalid={invalid}>
        {match}
      </Wrapper>
    ) : (
      <span>
        <Wrapper focused={focused}>{match.slice(0, caret)}</Wrapper>
        <span>{match.slice(caret)}</span>
      </span>
    );
  } else {
    return enclosed ? (
      <Wrapper focused={focused} invalid={invalid}>
        {match}
      </Wrapper>
    ) : (
      <span>{match}</span>
    );
  }
};

export const Token = ({ token, caret, variables, onSelectVariable }) => {
  const focused = caret > 0 && caret <= token.match.length;

  const search = getSearchString(token, caret);
  const suggestions = search !== null ? variables.filter((variable) => variable.includes(search)) : [];

  const handleVariableClick = ({ key }) => {
    onSelectVariable(key, token);
  };

  const dropdownItems = suggestions.map((variable) => ({
    key: variable,
    label: (
      <Highlighter
        highlightClassName="dynamic-path-field__var"
        highlightTag="span"
        searchWords={[search]}
        autoEscape={true}
        textToHighlight={variable}
      />
    ),
    onClick: handleVariableClick,
  }));

  const hasSuggestions = suggestions.length > 0;

  const valid = variables.includes(token.variable);

  return (
    <Dropdown
      menu={{ items: dropdownItems }}
      open={focused && hasSuggestions}
      dropdownRender={(node) => <DropdownRender children={node} />}
    >
      <Content token={token} caret={caret} focused={focused} valid={valid} />
    </Dropdown>
  );
};
