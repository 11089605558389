import useSWR from "swr";
import type { ReleaseFilters } from "./types";
import { fetchRelease, fetchReleases } from "@/api/releases/index";

export const useReleases = (filters: ReleaseFilters = {}) =>
  useSWR(["releases", filters], ([_, filters]) => fetchReleases(filters), {
    fallbackData: { totalCount: 0, data: [] },
  });

export const useRelease = (id: string) => useSWR(["releases", id], ([_, id]) => fetchRelease(id));
