import { useParams } from "react-router-dom";
import { ScriptForm } from "./ScriptForm";
import { Spinner } from "../../common/Spinner/Spinner";
import { useScript } from "@/api/flows/hooks";

export const ScriptPage = () => {
  const { key } = useParams<{ key: string }>();

  const { data: script, isLoading } = useScript(key);

  if (isLoading) {
    return <Spinner />;
  }

  return <ScriptForm initialValues={script} />;
};
