import { Space } from "antd";
import { useDownload } from "@/utils/useDownload";
import { exportReport } from "@/api/reports";
import { ExportOutlined, LoadingOutlined } from "@ant-design/icons";

type Props = {
  id: string;
};

export const ExportReportButton = ({ id }: Props) => {
  const { start: download, loading } = useDownload(() => exportReport(id), {
    message: "File is being generated. Download will start automatically",
  });
  return (
    <Space role="button" onClick={download}>
      {loading ? <LoadingOutlined /> : <ExportOutlined />}
      <span>Export as .JSON</span>
    </Space>
  );
};
