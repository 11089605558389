import { Radio } from "antd";
import type { ComponentProps } from "react";

type Option = {
  value: string;
  label: string;
};

interface Props extends ComponentProps<typeof Radio.Group> {
  options: Option[];
}

export const RadioFilter = ({ options, ...props }: Props) => (
  <Radio.Group buttonStyle="solid" defaultValue={null} {...props}>
    <Radio.Button value={null} defaultChecked={true}>
      All
    </Radio.Button>
    {options.map((option) => (
      <Radio.Button value={option.value}>{option.label}</Radio.Button>
    ))}
  </Radio.Group>
);
