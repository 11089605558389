import React, { useCallback, useMemo } from "react";
import { Form } from "antd";
import { getStatusLabel, Status, TemplateType } from "@/utils";
import { useBoolean } from "ahooks";
import { SaveAsTemplateButton } from "../../../Templates/SaveAsTemplateButton";
import { SaveAsTemplateForm } from "../../../Templates/SaveAsTemplateForm";

export const SaveAsFiltersTemplate = ({ disabled }) => {
  const outerForm = Form.useFormInstance();

  const [isFormVisible, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const getTemplateFields = useCallback(
    () => ({
      type: TemplateType.Filters,
      data: {
        tenantCode: outerForm.getFieldValue("tenantCode"),
        configuration: outerForm.getFieldValue(["filter", "configuration"]),
      },
    }),
    [outerForm]
  );

  const feedStatus = Form.useWatch("status");
  const filter = Form.useWatch(["filter", "configuration"]);
  const isUnavailable = useMemo(() => {
    return feedStatus === Status.Draft || !filter;
  }, [filter, feedStatus]);

  return (
    <>
      <SaveAsTemplateButton
        onClick={openForm}
        disabled={disabled}
        unavailable={isUnavailable}
        unavailableTooltip={`Save as template couldn't be completed when status of feed is ${getStatusLabel(
          Status.Draft
        )} or filters are empty`}
      />
      <SaveAsTemplateForm
        isVisible={isFormVisible}
        onClose={closeForm}
        getTemplateFields={getTemplateFields}
      />
    </>
  );
};
