import Icon from "@ant-design/icons";
import { Plus } from "./Icons";
import { Tabs } from "antd";
import { tab } from "@/utils/tab";
import { useEffect, useRef, useState } from "react";

export const EditableTabs = ({ disabled, items = [], onAdd, onRemove, invalidKeys, hideAdd = false }) => {
  const [activeKey, setActiveKey] = useState(items[0]?.key);
  const handleTabChange = (key) => {
    setActiveKey(+key);
  };

  // TODO Refactor
  const prevItemCountRef = useRef(items.length);

  useEffect(() => {
    const itemCount = items.length;
    const prevItemCount = prevItemCountRef.current;
    if (prevItemCount < itemCount) {
      setActiveKey(items[items.length - 1].key);
    } else if (prevItemCount > itemCount) {
      setActiveKey((prevKey) => {
        const keyExists = items.some((item) => item.key === prevKey);
        return keyExists ? prevKey : items[items.length - 1].key;
      });
    }
    prevItemCountRef.current = itemCount;
  }, [items]);

  return (
    <Tabs
      activeKey={activeKey}
      onChange={handleTabChange}
      type="editable-card"
      hideAdd={hideAdd || disabled}
      items={items.map((item) => {
        const { closable = true } = item;
        return tab({
          ...item,
          closable: closable && !disabled,
          hasErrors: invalidKeys.includes(item.key),
        });
      })}
      onEdit={(targetKey, action) => {
        if (action === "add") {
          onAdd();
        } else {
          onRemove(targetKey);
        }
      }}
      addIcon={<Icon component={Plus} />}
    />
  );
};
