import { ActivityFilters, ActivityTable } from "../../Activity/ActivityTable/ActivityTable";
import { ActivityTableColumns } from "../../Activity/ActivityTable/columns";
import { useGridParams } from "@/utils/useGridParams";
import { useRequest } from "ahooks";
import { getActivityEntries } from "@/api/activity";
import { Operation, prepareParams } from "@/utils";

const columns = [
  ActivityTableColumns.Status,
  ActivityTableColumns.StartedAt,
  ActivityTableColumns.Feed,
  ActivityTableColumns.UPC,
  ActivityTableColumns.ReleaseArtist,
  ActivityTableColumns.ReleaseTitle,
];

const filtersConfig = [
  ActivityFilters.Status,
  ActivityFilters.UPC,
  ActivityFilters.UpcContains,
  ActivityFilters.StartedAt,
];

export const PartnerImports = ({ partnerId }) => {
  const { pagination, sort, filters } = useGridParams();

  const { loading, data: entries = [] } = useRequest(
    () =>
      getActivityEntries(
        prepareParams({ partnerId, operation: Operation.Import, ...filters }, pagination, sort)
      ),
    { refreshDeps: [partnerId, pagination, sort, filters], debounceInterval: 100 }
  );

  return (
    <div className="secondary-screen-table">
      <ActivityTable loading={loading} entries={entries} columns={columns} filters={filtersConfig} />
    </div>
  );
};
