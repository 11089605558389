import React from "react";
import ReactDOM from "react-dom";
import App from "@/App";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig value={{ shouldRetryOnError: (err) => err.status !== 403 }}>
      <Router>
        <App />
      </Router>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById("root")
);
