import { MultiSelect, type MultiSelectProps } from "./MultiSelect/MultiSelect";
import { BaseTag } from "./BaseTag/BaseTag";
import type { BaseOptionType, DefaultOptionType } from "rc-select/lib/Select";

export interface TagInputProps<
  TValue extends any[] = string[],
  TOption extends BaseOptionType = DefaultOptionType
> extends MultiSelectProps<TValue, TOption> {
  readOnlyTags?: string[];
  checkValue?: (value: string) => boolean;
}

export const TagInput = ({
  value,
  onChange,
  placeholder,
  disabled,
  checkValue = (_) => true,
  onBlur,
  readOnlyTags = [],
}: TagInputProps) => {
  return (
    <MultiSelect
      value={value ?? []}
      onChange={onChange}
      mode="tags"
      placeholder={placeholder}
      disabled={disabled}
      tokenSeparators={[" "]}
      tagRender={({ label, closable, onClose, value }) => {
        const valid = checkValue(value);
        const isReadOnly = readOnlyTags.includes(value);
        return (
          <BaseTag closable={closable && !isReadOnly} onClose={onClose} hasWarning={!valid}>
            {label}
          </BaseTag>
        );
      }}
      notFoundContent={null}
      onBlur={onBlur}
      dropdownRender={() => null}
    />
  );
};
