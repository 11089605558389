import { ErrorCode } from "./errorCode";

export const ERROR_MESSAGES = {
  [ErrorCode.DataValidation]: "Validation failed",
  [ErrorCode.Auth0Unauthorized]: "Something went wrong",
  [ErrorCode.Auth0AccountBlocked]: "Your account is deactivated. Please contact your administrator",
  [ErrorCode.Auth0AccountDoesNotExist]: "Please verify and re-enter your email and password",

  [ErrorCode.UserEmailConflict]: "A user with entered email address already exists in the system",
  [ErrorCode.UserNameConflict]: "A user with entered name already exists in the system",
  [ErrorCode.PartnerNameConflict]: "A partner with entered name already exists in the system",
  [ErrorCode.PartnerDDEXPartyIdConflict]:
    "A partner with entered DDEX Party ID  already exists in the system",
  [ErrorCode.PartnerContactEmailConflict]: "A partner with entered email already exists in the system",
  [ErrorCode.FeedNameConflict]: "A feed with entered name already exists in the system",
  [ErrorCode.IncorrectStorageType]: "-",
  [ErrorCode.TestStorageFailed]: "Connection is not available",
  [ErrorCode.CsvIsEmptyOrInvalidUpcs]: "Entered UPCs are not valid. Please check UPCs or tenant name.",
  [ErrorCode.CsvIsEmptyOrInvalidProjectNumbers]:
    "Entered project numbers are not valid. Please check project numbers or tenant name.",
  [ErrorCode.TemplateNameConflict]: "A template with entered name already exists in the system",
  [ErrorCode.SalesforceRequestFailed]: "The request to Salesforce API failed",
};
