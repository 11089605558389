import type { ReactNode } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import "./MappingListItem.less";

type Props = {
  left: ReactNode;
  right: ReactNode;
  onRemove: () => void;
};

export const MappingListItem = ({ left, right, onRemove }: Props) => (
  <div className="mapping-list-item">
    <div className="mapping-list-item__left">{left}</div>
    <ArrowRightOutlined className="mapping-list-item__arrow" />
    <div className="mapping-list-item__right">{right}</div>
    <IconButton className="mapping-list-item__remove" icon={Remove} danger onClick={onRemove} />
  </div>
);
