import { useParams } from "react-router-dom";
import { useOpenplayObject } from "@/api/openplay-objects/hooks";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { Empty, PageHeader } from "antd";
import { OpenplayObjectFieldTree } from "./OpenplayObjectFieldTree";
import { ImportFromOpenPlayButton } from "@/components/OpenplayObjects/Details/ImportFromOpenPlayButton";
import type { OpenplayObjectType } from "@/types/common";

export const OpenplayObjectPage = () => {
  const { name } = useParams<{ name: OpenplayObjectType }>();
  const { isLoading, data: entry, mutate } = useOpenplayObject(name);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{entry.label}</h1>}
        extra={entry.root && <ImportFromOpenPlayButton objectName={entry.name} onFinish={mutate} />}
      />
      {entry.root ? (
        <OpenplayObjectFieldTree root={entry.root} onFieldEdited={() => mutate()} />
      ) : (
        <Empty
          description={
            <>
              The object does not contain any field data. <br /> Please click{" "}
              <strong>Import from OpenPlay</strong> to load one.
            </>
          }
        >
          <ImportFromOpenPlayButton objectName={entry.name} onFinish={mutate} />
        </Empty>
      )}
    </>
  );
};
