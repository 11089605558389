import { Form, PageHeader, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { Operation, OpenplayObjectType } from "@/types/common";
import { toOptions } from "@/utils/toOptions";
import { capitalize } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import "./PartnerActivity.less";
import { PartnerDeliveries } from "@/components/Partners/Activity/PartnerDeliveries";
import { PartnerImports } from "@/components/Partners/Activity/PartnerImports";
import { Summary } from "@/components/common/Summary/Summary";
import { useRequest } from "ahooks";
import { getPartner } from "@/api/partners";

export const PartnerActivity = () => {
  const { replace } = useHistory();
  const { partnerId, targetObject, operation } = useParams<{
    partnerId: string;
    targetObject: OpenplayObjectType;
    operation: Operation;
  }>();

  const { data: partner } = useRequest(() => getPartner(partnerId), {
    cacheKey: `partner-${partnerId}`,
  });

  const handleTargetObjectChange = (newTargetObject: OpenplayObjectType) =>
    replace(`../${newTargetObject}/${operation}`);

  const handleOperationChange = (newOperation: Operation) =>
    replace(`../${OpenplayObjectType.Release}/${newOperation}`);

  return (
    <>
      <PageHeader
        title="Partner Activity"
        extra={
          <div className="partner-activity__controls">
            {/* @ts-expect-error */}
            <Summary items={[{ label: "Partner", value: partner?.name }]} />
            <Form.Item label="Operation">
              <Select
                value={operation}
                onChange={handleOperationChange}
                options={toOptions(Object.values(Operation), capitalize)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
            <Form.Item label="Target Object">
              <Select
                value={targetObject}
                onChange={handleTargetObjectChange}
                options={toOptions(Object.values(OpenplayObjectType), capitalize)}
                suffixIcon={<Icon component={DropdownArrow} />}
                disabled={operation === Operation.Import}
              />
            </Form.Item>
          </div>
        }
      />
      {operation === Operation.Export && (
        <PartnerDeliveries key={targetObject} partnerId={partnerId} targetObject={targetObject} />
      )}
      {operation === Operation.Import && <PartnerImports partnerId={partnerId} />}
    </>
  );
};
