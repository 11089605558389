import type { ReactElement, ReactNode } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import { DATE_FORMAT } from "@/utils";
import { DictionarySelect } from "./DictionarySelect";
import { UpcList } from "./UpcList/UpcList";
import { MultiSelect } from "../MultiSelect/MultiSelect";
import cn from "classnames";
import "./FilterInput.less";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../Icons";
import { RadioFilter } from "./Status";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { AutocompleteFilter } from "./AutocompleteFilter";

const decideDictionary = (name: string) => {
  switch (name) {
    case "configurations[]":
      return "configurations";
    case "genres[]":
      return "genres";
    default:
      console.error(`Unknown dictionary for "${name}" name`);
  }
};

export type SelectOption = {
  value: string;
  label: string;
};

export enum FilterType {
  Text = "text",
  Radio = "radio",
  Select = "select",
  MultiSelect = "multi-select",
  DateRange = "date-range",
  Dictionary = "dictionary",
  UPCs = "upcs",
  CustomInput = "custom-input",
  Autocomplete = "autocomplete",
}

export type Filter = {
  name: string;
  label?: string;
  type: FilterType;
  options?: SelectOption[];
  tagRender?: (props: CustomTagProps) => ReactElement;
  input?: ReactNode;
  getSuggestions?: (query: string) => Promise<string[]>;
};

type Props = {
  className?: string;
  disabled?: boolean;
} & Filter;

export const FilterInput = ({
  name,
  label,
  type,
  disabled = false,
  className,
  options = [],
  tagRender,
  input,
  getSuggestions,
}: Props) => {
  switch (type) {
    case FilterType.Radio:
      return (
        <Form.Item
          key={name}
          name={name}
          className={className}
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <RadioFilter options={options} />
        </Form.Item>
      );
    case FilterType.Select:
      return (
        <Form.Item
          key={name}
          name={name}
          className={className}
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            placeholder={label}
            options={[{ value: null, label: "All" }, ...options]}
            disabled={disabled}
            suffixIcon={<Icon component={DropdownArrow} />}
          />
        </Form.Item>
      );
    case FilterType.MultiSelect:
      return (
        <Form.Item
          key={name}
          name={name}
          className={className}
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <MultiSelect placeholder={label} options={options} disabled={disabled} tagRender={tagRender} />
        </Form.Item>
      );
    case FilterType.DateRange:
      return (
        <div className={cn("date-range-filter", className)}>
          {label && (
            <div key={`${name}-header`} className="filter-header">
              {label}
            </div>
          )}
          <div className="date-range-filter__pickers">
            <Form.Item
              key={`${name}-from`}
              name="startDate"
              normalize={(value) => value?.utcOffset(0).startOf("day")}
            >
              <DatePicker format={DATE_FORMAT} placeholder="Start Date" disabled={disabled} />
            </Form.Item>
            <Form.Item
              key={`${name}-to`}
              name="endDate"
              dependencies={["startDate"]}
              normalize={(value) => value?.utcOffset(0).endOf("day")}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue("startDate");
                    if (startDate && value) {
                      if (startDate > value) {
                        return Promise.reject(new Error("The end date can't be earlier than the start date"));
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker format={DATE_FORMAT} placeholder="End Date" disabled={disabled} />
            </Form.Item>
          </div>
        </div>
      );
    case FilterType.UPCs:
      return (
        <Form.Item key={name} name={name} className={className}>
          <UpcList placeholder={label} disabled={disabled} />
        </Form.Item>
      );
    case FilterType.Dictionary:
      return (
        <Form.Item key={name} name={name} className={className}>
          <DictionarySelect dictionaryName={decideDictionary(name)} placeholder={label} disabled={disabled} />
        </Form.Item>
      );
    case FilterType.Text:
      return (
        <Form.Item key={name} name={name} className={className}>
          <Input placeholder={label} disabled={disabled} />
        </Form.Item>
      );
    case FilterType.CustomInput:
      return (
        <Form.Item
          key={name}
          label={label}
          name={name}
          className={className}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          {input}
        </Form.Item>
      );
    case FilterType.Autocomplete:
      return (
        <Form.Item key={name} name={name} className={className}>
          <AutocompleteFilter
            filterName={name}
            getSuggestions={getSuggestions}
            placeholder={label}
            disabled={disabled}
          />
        </Form.Item>
      );
  }
};

export default FilterInput;
