import { Alert, Table } from "antd";
import "./DetailsTable.less";
import { StatusCounters } from "@/components/common/StatusCounters/StatusCounters";

export const DetailsTable = ({ records, counts, columns, getStatusLabel, getStatusColor, title }) => {
  return (
    <div className="activity-details-table">
      <div className="activity-details-table__header">
        <h4 className="activity-details-table__title">{title}</h4>
        <StatusCounters counts={counts} getStatusColor={getStatusColor} getStatusLabel={getStatusLabel} />
      </div>
      <Table
        columns={columns}
        dataSource={records}
        pagination={false}
        rowKey={(record) => record.id}
        expandable={{
          rowExpandable: (record) => !!record.reason,
          expandedRowRender: (record) => (
            <div>
              <Alert className="activity-details-table__alert" message={record.reason} type="error" />
            </div>
          ),
        }}
      />
    </div>
  );
};
