import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";

const options = [
  { label: "Ascending", value: "asc" },
  { label: "Descending", value: "desc" },
];

export const OrderDirectionPicker = (props: Omit<SelectProps, "options">) => {
  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Salesforce Object"
      {...props}
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
