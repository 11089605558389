import ReleaseFilters from "../../../common/ReleaseFilters/ReleaseFilters";
import React from "react";
import { CopyFromTemplate } from "../../../common/CopyFromTemplate/CopyFromTemplate";
import { TemplateType, useAuth } from "../../../../utils";
import "./FiltersForm.less";
import { useDictionaries } from "../../../../api/dictionaries/useDictionaries";
import * as R from "ramda";
import { Form, Modal } from "antd";
import { SaveAsFiltersTemplate } from "../SaveAsTemplate/SaveAsFiltersTemplate";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export const FiltersForm = ({ disabled }) => {
  const form = Form.useFormInstance();

  const { dictionaries } = useDictionaries();
  const { tenants } = dictionaries.openplay;
  const tenantLookup = R.indexBy(R.prop("code"), tenants);

  const tenantCode = Form.useWatch("tenantCode");
  const filter = Form.useWatch(["filter", "configuration"]);

  const feedTenantName = (tenantCode && tenantLookup[tenantCode]?.name) || "empty";

  const applyTemplate = (template) => {
    form.setFieldValue("tenantCode", template.data.tenantCode);
    form.setFieldValue(["filter", "configuration"], template.data.configuration);
  };

  const handleCopyFromTemplate = (template) => {
    const templateTenantName = tenantLookup[template.data.tenantCode].name;
    const needsConfirmation = !filter || tenantCode !== template.data.tenantCode;

    const confirmationText =
      tenantCode !== template.data.tenantCode
        ? `This action will override filters and the feed's tenant name "${feedTenantName}" with the "${templateTenantName}" tenant name from template . Do you want to proceed?`
        : "Are you sure you want to override current settings?";

    if (needsConfirmation) {
      Modal.confirm({
        title: "Copy from a custom template?",
        content: confirmationText,
        onOk: () => {
          applyTemplate(template);
        },
      });
    } else {
      applyTemplate(template);
    }
  };

  const { ability } = useAuth();

  return (
    <div className="filters-form">
      <div className="filters-form__actions">
        <CopyFromTemplate
          filter={{ type: TemplateType.Filters }}
          disabled={disabled}
          onCopy={handleCopyFromTemplate}
        />
        {ability.can(PermissionAction.Create, PermissionSubject.Template) && (
          <SaveAsFiltersTemplate disabled={disabled} />
        )}
      </div>
      <ReleaseFilters disabled={disabled} />
    </div>
  );
};
