import type { Group, Rule } from "./utils";
import type { Config } from "@react-awesome-query-builder/core";
import { GroupContainer } from "./GroupContainer";
import { RuleContainer } from "./RuleContainer";

type Props = { expression: Rule | Group; config: Config };

export const ExpressionContainer = ({ expression, config }: Props) => {
  return expression.type === "group" ? (
    <GroupContainer group={expression} config={config} />
  ) : (
    <RuleContainer rule={expression} config={config} />
  );
};
