import type { SelectProps } from "antd";
import { Select } from "antd";
import { useMemo } from "react";
import { useDictionaries } from "@/api/dictionaries/useDictionaries";
import { dictionaryToOptions } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../Icons";
import { isArray } from "@craco/craco/dist/lib/utils";

type Props = {
  dictionaryName: string;
  placeholder: string;
  disabled: boolean;
} & Pick<SelectProps, "value" | "onChange">;

const prepareValue = (value: any) => {
  if (isArray(value)) {
    return value[0] ?? null;
  }
  return value ? Number(value) : null;
};

export const DictionarySelect = ({ dictionaryName, value, onChange, placeholder }: Props) => {
  const { dictionaries } = useDictionaries();

  const options = useMemo(
    () => (dictionaryName ? dictionaryToOptions(dictionaries?.openplay[dictionaryName]) : []),
    [dictionaries, dictionaryName]
  );

  return (
    <Select
      options={options}
      showSearch
      allowClear
      onChange={onChange}
      value={prepareValue(value)}
      filterOption
      optionFilterProp="label"
      suffixIcon={<Icon component={DropdownArrow} />}
      placeholder={placeholder}
    />
  );
};
