import { DeliveryTableColumns } from "../../../common/DeliveryTable";

import { DATETIME_FORMATTER } from "@/utils";

export const columns = [
  DeliveryTableColumns.Status,
  {
    title: "Delivery Date",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    render: (_, delivery) => DATETIME_FORMATTER.format(new Date(delivery.deliveryDate)),
  },
  {
    title: "Feed",
    dataIndex: "feed_name",
    key: "feed_name",
    render: (_, delivery) => delivery.feed.name,
  },
  {
    title: "Partner",
    dataIndex: "partner_name",
    key: "feed_name",
    render: (_, delivery) => delivery.feed.partner.name,
  },
  { ...DeliveryTableColumns.Download, sorter: false },
  { ...DeliveryTableColumns.Resend, sorter: false },
];
