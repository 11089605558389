import { useRequest } from "ahooks";
import { useEffect } from "react";
import { formatMappings } from "./utils";
import { mappingSettings } from "../../../api/feeds";

export const useDefaultMappings = (from, to) => {
  const { data: mappings, loading, run: loadMappings } = useRequest(mappingSettings, {
    manual: true,
    formatResult: (mappings) => formatMappings(mappings),
    initialData: [],
  });

  useEffect(() => {
    from && to && loadMappings(from, to).catch(console.error);
  }, [from, to, loadMappings]);

  return {
    loading,
    mappings,
  };
};
