import { checkPartnerExistence } from "@/api/partners";

export const isUniqueField = (name, label) => (form) => {
  const excludeId = form.getFieldValue("id");
  return {
    transform: (value) => value?.trim(),
    validateTrigger: ["onBlur"],
    validator: async (_, value) => {
      if (value?.length) {
        const exists = await checkPartnerExistence({ [name]: value }, excludeId);
        return exists
          ? Promise.reject(`A partner with entered ${label} already exists in the system`)
          : Promise.resolve();
      }
    },
  };
};
