import useSWR from "swr";
import { getSettings, updateSettings } from "@/api/settings/index";
import useSWRMutation from "swr/mutation";
import type { Settings } from "./types";

const key = "settings";

export const useSettings = () => useSWR(key, getSettings);

export const useSettingsMutation = () =>
  useSWRMutation(key, (_, { arg: params }: { arg: Partial<Settings> }) => updateSettings(params));
