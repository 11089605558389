import cn from "classnames";
import type { ReactNode } from "react";
import "./Box.less";
import { Space } from "antd";

type Props = {
  readOnly?: boolean;
  bodyReadOnly?: boolean;
  title?: string;
  className?: string;
  children: ReactNode;
  actions?: ReactNode[];
};

export const Box = ({ readOnly, title, className, children, actions, bodyReadOnly }: Props) => (
  <div className={cn("box", readOnly && "box--read-only", className, !!actions && "box--with-actions")}>
    <div className="box__header">
      {title && <h3 className="box__title">{title}</h3>}
      {actions && (
        <div className="box__actions">
          <Space size={16} align="center">
            {actions}
          </Space>
        </div>
      )}
    </div>
    <div className={cn("box__body", bodyReadOnly && "box__body--read-only")}>{children}</div>
  </div>
);
