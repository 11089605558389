import { DynamicPathField } from "../../../common/DynamicPathField/DynamicPathField";
import { Form } from "antd";
import { CONSTANT_MAPPING_PREFIX, EMPTY_VALUE_PLACEHOLDER } from "../../../common/CommonMappings/utils";

export const DynamicPathFormItem = ({ name, label }) => {
  const mappings = Form.useWatch(["mapping", "data"]);
  const variablesFromMappings =
    mappings
      ?.map((mapping) => mapping?.source?.[0])
      .filter(Boolean)
      .filter((value) => !(value === EMPTY_VALUE_PLACEHOLDER || value.startsWith(CONSTANT_MAPPING_PREFIX))) ??
    [];
  const pathVariables = ["timestamp", ...variablesFromMappings];

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            const variablesInValue = value.match(/\{([^{}]*)}/g)?.map((match) => match.slice(1, -1)) ?? [];
            if (variablesInValue.some((variable) => !pathVariables.includes(variable))) {
              return Promise.reject("Some of the variables are incorrect");
            }
            return Promise.resolve();
          },
        },
      ]}
      extra="To insert a variable enclose it in curly braces ({})"
    >
      <DynamicPathField placeholder="Path" variables={pathVariables} />
    </Form.Item>
  );
};
