import { useLayoutEffect } from "react";
import { useInViewport } from "ahooks";

export const getTokens = (value) => {
  const rx = RegExp("{([^{}]*)}?", "dg");
  const result = [];
  let currentMatch;
  while ((currentMatch = rx.exec(value)) !== null) {
    const [start, end] = currentMatch.indices[0];
    const match = currentMatch[0];
    const variable = currentMatch[1];
    const enclosed = /^\{[^{}]*}$/.test(match);
    result.push({
      match,
      variable,
      indices: { start, end },
      enclosed,
    });
  }
  return result;
};

export const useDynamicFieldHeight = (fieldRef, value) => {
  const visible = useInViewport(fieldRef.current);
  return useLayoutEffect(() => {
    if (visible) {
      fieldRef.current.style.height = "0px";
      const scrollHeight = fieldRef.current.scrollHeight;
      fieldRef.current.style.height = scrollHeight + "px";
    }
  }, [value, visible, fieldRef]);
};
