import { useCallback, useContext } from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./useAuth";
import { notification } from "antd";
import { LayoutContext } from "@/contexts/layout";
import type { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  permissions?: [PermissionAction, PermissionSubject][];
  secondaryScreen?: boolean;
  fullWidth?: boolean;
} & Omit<RouteProps, "render">;

export const PrivateRoute = ({
  permissions = [],
  secondaryScreen = false,
  fullWidth = false,
  children,
  ...rest
}: Props) => {
  const { isAuthenticated, ability } = useAuth();
  const isAllowed = permissions.every((permission) => ability.can(...permission));
  const { setSecondary, setPrimary, setFullWidth } = useContext(LayoutContext);

  // @ts-ignore
  const nestedContent: RouteProps["render"] = useCallback(
    ({ location }) => {
      if (!isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      } else if (!isAllowed) {
        notification.error({
          message: "Permission denied",
          placement: "bottomRight",
        });
        return null;
      } else {
        if (secondaryScreen) {
          setSecondary();
        } else {
          setPrimary();
        }
        setFullWidth(fullWidth);
        return children;
      }
    },
    [secondaryScreen, setPrimary, setSecondary, isAllowed, isAuthenticated, children, setFullWidth, fullWidth]
  );

  return <Route {...rest} render={nestedContent} />;
};
