import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { EmailAlertForm } from "@/components/EmailAlerts/Details/EmailAlertForm";
import { useEmailAlert } from "@/api/email-alerts/hooks";

export const EmailAlertPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: alert, isLoading } = useEmailAlert(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <EmailAlertForm initialValues={alert} />;
};
