import { Input } from "antd";

export default function CSVColumnInput({ value, onChange }) {
  const handleChange = (e) => {
    onChange([e.target.value]);
  };

  const _value = value?.[0];

  return <Input value={_value} onChange={handleChange} />;
}
