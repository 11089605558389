import { Box } from "@/components/common/Box/Box";
import { Form } from "antd";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import type { OpenplayObjectType } from "@/types/common";
import { ExportScheduleFields } from "@/components/ExportSchedules/Details/ExportScheduleFields";

type Props = {
  title?: string;
  disabled?: boolean;
  onRemove: () => void;
};

export const ExportSchedule = ({ onRemove, disabled, title }: Props) => {
  const targetObject = Form.useWatch<OpenplayObjectType>("targetObject");
  return (
    <Box title={title} readOnly={disabled} actions={[<IconButton icon={Remove} onClick={onRemove} />]}>
      <ExportScheduleFields targetObject={targetObject} />
    </Box>
  );
};
