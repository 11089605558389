import { Col, Form, Input, Row } from "antd";
import { getUserRoleLabel, UserRole } from "@/utils";
import { Box } from "../../common/Box/Box";
import { TimezoneSelect } from "../../common/TimezoneSelect/TimezoneSelect";
import type { User } from "@/api/users/types";
import { isUniqueName } from "@/utils/validation";
import { checkUserExistence } from "@/api/users";
import { HiddenField } from "@/components/common/HiddenField";

type Props = {
  disabled: boolean;
  initialData: User;
};

export const isUniqueUserName = isUniqueName(
  (name: string, excludeId: string) => checkUserExistence({ name }, excludeId),
  `A user with entered name already exists in the system`
);

export const ProfileForm = ({ disabled, initialData }: Props) => (
  <Row gutter={[16, 16]}>
    <HiddenField name="id" />
    <Col span={24}>
      <Box readOnly={disabled}>
        <Row align="top" gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                {
                  required: true,
                  message: "Field is mandatory.",
                },
                isUniqueUserName,
              ]}
            >
              <Input placeholder="User Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <div>{initialData?.email}</div>
            </Form.Item>
          </Col>
        </Row>
      </Box>
    </Col>
    <Col span={24}>
      <Box readOnly={disabled}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name="role" label="Role">
              <div>{initialData?.role && getUserRoleLabel(initialData?.role)}</div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="timeZone" label="Timezone">
              <TimezoneSelect disabled={disabled} placeholder="Timezone" />
            </Form.Item>
          </Col>
        </Row>
      </Box>
    </Col>
    {initialData?.role === UserRole.Operational && (
      <Col span={24}>
        <Box readOnly={disabled}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="partners"
                label="Assigned To"
                className="assigned-to-input"
                wrapperCol={{ span: 19, offset: 1 }}
                labelCol={{ span: 4 }}
              >
                {initialData?.partners?.map((partner) => (
                  <div key={partner.id}>{partner.name}</div>
                ))}
              </Form.Item>
            </Col>
          </Row>
        </Box>
      </Col>
    )}
  </Row>
);
