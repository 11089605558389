import { Field } from "@/components/common/Form/Field";
import { TextFieldWithVariables } from "@/components/common/RichTextEditor/TextFieldWithVariables";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";

type Props = {
  variables: Variables;
};

export const SpreadsheetFilenameField = ({ variables }: Props) => {
  return (
    <Field
      name={["spreadsheet", "filename"]}
      label="Filename"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      colon={false}
      validateTrigger={["onChange", "onBlur"]}
      rules={[
        { required: true, message: "Field is mandatory" },
        {
          type: "string",
          validateTrigger: "onBlur",
          pattern: /^([\w.-]|<variable>.*?<\/variable>)+$/,
          message: "The filename can only contain letters, numbers, underscores, dashes, and periods",
        },
      ]}
    >
      <TextFieldWithVariables variables={variables} />
    </Field>
  );
};
