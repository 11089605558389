import { Button, Form } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FormattingRule } from "../FormattingRule";
import "./FormattingRuleList.less";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import type { ObjectField } from "@/utils/dynamic-fields/types";
import { useMemo } from "react";
import { getFieldsFromSalesforceObject } from "@/utils/dynamic-fields";
import { useOpenplayCalculatedFields } from "@/api/spreadsheets/hooks";
import { useFieldPath } from "@/components/common/Form/hooks";
import { TabSource } from "@/utils";

type Props = {
  sourceObject: string;
};

export const FormattingRuleList = ({ sourceObject }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const { obj: salesforceObject, isLoading: isLoadingSalesforceObject } = useSalesforceObject(sourceObject);
  const { data: fields, isLoading: isLoadingCalculatedFields } = useOpenplayCalculatedFields();

  const source = Form.useWatch(getAbsolutePath("source")) ?? TabSource.Salesforce;

  const isLoadingDataForFields = isLoadingSalesforceObject || isLoadingCalculatedFields;

  const ruleFields: ObjectField[] = useMemo(
    () =>
      [
        salesforceObject && {
          name: "record",
          label: "Record",
          type: "object" as const,
          children: getFieldsFromSalesforceObject(salesforceObject),
        },
        source === TabSource.OpenPlay &&
          fields.length > 0 && {
            name: "openplay",
            label: "OpenPlay",
            type: "object" as const,
            children: [
              {
                name: "calculated",
                label: "Calculated",
                type: "object" as const,
                children: fields.map((field) => ({
                  name: field.key.replaceAll(".", "__"),
                  label: field.label,
                  type: field.type,
                })),
              },
            ],
          },
      ].filter(Boolean),

    [fields, salesforceObject, source]
  );

  return (
    <FieldList name="rules">
      {(fields, { add, remove, move }) => (
        <div className="formatting-rule-list">
          <Button
            size="small"
            icon={<Icon component={Plus} />}
            onClick={() => add({ background: null, color: null })}
            className="formatting-rule-list__add"
          >
            Add Formatting Rule
          </Button>
          <SortableList onMove={move}>
            {fields.map((field) => (
              <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                <FieldListItem name={field.name}>
                  <FormattingRule fields={ruleFields} isLoadingFields={isLoadingDataForFields} />
                </FieldListItem>
              </SortableListItem>
            ))}
          </SortableList>
        </div>
      )}
    </FieldList>
  );
};
