import type { Status } from "@/utils";
import { Color, getStatusLabel, isActive, truncate } from "@/utils";
import { Tag } from "./Tag/Tag";
import { Tooltip } from "@/components/common/Tooltip";
import type { TagProps } from "antd";

type Props = {
  status: Status;
  tooltipMessage?: string;
} & Pick<TagProps, "onClick">;

const StatusTag = ({ status, tooltipMessage, onClick }: Props) => {
  return tooltipMessage ? (
    <Tooltip title={truncate(tooltipMessage, 150)}>
      <Tag onClick={onClick} color={isActive(status) ? Color.Active : Color.Inactive}>
        {getStatusLabel(status)}
      </Tag>
    </Tooltip>
  ) : (
    <Tag onClick={onClick} color={isActive(status) ? Color.Active : Color.Inactive}>
      {getStatusLabel(status)}
    </Tag>
  );
};

export { StatusTag };

export default StatusTag;
