import React from "react";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import PropTypes from "prop-types";
import { Plus } from "../../common/Icons";
import { Link } from "react-router-dom";
import { FeedTab } from "../../../utils";

export const AddFeedButton = ({ partnerId }) => {
  return (
    <Link
      to={`/partners/${partnerId}/feeds/new/${FeedTab.Summary}`}
      component={({ navigate }) => (
        <Button
          onClick={navigate}
          icon={<Icon component={Plus} />}
          type="primary"
          style={{ marginLeft: "auto" }}
        >
          New Feed
        </Button>
      )}
    />
  );
};

AddFeedButton.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
