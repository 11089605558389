import { Form, Input } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { PathField } from "./PathField";
import { requiredIfNotDraft } from "../../../../utils/validation";

export const S3Fields = ({ fieldName }) => {
  return (
    <>
      <Form.Item
        name={[fieldName, "configuration", "accessKeyId"]}
        label="Access key id"
        rules={[requiredIfNotDraft]}
      >
        <Input placeholder="Access key id" />
      </Form.Item>
      <Form.Item
        name={[fieldName, "configuration", "secretAccessKey"]}
        label="Secret Access Key"
        rules={[requiredIfNotDraft]}
      >
        <Input placeholder="Secret Access Key" />
      </Form.Item>
      <PathField name={[fieldName, "configuration", "path"]} />
    </>
  );
};

S3Fields.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
