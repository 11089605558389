import { Form, Input } from "antd";
import PropTypes from "prop-types";
import { requiredIfNotDraft } from "@/utils/validation";

export const UCSFields = ({ fieldName }) => (
  <>
    <Form.Item name={[fieldName, "configuration", "clientId"]} label="Client ID" rules={[requiredIfNotDraft]}>
      <Input placeholder="Client ID" />
    </Form.Item>
    <Form.Item
      name={[fieldName, "configuration", "clientSecret"]}
      label="Client Secret"
      rules={[requiredIfNotDraft]}
    >
      <Input.Password placeholder="Client Secret" />
    </Form.Item>
  </>
);

UCSFields.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
