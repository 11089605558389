import { useCallback } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useBoolean, useRequest } from "ahooks";
import { IconButton } from "../../common/IconButton/IconButton";
import { Close, Copy } from "../../common/Icons";
import { isUniqueName } from "@/utils/validation";
import { Field } from "@/components/common/Form/Field";
import type { EmailAlert } from "@/api/email-alerts/types";
import { EmailAlertTab } from "@/utils/email-alert-tab";
import { duplicateEmailAlert, checkEmailAlertExistence } from "@/api/email-alerts";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  alert: EmailAlert;
};

type FormValues = Pick<EmailAlert, "name">;

export const DuplicateEmailAlertButton = ({ alert }: Props) => {
  const { ability } = useAuth();
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const [visible, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const { loading, run: trigger } = useRequest(duplicateEmailAlert, {
    manual: true,
    throwOnError: true,
  });

  const handleCancel = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [form, hideModal]);

  const handleSave = useCallback(
    async ({ name }: FormValues) => {
      try {
        const { id } = await trigger(alert.id, name);
        history.push(`/email-alerts/${id}/${EmailAlertTab.Summary}`, {
          editMode: true,
        });
      } catch (error) {
        console.error("unhandled error", error);
      } finally {
        handleCancel();
      }
    },
    [handleCancel, history, alert.id, trigger]
  );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        icon={Copy}
        onClick={showModal}
        disabled={ability.cannot(PermissionAction.Create, PermissionSubject.EmailAlert)}
      />
      <Modal
        open={visible}
        title={`Duplicate email alert '${alert.name}'?`}
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={form.submit}>
              Duplicate
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
      >
        <Form form={form} onFinish={handleSave} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
          <Field
            name="name"
            label="Name"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, message: "Please, provide a name for new email alert" },
              isUniqueName(
                checkEmailAlertExistence,
                "An email alert with entered name already exists in the system"
              ),
            ]}
          >
            <Input placeholder="New Email Alert Name" />
          </Field>
        </Form>
      </Modal>
    </div>
  );
};
