import { api } from "../core";
import type { Campaign, GetObjectCountDto } from "@/api/salesforce-api/types";

export const url = "/api/salesforce";

export const getSalesforceObjectCount = (params: GetObjectCountDto) =>
  params.where ? api.post<any, number>(`${url}/count`, params) : Promise.resolve(0);

export const getCampaigns = () => api.get<any, Campaign[]>(`${url}/campaigns`);

export const getLabelGroups = () => api.get<any, string[]>(`${url}/label-groups`);
export const getCompanies = () => api.get<any, string[]>(`${url}/companies`);
