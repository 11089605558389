import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import FilterableContent from "@/components/Layout/FilterableContent";
import { TableWithPaginationAndSorting } from "@/components/common";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { columns } from "./columns";
import { useGridParams } from "@/utils/useGridParams";
import { prepareParams, useAuth } from "@/utils";
import { useOnDemandReports } from "@/api/on-demand-reports/hooks";
import { OnDemandReportTab } from "@/components/OnDemandReports/Details/OnDemandReportForm";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const filterConfig = [
  { name: "nameContains", label: "Name", type: FilterType.Text },
  { name: "descriptionContains", label: "Description", type: FilterType.Text },
];

export const OnDemandReportList = () => {
  const history = useHistory();

  const { filters, pagination, sort } = useGridParams();

  const { data, isLoading } = useOnDemandReports(prepareParams(filters, pagination, sort));

  const handleEdit = useCallback(
    ({ id }) => history.push(`/on-demand-reports/${id}/${OnDemandReportTab.Summary}`),
    [history]
  );

  const handleCreate = () => {
    history.push(`/on-demand-reports/new/${OnDemandReportTab.Summary}`);
  };

  const { ability } = useAuth();

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>On-Demand Reports</h1>}
        extra={
          ability.can(PermissionAction.Create, PermissionSubject.OnDemandReport)
            ? [
                <Button key="new" type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
                  New On-Demand Report
                </Button>,
              ]
            : []
        }
      />
      <FilterableContent
        formConfig={filterConfig}
        filterName="on-demand-report-filter"
        isFiltersConfigurable={false}
      >
        <TableWithPaginationAndSorting
          rowKey="id"
          onRowClick={handleEdit}
          columns={columns}
          data={data}
          loading={isLoading}
        />
      </FilterableContent>
    </>
  );
};
