import { useParams } from "react-router-dom";
import { Spinner } from "../../common/Spinner/Spinner";
import { PartnerForm } from "./PartnerForm";
import { usePartner } from "@/api/partners/hooks";

export const Partner = () => {
  const { partnerId } = useParams();

  const { isLoading, data: partner } = usePartner(partnerId);

  if (isLoading) {
    return <Spinner />;
  }

  return <PartnerForm defaultValues={partner} />;
};
