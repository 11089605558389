import React from "react";
import "./logo.less";
import { LogoColor, LogoSize } from "./constants";
import { LogoLarge } from "./LogoLarge";
import { LogoSmall } from "./LogoSmall";

const Logo = ({ width, height, size, color }) => (
  <div className="logo-wrapper">
    {size === LogoSize.Large && <LogoLarge width={width} height={height} color={color} />}
    {size === LogoSize.Small && <LogoSmall width={width} height={height} color={color} />}
  </div>
);

export { Logo, LogoColor, LogoSize };
