import { Cascader, Form, Modal } from "antd";
import React, { useCallback, useRef, useState } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { CONSTANT_MAPPING_PREFIX, EMPTY_VALUE_PLACEHOLDER } from "./utils";
import { getDDEXPaths } from "@/utils/mapping-paths/utils";
import { Format } from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import OverrideConfirmation from "./OverrideConfirmation";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../Icons";
import { openplayPathsToOptions } from "@/components/common/ObjectPathPicker/utils";
import { useOpenplayObject } from "@/api/openplay-objects/hooks";
import { RestrictedField } from "@/components/common/RestrictedField";

const runtimeVariablesToOptions = (variables) =>
  variables.map((variable) => ({
    value: `${CONSTANT_MAPPING_PREFIX}%${variable}%`,
    label: `Constant: %${variable}%`,
  }));

export default function PathSelect({
  fieldIndex,
  value,
  onChange,
  format,
  excludeArrays = false,
  canAddConstants = false,
  includeEmpty = false,
  runtimeVariables = [],
  defaults = [],
  objectType = OpenplayObjectType.Release,
}) {
  const { data: openplayObject, error } = useOpenplayObject(objectType);

  const pathOptions =
    format === Format.JSON
      ? openplayPathsToOptions(openplayObject?.root?.children ?? [], excludeArrays)
      : getDDEXPaths(format, excludeArrays);

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  };

  const [search, setSearch] = useState("");

  const ref = useRef();

  const form = Form.useFormInstance();

  const handleAddConstant = useCallback(() => {
    onChange([CONSTANT_MAPPING_PREFIX + search]);
    ref.current?.blur();
  }, [search, onChange]);

  const handleChange = (values) => {
    const str = values?.join("/") || "";
    if (defaults.includes(str)) {
      Modal.confirm({
        title: "Override",
        closable: true,
        icon: null,
        className: "popup-override",
        content: <OverrideConfirmation form={form} targetPath={str} fieldIndex={fieldIndex} />,
        okText: "Override",
        onOk: () => onChange(values),
      });
    } else {
      onChange(values);
    }
  };

  const displayRender = (labels) => {
    const lastLabel = R.last(labels);
    if (lastLabel === EMPTY_VALUE_PLACEHOLDER) {
      return "-- empty --";
    }
    return lastLabel.replace(CONSTANT_MAPPING_PREFIX, "Constant: ");
  };

  const handleSearch = (value) => setSearch(value.trim());

  const options = [...pathOptions, ...runtimeVariablesToOptions(runtimeVariables)];

  if (includeEmpty) {
    options.unshift({
      value: EMPTY_VALUE_PLACEHOLDER,
      label: "-- empty --",
    });
  }

  if (error?.status === 403) {
    return <RestrictedField message="You need 'Read - OpenPlay Object' permission to view this field" />;
  }

  return (
    <Cascader
      ref={ref}
      options={options}
      style={{ width: "100%" }}
      displayRender={displayRender}
      value={value}
      onChange={handleChange}
      showSearch={{ filter }}
      onSearch={handleSearch}
      notFoundContent={null}
      suffixIcon={<Icon component={DropdownArrow} />}
      dropdownRender={(menus) => (
        <>
          {!!search && canAddConstants && (
            <div role="button" className="cascader-add-constant-button" onClick={handleAddConstant}>
              Use '{search}' as static value
            </div>
          )}
          {menus}
        </>
      )}
    />
  );
}

PathSelect.propTypes = {
  format: PropTypes.oneOf(Object.values(Format)),
  excludeArrays: PropTypes.bool,
  canAddConstants: PropTypes.bool,
  runtimeVariables: PropTypes.arrayOf(PropTypes.string),
};
