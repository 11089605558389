import type { Logic } from "@/api/types";
import type { FieldType } from "@/types/salesforce";

export enum BlockType {
  Text = "text",
  Condition = "condition",
  FieldList = "field-list",
  Loop = "loop",
}

export type Content = ContentBlock[];

export type ContentBlock = TextBlock | ConditionBlock | FieldListBlock | LoopBlock;

export type TextBlock = {
  type: BlockType.Text;
  text: string;
};

export type BasicCondition = {
  type: "basic";
  condition: Logic;
  content: Content;
};

export type QueryCondition = {
  type: "query";
  mode: "some" | "all" | "none";
  queryId: string;
  condition: Logic;
  content: Content;
};

export type Condition = BasicCondition | QueryCondition;

export type ConditionBlock = {
  type: BlockType.Condition;
  ifs: Condition[];
  else?: Content;
};

export type LoopBlock = {
  type: BlockType.Loop;
  object: string;
  field: string;
  content: Content;
};

interface BaseField {
  path: string[];
  label?: string;
}

interface PlainField extends BaseField {
  type: Exclude<FieldType, "date" | "datetime">;
}

interface DateField extends BaseField {
  type: "date" | "datetime";
  format?: string;
  timezone?: string;
}

export type Field = PlainField | DateField;

export type FieldListBlock = {
  type: BlockType.FieldList;
  description?: string;
  fields: Field[];
};
