import { ListTooltip, StatusTag } from "../../common";
import { Users } from "../../common/Icons";
import Icon from "@ant-design/icons";
import { Tooltip } from "@/components/common/Tooltip";
import { UserRole } from "@/utils";
import { TagFilter } from "@/components/common/TagFilter";

export const getColumns = (role) =>
  [
    {
      title: "Partner Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      className: "name-of-partner-column",
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      title: "DDEX Party ID",
      dataIndex: "ddexPartyId",
      key: "ddexPartyId",
      className: "ddex-party-id-column",
      sorter: true,
      render: (ddexPartyId) => <Tooltip title={ddexPartyId}>{ddexPartyId}</Tooltip>,
    },
    role >= UserRole.Admin && {
      title: "Assigned Users",
      dataIndex: "users",
      key: "users",
      sorter: true,
      className: "assigned-user-cell",
      render: (users) => (
        <ListTooltip
          data={users}
          dataKey="name"
          alt={"assigned users"}
          src={<Icon className="users-icon" component={Users} />}
        />
      ),
    },
    {
      title: "Primary Contact Name",
      dataIndex: "primaryContactName",
      key: "primaryContactName",
      className: "primary-contact-name-column",
      sorter: true,
      render: (primaryContactName) => <Tooltip title={primaryContactName}>{primaryContactName}</Tooltip>,
    },
    {
      title: "Contacts",
      dataIndex: "contacts",
      className: "contacts-cell",
      render: (_, allData) => {
        return (
          <>
            <Tooltip title={allData.address}>
              <Icon icon="Location" />
            </Tooltip>
            <Tooltip title={allData.primaryContactEmail}>
              <Icon icon="Email" />
            </Tooltip>
            <Tooltip title={allData.primaryContactPhone}>
              <Icon icon="Phone" />
            </Tooltip>
          </>
        );
      },
    },
    {
      title: " Status",
      dataIndex: "status",
      className: "status-partner-column",
      sorter: true,
      render: (_, partner) => (
        <TagFilter filters={{ status: partner.status }}>
          <StatusTag status={partner.status} tooltipMessage={partner.deactivationReason} />
        </TagFilter>
      ),
    },
  ].filter(Boolean);
