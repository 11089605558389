import { Button, Form, Input, Modal, Space } from "antd";
import { useBoolean } from "ahooks";
import { Field } from "@/components/common/Form/Field";
import { useFlowEventImport } from "@/api/flows/hooks";

type FormValues = { name: string };

export const ImportPlatformEventButton = () => {
  const [form] = Form.useForm<FormValues>();

  const { isMutating: pullingCustomEvent, trigger: startPullingCustomEvent } = useFlowEventImport();

  const [isFormOpen, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const handleFinish = async ({ name }: FormValues) => {
    try {
      await startPullingCustomEvent({ name: name.trim() });
      form.resetFields();
      closeForm();
    } catch (error) {
      form.setFields([{ name: "name", errors: [error.message] }]);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    closeForm();
  };

  return (
    <>
      <Button key="platform" type="primary" onClick={openForm}>
        Import Platform Event
      </Button>
      <Modal
        title="Import Platform Event"
        open={isFormOpen}
        footer={
          <Space>
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>
            <Button key="submit" type="primary" onClick={form.submit} loading={pullingCustomEvent}>
              Import
            </Button>
          </Space>
        }
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleFinish}>
          <Field
            name="name"
            rules={[{ required: true, message: "Field is mandatory", transform: (value) => value?.trim() }]}
          >
            <Input placeholder="Event Name" />
          </Field>
        </Form>
      </Modal>
    </>
  );
};
