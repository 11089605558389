import { TableWithPaginationAndSorting } from "../index";
import { DeliveryError } from "./DeliveryError";
import FilterableContent from "../../Layout/FilterableContent";
import { useState } from "react";
import { Button, Modal } from "antd";
import { canBeResent, hasFeedTenantChanged } from "./utils";
import { useResendDeliveries } from "@/components/common/DeliveryTable/hooks";
import "./DeliveryTable.less";

export const DeliveryTable = ({ loading, deliveries, columns, filters }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { loading: resending, resendMany } = useResendDeliveries();

  const resetSelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };

  const handleSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleResendSelected = async () => {
    const selectedDeliveries = deliveries.data?.filter((delivery) => selectedRowKeys.includes(delivery.id));
    if (selectedDeliveries.some(hasFeedTenantChanged)) {
      Modal.confirm({
        title: "Resend deliveries with updated tenants?",
        content: (
          <>
            Some of the deliveries' feed tenants have been changed. Do you wish to send the deliveries with
            the updated tenants?
          </>
        ),
        onOk: async () => {
          await resendMany(selectedRowKeys);
          resetSelectedRowKeys();
        },
      });
    } else {
      await resendMany(selectedRowKeys);
      resetSelectedRowKeys();
    }
  };

  const hasSelectedDeliveries = selectedRowKeys.length > 0;
  return (
    <div className="release-delivery-table">
      <FilterableContent
        formConfig={filters}
        filterName="release-delivery-filter"
        isFiltersConfigurable={false}
        buttons={
          hasSelectedDeliveries
            ? [
                <Button key="resend" loading={resending} onClick={handleResendSelected}>
                  Resend Selected ({selectedRowKeys.length})
                </Button>,
              ]
            : []
        }
      >
        <TableWithPaginationAndSorting
          loading={loading}
          columns={columns}
          defaultSortColumn="deliveryDate"
          defaultOrder="descend"
          data={deliveries}
          expandable={{
            expandedRowRender: (delivery) => <DeliveryError delivery={delivery} />,
            rowExpandable: (delivery) => !!delivery.reason,
          }}
          onPageChange={resetSelectedRowKeys}
          rowSelection={{
            selectedRowKeys,
            onChange: handleSelectChange,
            getCheckboxProps: (delivery) => ({
              disabled: !canBeResent(delivery),
            }),
          }}
        />
      </FilterableContent>
    </div>
  );
};
