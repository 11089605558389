import { ConditionBox } from "@/components/common/ConditionBox";
import { Subject } from "../Subject";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

export const SubjectVariation = () => {
  const { fieldsForConditions } = useEmailMessageContext();
  return (
    <ConditionBox fields={fieldsForConditions}>
      <Subject />
    </ConditionBox>
  );
};
