import useUrlState from "@ahooksjs/use-url-state";
import { useCallback, useRef } from "react";
import * as R from "ramda";
import { ORDER_BY_NAME, ORDER_BY_SORT } from "./const";
import { PAGINATION_PAGE_NUMBER, PAGINATION_PAGE_SIZE } from "./usePagination";

const excludeExtraFields = R.omit([
  ORDER_BY_NAME,
  ORDER_BY_SORT,
  PAGINATION_PAGE_NUMBER,
  PAGINATION_PAGE_SIZE,
]);

export const useFilters = () => {
  const [state, setState] = useUrlState({}, { navigateMode: "replace" });
  const preparedState = useRef({});
  //We need all of it because mesh useUrlState returns *new object* every time, without any memorization
  const newState = excludeExtraFields(state);
  if (!R.equals(newState, preparedState.current)) {
    preparedState.current = newState;
  }
  const setFilters = useCallback((f) => setState({ ...f, [PAGINATION_PAGE_NUMBER]: 1 }), [setState]);

  const appendFilters = useCallback(
    (f) => {
      setFilters({
        ...preparedState.current,
        ...f,
      });
    },
    [setFilters]
  );

  return { filters: preparedState.current, setFilters, appendFilters };
};
