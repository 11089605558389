import * as R from "ramda";

type DictionaryItem = { id?: number | string; name?: string; code?: string };
type Option = { value: string | number; title: string; label: string };

export const dictionaryToOptions = R.pipe(
  R.map<DictionaryItem, Option>(({ id, name, code }) => ({
    value: code || id || name,
    title: name,
    label: name,
  })),
  R.sortBy<Option>(R.compose(R.toLower, R.prop("label")))
);
