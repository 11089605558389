import { useCallback, useContext, useState } from "react";
import { FieldGroupContext, FormErrorsContext } from "./context";
import { joinFieldName } from "./utils";
import { findLastIndex } from "ramda";
import { FieldGroupType } from "./constants";
import type { FieldData, NamePath } from "rc-field-form/es/interface";
import type { FormProps } from "antd";
import { useDebounceFn } from "ahooks";
import type { PathPiece } from "./types";

const fromPieces = (pieces: PathPiece[]) => pieces.map((piece) => piece.prefix);

export const useFieldPath = () => {
  const { path } = useContext(FieldGroupContext);
  const closestListIndex = findLastIndex((piece) => piece.type === FieldGroupType.List, path);
  const closestListItemIndex = findLastIndex((piece) => piece.type === FieldGroupType.ListItem, path);
  const isNotSameList = closestListIndex !== -1 && closestListItemIndex - closestListIndex !== 1;
  const absolutePath = fromPieces(isNotSameList ? path.slice(0, closestListIndex) : path);
  const localPath = fromPieces(closestListItemIndex === -1 ? path : path.slice(closestListItemIndex));
  return {
    absolutePath,
    localPath,
    getAbsolutePath: (name: NamePath = []) => joinFieldName(...absolutePath, name),
  };
};

export const useFormErrors = () => useContext(FormErrorsContext);

export const useFormErrorsState = () => {
  const [fieldsWithErrors, setFieldsWithErrors] = useState<FieldData[]>([]);

  const { run: handleFieldsChange } = useDebounceFn<FormProps["onFieldsChange"]>(
    (_, allFields) => {
      setFieldsWithErrors(allFields.filter((field) => field.errors.length > 0));
    },
    { wait: 500 }
  );

  const resetErrors = useCallback(() => {
    setFieldsWithErrors([]);
  }, []);

  return { fieldsWithErrors, handleFieldsChange, resetErrors };
};
