import { MultiSelect } from "../../../common/MultiSelect/MultiSelect";
import { useRequest } from "ahooks";
import { fetchUsers } from "@/api/users";
import { Status, UserRole } from "@/utils";
import { BaseTag } from "../../../common/BaseTag/BaseTag";

export const UsersSelect = ({ value, onChange, disabled }) => {
  const {
    data: { data: operationalUsers },
  } = useRequest(() => fetchUsers({ role: UserRole.Operational, status: Status.Active }), {
    initialData: { data: [] },
  });
  return (
    <MultiSelect
      value={value}
      onChange={onChange}
      options={operationalUsers}
      fieldNames={{ label: "name", value: "id" }}
      tagRender={({ label, closable, onClose }) => (
        <BaseTag closable={closable} onClose={onClose}>
          {label}
        </BaseTag>
      )}
      notFoundContent="No operational users found"
      placeholder="Select users..."
      disabled={disabled}
    />
  );
};
