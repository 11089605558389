import { StatusCounter } from "./StatusCounter";
import "./StatusCounters.less";

type Props<TStatus extends string> = {
  counts: Record<TStatus, number>;
  getStatusColor: (status: TStatus) => string;
  getStatusLabel: (status: TStatus) => string;
};

export const StatusCounters = <TStatus extends string>({
  counts,
  getStatusColor,
  getStatusLabel,
}: Props<TStatus>) => (
  <div className="status-counters">
    {Object.entries<number>(counts)
      .filter(([_, count]) => count > 0)
      .map(([status, count]) => (
        <StatusCounter
          key={status}
          status={status}
          count={count}
          getColor={getStatusColor}
          getLabel={getStatusLabel}
        />
      ))}
  </div>
);
