import { Space } from "antd";
import { Color, DATE_FORMATTER, truncate } from "@/utils";
import { getFeedScheduleStatusLabel } from "@/utils/feed-schedule-status";
import { StatusTag } from "../../common";
import { DuplicateFeedButton } from "./DuplicateFeedButton";
import { OperationTag } from "../../common/OperationTag/OperationTag";
import { Link } from "react-router-dom";
import { Movement } from "../../common/Icons";
import { IconButton } from "../../common/IconButton/IconButton";
import { TargetObjectTag } from "../../common/TargetObjectTag/TargetObjectTag";
import { DescriptionCell } from "../../common/DescriptionCell";
import { Tooltip } from "@/components/common/Tooltip";
import { Fragment } from "react";
import { TagFilter } from "@/components/common/TagFilter";

export const columns = [
  {
    title: "Feed Name",
    dataIndex: "name",
    sorter: true,
    render: (_, feed) => {
      const truncatedName = truncate(feed.name, 30);
      const isTruncated = feed.name.length > truncatedName.length;
      const Wrapper = isTruncated
        ? (props) => <Tooltip title={feed.name}>{props.children}</Tooltip>
        : Fragment;
      return (
        <Wrapper>
          <Space size={4}>
            <TagFilter filters={{ targetObject: feed.targetObject }}>
              <TargetObjectTag targetObject={feed.targetObject} />
            </TagFilter>
            <TagFilter filters={{ operation: feed.operation }}>
              <OperationTag operation={feed.operation} />
            </TagFilter>
            <span>{truncatedName}</span>
          </Space>
        </Wrapper>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (description) => <DescriptionCell description={description} />,
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    sorter: true,
    render: (_, feed) => (
      <span>
        {DATE_FORMATTER.format(feed.updatedAt)}
        {feed.updatedBy && (
          <>
            , <span style={{ color: Color.Inactive }}>by {feed.updatedBy.name}</span>
          </>
        )}
      </span>
    ),
  },
  {
    title: "Scheduled",
    dataIndex: "scheduled",
    sorter: true,
    render(_, feed) {
      const tooltipText = [
        `Start Date: ${feed.startDate ? DATE_FORMATTER.format(feed.startDate) : "-"}`,
        `End Date: ${feed.endDate ? DATE_FORMATTER.format(feed.endDate) : "-"}`,
      ];
      return (
        <Tooltip title={<span style={{ whiteSpace: "pre" }}>{tooltipText.join("\n")}</span>}>
          {getFeedScheduleStatusLabel(feed.scheduled)}
        </Tooltip>
      );
    },
  },
  {
    title: " Status",
    dataIndex: "status",
    sorter: true,
    render: (_, feed) => (
      <TagFilter filters={{ status: feed.status }}>
        <StatusTag status={feed.status} tooltipMessage={feed.deactivationReason} />
      </TagFilter>
    ),
  },
  {
    title: "Duplicate",
    dataIndex: "duplicate",
    render: (_, allData) => <DuplicateFeedButton data={allData} />,
  },
  {
    title: "Activity",
    key: "activity",
    render: (_, feed) => (
      <div onClick={(e) => e.stopPropagation()}>
        <Link to={`./feeds/${feed.id}/activity`} component={ActivityLink} />
      </div>
    ),
  },
];

const ActivityLink = ({ navigate }) => <IconButton icon={Movement} onClick={navigate} />;
