import { Button, Col } from "antd";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { MappingListItem } from "./MappingListItem";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import type { ComponentProps, ReactNode } from "react";
import "./MappingList.less";

type Props = {
  name: ComponentProps<typeof FieldList>["name"];
  left: ReactNode;
  right: ReactNode;
};

export const MappingList = ({ left, right, name }: Props) => (
  <FieldList name={name}>
    {(fields, { add, remove }) => (
      <div className="mapping-list">
        {fields.map((field) => (
          <FieldListItem key={field.key} name={field.name}>
            <Col span={24}>
              <MappingListItem left={left} right={right} onRemove={() => remove(field.name)} />
            </Col>
          </FieldListItem>
        ))}
        <Button
          className="mapping-list__add"
          size="small"
          onClick={() => add()}
          icon={<Icon component={Plus} />}
        >
          Add Mapping
        </Button>
      </div>
    )}
  </FieldList>
);
