import { api } from "../core";
import type { PaginatedResponse } from "@/api/types";
import type { Delivery, Release, ReleaseDeliveryFilters, ReleaseFilters } from "./types";

const url = "/api/releases";

export const fetchReleases = async (params: ReleaseFilters) =>
  api.get<any, PaginatedResponse<Release>>(url, { params });

export const generateReport = async (params: ReleaseFilters) =>
  api.get("/api/releases-report", {
    params,
    responseType: "blob",
  });

export const fetchRelease = async (id: string) => api.get<any, Release>(`${url}/${id}`);

export const fetchReleaseDeliveries = async (id: string, params: ReleaseDeliveryFilters) =>
  api.get<any, PaginatedResponse<Delivery>>(`${url}/${id}/deliveries`, { params });

export const searchArtists = (name: string) => api.get<any, string[]>(`${url}/artists`, { params: { name } });
