import { Form } from "antd";
import { RecipientList } from "./RecipientList";
import type { ComponentProps } from "react";

type Props = {
  label: string;
  name: string;
} & Pick<ComponentProps<typeof RecipientList>, "rules">;

export const Recipients = ({ label, name, rules }: Props) => (
  <Form.Item label={label} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
    <RecipientList name={name} rules={rules} />
  </Form.Item>
);
