import { Button, Col, Form, Row, Select } from "antd";
import React from "react";
import { capitalize, Operation } from "../../../utils";
import { OpenplayObjectType } from "@/types/common";
import { toOptions } from "../../../utils/toOptions";
import { Box } from "../../common/Box/Box";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../common/Icons";

export const FeedTypeSelector = ({ onSelect }) => {
  const [form] = Form.useForm();

  const targetObject = Form.useWatch("targetObject", form);

  const handleFinish = (values) => {
    const result = {
      targetObject: values.targetObject,
      operation: values.operation ?? Operation.Export,
    };
    onSelect(result);
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        initialValues={{
          operation: Operation.Export,
        }}
        component="div"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Target Object"
              rules={[{ required: true, message: "Field is mandatory." }]}
              name="targetObject"
            >
              <Select
                placeholder="Select target object"
                options={toOptions(Object.values(OpenplayObjectType), capitalize)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
          </Col>
          {targetObject === OpenplayObjectType.Release && (
            <Col span={12}>
              <Form.Item
                label="Operation"
                rules={[{ required: true, message: "Field is mandatory." }]}
                name="operation"
              >
                <Select
                  options={toOptions(Object.values(Operation), capitalize)}
                  placeholder="Select operation"
                  suffixIcon={<Icon component={DropdownArrow} />}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Feed
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
