import { useLocation } from "react-router-dom";
import { Breadcrumb } from "antd";
import * as R from "ramda";
import { useMemo } from "react";
import { FeedTab, GUID_PATTERN, isEmpty, isNotEmpty, PartnerTab, ReportTab } from "@/utils";
import { LinkWithConfirmation } from "@/utils/useNavigationConfirm";
import Icon from "@ant-design/icons";
import { ChevronRight } from "../common/Icons";
import { MessageType } from "@/api/webhooks/types";
import { EmailAlertTab } from "@/utils/email-alert-tab";

const USERS = "/users";
const USERS_DETAILS = `${USERS}/${GUID_PATTERN}`;
const PARTNERS = "/partners";
const PARTNERS_DETAILS = `${PARTNERS}/${GUID_PATTERN}`;
const CREATE_FEED = "new";
const FEEDS = `${PARTNERS_DETAILS}/feeds`;
const FEEDS_DETAILS = `${FEEDS}/${GUID_PATTERN}`;
const RELEASES = "/releases";
const RELEASE_DELIVERY = `${RELEASES}/${GUID_PATTERN}`;
const feedTabs = `(?:${Object.values(FeedTab).join("|")})`;
const TEMPLATES = "/templates";
const TEMPLATES_DETAILS = `${TEMPLATES}/${GUID_PATTERN}`;

const EXPORT_SCHEDULES = "/export-schedules";
const EXPORT_SCHEDULE_DETAILS = `${EXPORT_SCHEDULES}/${GUID_PATTERN}`;
const CREATE_EXPORT_SCHEDULE = `${EXPORT_SCHEDULES}/new`;

const reportTabs = `(?:${Object.values(ReportTab).join("|")})`;
const REPORTS = "/reports";
const REPORTS_LOGS = `${REPORTS}/logs`;
const REPORT_DETAILS = `${REPORTS}/${GUID_PATTERN}`;
const REPORT_LOGS = `${REPORTS}/${GUID_PATTERN}/logs`;
const CREATE_REPORTS = `${REPORTS}/new/${reportTabs}`;

const COLUMN_SETS = "/column-sets";
const COLUMN_SET_DETAILS = `${COLUMN_SETS}/${GUID_PATTERN}`;
const CREATE_COLUMN_SET = `${COLUMN_SETS}/new`;

const WEBHOOKS = "/webhooks";
const webhookType = `(?:${Object.values(MessageType).join("|")})`;
const WEBHOOK_DETAILS = `${WEBHOOKS}/${webhookType}`;

const EMAIL_ALERTS = "/email-alerts";
const emailAlertTabs = `(?:${Object.values(EmailAlertTab).join("|")})`;
const EMAIL_ALERT_DETAILS = `${EMAIL_ALERTS}/${GUID_PATTERN}/${emailAlertTabs}`;
const CREATE_EMAIL_ALERT = `${EMAIL_ALERTS}/new/${emailAlertTabs}`;

const FLOWS = "/flows";
const FLOW_DETAILS = `${FLOWS}/${GUID_PATTERN}`;
const CREATE_FLOW = `${FLOWS}/new`;

const SCRIPTS = "/scripts";
const SCRIPT_DETAILS = `${SCRIPTS}/[\\w-]+`;

const OPENPLAY_OBJECTS = "/openplay-objects";
const OPENPLAY_OBJECT_DETAILS = `${OPENPLAY_OBJECTS}/\\w+`;

const OPENPLAY_QUERIES = "/openplay-queries";
const OPENPLAY_QUERY_DETAILS = `${OPENPLAY_QUERIES}/${GUID_PATTERN}`;
const CREATE_OPENPLAY_QUERY = `${OPENPLAY_QUERIES}/new`;

const ON_DEMAND_REPORTS = "/on-demand-reports";
const ON_DEMAND_REPORT_DETAILS = `${ON_DEMAND_REPORTS}/${GUID_PATTERN}`;
const CREATE_ON_DEMAND_REPORT = `${ON_DEMAND_REPORTS}/new`;

const breadcrumbNameMap = {
  [USERS_DETAILS]: "User Details",
  [`${USERS}/new`]: "User Details",
  [USERS]: "Users",
  [PARTNERS]: "Partners",
  [PARTNERS_DETAILS]: "Partner Details",
  [`${PARTNERS_DETAILS}/${PartnerTab.Summary}`]: "Summary",
  [`${PARTNERS_DETAILS}/activity`]: "Activity",
  [FEEDS]: "Feeds",
  [`${FEEDS}/${CREATE_FEED}/${feedTabs}`]: "Feed Configuration",
  [`${FEEDS_DETAILS}`]: "Feed Configuration",
  [`${FEEDS_DETAILS}/manual-export`]: "Manual Export",
  [`${FEEDS_DETAILS}/deliveries`]: "Deliveries",
  [`${FEEDS_DETAILS}/activity`]: "Activity",
  [RELEASES]: "Releases",
  [RELEASE_DELIVERY]: "Release Delivery",
  [TEMPLATES_DETAILS]: "Template Details",
  [`${TEMPLATES}/new`]: "Template Details",
  [TEMPLATES]: "Templates",
  [EXPORT_SCHEDULE_DETAILS]: "Export Schedule Details",
  [CREATE_EXPORT_SCHEDULE]: "Export Schedule Details",
  [EXPORT_SCHEDULES]: "Export Schedules",
  [REPORT_LOGS]: "Logs",
  [REPORT_DETAILS]: "Report Details",
  [CREATE_REPORTS]: "Report Details",
  [REPORTS]: "Reports",
  [REPORTS_LOGS]: "Logs",
  [COLUMN_SET_DETAILS]: "Column Set Details",
  [CREATE_COLUMN_SET]: "Column Set Details",
  [COLUMN_SETS]: "Column Sets",
  [WEBHOOKS]: "Webhooks",
  [WEBHOOK_DETAILS]: "Webhook Configuration",
  [`${WEBHOOK_DETAILS}/activity`]: "Activity",
  [EMAIL_ALERTS]: "Email Alerts",
  [EMAIL_ALERT_DETAILS]: "Email Alert Details",
  [CREATE_EMAIL_ALERT]: "Email Alert Details",
  [FLOWS]: "Flows",
  [FLOW_DETAILS]: "Flow Details",
  [CREATE_FLOW]: "Flow Details",
  [OPENPLAY_OBJECTS]: "OpenPlay Objects",
  [OPENPLAY_OBJECT_DETAILS]: "OpenPlay Object Details",
  [SCRIPTS]: "Scripts",
  [SCRIPT_DETAILS]: "Script Details",
  [OPENPLAY_QUERIES]: "OpenPlay Queries",
  [OPENPLAY_QUERY_DETAILS]: "OpenPlay Query Details",
  [CREATE_OPENPLAY_QUERY]: "OpenPlay Query Details",
  [ON_DEMAND_REPORTS]: "On-Demand Reports",
  [ON_DEMAND_REPORT_DETAILS]: "On-Demand Report Details",
  [CREATE_ON_DEMAND_REPORT]: "On-Demand Report Details",
};

function urlReducer(acc, segment) {
  if (isEmpty(acc)) {
    return [`/${segment}`];
  } else {
    const prev = acc[acc.length - 1];
    return [...acc, [prev, segment].join("/")];
  }
}

function matchLinks(url) {
  const match = Object.entries(breadcrumbNameMap).find(([pattern]) => new RegExp(`^${pattern}$`).test(url));
  if (match) {
    return { title: match?.[1], url };
  }

  return null;
}

const split = R.pipe(
  R.split("/"),
  R.filter(isNotEmpty),
  R.uniq,
  R.reduce(urlReducer, []),
  R.map(matchLinks),
  R.filter(R.compose(R.not, R.isNil))
);

export default function Breadcrumbs() {
  const location = useLocation();
  return useMemo(() => {
    const segments = split(location.pathname).map(({ title, url }) => (
      <Breadcrumb.Item key={url}>
        <LinkWithConfirmation to={url}>{title}</LinkWithConfirmation>
      </Breadcrumb.Item>
    ));

    if (segments.length < 2) {
      return "";
    }
    return <Breadcrumb separator={<Icon component={ChevronRight} />}>{segments}</Breadcrumb>;
  }, [location.pathname]);
}
