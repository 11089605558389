import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { Button, Col, Form, Row } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { SubjectVariation } from "./SubjectVariation";
import { Subject } from "../Subject";
import "./SubjectVariations.less";

export const SubjectVariations = () => (
  <Form.Item label="Subject" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <FieldList name="subjectVariations">
          {(fields, { add, remove, move }) => (
            <div className="subject-variations__list">
              <Button
                size="small"
                icon={<Icon component={Plus} />}
                onClick={() => add({ condition: null, subject: "" })}
                className="subject-variations__add"
              >
                Add Variation
              </Button>
              <SortableList onMove={move}>
                {fields.map((field) => (
                  <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                    <FieldListItem name={field.name}>
                      <SubjectVariation />
                    </FieldListItem>
                  </SortableListItem>
                ))}
              </SortableList>
            </div>
          )}
        </FieldList>
      </Col>
      <Col span={24}>
        <Subject />
      </Col>
    </Row>
  </Form.Item>
);
