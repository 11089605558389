import { useCallback } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { useBoolean, useRequest } from "ahooks";
import { ReportTab, useAuth } from "@/utils";
import { IconButton } from "../../common/IconButton/IconButton";
import { Close, Copy } from "../../common/Icons";
import type { Report } from "@/api/reports/types";
import { isUniqueName } from "@/utils/validation";
import { duplicateReport, checkReportExistence } from "@/api/reports";
import { Field } from "@/components/common/Form/Field";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  report: Report;
};

type FormValues = Pick<Report, "name">;

export const DuplicateReportButton = ({ report }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const history = useHistory();
  const [visible, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const { loading, run: trigger } = useRequest(duplicateReport, {
    manual: true,
    throwOnError: true,
  });

  const handleCancel = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [form, hideModal]);

  const handleSave = useCallback(
    async ({ name }: FormValues) => {
      try {
        const { id } = await trigger(report.id, name);
        history.push(`/reports/${id}/${ReportTab.Summary}`, {
          editMode: true,
        });
      } catch (error) {
        console.error("unhandled error", error);
      } finally {
        handleCancel();
      }
    },
    [handleCancel, history, report.id, trigger]
  );

  const { ability } = useAuth();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        icon={Copy}
        onClick={showModal}
        disabled={ability.cannot(PermissionAction.Create, PermissionSubject.Report)}
      />
      <Modal
        open={visible}
        title={`Duplicate report '${report.name}'?`}
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={form.submit}>
              Duplicate
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
      >
        <Form form={form} onFinish={handleSave} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
          <Field
            name="name"
            label="Name"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, message: "Please, provide a name for new report" },
              isUniqueName(checkReportExistence, "A report with entered name already exists in the system"),
            ]}
          >
            <Input placeholder="New Report Name" />
          </Field>
        </Form>
      </Modal>
    </div>
  );
};
