import { PageHeader } from "antd";
import { Summary } from "@/components/common/Summary/Summary";
import { useParams } from "react-router-dom";
import type { MessageType } from "@/api/webhooks/types";
import { getSalesforceMessageTypeLabel } from "@/utils/salesforce-message-type";
import { WebhookActivityTable } from "../WebhookActivityTable";

export const WebhookActivityPage = () => {
  const { type } = useParams<{ type: MessageType }>();
  return (
    <>
      <PageHeader
        title="Webhook Activity"
        extra={<Summary items={[{ label: "Action", value: getSalesforceMessageTypeLabel(type) }]} />}
      />
      <WebhookActivityTable messageType={type} />
    </>
  );
};
