import useSWR from "swr";
import type { CreateTemplateDto, TemplateFilterDto, UpdateTemplateDto } from "@/api/templates/types";
import { fetchTemplate, fetchTemplates, saveTemplate } from "@/api/templates/index";
import useSWRMutation from "swr/mutation";

export const useTemplates = (filter: TemplateFilterDto) =>
  useSWR(filter && ["templates", filter], ([, filter]) => fetchTemplates(filter), {
    fallbackData: { data: [] },
  });

export const useTemplate = (id: string) => useSWR(["templates", id], ([, id]) => fetchTemplate(id));

export const useTemplateMutation = (id: string) =>
  useSWRMutation(id ? ["templates", id] : "templates", (_, { arg: dto }: { arg: CreateTemplateDto | UpdateTemplateDto }) =>
    saveTemplate(dto)
  );