import React, { useCallback } from "react";
import { Form } from "antd";
import { getStatusLabel, Status, TemplateType } from "../../../../utils";
import { useBoolean } from "ahooks";
import { prepareMappingItems } from "../../../common/CommonMappings/utils";
import { SaveAsTemplateForm } from "../../../Templates/SaveAsTemplateForm";
import { SaveAsTemplateButton } from "../../../Templates/SaveAsTemplateButton";

export const SaveAsMappingTemplate = ({ disabled }) => {
  const outerForm = Form.useFormInstance();

  const feedStatus = Form.useWatch("status");

  const [isFormVisible, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const handleClick = async () => {
    const fieldsToValidate = outerForm.getFieldValue(["mapping", "data"]).flatMap((_, index) => [
      ["mapping", "data", index, "source"],
      ["mapping", "data", index, "target"],
    ]);
    try {
      await outerForm.validateFields(fieldsToValidate);
      openForm();
    } catch (e) {
      console.error(e);
    }
  };

  const getTemplateFields = useCallback(() => {
    const mappings = prepareMappingItems(outerForm.getFieldValue(["mapping", "data"]));
    return {
      type: TemplateType.Mapping,
      data: {
        targetObject: outerForm.getFieldValue("targetObject"),
        operation: outerForm.getFieldValue("operation"),
        inputFormat: outerForm.getFieldValue(["mapping", "inputFormat"]),
        outputFormat: outerForm.getFieldValue(["mapping", "outputFormat"]),
        mappings,
      },
    };
  }, [outerForm]);

  return (
    <>
      <SaveAsTemplateButton
        onClick={handleClick}
        disabled={disabled}
        unavailable={feedStatus === Status.Draft}
        unavailableTooltip={`Save as template couldn't be completed when status of feed is ${getStatusLabel(
          Status.Draft
        )}`}
      />
      <SaveAsTemplateForm
        isVisible={isFormVisible}
        onClose={closeForm}
        getTemplateFields={getTemplateFields}
      />
    </>
  );
};
