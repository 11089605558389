import { Button, Form, Modal } from "antd";
import type { ComponentProps } from "react";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { atLeastOne } from "@/utils/validation";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { FieldListBlockField } from "./FieldListBlockField";
import { DescriptionField } from "./FieldListDescription";
import { FormProvider } from "@/components/common/Form/FormProvider";
import { Field } from "@/components/common/Form/Field";

export const FieldListForm = ({
  form,
  onFinish,
  ...props
}: ComponentProps<typeof Modal> & Pick<ComponentProps<typeof Form>, "form" | "onFinish">) => {
  return (
    <Modal onOk={form.submit} title="Edit Field List" width={600} {...props}>
      <FormProvider>
        <Form component="div" className="field-list-modal" form={form} onFinish={onFinish}>
          <Field name="description" label="Description" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <DescriptionField />
          </Field>
          <Form.Item label="Fields" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <FieldList name="fields" rules={[atLeastOne("At least one field should be added")]}>
              {(fields, { add, remove, move }, { errors }) => (
                <div className="field-list-modal__fields">
                  <SortableList onMove={move}>
                    {fields.map((field) => (
                      <SortableListItem
                        index={field.name}
                        key={field.key}
                        onRemove={() => remove(field.name)}
                      >
                        <FieldListItem name={field.name}>
                          <FieldListBlockField />
                        </FieldListItem>
                      </SortableListItem>
                    ))}
                  </SortableList>
                  <Button
                    size="small"
                    icon={<Icon component={Plus} />}
                    onClick={() => add()}
                    className="field-list-modal__add"
                  >
                    Add Field
                  </Button>
                  {errors.length > 0 && <Form.ErrorList errors={errors} />}
                </div>
              )}
            </FieldList>
          </Form.Item>
        </Form>
      </FormProvider>
    </Modal>
  );
};
