import { useLabelGroups } from "@/api/salesforce-api/hooks";
import { toOptions } from "@/utils/toOptions";
import type { ComponentProps } from "react";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";

type Props = Omit<ComponentProps<typeof MultiSelect>, "options">;

export const LabelGroupPicker = (props: Props) => {
  const { data, isLoading } = useLabelGroups();
  return <MultiSelect options={toOptions(data)} {...props} disabled={isLoading || props.disabled} />;
};
