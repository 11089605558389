import type { ColumnsType } from "antd/lib/table/interface";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { DescriptionCell } from "@/components/common/DescriptionCell";

export const columns: ColumnsType<OpenplayQuery> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "source",
    render: (_, entry) => <DescriptionCell description={entry.description} />,
  },
];
