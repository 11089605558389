import React from "react";
import { Color, DATE_FORMATTER, DeliveryStatus } from "@/utils";
import { DeliveryStatusTag } from "../DeliveryStatusTag";
import { Col, Popover, Row, Space } from "antd";
import ResendDeliveryButton from "./ResendDeliveryButton";
import DownloadDeliveryButton from "./DownloadDeliveryButton";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FeedCell } from "@/components/common/FeedCell";
import { PartnerCell } from "@/components/common/PartnerCell";

export const ReleaseCell = ({ release }) => (
  <Popover
    title={<span style={{ fontSize: 16 }}>{release.upc}</span>}
    content={
      <>
        <Row gutter={[8, 8]} style={{ maxWidth: 250 }}>
          {release.tenant?.code && (
            <Col span={24}>
              <strong>Tenant: </strong>
              <span>{release.tenant?.name}</span>
            </Col>
          )}
          <Col span={24}>
            <strong>Artist: </strong>
            <span>{release.artist}</span>
          </Col>
          <Col span={24}>
            <strong>Title: </strong>
            <span>{release.title}</span>
          </Col>
          {release.version && (
            <Col span={24}>
              <strong>Version: </strong>
              <span>{release.version}</span>
            </Col>
          )}
          <Col span={24}>
            <strong>Configuration: </strong>
            <span>{release.configuration}</span>
          </Col>
        </Row>
      </>
    }
  >
    {release.upc}
  </Popover>
);

export const ProjectCell = ({ project }) => (
  <Popover
    title={<span style={{ fontSize: 16 }}>{project.projectNumber}</span>}
    content={
      <>
        <Row gutter={[8, 8]} style={{ maxWidth: 250 }}>
          {project.tenant?.code && (
            <Col span={24}>
              <strong>Tenant: </strong>
              <span>{project.tenant?.name}</span>
            </Col>
          )}
          <Col span={24}>
            <strong>Title: </strong>
            <span>{project.title}</span>
          </Col>
        </Row>
      </>
    }
  >
    {project.projectNumber}
  </Popover>
);

export const DeliveryDate = ({ date }) => (date ? DATE_FORMATTER.format(new Date(date)) : "-");

export const DeliveryTableColumns = {
  Status: {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    render: (_, delivery) => (
      <Space size={4} className="delivery-status">
        {delivery.wasDelayed && (
          <>
            <DeliveryStatusTag status={DeliveryStatus.Delayed} className="delivery-status__previous" />
            <ArrowRightOutlined color={Color.Inactive} size={8} className="delivery-status__icon" />
          </>
        )}
        <DeliveryStatusTag status={delivery.status} />
      </Space>
    ),
  },
  Feed: {
    title: "Feed",
    dataIndex: "feed_name",
    key: "feed",
    sorter: true,
    render: (_, delivery) => <FeedCell feed={delivery.feed} />,
  },
  Partner: {
    title: "Partner",
    dataIndex: "partner_name",
    key: "partner",
    sorter: true,
    render: (_, delivery) => <PartnerCell partner={delivery.feed.partner} />,
  },
  FirstDelivery: {
    title: "First Delivery",
    dataIndex: "firstDeliveryDate",
    key: "firstDeliveryDate",
    sorter: true,
    render: (_, delivery) => <DeliveryDate date={delivery.firstDeliveryDate} />,
  },
  LastDelivery: {
    title: "Last Delivery",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    sorter: true,
    render: (_, delivery) => <DeliveryDate date={delivery.deliveryDate} />,
  },
  Download: {
    title: "Download",
    dataIndex: "download",
    render: (_, delivery) => <DownloadDeliveryButton outputFilename={delivery.outputFilename} />,
  },
  Resend: {
    title: "Resend",
    dataIndex: "resend",
    render: (_, delivery) => <ResendDeliveryButton delivery={delivery} />,
  },
  UPC: {
    title: "UPC",
    dataIndex: "upc",
    render: (_, delivery) => <ReleaseCell release={delivery.release} />,
  },
  ProjectNumber: {
    title: "Project Number",
    dataIndex: "projectNumber",
    render: (_, delivery) => <ProjectCell project={delivery.project} />,
  },
};
