import React, { useCallback, useState } from "react";
import "./Login.less";
import { Button, Form, Input, Spin } from "antd";
import { useAuth } from "@/utils";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Logo, LogoColor, LogoSize } from "../Layout/Logo";
import { ERROR_MESSAGES } from "@/api/messages";

function Login() {
  const [error, setError] = useState("");
  const location = useLocation();
  const { isAuthenticated, login, isLoading } = useAuth();
  const [form] = Form.useForm();
  const tryLogin = useCallback(async () => {
    try {
      setError("");
      const { username, password } = await form.validateFields();
      await login(username, password);
    } catch (e) {
      ERROR_MESSAGES.hasOwnProperty(e.errorCode)
        ? setError(ERROR_MESSAGES[e.errorCode])
        : setError("Please enter your email and password.");
    }
  }, [form, login]);

  if (isAuthenticated) {
    return <Redirect to={location.state?.from ?? "/"} />;
  }

  return (
    <div className="login-screen">
      <Spin spinning={isLoading}>
        <Form name="normal_login" className="login-form" form={form}>
          <div className="logo-indent">
            <Logo size={LogoSize.Large} color={LogoColor.Black} width={200} height={60} />
          </div>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Email" size="large" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.Password placeholder="Password" iconRender={() => <div className="show">Show</div>} />
          </Form.Item>
          <div className="login-error">{error}</div>
          <Form.Item>
            <Button type="primary" className="login-form-button" onClick={tryLogin}>
              Login
            </Button>
            <div className="link-wrapper">
              <Link to={"/reset-password"} className="reset-password">
                Reset Password
              </Link>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default Login;
