import { type ReactNode, useContext } from "react";
import { FieldGroupContext } from "./context";
import { FieldGroupType } from "./constants";
import type { NamePath } from "rc-field-form/es/interface";

type FieldGroupProps = {
  prefix: NamePath;
  type?: FieldGroupType;
  children: ReactNode;
};

export const FieldGroup = ({ prefix, type = FieldGroupType.Group, children }: FieldGroupProps) => {
  const { path } = useContext(FieldGroupContext);
  return (
    <FieldGroupContext.Provider value={{ path: [...path, { prefix, type }] }}>
      {children}
    </FieldGroupContext.Provider>
  );
};
