import { Box } from "@/components/common/Box/Box";
import { Col, Input, Row } from "antd";
import { isUniqueName } from "@/utils/validation";
import { Field } from "@/components/common/Form/Field";
import { checkEmailAlertExistence } from "@/api/email-alerts";

const { TextArea } = Input;

type Props = {
  readOnly: boolean;
};

export const EmailAlertSummaryForm = ({ readOnly }: Props) => (
  <Box readOnly={readOnly}>
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Field
          name="name"
          label="Name"
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            { required: true, message: "Field is mandatory" },
            isUniqueName(
              checkEmailAlertExistence,
              "An email alert with entered name already exists in the system"
            ),
          ]}
        >
          <Input placeholder="Name" />
        </Field>
      </Col>
      <Col span={12}>
        <Field name="description" label="Description">
          <TextArea placeholder="Description" rows={2} />
        </Field>
      </Col>
    </Row>
  </Box>
);
