import { useParams } from "react-router-dom";
import type { MessageType } from "@/api/webhooks/types";
import { WebhookForm } from "./WebhookForm";
import { Spinner } from "../../common/Spinner/Spinner";
import { useWebhook } from "@/api/webhooks/hooks";

export const WebhookPage = () => {
  const { type } = useParams<{ type: MessageType }>();

  const { data: webhookWithSettings, isLoading } = useWebhook(type);

  if (isLoading) {
    return <Spinner />;
  }

  return <WebhookForm initialValues={webhookWithSettings} />;
};
