import { useEditor as useTipTapEditor } from "@tiptap/react";
import type { EditorOptions } from "@tiptap/core";
import { useEffect } from "react";

type UseEditorOptions = Pick<EditorOptions, "extensions" | "content"> & {
  onChange?: (html: string) => void;
};

export const useEditor = ({ extensions, content, onChange }: UseEditorOptions) => {
  const editor = useTipTapEditor(
    {
      extensions,
      content,
      onUpdate: ({ editor }) => {
        onChange?.(editor.getHTML());
      },
    },
    [extensions]
  );

  useEffect(() => {
    if (!editor) {
      return;
    }
    const contentFromEditor = editor.getHTML();
    if (content !== contentFromEditor) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return editor;
};
