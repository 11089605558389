import { Box } from "@/components/common/Box/Box";
import { Button, Col, Form, Row, Select } from "antd";
import type { Feed } from "@/api/feeds/types";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import useSWR from "swr";
import { Operation } from "@/types/common";
import { fetchFeedsByOperation } from "@/api/feeds/api";

type FormValues = { feedId: string };

type Props = {
  onSelect: (values: { feed: Pick<Feed, "id" | "name" | "targetObject"> }) => void;
};

export const ExportScheduleTypeSelector = ({ onSelect }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const { data: feeds, isLoading } = useSWR("export-feeds", () => fetchFeedsByOperation(Operation.Export), {
    fallbackData: [],
  });

  const handleFinish = ({ feedId }: FormValues) => {
    const feed = feeds.find((f) => f.id === feedId);
    if (feed) {
      onSelect({ feed });
    }
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        component="div"
        validateMessages={{ required: "Field is mandatory" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="Feed" rules={[{ required: true }]} name="feedId">
              <Select
                style={{ minWidth: 200 }}
                placeholder="Select Feed"
                loading={isLoading}
                options={feeds.map((feed) => ({ value: feed.id, label: feed.name }))}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Export Schedule
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
