import React from "react";
import { ActivityFilters, ActivityTable } from "./ActivityTable/ActivityTable";
import { useGridParams } from "@/utils/useGridParams";
import { useRequest } from "ahooks";
import { prepareParams } from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import { getActivityEntries } from "@/api/activity";
import { ActivityTableColumns } from "./ActivityTable/columns";
import { Tabs } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { defaultStatusesQuery } from "@/components/Activity/utils";

const columns = [
  ActivityTableColumns.Operation,
  ActivityTableColumns.Status,
  ActivityTableColumns.OperationType,
  ActivityTableColumns.StartedAt,
];

const releaseColumns = [
  ...columns,
  ActivityTableColumns.UPC,
  ActivityTableColumns.Tenant,
  ActivityTableColumns.ReleaseArtist,
  ActivityTableColumns.ReleaseTitle,
  ActivityTableColumns.ReleaseLabel,
];

const projectColumns = [
  ...columns,
  ActivityTableColumns.ProjectNumber,
  ActivityTableColumns.Tenant,
  ActivityTableColumns.ProjectTitle,
  ActivityTableColumns.ProjectLabelGroup,
];

const filtersConfig = [ActivityFilters.Status, ActivityFilters.Operation, ActivityFilters.StartedAt];

const releasesFiltersConfig = [...filtersConfig, ActivityFilters.UPC, ActivityFilters.UpcContains];

export const ActivityPage = () => {
  const { filters, pagination, sort } = useGridParams();

  const { targetObject = OpenplayObjectType.Release } = useParams();
  const history = useHistory();

  const { loading, data: entries = [] } = useRequest(
    () => getActivityEntries({ ...prepareParams(filters, pagination, sort), targetObject }),
    { refreshDeps: [filters, pagination, sort, targetObject] }
  );

  const handleTabChange = (tab) => {
    history.replace({ pathname: `/activity/${tab}`, search: defaultStatusesQuery });
  };

  return (
    <>
      <h1>Activity</h1>
      <Tabs activeKey={targetObject} onChange={handleTabChange} destroyInactiveTabPane>
        <Tabs.TabPane key={OpenplayObjectType.Release} tab="Releases">
          <ActivityTable
            loading={loading}
            entries={entries}
            columns={releaseColumns}
            filters={releasesFiltersConfig}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={OpenplayObjectType.Project} tab="Projects">
          <ActivityTable
            loading={loading}
            entries={entries}
            columns={projectColumns}
            filters={filtersConfig}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
