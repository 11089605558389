import type { Rule } from "./utils";
import { formatValue, getField, getFieldLabel, getHumanReadableOperator, isUnaryOperator } from "./utils";
import type { Config } from "@react-awesome-query-builder/core";

type Props = {
  rule: Rule;
  config: Config;
};

export const RuleContainer = ({ rule, config }: Props) => {
  const field = getField(config, rule.field.split("."));
  return (
    <div className="readonly-condition-rule">
      <span>
        <span className="readonly-condition-rule__field">
          {field ? getFieldLabel(config, rule.field.split(".")) : rule.field}
        </span>{" "}
        {getHumanReadableOperator(rule.operator)}{" "}
        {!isUnaryOperator(rule.operator) && (
          <>
            {" "}
            <span className="readonly-condition-rule__value">{formatValue(rule.value, field)}</span>
          </>
        )}
      </span>
    </div>
  );
};
