import type { SalesforceQuery } from "@/api/reports/types";
import "./ParameterSourceList.less";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { Button } from "antd";
import { ParameterSourceListItem } from "@/components/OpenplayQueries/OpenplayQueryList/OpenplayQueryListItem/ParameterSourceListItem";

type Props = {
  parameters: string[];
  salesforceQueries: SalesforceQuery[];
};

export const ParameterSourceList = ({ parameters, salesforceQueries }: Props) => {
  return (
    <FieldList name="parameterSources">
      {(fields, { add, remove }) => (
        <div className="parameter-source-list">
          <Button
            className="parameter-source-list__add"
            size="small"
            icon={<Icon component={Plus} />}
            onClick={() => add({})}
          >
            Add Source
          </Button>
          {fields.map((field) => (
            <FieldListItem key={field.key} name={field.name}>
              <ParameterSourceListItem
                parameters={parameters}
                salesforceQueries={salesforceQueries}
                onRemove={() => remove(field.name)}
              />
            </FieldListItem>
          ))}
        </div>
      )}
    </FieldList>
  );
};
