import { SortableContainer } from "react-sortable-hoc";
import type { PropsWithChildren } from "react";
import "./SortableList.less";

const Container = SortableContainer<PropsWithChildren>(({ children }) => (
  <div className="sortable-list">{children}</div>
));

type Props = PropsWithChildren<{
  onMove?: (oldIndex: number, newIndex: number) => void;
}>;

const SortableList = ({ children, onMove }: Props) => (
  <Container
    children={children}
    helperClass="sortable-list-item--ghost"
    useDragHandle
    onSortEnd={({ oldIndex, newIndex }) => onMove?.(oldIndex, newIndex)}
    lockAxis="y"
  />
);

export { SortableList };
