import { Alert, Button, Form, PageHeader, Popconfirm, Tooltip } from "antd";
import { FormActions } from "@/components/common/FormActions";
import { Box } from "@/components/common/Box/Box";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { useOpenplayQueryMutation } from "@/api/openplay-queries/hooks";
import { deleteOpenplayQuery } from "@/api/openplay-queries";
import { HiddenField } from "@/components/common/HiddenField";
import { useFormActions } from "@/utils/useFormActions";
import { OpenplayQueryFields } from "../OpenplayQueryFields";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  initialValues?: Partial<OpenplayQuery>;
  isEditingByDefault?: boolean;
};

type FormValues = Partial<OpenplayQuery>;

export const OpenplayQueryForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const { trigger: save } = useOpenplayQueryMutation(initialValues.id);

  const {
    editing,
    submitting,
    handleFinish,
    handleFinishFailed,
    handleSubmit,
    handleCancel,
    handleEdit,
    handleValueChange,
    handleDelete,
  } = useFormActions<FormValues>({
    onSave: save,
    onDelete: deleteOpenplayQuery,
    form,
    listingUrl: "/openplay-queries",
    editUrl: (id) => `/openplay-queries/${id}`,
    initialValues,
    isEditingByDefault,
  });

  const title = initialValues.name ?? "New OpenPlay Query";
  const isInUse =
    initialValues.reportOpenplayQueries?.length > 0 ||
    initialValues.onDemandReportOpenplayQueries?.length > 0;
  const isDeletable = !!initialValues.id && !editing && !isInUse;

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.OpenplayQuery);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.OpenplayQuery);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            <FormActions
              isEditing={editing}
              onSave={handleSubmit}
              isSaving={submitting}
              canEdit={canEdit}
              onEdit={handleEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the OpenPlay query?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
            {isInUse && !editing && (
              <Tooltip title="This OpenPlay query is currently in use by one or more reports and cannot be deleted">
                <Button disabled>Delete</Button>
              </Tooltip>
            )}
          </>
        }
      />
      <Form
        form={form}
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        onValuesChange={handleValueChange}
        labelAlign="left"
        initialValues={initialValues}
      >
        <HiddenField name="id" />
        <Box readOnly={!editing}>
          {isInUse && (
            <Alert
              type="warning"
              style={{ marginBottom: 20 }}
              message="The OpenPlay query is currently in use by one or more reports. Editing it will affect all reports that reference it."
            />
          )}
          <OpenplayQueryFields />
        </Box>
      </Form>
    </>
  );
};
