import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, Modal, Typography } from "antd";
import { FilterInput } from "../../common";
import Icon from "@ant-design/icons";
import { prepareFilters, useFilters, useShowModal } from "@/utils";
import { FiltersConfigurationForm } from "./FiltersConfigurationForm/FiltersConfigurationForm";
import PropTypes from "prop-types";
import ls from "local-storage";
import classNames from "classnames";
import * as R from "ramda";
import { Close, Filters } from "../../common/Icons";
import { FilterType } from "@/components/common/FilterInput/FilterInput";

const { Text, Paragraph } = Typography;

const FILTERS_CONFIG_DATASTORE_PREFIX = "filtersConfig-";
const getDateFilters = (filters) =>
  filters
    .filter((filter) => filter.type === "date" || filter.type === FilterType.DateRange)
    .flatMap((filter) => (filter.type === FilterType.DateRange ? ["startDate", "endDate"] : filter.name));

export default function FilterableSidebar({
  availableFilters,
  filterName,
  defaultFilters = [],
  isFiltersConfigurable = true,
}) {
  const [form] = Form.useForm();
  const { filters, setFilters } = useFilters();

  const [enabledFilters, setEnabledFilters] = useState(() => {
    const lsData = ls.get(`${FILTERS_CONFIG_DATASTORE_PREFIX}${filterName}`);
    const defaultValue = isFiltersConfigurable ? defaultFilters : R.pluck("name", availableFilters);

    return R.uniq(R.concat(lsData || defaultValue, R.keys(filters)));
  });
  const config = useMemo(
    () => (isFiltersConfigurable ? prepareFilters(availableFilters, enabledFilters) : availableFilters),
    [isFiltersConfigurable, availableFilters, enabledFilters]
  );

  const onFiltersConfigChange = useCallback(
    (config) => {
      if (isFiltersConfigurable) {
        ls.set(`${FILTERS_CONFIG_DATASTORE_PREFIX}${filterName}`, config);
        setEnabledFilters(config);
        //exclude disabled filters from filter set
        const f = R.mapObjIndexed((val, key) => (config.includes(key) ? val : undefined))(filters);
        setFilters(f);
      }
    },
    [isFiltersConfigurable, filterName, filters, setFilters]
  );

  const onApply = useCallback(
    (values) => {
      getDateFilters(availableFilters).forEach((f) => {
        if (R.isNil(values[f])) {
          return values[f];
        }
        return (values[f] =
          f === "startDate"
            ? values[f].utcOffset(0).startOf("day").toISOString()
            : values[f].utcOffset(0).endOf("day").toISOString());
      });
      setFilters(values);
    },
    [availableFilters, setFilters]
  );

  const [modalVisible, showModal, hideModal] = useShowModal();
  const [updatedFiltersConfig, setUpdatedFiltersConfig] = useState(enabledFilters);

  useEffect(() => {
    setUpdatedFiltersConfig(enabledFilters);
  }, [enabledFilters]);

  const initialFilters = useMemo(
    () =>
      config.reduce(
        (acc, filter) => ({
          ...acc,
          ...(filter.type === FilterType.DateRange
            ? { startDate: null, endDate: null }
            : { [filter.name]: filter.name.includes("[]") ? [] : null }),
        }),
        {}
      ),
    [config]
  );
  const onReset = useCallback(() => {
    setFilters(initialFilters);
    form.resetFields();
  }, [form, setFilters, initialFilters]);

  useEffect(() => {
    const values = R.clone(filters);
    getDateFilters(availableFilters).forEach((field) => {
      values[field] = R.isNil(values[field]) ? null : moment.utc(values[field]);
    });
    form.setFieldsValue(values);
  }, [filters, availableFilters, form]);

  return (
    <div>
      {isFiltersConfigurable && (
        <>
          <Paragraph
            onClick={showModal}
            className={classNames({
              "modal-visible": modalVisible,
              "advanced-filters": true,
            })}
          >
            <Icon className="advanced-filters__icon" component={Filters} />
            <Text className="advanced-filters__heading">Filter Settings</Text>
          </Paragraph>
          <Modal
            title="Edit Filters"
            visible={modalVisible}
            onOk={() => {
              onFiltersConfigChange(updatedFiltersConfig);
              hideModal();
            }}
            onCancel={hideModal}
            destroyOnClose
            maskClosable={false}
            okText="Save"
            closeIcon={<Close />}
          >
            <FiltersConfigurationForm
              availableFilters={availableFilters}
              enabledFilters={updatedFiltersConfig}
              onChange={setUpdatedFiltersConfig}
            />
          </Modal>
        </>
      )}
      <Form
        initialValues={initialFilters}
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onApply}
        component="div"
      >
        <div className="filters-list">
          {config.map((props) => (
            <FilterInput key={props.name} {...props} />
          ))}
        </div>
        <div className="filterable-sidebar__actions">
          <Button onClick={onReset}>Reset</Button>
          <Button type="primary" onClick={form.submit}>
            Apply
          </Button>
        </div>
      </Form>
    </div>
  );
}

FilterableSidebar.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  filterName: PropTypes.string.isRequired,
  defaultFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFiltersConfigurable: PropTypes.bool,
};
