import { Select } from "antd";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useMount, useRequest } from "ahooks";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "./Icons";

export default function Autocomplete({ multiselect = true, withEmpty = false, search, ...rest }) {
  const { loading, data, mutate, run, cancel } = useRequest(search, {
    initialData: [],
    manual: true,
  });
  useMount(() => {
    if (withEmpty) {
      run("").then(console.log).catch(console.error);
    }
  });

  const searchHandle = useCallback(
    (value) => {
      cancel();
      mutate([]);
      run(value).then(console.log).catch(console.error);
    },
    [run, mutate, cancel]
  );

  return (
    <Select
      {...rest}
      options={data}
      loading={loading}
      filterOption={false}
      onSearch={searchHandle}
      suffixIcon={<Icon component={DropdownArrow} />}
      {...(multiselect
        ? {
            mode: "multiple",
            className: "select-tags-control",
            labelInValue: true,
          }
        : { showArrow: false, showSearch: true })}
    />
  );
}

Autocomplete.propTypes = {
  search: PropTypes.func.isRequired,
  multiselect: PropTypes.bool,
  withEmpty: PropTypes.bool,
};
