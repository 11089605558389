import { AntdConfig } from "@react-awesome-query-builder/antd";
import Button from "./widgets/Button";
import type { Config } from "@react-awesome-query-builder/ui";
import { FieldCascader } from "./widgets/FieldCascader";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";
import ButtonGroup from "./widgets/ButtonGroup";
import { Conjunctions } from "./widgets/Conjunctions";
import moment from "moment/moment";
import { ValueSources } from "./ValueSources";
import { relativeDateFuncs } from "./functions";

const formatDateValue = (val: any) => moment(val).format("YYYY-MM-DD");
const formatDatetimeValue = (val: any) => moment(val).toISOString();

export const CustomAntdConfig: Config = {
  ...AntdConfig,
  widgets: {
    ...AntdConfig.widgets,
    date: {
      ...AntdConfig.widgets.date,
      jsonLogic: formatDateValue,
      dateFormat: "MM/DD/YYYY",
      valueFormat: "YYYY-MM-DD",
    },
    datetime: {
      ...AntdConfig.widgets.datetime,
      jsonLogic: formatDatetimeValue,
      dateFormat: "MM/DD/YYYY",
      valueFormat: "YYYY-MM-DDTHH:mm:ssZ",
    },
    multiselect: {
      ...AntdConfig.widgets.multiselect,
      // @ts-expect-error
      factory: ({ value, setValue, readonly, listValues, placeholder }) => (
        <MultiSelect
          value={value}
          onChange={setValue}
          disabled={readonly}
          options={listValues}
          fieldNames={{ label: "title", value: "value" }}
          placeholder={placeholder}
        />
      ),
    },
  },
  funcs: relativeDateFuncs,
  settings: {
    ...AntdConfig.settings,
    valueSourcesInfo: {
      value: { label: "Absolute" },
      func: {
        label: "Relative",
        widget: "func",
      },
    },
    renderValueSources: (props) => <ValueSources {...props} />,
    funcPlaceholder: "Select relative date",
    maxNesting: 3,
    canLeaveEmptyGroup: false,
    renderSize: "medium",
    renderButton: (props) => <Button {...props} />,
    renderButtonGroup: ({ children }) => <ButtonGroup>{children}</ButtonGroup>,
    renderField: (props) => <FieldCascader {...props} />,
    renderConjs: (props) => <Conjunctions {...props} />,
  },
};
