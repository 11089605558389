import { useRequest } from "ahooks";
import { checkAcknowledgementSweepStatus, processAcknowledgements } from "@/api/deliveries";
import { openNotification } from "@/components/common/CommonNotification";
import { DeliveryStatusTag } from "@/components/common/DeliveryStatusTag";
import { Button, Progress } from "antd";
import "./AcknowledgementSweep.less";

export const AcknowledgementSweep = () => {
  const { run: runProcessing, loading } = useRequest(processAcknowledgements, {
    manual: true,
    onSuccess: () => {
      openNotification({ message: "The sweep for acknowledgements has been started" });
    },
  });

  const { data: status, refresh: refreshStatus } = useRequest(checkAcknowledgementSweepStatus, {
    pollingInterval: 5000,
    pollingWhenHidden: false,
    initialData: { active: false },
  });

  const handleClick = async () => {
    await runProcessing();
    await refreshStatus();
  };

  return (
    <div className="acknowledgement-sweep">
      <Button type="primary" onClick={handleClick} loading={loading || status.active}>
        Process Acknowledgements
      </Button>
      {status.active && (
        <div className="acknowledgement-sweep__status">
          <div className="acknowledgement-sweep__message">
            <span>Processing deliveries with status(-es):</span>
            <ul>
              {status.job.data.statuses.map((status) => (
                <li key={status}>
                  <DeliveryStatusTag status={status} className="" />
                </li>
              ))}
            </ul>
          </div>
          <Progress
            percent={status.job.progress}
            format={(percent) => percent.toFixed(1) + "%"}
            size="small"
            status="active"
          />
        </div>
      )}
    </div>
  );
};
