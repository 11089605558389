import type { Recipient, Sender } from "@/api/email-alerts/types";

export type PaginatedResponse<T> = {
  data?: T[];
  page?: number;
  limit?: number;
  totalCount?: number;
};

export type Pagination = {
  page?: number;
  limit?: number;
  sort_by?: string;
  order?: "ASC" | "DESC";
};

export type Logic = Record<string, any>;

export type EmailMessage = {
  from: Sender;
  subject: string;
  to: Recipient[];
  cc: Recipient[];
  body: string;
  addSenderToBcc?: boolean;
};

export enum RecurrenceInterval {
  Daily = "daily",
  Weekly = "weekly",
  Monthly = "monthly",
  Custom = "custom",
}

export type Recurrency = {
  interval: RecurrenceInterval;
  timeOfDay?: string;
  daysOfWeek?: number[];
  daysOfMonth?: number[];
  cronExpression?: string;
  timezone: string;
};
