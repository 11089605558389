import * as R from "ramda";
import { memoizeWith } from "ramda";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import type { Fields, FieldStruct, ListValues, SimpleField } from "@react-awesome-query-builder/core";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import type * as Salesforce from "@/types/salesforce";
import { getFieldsFromSalesforceObject, getSimpleField } from "@/utils/dynamic-fields";
import type { Field, FieldType } from "@/utils/dynamic-fields/types";

const excludeFieldTypes: Salesforce.FieldType[] = ["id", "textarea", "address"];

export const getDateField = (label: string, type: "date" | "datetime" = "date"): SimpleField => ({
  label: label,
  type,
  excludeOperators: ["between", "not_between"],
  valueSources: ["value", "func"],
});

export const getBooleanField = (label: string): SimpleField => ({
  label: label,
  type: "boolean",
  valueSources: ["value"],
  operators: ["equal"],
});

export const getSelectField = (label: string, options: ListValues): SimpleField => ({
  label: label,
  type: "select",
  valueSources: ["value"],
  listValues: options,
});

export const getMultiselectField = (label: string, options: ListValues): SimpleField => ({
  label: label,
  type: "multiselect",
  valueSources: ["value"],
  listValues: options,
});

export const getNumberField = (label: string): SimpleField => ({
  label: label,
  type: "number",
  valueSources: ["value"],
  excludeOperators: ["between", "not_between"],
});

export const getTextField = (label: string): SimpleField => ({
  label: label,
  type: FilterType.Text,
  valueSources: ["value"],
  excludeOperators: ["starts_with", "ends_with", "proximity"],
});

export const getObjectField = (label: string, subfields: Fields): FieldStruct => ({
  type: "!struct",
  label,
  subfields,
});

export const getFieldsForQueryBuilder = (fields: Field[], includeFieldTypes: FieldType[] = []): Fields =>
  R.fromPairs(
    fields
      .filter((field) => (includeFieldTypes.length > 0 ? includeFieldTypes.includes(field.type) : true))
      .map((field) => [
        field.name,
        field.type === "object"
          ? getObjectField(field.label, getFieldsForQueryBuilder(field.children, includeFieldTypes))
          : getSimpleField(field),
      ])
  );

export const getSalesforceObjectQueryBuilderFields = memoizeWith<
  (obj: SalesforceObject, nesting: number) => Fields
>(
  (obj, nesting = 0) => `${obj.name}:${obj.updatedAt}:${nesting}`,
  (obj, nesting = 0) => {
    const fields = getFieldsFromSalesforceObject(obj, [], excludeFieldTypes, nesting);
    return getFieldsForQueryBuilder(fields);
  }
);
