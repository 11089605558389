import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import { useDownload } from "@/utils/useDownload";
import { deleteFeed, feedLogExport } from "@/api/feeds";
import { useRequest } from "ahooks";
import { Close } from "../../common/Icons";
import { Operation, useAuth } from "@/utils";
import { ExtraActions } from "@/components/common/ExtraActions/ExtraActions";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export const FeedActions = ({ feed, showDeleteButton = true, isManualExportEnabled = true, onDeleted }) => {
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const { loading, run: runDeleteFeed } = useRequest(deleteFeed, {
    manual: true,
    onSuccess: () => {
      setConfirmVisible(false);
      onDeleted();
    },
  });
  const showModal = useCallback(() => setConfirmVisible(true), []);
  const handleOk = useCallback(() => runDeleteFeed(feed?.id), [runDeleteFeed, feed]);
  const handleCancel = useCallback(() => setConfirmVisible(false), []);

  const { start: feedLogDownload } = useDownload(feedLogExport, {
    message: "File is being generated. Download will start automatically",
  });

  const { ability } = useAuth();

  const canReadActivity = ability.can(PermissionAction.Read, PermissionSubject.Transfer);

  const menuItems = [
    showDeleteButton && { key: "delete", label: "Delete", onClick: () => showModal() },
    feed?.id &&
      feed.operation === Operation.Export && {
        key: "manual-export",
        disabled: !isManualExportEnabled,
        label: <Link to="./manual-export">Manual Export</Link>,
      },
    canReadActivity && {
      key: "activity",
      label: <Link to="./activity">Feed Activity</Link>,
    },
    canReadActivity && {
      key: "feed-log",
      onClick: () => feedLogDownload(feed.id),
      label: "Download Feed Activity",
    },
  ].filter(Boolean);

  return (
    <>
      <ExtraActions items={menuItems} />
      <Modal
        open={isConfirmVisible}
        title="Delete feed"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" loading={loading} onClick={handleOk}>
            Delete
          </Button>,
          <Button onClick={handleCancel}>Cancel</Button>,
        ]}
        closeIcon={<Close />}
      >
        <p>Are you sure you want to delete '{feed?.name}' feed?</p>
      </Modal>
    </>
  );
};

FeedActions.propTypes = {
  feed: PropTypes.object,
  showDeleteButton: PropTypes.bool,
  isManualExportEnabled: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
};
