import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import type { Fields } from "@react-awesome-query-builder/core";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";

type EmailMessageContextType = {
  variables: Variables;
  sourceObject?: string;
  fieldsForConditions?: Fields;
  parentType?: "report" | "email-alert";
};

const EmailMessageContext = createContext<EmailMessageContextType>({ variables: [] });

export const useEmailMessageContext = () => useContext(EmailMessageContext);

export const EmailMessageContextProvider = ({
  children,
  ...ctx
}: PropsWithChildren<EmailMessageContextType>) => (
  <EmailMessageContext.Provider value={ctx}>{children}</EmailMessageContext.Provider>
);
