import type { EntityProcessingResult } from "@/api/webhooks/types";
import { ProcessingStatus } from "@/utils/processing-status";

export const getEntityProcessingStatus = (entry: EntityProcessingResult) => {
  if (entry.log.some((log) => log.type === "error")) {
    return ProcessingStatus.Failed;
  }
  if (entry.log.some((log) => log.type === "warning")) {
    return ProcessingStatus.FinishedWithWarnings;
  }
  return ProcessingStatus.Finished;
};
