import { Form, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { toOptions } from "@/utils/toOptions";
import { FlowActionType } from "@/api/flows/types";
import { getFlowActionLabel } from "@/components/Flows/getFlowActionLabel";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { EmailAlertPicker } from "@/components/EmailAlerts/EmailAlertPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import "./FlowAction.less";
import { ScriptPicker } from "@/components/Flows/Details/FlowActions/ScriptPicker";

type Props = {
  index: number;
  sourceObject: string;
};

export const FlowAction = ({ sourceObject, index }: Props) => {
  const { getAbsolutePath } = useFieldPath();

  const selectedActionType = Form.useWatch(getAbsolutePath("type"));

  return (
    <div className="flow-action">
      <div className="flow-action__index">{`#${index + 1}`}</div>
      <Field name="type" rules={[{ required: true, message: "Please, select an action type" }]}>
        <Select
          placeholder="Select action type"
          options={toOptions(Object.values(FlowActionType), getFlowActionLabel)}
          suffixIcon={<Icon component={DropdownArrow} />}
        />
      </Field>
      {selectedActionType === FlowActionType.SendEmailAlert && (
        <Field
          name="emailAlertId"
          rules={[{ required: true, message: "Please, select an email alert to send" }]}
        >
          <EmailAlertPicker
            placeholder="Select email alert"
            sourceObject={sourceObject}
            notFoundContent="No email alerts found for the selected event"
          />
        </Field>
      )}
      {selectedActionType === FlowActionType.RunScript && (
        <Field name="scriptKey" rules={[{ required: true, message: "Please, select a script to run" }]}>
          <ScriptPicker
            placeholder="Select script"
            sourceObject={sourceObject}
            notFoundContent="No scripts found for the selected event"
          />
        </Field>
      )}
    </div>
  );
};
