import { Operation, OpenplayObjectType } from "@/types/common";

export enum Format {
  DDEX_3_8 = "ddex_3.8",
  DDEX_4_1 = "ddex_4.1",
  DDEX_4_2 = "ddex_4.2",
  CSV = "csv",
  JSON = "json",
}

export const formatNameLookup = {
  [Format.DDEX_3_8]: "DDEX 3.8",
  [Format.DDEX_4_1]: "DDEX 4.1",
  [Format.DDEX_4_2]: "DDEX 4.2",
  [Format.CSV]: "CSV",
  [Format.JSON]: "JSON",
};

export const getFormatName = (format: Format) => formatNameLookup[format];

export const mappingTemplates = [
  {
    operation: Operation.Export,
    targetObjects: [OpenplayObjectType.Release],
    formats: [Format.JSON, Format.DDEX_3_8],
  },
  {
    operation: Operation.Export,
    targetObjects: [OpenplayObjectType.Release],
    formats: [Format.JSON, Format.DDEX_4_1],
  },
  {
    operation: Operation.Export,
    targetObjects: [OpenplayObjectType.Release],
    formats: [Format.JSON, Format.DDEX_4_2],
  },
  {
    operation: Operation.Export,
    targetObjects: [OpenplayObjectType.Release, OpenplayObjectType.Project],
    formats: [Format.JSON, Format.CSV],
  },
  {
    operation: Operation.Import,
    targetObjects: [OpenplayObjectType.Release],
    formats: [Format.DDEX_4_1, Format.JSON],
  },
  {
    operation: Operation.Import,
    targetObjects: [OpenplayObjectType.Release],
    formats: [Format.DDEX_4_1, Format.CSV],
  },
];
