import { Form, Modal } from "antd";
import { FieldList, FieldListItem } from "../../../common/Form/FieldList";
import { Plus } from "../../../common/Icons";
import { FullWidthButton } from "../../../common/FullWidthButton/FullWidthButton";
import { truncate } from "@/utils";
import { SpreadsheetTab } from "./SpreadsheetTab";
import { EditableTabs } from "../../../common/EditableTabs";
import { useFormErrors } from "@/components/common/Form/hooks";
import { atLeastOne } from "@/utils/validation";

const { confirm } = Modal;

type TabNameProps = {
  tabIndex: number;
};

const TabName = ({ tabIndex }: TabNameProps) => {
  const name = Form.useWatch(["spreadsheet", "tabs", tabIndex, "name"]) ?? null;
  return name ? truncate(name, 15) : `New Tab`;
};

export const SpreadsheetTabs = ({ disabled }) => {
  const { fieldsWithErrors } = useFormErrors();

  const invalidKeys = fieldsWithErrors
    .filter((field) => field.name[1] === "tabs")
    .map((field) => field.name[2]);

  return (
    <FieldList name="tabs" rules={[atLeastOne("At least one tab should be configured")]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.length > 0 ? (
            <EditableTabs
              invalidKeys={invalidKeys}
              disabled={disabled}
              onAdd={() => add({ name: "" })}
              onRemove={(targetKey) => {
                confirm({
                  closable: true,
                  type: "warning",
                  content: "Are you sure you want to remove the tab?",
                  okText: "Remove",
                  onOk: () => {
                    remove(targetKey);
                  },
                });
              }}
              items={fields.map((field) => ({
                key: field.name,
                label: <TabName tabIndex={field.name} />,
                content: (
                  <FieldListItem key={field.key} name={field.name}>
                    <SpreadsheetTab disabled={disabled} />
                  </FieldListItem>
                ),
              }))}
            />
          ) : (
            <FullWidthButton
              label="Add Tabs"
              icon={Plus}
              onClick={() => add({ name: "" })}
              disabled={disabled}
            />
          )}
          <Form.ErrorList errors={errors} />
        </>
      )}
    </FieldList>
  );
};
