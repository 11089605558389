export enum VariableType {
  General = "general",
  Variation = "variation",
  Query = "query",
  Record = "record",
  LoopItem = "loop-item",
}

export enum Aggregation {
  Total = "total",
  Sum = "sum",
}

export type Variable = { name: string; label: string };
