import { Alert, Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import PropTypes from "prop-types";
import { isNil } from "ramda";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  capitalize,
  DeliveryType,
  ERN_CHOREOGRAPHY_LIST,
  ERNChoreography,
  exportStorageServices,
  getStorageServiceLabel,
  imageQualities,
  importStorageServices,
  Operation,
  StorageService,
} from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import { ErrorCode } from "@/api/errorCode";
import { ERROR_MESSAGES } from "@/api/messages";
import { useRequest } from "ahooks";
import { testStorageService } from "@/api/feeds";
import { toOptions } from "@/utils/toOptions";
import { FTPFields } from "./FTPFields";
import { S3Fields } from "./S3Fields";
import { UCSFields } from "./UCSFields";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../../common/Icons";
import { Box } from "../../../common/Box/Box";
import "./StorageForm.less";

export default function StorageForm({ fieldName, operation, targetObject, disabled = false }) {
  const form = Form.useFormInstance();

  const [storageServiceError, setStorageServiceError] = useState(null);

  const storageServiceTestMessage = useMemo(() => {
    if (isNil(storageServiceError)) {
      return <Alert message="Test feed after entering storage data." type="info" />;
    } else if (storageServiceError === false) {
      return <Alert message="Connection is available!" type="success" />;
    } else {
      return <Alert message={storageServiceError} type="error" />;
    }
  }, [storageServiceError]);

  const { run: runStorageServiceTest, loading: isStorageServiceBeingTested } = useRequest(
    testStorageService,
    {
      manual: true,
      throwOnError: true,
    }
  );

  useEffect(() => {
    disabled && setStorageServiceError(null);
  }, [disabled]);

  const handleStorageTest = useCallback(async () => {
    const storageService = form.getFieldValue(fieldName);

    try {
      await runStorageServiceTest(storageService);
      setStorageServiceError(false);
    } catch (e) {
      switch (e.errorCode) {
        case ErrorCode.DataValidation:
        case ErrorCode.IncorrectStorageType:
          setStorageServiceError(e.message);
          break;
        case ErrorCode.TestStorageFailed:
          setStorageServiceError(ERROR_MESSAGES[ErrorCode.TestStorageFailed]);
          break;
        default:
          console.error("unhandled error", e);
      }
    }
  }, [runStorageServiceTest, form, fieldName]);

  const services = operation === Operation.Export ? exportStorageServices : importStorageServices;
  const disabledServices = operation === Operation.Export ? [] : exportStorageServices;
  const choreography = Form.useWatch([fieldName, "configuration", "folderStructure"]);
  const deliveryType = Form.useWatch("deliveryType");

  return (
    <Row gutter={[16, 16]} className="storage-form">
      <Col span={12}>
        <Box readOnly={disabled}>
          <div className="storage-form__fields">
            <Form.Item hidden name={[fieldName, "id"]}>
              <Input />
            </Form.Item>
            {operation === Operation.Export && targetObject === OpenplayObjectType.Release && (
              <>
                <Form.Item label="ERN Choreography" name={[fieldName, "configuration", "folderStructure"]}>
                  <Select options={ERN_CHOREOGRAPHY_LIST} suffixIcon={<Icon component={DropdownArrow} />} />
                </Form.Item>
                {choreography === ERNChoreography.Batch && (
                  <Form.Item
                    label="Batch Size"
                    tooltip="Default: 50"
                    name={[fieldName, "batchSize"]}
                    rules={[{ type: "number", min: 1, message: "Batch size cannot be less than 1" }]}
                  >
                    <InputNumber placeholder="50" />
                  </Form.Item>
                )}
                {deliveryType === DeliveryType.AssetsAndData && (
                  <Form.Item label="Cover art quality" name={[fieldName, "imageQuality"]}>
                    <Select
                      options={toOptions(imageQualities, capitalize)}
                      suffixIcon={<Icon component={DropdownArrow} />}
                    />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item label="Storage Service" name={[fieldName, "type"]}>
              <Select
                options={toOptions(services, getStorageServiceLabel, disabledServices)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Form.Item>
            <Form.Item noStyle dependencies={[[fieldName, "type"]]}>
              {({ getFieldValue }) => {
                const type = getFieldValue([fieldName, "type"]);
                switch (type) {
                  case StorageService.UCS:
                    return <UCSFields fieldName={fieldName} />;
                  case StorageService.SFTP:
                  case StorageService.FTP:
                    return <FTPFields fieldName={fieldName} />;
                  case StorageService.S3:
                    return <S3Fields fieldName={fieldName} />;
                  default:
                    return null;
                }
              }}
            </Form.Item>
          </div>
        </Box>
      </Col>
      <Col span={12}>
        <Box readOnly={disabled}>
          <div className="storage-form__fields">
            <Button
              className="storage-form__button"
              onClick={handleStorageTest}
              loading={isStorageServiceBeingTested}
            >
              Test Connection
            </Button>
            {storageServiceTestMessage}
          </div>
        </Box>
      </Col>
    </Row>
  );
}

StorageForm.propTypes = {
  fieldName: PropTypes.string.isRequired,
  operation: PropTypes.oneOf([Operation.Import, Operation.Export]).isRequired,
  disabled: PropTypes.bool,
};
