import { useGridParams } from "@/utils/useGridParams";
import { useRequest } from "ahooks";
import { getDeliveriesByFeedId } from "@/api/feeds";
import { DeliveryStatus, getDeliveryStatusLabel, prepareParams } from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import { DeliveryTable, DeliveryTableColumns } from "../../common/DeliveryTable";
import { useMemo } from "react";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";

export const FeedDeliveries = ({ feedId, targetObject }) => {
  const { filters, pagination, sort } = useGridParams();

  const { loading, data: deliveries = [] } = useRequest(
    () => getDeliveriesByFeedId(feedId, prepareParams(filters, pagination, sort)),
    { refreshDeps: [feedId, filters, pagination, sort] }
  );

  const columns = useMemo(
    () => [
      DeliveryTableColumns.Status,
      targetObject === OpenplayObjectType.Release
        ? DeliveryTableColumns.UPC
        : DeliveryTableColumns.ProjectNumber,
      DeliveryTableColumns.FirstDelivery,
      DeliveryTableColumns.LastDelivery,
      DeliveryTableColumns.Download,
      DeliveryTableColumns.Resend,
    ],
    [targetObject]
  );

  const filterConfig = useMemo(
    () =>
      [
        {
          name: "status",
          label: "Delivery status",
          type: FilterType.Select,
          options: toOptions(Object.values(DeliveryStatus), getDeliveryStatusLabel),
        },
        targetObject === OpenplayObjectType.Release && {
          name: "upcs[]",
          label: "UPCs",
          type: FilterType.UPCs,
        },
        targetObject === OpenplayObjectType.Release && {
          name: "upcContains",
          label: "UPC Contains",
          type: FilterType.Text,
        },
        { name: "deliveryDate", label: "Delivery date", type: FilterType.DateRange },
      ].filter(Boolean),
    [targetObject]
  );

  return <DeliveryTable columns={columns} filters={filterConfig} loading={loading} deliveries={deliveries} />;
};
