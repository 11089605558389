import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useScripts } from "@/api/flows/hooks";

type Props = Omit<SelectProps, "options" | "loading"> & {
  sourceObject?: string;
};

export const ScriptPicker = ({ sourceObject, ...props }: Props) => {
  const { isLoading, data: entries } = useScripts({ sourceObject });
  return (
    <Select
      {...props}
      disabled={isLoading}
      options={entries.map((entry) => ({ value: entry.key, label: entry.label }))}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
