import * as R from "ramda";
import { assetNamingDelimiters, formatNameLookup, isEmpty } from "../../../utils";

function nameConcat(prefix, name) {
  const p = !R.isEmpty(prefix) && name?.[0] !== "$" ? `${prefix}.` : prefix;
  return `${p}${name}`.replace("$", "[]");
}

function defaultData(
  { type, value, iterationMappings, inputPath, inputPaths, outputPath },
  outputPrefix = "",
  inputPrefix = ""
) {
  if (type === "collection") {
    return iterationMappings.map((child) =>
      defaultData(
        child,
        nameConcat(outputPrefix, outputPath),
        nameConcat(inputPrefix, inputPath || inputPaths[0])
      )
    );
  } else if (type === "const") {
    return {
      source: `Constant: ${value}`,
      target: nameConcat(outputPrefix, outputPath),
    };
  } else {
    return {
      source: nameConcat(inputPrefix, inputPaths?.[0] ?? `Constant: ${value}`),
      target: nameConcat(outputPrefix, outputPath),
    };
  }
}

const preparePath = R.pipe(R.split("|"), R.head, R.trim, R.replace(/\.+/g, "/"));

export const formatMappings = R.pipe(
  R.map(defaultData),
  R.flatten,
  R.map(({ source, target }) => ({
    source,
    target: preparePath(target),
  }))
);

export const CONSTANT_MAPPING_PREFIX = ":const:";
export const EMPTY_VALUE_PLACEHOLDER = "__empty__";

export const parseMappingItem = (mappingItem) => ({
  source: mappingItem.inputPaths?.[0].split(".") || [
    mappingItem.value === "" ? EMPTY_VALUE_PLACEHOLDER : `${CONSTANT_MAPPING_PREFIX}${mappingItem.value}`,
  ],
  target: mappingItem.outputPath.split("."),
});

export const extractConstantValue = (value) => value.slice(CONSTANT_MAPPING_PREFIX.length);

export const prepareMappingItem = (formValue) => {
  const outputPath = formValue.target?.join(".") ?? "";
  if (formValue.source[0] === EMPTY_VALUE_PLACEHOLDER) {
    return {
      value: "",
      outputPath,
    };
  }
  if (formValue.source[0].startsWith(CONSTANT_MAPPING_PREFIX)) {
    return {
      value: extractConstantValue(formValue.source[0]),
      outputPath,
    };
  }
  return {
    inputPaths: [formValue.source.join(".")],
    outputPath,
  };
};

export const prepareMappingItems = (items) => items.map(prepareMappingItem);

export const prepareMapping = (mapping) => {
  if (!isEmpty(mapping?.data)) {
    return {
      ...mapping,
      data: prepareMappingItems(mapping.data),
    };
  } else if (!isEmpty(mapping)) {
    mapping = { ...mapping, data: [] };
  }
  return mapping;
};

export const getTemplateName = (from, to) => `${formatNameLookup[from]} to ${formatNameLookup[to]}`;

export const mappingTemplatesToOptions = (templates) =>
  templates.map(([from, to]) => ({
    value: `${from}_to_${to}`,
    label: getTemplateName(from, to),
  }));

export const parseAssetNaming = (naming) => {
  const isCustomDelimiter = !assetNamingDelimiters.includes(naming.delimiter);
  return {
    ...naming,
    delimiter: isCustomDelimiter ? "custom" : naming.delimiter,
    customDelimiter: isCustomDelimiter ? naming.delimiter : null,
  };
};

export const prepareAssetNaming = (naming) => ({
  ...naming,
  delimiter: naming.delimiter === "custom" ? naming.customDelimiter : naming.delimiter,
  segments: naming.segments.filter((item) => !!item),
});
