import type { User } from "@/api/users/types";
import { PermissionAction, PermissionSubject } from "@/api/users/types";
import { Col, Form, Input, PageHeader, Radio, Row } from "antd";
import { FormActions } from "@/components/common/FormActions";
import { Box } from "@/components/common/Box/Box";
import { toOptions } from "@/utils/toOptions";
import { getUserRoleLabel, mapIds, roles, useAuth, UserRole } from "@/utils";
import { StatusSwitch } from "@/components/common/StatusSwitch/StatusSwitch";
import { Tag } from "@/components/common/Tag/Tag";
import { PartnerSelect } from "@/components/Users/Details/PartnerSelect";
import { useHistory, useParams } from "react-router-dom";
import { useBoolean, useRequest } from "ahooks";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { getPartnerWithoutUsers } from "@/api/partners";
import { checkUserExistence } from "@/api/users";
import { isUniqueName } from "@/utils/validation";
import { useUserMutation } from "@/api/users/hooks";
import { PermissionList } from "@/components/Users/Details/Permissions/PermissionList";
import { subject } from "@casl/ability";

type Props = {
  initialValues?: Partial<User>;
  isEditingByDefault?: boolean;
};

type UserFields = Omit<Partial<User>, "partners"> & { partners: string[] };

const parseUserFields = (user: Partial<User>): UserFields => ({
  ...user,
  partners: mapIds(user.partners ?? []),
});

export const isUniqueUserName = isUniqueName(
  (name: string, excludeId: string) => checkUserExistence({ name }, excludeId),
  `A user with entered name already exists in the system`
);

export const isUniqueEmail = isUniqueName(
  (email: string, excludeId: string) => checkUserExistence({ email }, excludeId),
  `A user with entered email already exists in the system`
);

export const UserForm = ({ initialValues = {}, isEditingByDefault }: Props) => {
  const history = useHistory();
  const [form] = Form.useForm<UserFields>();
  const { id } = useParams<{ id: string }>();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();
  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean(isEditingByDefault);

  const { ability } = useAuth();

  const role = Form.useWatch<UserRole>("role", form);

  // TODO Migrate partners api methods to TS
  const { data: partnersWithoutUsers, mutate: mutatePartners, run: checkPartners } = useRequest(
    getPartnerWithoutUsers,
    {
      manual: true,
    }
  );

  const handleValueChange = (changedValues) => {
    preventNavigation();
    if (changedValues.hasOwnProperty("isActive")) {
      if (!changedValues.isActive) {
        checkPartners(id).catch(console.error);
      } else {
        // @ts-expect-error
        mutatePartners([]);
      }
    }
  };

  const handleCancel = () => {
    allowNavigation();
    if (initialValues?.id) {
      form.setFieldsValue(parseUserFields(initialValues));
      disableEditing();
    } else {
      history.push("/users");
    }
  };

  const { isMutating: isSaving, trigger: save } = useUserMutation(initialValues.id);

  const handleSave = async (values) => {
    try {
      // @ts-expect-error: types to be updated
      const { id } = await save(values);
      allowNavigation();
      if (!initialValues.id) {
        history.push(`/users/${id}`);
        return;
      }
      disableEditing();
    } catch (e) {
      console.error(e);
    }
  };

  // @ts-expect-error
  const canEdit = ability.can(PermissionAction.Update, subject(PermissionSubject.User, initialValues));

  const canUpdateStatus = ability.can(
    PermissionAction.Update,
    // @ts-expect-error
    subject(PermissionSubject.User, initialValues),
    "status"
  );

  return (
    <Form
      form={form}
      onFinish={handleSave}
      onValuesChange={handleValueChange}
      initialValues={parseUserFields(initialValues)}
      colon={false}
      labelAlign="left"
      wrapperCol={{ span: 15, offset: 1 }}
      labelCol={{ span: 8 }}
    >
      <PageHeader
        title={initialValues?.name ?? "New User"}
        extra={
          <FormActions
            onEdit={enableEditing}
            onCancel={handleCancel}
            isEditing={isEditing}
            isSaving={isSaving}
            onSave={form.submit}
            canEdit={canEdit}
          />
        }
      />
      <Form.Item name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Box readOnly={!isEditing}>
            <Row align="top" gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[{ required: true, message: "Field is mandatory." }, isUniqueUserName]}
                >
                  <Input placeholder="User Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    { required: true, message: "Field is mandatory." },
                    { type: "email", message: "Invalid email format" },
                    isUniqueEmail,
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
          </Box>
        </Col>
        <Col span={24}>
          <Box readOnly={!isEditing}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item name="role" label="Role">
                  <Radio.Group
                    buttonStyle="solid"
                    options={toOptions(
                      roles.filter((role) =>
                        //@ts-expect-error
                        ability.can(PermissionAction.Manage, subject(PermissionSubject.User, { role }))
                      ),
                      getUserRoleLabel
                    )}
                    optionType="button"
                  />
                </Form.Item>
              </Col>
              {initialValues?.id && canUpdateStatus && (
                <Col span={12}>
                  <Form.Item name="isActive" label="Status" valuePropName="checked">
                    <StatusSwitch />
                  </Form.Item>
                </Col>
              )}
              {/* @ts-expect-error */}
              {initialValues?.id && partnersWithoutUsers?.length > 0 && (
                <Col span={12}>
                  <Form.Item
                    label="Partners without assigned users"
                    wrapperCol={{ span: 15, offset: 1 }}
                    labelCol={{ span: 8 }}
                    className="partners-warning"
                  >
                    <div className="partners-warning-box">
                      {/* @ts-expect-error */}
                      {partnersWithoutUsers.map(({ name }) => (
                        <Tag key={name} color="#B2E6A1">
                          {name}
                        </Tag>
                      ))}
                    </div>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Box>
        </Col>
        {role === UserRole.Operational && (
          <Col span={24}>
            <Box readOnly={!isEditing}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="partners"
                    label="Assigned To"
                    wrapperCol={{ span: 15, offset: 1 }}
                    labelCol={{ span: 8 }}
                    className="select-partners"
                  >
                    <PartnerSelect placeholder="Select partners..." />
                  </Form.Item>
                </Col>
              </Row>
            </Box>
          </Col>
        )}
        {role === UserRole.Operational && (
          <Col span={24}>
            <Box readOnly={!isEditing} title="Permissions">
              <PermissionList />
            </Box>
          </Col>
        )}
      </Row>
    </Form>
  );
};
