import { useCurrentUser } from "@/api/users/hooks";
import { useCallback, useMemo } from "react";
import { login, logout } from "@/api/auth";
import { useHistory } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import type { MongoQuery, RawRule } from "@casl/ability";
import { createMongoAbility } from "@casl/ability";
import type { CurrentUser } from "@/api/users/types";
import { PermissionAction, PermissionSubject, PermissionType } from "@/api/users/types";
import { UserRole } from "@/utils/user-role";
import { createCanBoundTo } from "@casl/react";

type AbilityTuple = [PermissionAction, PermissionSubject];

const getPermissions = (user: CurrentUser): RawRule<AbilityTuple, MongoQuery>[] => {
  if (user?.role === UserRole.Operational) {
    return [
      // Default permissions
      { action: PermissionAction.Read, subject: PermissionSubject.Release },
      { action: PermissionAction.Read, subject: PermissionSubject.Transfer },
      { action: PermissionAction.Read, subject: PermissionSubject.SalesforceObject },
      { action: PermissionAction.Read, subject: PermissionSubject.OpenplayObject },
      { action: PermissionAction.Read, subject: PermissionSubject.Partner },
      { action: PermissionAction.Update, subject: PermissionSubject.Partner },
      {
        inverted: true,
        action: PermissionAction.Update,
        subject: PermissionSubject.Partner,
        fields: ["users", "status"],
      },
      { action: PermissionAction.Manage, subject: PermissionSubject.Feed },
      { inverted: true, action: PermissionAction.Create, subject: PermissionSubject.Feed },
      { inverted: true, action: PermissionAction.Delete, subject: PermissionSubject.Feed },
      { action: PermissionAction.Manage, subject: PermissionSubject.Template },
      {
        inverted: true,
        action: PermissionAction.Update,
        subject: PermissionSubject.Template,
        conditions: { updatedById: { $ne: user.userId } },
      },
      {
        inverted: true,
        action: PermissionAction.Delete,
        subject: PermissionSubject.Template,
        conditions: { updatedById: { $ne: user.userId } },
      },
      // Assigned permissions
      ...(user.permissions ?? []).map((permission) => ({
        action: permission.action,
        subject: permission.subject,
        inverted: permission.type === PermissionType.Cannot,
      })),
    ];
  }
  if (user?.role === UserRole.Admin) {
    return [
      { action: PermissionAction.Manage, subject: PermissionSubject.All },
      {
        inverted: true,
        action: PermissionAction.Manage,
        subject: PermissionSubject.User,
        conditions: { role: UserRole.Superadmin },
      },
      {
        inverted: true,
        action: PermissionAction.Update,
        subject: PermissionSubject.User,
        fields: ["status"],
        conditions: { id: user.userId },
      },
    ];
  }
  if (user?.role === UserRole.Superadmin) {
    return [
      { action: PermissionAction.Manage, subject: PermissionSubject.All },
      {
        inverted: true,
        action: PermissionAction.Update,
        subject: PermissionSubject.User,
        fields: ["status"],
        conditions: { id: user.userId },
      },
    ];
  }
  return [];
};

export const useAuth = () => {
  const history = useHistory();
  const { data: user, mutate, isLoading: isLoadingUser } = useCurrentUser();

  const { isMutating: isLoggingIn, trigger: triggerLoggingIn } = useSWRMutation(
    "login",
    (_, { arg: { email, password } }: { arg: { email: string; password: string } }) => login(email, password),
    { throwOnError: true }
  );

  const { isMutating: isLoggingOut, trigger: triggerLoggingOut } = useSWRMutation("logout", logout, {
    throwOnError: true,
  });

  const handleLogin = useCallback(
    async (email: string, password: string) => {
      await triggerLoggingIn({ email, password });
      await mutate();
    },
    [mutate, triggerLoggingIn]
  );

  const handleLogout = useCallback(async () => {
    await triggerLoggingOut();
    await mutate(null);
    history.push("/login");
  }, [history, mutate, triggerLoggingOut]);

  const isLoading = isLoadingUser || isLoggingIn || isLoggingOut;

  const ability = useMemo(() => createMongoAbility<AbilityTuple>(getPermissions(user)), [user]);

  const Can = useMemo(() => createCanBoundTo(ability), [ability]);

  return {
    isAuthenticated: !!user,
    user: user,
    login: handleLogin,
    logout: handleLogout,
    refresh: () => mutate(),
    ability,
    Can,
    isLoading,
  };
};
