import { RichTextEditor } from "@/components/common/RichTextEditor";
import { Field } from "../../Form/Field";
import { useEmailMessageContext } from "../../EmailMessageForm/context";
import { useLoopItemVariables } from "../LoopBlock/context";
import { useMemo } from "react";

export const TextBlock = () => {
  const { variables: emailMessageVariables } = useEmailMessageContext();
  const { variables: loopVariables } = useLoopItemVariables();
  const variables = useMemo(() => [...emailMessageVariables, ...loopVariables], [
    emailMessageVariables,
    loopVariables,
  ]);
  return (
    <Field name="text" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <RichTextEditor variables={variables} />
    </Field>
  );
};
