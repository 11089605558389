import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { useExportSchedule } from "@/api/export-schedules/hooks";
import { ExportScheduleForm } from "@/components/ExportSchedules/Details/ExportScheduleForm";

export const ExportSchedulePage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: entry, isLoading } = useExportSchedule(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <ExportScheduleForm initialValues={entry} />;
};
