import "./DynamicPathField.less";
import { useMemo, useRef, useState } from "react";
import { getTokens, useDynamicFieldHeight } from "./utils";
import { Highlight } from "./Highlight";
import { Form } from "antd";
import cn from "classnames";

const updateCaretPosition = (element, caretPosition) => {
  element.setSelectionRange(caretPosition, caretPosition);
  element.focus();
};

export const DynamicPathField = ({ value, onChange, placeholder, variables }) => {
  const handleChange = (e) => {
    e.persist();
    onChange(e.target.value);
  };

  const [caret, setCaret] = useState(0);

  const handleSelect = (e) => {
    setCaret(e.target.selectionStart);
  };

  const handleBlur = () => {
    setCaret(0);
  };

  const fieldRef = useRef();
  useDynamicFieldHeight(fieldRef, value);

  const tokens = useMemo(() => getTokens(value), [value]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
    }
  };

  const handleSelectVariable = (variable, token) => {
    const stringBeforeToken = value.slice(0, token.indices.start);
    const stringAfterToken = value.slice(token.enclosed ? token.indices.end : caret);
    const insertion = `{${variable}}`;
    onChange(stringBeforeToken + insertion + stringAfterToken);
    setTimeout(() => updateCaretPosition(fieldRef.current, token.indices.start + insertion.length), 0);
  };

  const { status } = Form.Item.useStatus();

  return (
    <div className="dynamic-path-field">
      <textarea
        ref={fieldRef}
        className={cn("dynamic-path-field__input", status === "error" && "dynamic-path-field__input--error")}
        placeholder={placeholder}
        onChange={handleChange}
        onSelect={handleSelect}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        value={value}
      />
      <Highlight
        value={value}
        tokens={tokens}
        caret={caret}
        variables={variables}
        onSelectVariable={handleSelectVariable}
      />
    </div>
  );
};
