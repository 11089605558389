export enum TabSource {
  Salesforce = "salesforce",
  OpenPlay = "openplay",
  OpenPlayQuery = "openplay-query",
}

const tabSourceLabelLookup: Record<TabSource, string> = {
  [TabSource.Salesforce]: "Salesforce",
  [TabSource.OpenPlay]: "OpenPlay",
  [TabSource.OpenPlayQuery]: "OpenPlay Query",
};

export const getTabSourceLabel = (tabSource: TabSource) => tabSourceLabelLookup[tabSource];
