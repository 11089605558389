import type { SalesforceObject, SalesforceObjectField } from "@/api/salesforce-objects/types";
import type { Field, SelectField, SimpleField, SimpleFieldType } from "./types";
import {
  getBooleanField,
  getDateField,
  getMultiselectField,
  getNumberField,
  getSelectField,
  getTextField,
} from "@/utils/query-builder";
import type { Variable } from "@/types/variables";

export const getSalesforceSimpleFieldType = (type: SalesforceObjectField["type"]): SimpleFieldType => {
  switch (type) {
    case "boolean":
      return "boolean";
    case "string":
    case "textarea":
    case "url":
    case "email":
    case "id":
    case "phone":
      return "string";
    case "currency":
    case "double":
    case "int":
    case "percent":
      return "number";
    case "date":
      return "date";
    case "datetime":
      return "datetime";
  }
};

export const getFieldsFromSalesforceObject = (
  object: SalesforceObject,
  includeTypes: SalesforceObjectField["type"][] = [],
  excludeTypes: SalesforceObjectField["type"][] = [],
  nesting = 3
): Field[] =>
  (object?.fields ?? []).flatMap((field) => {
    if (field.type === "address") {
      return [];
    }
    if (field.type === "reference") {
      if (!field.referenceTo) {
        return [];
      }
      if (nesting > 0) {
        const children = getFieldsFromSalesforceObject(
          field.referenceTo,
          includeTypes,
          excludeTypes,
          nesting - 1
        );
        if (children.length === 0) {
          return [];
        }
        return {
          type: "object",
          name: field.name,
          label: field.label,
          children,
        };
      } else {
        return [];
      }
    }
    if (includeTypes.length > 0 && !includeTypes.includes(field.type)) {
      return [];
    }
    if (excludeTypes.includes(field.type)) {
      return [];
    }
    if (field.type === "picklist" || field.type === "multipicklist") {
      return {
        type: field.type === "picklist" ? "select" : "multiselect",
        name: field.name,
        label: field.label,
        options: field.options.map((option) => ({ value: option.value, label: option.title })),
      };
    }
    return {
      type: getSalesforceSimpleFieldType(field.type),
      name: field.name,
      label: field.label,
    };
  });

export const getSimpleField = (field: SimpleField | SelectField) => {
  switch (field.type) {
    case "date":
    case "datetime":
      return getDateField(field.label, field.type);
    case "boolean":
      return getBooleanField(field.label);
    case "select":
      return getSelectField(
        field.label,
        field.options.map((option) => ({ value: option.value, title: option.label }))
      );
    case "multiselect":
      return getMultiselectField(
        field.label,
        field.options.map((option) => ({ value: option.value, title: option.label }))
      );
    case "number":
      return getNumberField(field.label);
    case "string":
      return getTextField(field.label);
    default:
      return getTextField("Something");
  }
};

export const flattenVariables = (fields: Field[], parents: Field[] = []): Variable[] =>
  fields.flatMap((field) => {
    const fieldWithParents = [...parents, field];
    if (field.type === "object") {
      return flattenVariables(field.children, fieldWithParents);
    }
    const name = fieldWithParents.map((f) => f.name).join(".");
    const label = fieldWithParents.map((f) => f.label).join(" / ");
    return { name, label };
  });
