import * as R from "ramda";
import { is } from "ramda";
import type { Logic } from "@/api/types";
import type { Config, FieldOrGroup } from "@react-awesome-query-builder/core";
import * as moment from "moment/moment";
import { relativeDates } from "@/components/common/QueryBuilder/functions";

type Func = {
  name: string;
  params: string[];
};

export type Value = string | number | boolean | null | string[] | Func;

export type Rule = {
  type: "rule";
  operator: string;
  field: string;
  value?: Value;
};

export type Group = {
  type: "group";
  operator: string;
  negated: boolean;
  children: Expression[];
};

export type Expression = Rule | Group;

const expand = (logic: Logic) => {
  const negated = "!" in logic;
  const rule = negated ? logic["!"] : logic;
  const operator = Object.keys(rule).at(0);
  if (operator === "var") {
    return { operator: "!", negated: false, rule: logic };
  }
  return { operator, negated, rule };
};

const parseFunc = (value: any): Func => {
  const name = Object.keys(value).at(0);
  const params = value[name];
  return { name, params };
};

export const parseLogic = (logic: Logic): Expression => {
  if (!logic || !is(Object, logic)) {
    return null;
  }
  const { operator, negated, rule } = expand(logic);

  if (!operator || !rule) {
    return null;
  }

  const operands = rule[operator];

  const simpleOperators = ["==", "!=", ">", ">=", "<", "<="];

  if (simpleOperators.includes(operator)) {
    const value = is(Object, operands[1]) ? parseFunc(operands[1]) : operands[1];
    return { type: "rule", field: operands[0].var, operator, value };
  }

  if (operator === "in") {
    if (typeof operands[0] === "string") {
      return {
        type: "rule",
        field: operands[1].var,
        operator: negated ? "not like" : "like",
        value: operands[0],
      };
    }
    return {
      type: "rule",
      field: operands[0].var,
      operator: negated ? "not in" : "in",
      value: operands[1],
    };
  }

  if (operator === "!") {
    return {
      type: "rule",
      operator: "is empty",
      field: rule[operator].var,
    };
  }

  if (operator === "!!") {
    return {
      type: "rule",
      operator: "is not empty",
      field: rule[operator].var,
    };
  }

  if (["and", "or"].includes(operator)) {
    return {
      type: "group",
      operator,
      negated,
      children: operands.map(parseLogic).filter(Boolean),
    };
  }

  if (operator === "some" || operator === "all") {
    return {
      type: "rule",
      field: operands[0].var,
      operator: operator === "some" ? (negated ? "excludes" : "includes") : negated ? "!=" : "==",
      value: operands[1]["in"][1],
    };
  }

  return null;
};

export const getField = (config: Config, path: string[]): FieldOrGroup => {
  const preparedPath = path.flatMap((piece, i) => (i === 0 ? piece : ["subfields", piece]));
  return R.path(preparedPath, config.fields);
};

export const getFieldLabel = (config: Config, path: string[]) => {
  const paths = path.map((_, index, arr) => arr.slice(0, index + 1));
  return paths
    .map((path) => getField(config, path))
    .filter(Boolean)
    .map((field) => field.label)
    .join(" / ");
};

export const getHumanReadableOperator = (operator: string) => {
  const lookup: Record<string, string> = {
    "==": "is",
    "!=": "is not",
    ">": "is greater than",
    ">=": "is greater than or equal to",
    "<": "is less than",
    "<=": "is less than or equal to",
    in: "is",
    "not in": "is not",
    like: "contains",
    "not like": "doesn't contain",
  };

  return lookup[operator] ?? operator;
};

export const formatValue = (value: Value, field: FieldOrGroup): string => {
  if (typeof value === "string") {
    if (field?.type === "date") {
      return moment(value).format("MMMM DD, YYYY");
    }
    if (field?.type === "datetime") {
      return moment(value).format("MMMM DD, YYYY [at] h:mmA");
    }
    return `"${value}"`;
  }
  if (typeof value === "number") {
    return String(value);
  }
  if (typeof value === "boolean") {
    return value ? "True" : "False";
  }
  if (value === null) {
    return "Null";
  }
  if (Array.isArray(value)) {
    return new Intl.ListFormat("en-US", {
      style: "long",
      type: field?.type === "multiselect" ? "conjunction" : "disjunction",
    }).format(value.map((x) => `"${x}"`));
  }
  const functionInfo = relativeDates.find((info) => info.name === value.name);
  return functionInfo ? functionInfo.label.replace("{n}", value.params.at(0)) : value.name;
};

export const isUnaryOperator = (operator: string) => ["is empty", "is not empty"].includes(operator);
