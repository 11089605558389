import { Color } from "@/utils/color";

export enum ProcessingStatus {
  Aborted = "aborted",
  Running = "running",
  Finished = "finished",
  Failed = "failed",
  FinishedWithWarnings = "finished_with_warnings",
}

const colorLookup = {
  [ProcessingStatus.Aborted]: Color.Inactive,
  [ProcessingStatus.Running]: Color.Accent,
  [ProcessingStatus.Failed]: Color.Failed,
  [ProcessingStatus.Finished]: Color.Success,
  [ProcessingStatus.FinishedWithWarnings]: Color.Warning,
};

export const getProcessingStatusColor = (status: ProcessingStatus) => colorLookup[status];

const labelLookup: Record<ProcessingStatus, string | string[]> = {
  [ProcessingStatus.Aborted]: "Aborted",
  [ProcessingStatus.Running]: "Running",
  [ProcessingStatus.Failed]: "Failed",
  [ProcessingStatus.Finished]: "Finished",
  [ProcessingStatus.FinishedWithWarnings]: ["Finished", "Finished with Warnings"],
};

export const getProcessingStatusLabel = (status: ProcessingStatus, full = false): string => {
  const labelOrLabels = labelLookup[status];
  return typeof labelOrLabels === "string" ? labelOrLabels : labelOrLabels[full ? 1 : 0];
};

export const getProcessingStatusFullLabel = (status: ProcessingStatus) =>
  getProcessingStatusLabel(status, true);
