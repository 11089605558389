import React, { useEffect, useMemo, useState } from "react";
import { SortableContainer, SortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { FilterInput } from "../../../common";
import { Divider, Select } from "antd";
import * as R from "ramda";
import { isNotEmpty, prepareFilters } from "@/utils";
import PropTypes from "prop-types";
import { Drag, DropdownArrow, PlusCircle, Remove } from "../../../common/Icons";
import { IconButton } from "../../../common/IconButton/IconButton";
import "./FiltersConfigurationForm.less";
import Icon from "@ant-design/icons";

const { Option } = Select;

const ListItem = ({ item, onDelete }) => {
  const { name, label, type } = item;

  return (
    <li className="filters-configuration__item">
      <DragHandle />
      <FilterInput
        key={name}
        type={type}
        name={name}
        value={null}
        label={label}
        disabled
        className="filters-configuration__field"
      />
      {onDelete && <IconButton icon={Remove} onClick={() => onDelete(name)} />}
    </li>
  );
};

const List = ({ list, onDelete }) => {
  return (
    <ul className="filters-configuration__list">
      {list.map((item, index) => (
        <SortableItem key={`item-${item.name}`} index={index} onDelete={onDelete} item={item} />
      ))}
    </ul>
  );
};

const DragHandle = sortableHandle(() => <Icon component={Drag} className="filters-configuration__drag" />);
const SortableItem = SortableElement((props) => <ListItem {...props} />);
const SortableList = SortableContainer((props) => <List {...props} />);

const prepareChange = R.pluck("name");

export const FiltersConfigurationForm = ({ availableFilters, enabledFilters = [], onChange = () => {} }) => {
  const sortable = prepareFilters(availableFilters, enabledFilters);
  const restItems = useMemo(
    () =>
      R.differenceWith(
        (formItem, filterItem) => formItem.name === filterItem.name,
        availableFilters,
        sortable
      ),
    [sortable, availableFilters]
  );

  const isRestItemsAvailable = isNotEmpty(restItems);
  const [restItemValue, setRestItemValue] = useState(() => (isRestItemsAvailable ? restItems[0].name : null));

  const firstInList = restItems?.[0]?.name;
  useEffect(() => {
    setRestItemValue(isRestItemsAvailable ? firstInList : null);
  }, [isRestItemsAvailable, firstInList]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const update = arrayMove(sortable, oldIndex, newIndex);
    onChange(prepareChange(update));
  };
  const onDelete = (name) => {
    const update = sortable.filter((item) => item.name !== name);
    onChange(prepareChange(update));
  };
  const onAdd = (name) => {
    const update = [...sortable, restItems.find((item) => item.name === name)];
    onChange(prepareChange(update));
  };

  return (
    <div className="filters-configuration">
      <SortableList
        list={sortable}
        onSortEnd={onSortEnd}
        onDelete={isNotEmpty(sortable) && sortable.length > 1 ? onDelete : null}
        useDragHandle
        helperClass="filters-configuration__item--ghost"
      />
      <Divider />
      {isRestItemsAvailable && (
        <div className="filters-configuration__add">
          <Select
            className="filters-configuration__select"
            value={restItemValue}
            style={{ width: 120 }}
            onChange={setRestItemValue}
            suffixIcon={<Icon component={DropdownArrow} />}
          >
            {restItems.map((item) => (
              <Option key={item.name} value={item.name}>
                {item.label}
              </Option>
            ))}
          </Select>
          <IconButton icon={PlusCircle} onClick={() => onAdd(restItemValue)} />
        </div>
      )}
    </div>
  );
};

FiltersConfigurationForm.propTypes = {
  availableFilters: PropTypes.array.isRequired,
  enabledFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};
