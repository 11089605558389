import { StatusTag } from "../../common";
import { DescriptionCell } from "../../common/DescriptionCell";
import { Space } from "antd";
import type { ColumnsType } from "antd/lib/table/interface";
import type { Report } from "@/api/reports/types";
import { DuplicateReportButton } from "./DuplicateReportButton";
import { getScheduleColumns } from "@/components/common/schedule-columns";
import { Color } from "@/utils";
import { Tag } from "@/components/common/Tag/Tag";
import { TagFilter } from "@/components/common/TagFilter";

export const columns: ColumnsType<Report> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    render: (_, report) => (
      <Space>
        <span>{report.name}</span>
      </Space>
    ),
  },
  ...getScheduleColumns<Report>(
    (report) => report.recurrency,
    "recurrency",
    (interval) => (
      <TagFilter filters={{ "recurrency[interval]": interval }}>
        <Tag color={Color.SecondaryAccent}>{interval}</Tag>
      </TagFilter>
    )
  ),
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: (_, report) => (
      <TagFilter filters={{ status: report.status }}>
        {/* @ts-expect-error */}
        <StatusTag status={report.status} />
      </TagFilter>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (description) => <DescriptionCell description={description} />,
  },
  {
    title: "Duplicate",
    render: (_, report) => <DuplicateReportButton report={report} />,
  },
];
