import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type {
  CreateOnDemandReport,
  OnDemandReportFilters,
  UpdateOnDemandReport,
} from "@/api/on-demand-reports/types";
import { fetchOnDemandReport, fetchOnDemandReports, saveOnDemandReport } from "@/api/on-demand-reports/index";
import { revalidateColumnSets } from "@/api/spreadsheets/hooks";

const key = "on-demand-reports";

export const useOnDemandReports = (filters: OnDemandReportFilters = {}) =>
  useSWR([key, filters], ([_, filters]) => fetchOnDemandReports(filters), {
    fallbackData: { data: [] },
  });

export const useOnDemandReport = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchOnDemandReport(id), {
    fallbackData: null,
  });

export const useOnDemandReportMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreateOnDemandReport | UpdateOnDemandReport }) => saveOnDemandReport(dto),
    { onSuccess: revalidateColumnSets }
  );
