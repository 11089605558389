import { AutoComplete } from "antd";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { toOptions } from "@/utils/toOptions";
import { getEmptyArray } from "@/utils/empty";
import { useState } from "react";

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  query?: OpenplayQuery;
  placeholder?: string;
};

export const OpenplayQueryFieldPicker = ({ value, onChange, query, placeholder = "Field" }: Props) => {
  const _value = (value ?? []).at(0) ?? undefined;
  const handleChange = (value: string) => {
    onChange(value ? [value] : []);
  };

  const [search, setSearch] = useState("");

  const suggestions =
    query?.fields.filter((field) => field.toLowerCase().includes(search.toLowerCase())) ?? getEmptyArray();

  return (
    <AutoComplete
      value={_value}
      onChange={handleChange}
      placeholder={placeholder}
      options={toOptions(suggestions)}
      onSearch={(value) => setSearch(value)}
    />
  );
};
