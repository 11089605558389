import useSWR from "swr";
import type { ReportLogFilters } from "@/api/report-logs/types";
import { fetchReportLogs } from "@/api/report-logs/index";

const key = "report-logs";

export const useReportLogs = (filters: ReportLogFilters = {}) =>
  useSWR([key, filters], ([_, filters]) => fetchReportLogs(filters), {
    fallbackData: { data: [] },
  });
