export enum TemplateType {
  Mapping = "mapping",
  Filters = "filters",
  AssetNaming = "asset-naming",
  SalesforceQuery = "salesforce-query",
  SpreadsheetColumnSet = 'spreadsheet-column-set',
}

const labelLookup: Record<TemplateType, string> = {
  [TemplateType.Mapping]: "Mapping",
  [TemplateType.Filters]: "Filters",
  [TemplateType.AssetNaming]: "Asset Naming",
  [TemplateType.SalesforceQuery]: "Salesforce Query",
  [TemplateType.SpreadsheetColumnSet]: "Spreadsheet Column Set",

};

export const getTemplateTypeLabel = (type: TemplateType) => labelLookup[type];
