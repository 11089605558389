import { EditorContent, mergeAttributes } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { useEffect, useMemo } from "react";
import "./RichTextEditor.less";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Underline } from "@tiptap/extension-underline";
import { Link } from "@tiptap/extension-link";
import { Toolbar } from "./Toolbar";
import { Variable } from "./Variables";
import { useEditor } from "./hooks";
import { Form } from "antd";
import cn from "classnames";
import { useVariableModal } from "./Variables/hooks";
import { getEmptyArray } from "@/utils/empty";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  variables?: Variables;
  readOnly?: boolean;
};

const RichTextEditor = ({ value: content, onChange, variables = getEmptyArray(), readOnly }: Props) => {
  const { modal, open } = useVariableModal(variables);

  const extensions = useMemo(
    () => [
      StarterKit.configure({
        paragraph: false,
        code: false,
        codeBlock: false,
        blockquote: false,
        horizontalRule: false,
      }),
      Paragraph.extend({
        parseHTML() {
          return [{ tag: "div" }];
        },
        renderHTML({ HTMLAttributes }) {
          return ["div", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
        },
      }),
      Underline,
      Variable.configure({
        variables,
        onClick: open,
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
    ],
    [open, variables]
  );

  const editor = useEditor({
    extensions,
    content,
    onChange,
  });

  useEffect(() => {
    if (!editor) {
      return undefined;
    }

    editor.setEditable(!readOnly);
  }, [editor, readOnly]);

  const { status } = Form.Item.useStatus();

  return (
    <div
      className={cn(
        "rich-text-editor",
        status === "error" && "rich-text-editor--error",
        readOnly && "rich-text-editor--read-only"
      )}
    >
      {!readOnly && <Toolbar editor={editor} variables={variables} />}
      <EditorContent editor={editor} />
      {modal}
    </div>
  );
};

export { RichTextEditor };
