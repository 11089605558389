import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import "./OrderByList.less";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { Button } from "antd";
import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { OrderByListItem } from "./OrderByListItem";

type Props = {
  sourceObject: string;
};

export const OrderByList = ({ sourceObject }: Props) => {
  return (
    <FieldList name="orderBy">
      {(fields, { add, remove, move }) => (
        <div className="order-by-list">
          <Button
            size="small"
            icon={<Icon component={Plus} />}
            onClick={() => add({ field: null, direction: "asc" })}
            className="order-by-list__add"
          >
            Add Field
          </Button>
          <SortableList onMove={move}>
            {fields.map((field) => (
              <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                <FieldListItem name={field.name}>
                  <OrderByListItem sourceObject={sourceObject} />
                </FieldListItem>
              </SortableListItem>
            ))}
          </SortableList>
        </div>
      )}
    </FieldList>
  );
};
