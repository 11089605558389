import type { NodeViewProps } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import { VariableType } from "@/types/variables";
import cn from "classnames";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import type { Variables } from "./types";
import { flattenVariables } from "@/utils/dynamic-fields";
import { pluralize } from "@/utils/pluralize";
import { escaped, unescape } from "./utils";
import { getTimezoneDescription } from "@/utils/timezones";

const getTransformationLabel = (transformation: string) => {
  const [name, args] = transformation.split(escaped(":"));
  if (name === "offset") {
    const [value, unit] = args.split(escaped(","));
    const parsedValue = parseInt(value);
    const sign = parsedValue > 0 ? "﹢" : "−";
    return `${sign}${Math.abs(parsedValue)} ${pluralize(parsedValue, unit, unit + "s")}`;
  }
  if (name === "format") {
    return unescape(args);
  }
  if (name === "timezone") {
    return getTimezoneDescription(unescape(args));
  }
};

export const NodeView = ({ node, extension, deleteNode, getPos, editor }: NodeViewProps) => {
  const variables = extension.options.variables as Variables;
  const flattenedVariables = flattenVariables(variables);

  const [path, ...transformations] = node.attrs.name.split(escaped("|"));

  const variable = flattenedVariables.find((x) => x.name === path);

  const type = variable?.name.split(".").at(0) ?? VariableType.General;

  const label = variable
    ? [variable.label, ...transformations.map(getTransformationLabel)].join(" | ")
    : node.attrs.name;

  const isUnknownVariable = !variable;

  const handleOpen = () => {
    if (editor.isEditable) {
      extension.options.onClick?.({ name: node.attrs.name, position: getPos(), editor });
    }
  };

  return (
    <NodeViewWrapper as="span" className="variable__inner">
      {type !== VariableType.General && (
        <span className={cn("variable__type", `variable__type--${type}`)}>
          {type.slice(0, 1).toUpperCase()}
        </span>
      )}
      <span
        onClick={isUnknownVariable ? undefined : handleOpen}
        className={cn(
          "variable__label",
          (isUnknownVariable || !editor.isEditable) && "variable__label--read-only"
        )}
      >
        {label}
      </span>
      {isUnknownVariable && <IconButton className="variable__remove" icon={Remove} onClick={deleteNode} />}
    </NodeViewWrapper>
  );
};
