import React from "react";
import { Col, Form, Input, Row, Spin } from "antd";
import ExtraMapping from "../../common/CommonMappings/ExtraMappings";
import { DefaultMappings } from "../../common/CommonMappings/DefaultMappings";
import { useDefaultMappings } from "../../common/CommonMappings/useMappings";

export const MappingTemplateForm = ({ disabled }) => {
  const inputFormat = Form.useWatch(["mapping", "inputFormat"]);
  const outputFormat = Form.useWatch(["mapping", "outputFormat"]);
  const { mappings, loading } = useDefaultMappings(inputFormat, outputFormat);
  return (
    <Spin spinning={loading}>
      <Form.Item name={["mapping", "inputFormat"]} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name={["mapping", "outputFormat"]} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ExtraMapping fieldName="mapping" disabled={disabled} defaultMappings={mappings} />
        </Col>
        {mappings?.length > 0 && (
          <Col span={24}>
            <DefaultMappings data={mappings} />
          </Col>
        )}
      </Row>
    </Spin>
  );
};
