import { Button, Form, PageHeader, Popconfirm, Tabs } from "antd";
import { FormActions } from "@/components/common/FormActions";
import { HiddenField } from "@/components/common/HiddenField";
import { useFormActions } from "@/utils/useFormActions";
import type {
  CreateOnDemandReport,
  OnDemandReport,
  UpdateOnDemandReport,
} from "@/api/on-demand-reports/types";
import { useOnDemandReportMutation } from "@/api/on-demand-reports/hooks";
import { deleteOnDemandReport } from "@/api/on-demand-reports";
import { useCallback, useMemo } from "react";
import { tab } from "@/utils/tab";
import * as R from "ramda";
import { useHistory, useParams } from "react-router-dom";
import { OnDemandReportSummaryForm } from "./OnDemandReportSummaryForm";
import { OnDemandReportQueriesForm } from "./OnDemandReportQueriesForm";
import { OnDemandReportSpreadsheetForm } from "./OnDemandReportSpreadsheetForm";
import { DownloadSpreadsheetButton } from "../DownloadSpreadsheetButton";
import { FormErrorsContext } from "@/components/common/Form/context";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export enum OnDemandReportTab {
  Summary = "summary",
  Query = "query",
  Spreadsheet = "spreadsheet",
}

const fieldsByTab = {
  [OnDemandReportTab.Summary]: ["name"],
  [OnDemandReportTab.Query]: ["openplayQueries"],
  [OnDemandReportTab.Spreadsheet]: ["spreadsheet"],
};

type Props = {
  initialValues?: Partial<OnDemandReport>;
  isEditingByDefault?: boolean;
};

type FormValues = Partial<CreateOnDemandReport | UpdateOnDemandReport> & { hasSpreadsheet: boolean };

export const parseReportFields = (fields: Partial<OnDemandReport>) => ({
  ...fields,
  hasSpreadsheet: true,
});

export const prepareReportDto = ({ hasSpreadsheet, ...values }: FormValues) => ({
  ...values,
  spreadsheet: hasSpreadsheet ? values.spreadsheet : null,
});

export const OnDemandReportForm = ({ initialValues = {}, isEditingByDefault = false }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const { trigger: save } = useOnDemandReportMutation(initialValues.id);

  const initialFields = useMemo(() => parseReportFields(initialValues), [initialValues]);

  const {
    editing,
    submitting,
    handleFinish,
    handleFinishFailed,
    handleSubmit,
    handleCancel,
    handleEdit,
    handleValueChange,
    handleDelete,
    fieldsWithErrors,
    handleFieldsChange,
    // @ts-expect-error
  } = useFormActions<FormValues>({
    onSave: (values) => {
      // @ts-expect-error
      return save(prepareReportDto(values));
    },
    onDelete: deleteOnDemandReport,
    form,
    listingUrl: "/on-demand-reports",
    editUrl: (id) => `/on-demand-reports/${id}/${OnDemandReportTab.Summary}`,
    initialValues: initialFields,
    isEditingByDefault,
  });

  const history = useHistory();

  const title = initialValues.name ?? "New On-Demand Report";
  const isDeletable = !!initialValues.id && !editing;

  const isSpreadsheetDownloadable = !!initialValues.id && !editing;

  const rootFieldsWithErrors = useMemo(() => fieldsWithErrors.map((field) => field.name[0]), [
    fieldsWithErrors,
  ]);

  const hasErrors = useCallback(
    (tab: OnDemandReportTab) => R.intersection(fieldsByTab[tab], rootFieldsWithErrors).length > 0,
    [rootFieldsWithErrors]
  );

  const { activeTab = OnDemandReportTab.Summary } = useParams<{ activeTab: OnDemandReportTab }>();

  const onTabChanged = useCallback(
    (newTab: OnDemandReportTab) =>
      history.replace(`/on-demand-reports/${initialValues?.id || "new"}/${newTab}`),
    [initialValues, history]
  );

  const tabs = useMemo(
    () => [
      tab({
        key: OnDemandReportTab.Summary,
        label: "Summary",
        hasErrors: hasErrors(OnDemandReportTab.Summary),
        content: <OnDemandReportSummaryForm readOnly={!editing} />,
      }),
      tab({
        key: OnDemandReportTab.Query,
        label: "Queries",
        hasErrors: hasErrors(OnDemandReportTab.Query),
        content: <OnDemandReportQueriesForm readOnly={!editing} />,
      }),
      tab({
        key: OnDemandReportTab.Spreadsheet,
        label: "Spreadsheet",
        hasErrors: hasErrors(OnDemandReportTab.Spreadsheet),
        content: <OnDemandReportSpreadsheetForm readOnly={!editing} />,
      }),
    ],
    [editing, hasErrors]
  );

  const { ability } = useAuth();

  const canEdit = ability.can(PermissionAction.Update, PermissionSubject.OnDemandReport);
  const canDelete = ability.can(PermissionAction.Delete, PermissionSubject.OnDemandReport);

  return (
    <>
      <PageHeader
        title={<h1 style={{ margin: 0 }}>{title}</h1>}
        extra={
          <>
            {/* @ts-expect-error */}
            {isSpreadsheetDownloadable && <DownloadSpreadsheetButton report={initialValues} />}
            <FormActions
              isEditing={editing}
              onSave={handleSubmit}
              isSaving={submitting}
              onEdit={handleEdit}
              canEdit={canEdit}
              onCancel={handleCancel}
            />
            {isDeletable && (
              <Popconfirm
                title="Are you sure to delete the on-demand report?"
                onConfirm={handleDelete}
                okText="Yes"
                cancelText="No"
                disabled={!canDelete}
              >
                <Button disabled={!canDelete}>Delete</Button>
              </Popconfirm>
            )}
          </>
        }
      />
      <FormErrorsContext.Provider value={{ fieldsWithErrors }}>
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          onValuesChange={handleValueChange}
          labelAlign="left"
          onFieldsChange={handleFieldsChange}
          initialValues={initialFields}
        >
          <HiddenField name="id" />
          <Tabs activeKey={activeTab} onChange={onTabChanged} items={tabs} />
        </Form>
      </FormErrorsContext.Provider>
    </>
  );
};
