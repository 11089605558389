import { useCallback } from "react";
import { Form } from "antd";
import { getStatusLabel, Status, TemplateType } from "@/utils";
import { useBoolean } from "ahooks";
import { prepareAssetNaming } from "../../../common/CommonMappings/utils";
import { SaveAsTemplateForm } from "../../../Templates/SaveAsTemplateForm";
import { SaveAsTemplateButton } from "../../../Templates/SaveAsTemplateButton";

export const SaveAsAssetNamingTemplate = ({ outerFormField, disabled, buttonType }) => {
  const outerForm = Form.useFormInstance();

  const feedStatus = Form.useWatch("status");
  const naming = Form.useWatch(outerFormField);

  const segments = (naming?.segments || []).filter((segment) => !!segment);

  const [isFormVisible, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const handleClick = async () => {
    const fieldsToValidate = [[...outerFormField, "customDelimiter"]];
    try {
      await outerForm.validateFields(fieldsToValidate);
      openForm();
    } catch (e) {
      console.error(e);
    }
  };

  const getTemplateFields = useCallback(() => {
    const assetNaming = prepareAssetNaming(outerForm.getFieldValue(outerFormField));
    return {
      type: TemplateType.AssetNaming,
      data: {
        assetType: assetNaming.assetType,
        delimiter: assetNaming.delimiter,
        segments: assetNaming.segments,
      },
    };
  }, [outerForm, outerFormField]);

  return (
    <>
      <SaveAsTemplateButton
        onClick={handleClick}
        disabled={disabled}
        unavailable={feedStatus === Status.Draft || segments.length === 0}
        unavailableTooltip={`Save as template couldn't be completed when status of feed is ${getStatusLabel(
          Status.Draft
        )} or no asset name segments are set`}
        buttonType={buttonType}
      />
      <SaveAsTemplateForm
        isVisible={isFormVisible}
        onClose={closeForm}
        getTemplateFields={getTemplateFields}
      />
    </>
  );
};
