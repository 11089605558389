import { Field } from "@/components/common/Form/Field";
import { Switch } from "antd";

export const AddSenderToBCC = () => (
  <Field
    label="Add sender to BCC?"
    name="addSenderToBcc"
    valuePropName="checked"
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
  >
    <Switch />
  </Field>
);
