import { useParams } from "react-router-dom";
import { Spinner } from "../../common/Spinner/Spinner";
import { ReportForm } from "./ReportForm";
import { useReport } from "@/api/reports/hooks";

export const ReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: report, isLoading } = useReport(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <ReportForm initialValues={report} />;
};
