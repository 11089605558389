import { Col, Form, Input, Modal, Popconfirm, Row, Select, Switch, Tree } from "antd";
import type {
  CreateOpenplayObjectFieldDto,
  OpenplayObjectField,
  UpdateOpenplayObjectFieldDto,
} from "@/api/openplay-objects/types";
import { types } from "@/api/openplay-objects/types";
import React, { useMemo } from "react";
import "./OpenplayObjectFieldTree.less";
import cn from "classnames";
import { useBoolean } from "ahooks";
import { HiddenField } from "@/components/common/HiddenField";
import { Field } from "@/components/common/Form/Field";
import { toOptions } from "@/utils/toOptions";
import { capitalize, stripUndefined, useAuth } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow, PlusCircle, Remove } from "@/components/common/Icons";
import {
  createOpenplayObjectField,
  deleteOpenplayObjectField,
  updateOpenplayObjectField,
} from "@/api/openplay-objects";
import { sortBy } from "ramda";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Tooltip } from "@/components/common/Tooltip";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type OpenplayFieldTreeNode = Omit<OpenplayObjectField, "children"> & {
  key: string;
  title: string;
  children: OpenplayFieldTreeNode[];
};

const prepareTreeData = (data: OpenplayObjectField[]): OpenplayFieldTreeNode[] =>
  sortBy(
    (field) => field.name,
    data.map((item) => ({
      ...item,
      title: item.name,
      key: item.id,
      children: prepareTreeData(item.children),
    }))
  );

const getNodeTypeLabel = (node: OpenplayFieldTreeNode): string => {
  if (node.type) {
    return node.isArray ? `${node.type}[]` : node.type;
  }

  return "unknown";
};

type Props = {
  root: OpenplayObjectField;
  onFieldEdited?: () => void;
};

type FormData = CreateOpenplayObjectFieldDto | UpdateOpenplayObjectFieldDto;

const fieldLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

export const OpenplayObjectFieldTree = ({ root, onFieldEdited }: Props) => {
  const { ability } = useAuth();
  const canUpdate = ability.can(PermissionAction.Update, PermissionSubject.OpenplayObject);

  const treeData = useMemo(() => prepareTreeData([root]), [root]);
  const [isModalOpen, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

  const [form] = Form.useForm<FormData>();

  const handleFieldClick = (node: OpenplayFieldTreeNode) => {
    if (!canUpdate) {
      return;
    }
    if (node.id === root.id) {
      return;
    }
    form.setFieldsValue(node);
    openModal();
  };

  const handleAddField = (parent: OpenplayFieldTreeNode) => {
    form.setFieldsValue({ parentId: parent.id });
    openModal();
  };

  const handleDeleteField = async (id: string) => {
    try {
      await deleteOpenplayObjectField(id);
      onFieldEdited?.();
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };

  const handleFinish = async (values: FormData) => {
    try {
      const valuesToSubmit = stripUndefined(values);
      if ("id" in valuesToSubmit) {
        await updateOpenplayObjectField(valuesToSubmit);
      } else {
        await createOpenplayObjectField(valuesToSubmit);
      }
      onFieldEdited?.();
    } catch (e) {
      console.error("unhandled error", e);
    }
    closeModal();
    form.resetFields();
  };

  return (
    <>
      <Tree
        className="openplay-field-tree"
        selectable={false}
        treeData={treeData}
        showLine
        defaultExpandedKeys={[root.id]}
        titleRender={(node) => (
          <span className={cn("openplay-field", node.hidden && "openplay-field--hidden")}>
            <span className="openplay-field__title" onClick={() => handleFieldClick(node)}>
              <span className="openplay-field__name">{node.name}</span>
              {node.label && <span className="openplay-field__label">{node.label}</span>}
              <span className={cn("openplay-field__type", !node.type && "openplay-field__type--unknown")}>
                {getNodeTypeLabel(node)}
              </span>
            </span>
            <span className="openplay-field__actions">
              {node.type === "object" && (
                <Tooltip title="Add Field">
                  <IconButton icon={PlusCircle} onClick={() => handleAddField(node)} />
                </Tooltip>
              )}
              {node.id !== root.id && canUpdate && (
                <Popconfirm
                  title={`Are you sure to delete the field?`}
                  onConfirm={() => handleDeleteField(node.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <IconButton icon={Remove} danger />
                </Popconfirm>
              )}
            </span>
          </span>
        )}
      />
      <Modal open={isModalOpen} onCancel={handleCancel} title="Edit Field" okText="Save" onOk={form.submit}>
        <Form form={form} onFinish={handleFinish}>
          <HiddenField name="id" />
          <HiddenField name="parentId" />
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Field
                name="name"
                label="Name"
                {...fieldLayout}
                rules={[
                  {
                    required: true,
                    message: "Please, provide a field name",
                    transform: (value) => value?.trim(),
                  },
                ]}
              >
                <Input placeholder="Field Label" />
              </Field>
            </Col>
            <Col span={12}>
              <Field name="type" label="Type" {...fieldLayout}>
                <Select
                  options={toOptions(types, capitalize)}
                  suffixIcon={<Icon component={DropdownArrow} />}
                  placeholder="Field Type"
                />
              </Field>
            </Col>
            <Col span={24}>
              <Field name="label" label="Label" requiredMark="optional" {...fieldLayout}>
                <Input placeholder="Field Label" />
              </Field>
            </Col>
            <Col>
              <Field name="isArray" label="Is Array?" valuePropName="checked" {...fieldLayout}>
                <Switch />
              </Field>
            </Col>
            <Col>
              <Field name="hidden" label="Is Hidden?" valuePropName="checked" {...fieldLayout}>
                <Switch />
              </Field>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
