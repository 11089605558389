import { useFilters } from "./useFilters";
import { useOrderBy } from "./useOrderBy";
import { usePagination } from "./usePagination";

export function useGridParams() {
  const [pagination] = usePagination();
  const [sort] = useOrderBy();
  const { filters } = useFilters();
  return { pagination, sort, filters };
}
