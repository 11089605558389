import type { Filter } from "@/components/common/FilterInput/FilterInput";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { DeliveryStatus, getDeliveryStatusLabel } from "@/utils";
import { searchArtists } from "@/api/releases";

export const filtersConfig: Filter[] = [
  { name: "release_date", label: "Release Date", type: FilterType.DateRange },
  {
    name: "status",
    label: "Warning Filter",
    type: FilterType.Radio,
    options: toOptions([DeliveryStatus.Succeeded, DeliveryStatus.Failed], getDeliveryStatusLabel),
  },
  { name: "upcs[]", label: "UPCs", type: FilterType.UPCs },
  { name: "upcContains", label: "UPC Contains", type: FilterType.Text },
  { name: "artist", label: "Artist", type: FilterType.Autocomplete, getSuggestions: searchArtists },
  { name: "title", label: "Title", type: FilterType.Text },
  {
    name: "configurations[]",
    label: "Configuration",
    type: FilterType.Dictionary,
  },
  { name: "genres[]", label: "Genres", type: FilterType.Dictionary },
];
