import { Space } from "antd";
import type { Correspondent } from "@/api/report-logs/types";
import { CorrespondentTag } from "./CorrespondentTag";

type Props = {
  recipients: Correspondent[];
};

export const RecipientList = ({ recipients }: Props) => {
  return (
    <Space wrap>
      {recipients.map((recipient) => (
        <CorrespondentTag
          key={typeof recipient === "string" ? recipient : recipient.address}
          correspondent={recipient}
        />
      ))}
    </Space>
  );
};
