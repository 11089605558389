import { Input, Tooltip } from "antd";

type Props = {
  message?: string;
};

export const RestrictedField = ({ message }: Props) => (
  <Tooltip title={message}>
    <div>
      <Input placeholder="Restricted" disabled />
    </div>
  </Tooltip>
);
