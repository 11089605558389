import { Button, Col, Form, Row } from "antd";
import { Box } from "../../common/Box/Box";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { HiddenField } from "@/components/common/HiddenField";

type FormValues = { sourceObject: { name: string; label: string } };

type Props = {
  onSelect: (values: FormValues) => void;
};

export const EmailAlertTypeSelector = ({ onSelect }: Props) => {
  const [form] = Form.useForm<FormValues>();

  const handleFinish = (values: FormValues) => {
    onSelect(values);
  };

  const handleChange = (_, option) => {
    form.setFieldValue(["sourceObject", "label"], option.label);
  };

  return (
    <Box>
      <Form
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
        form={form}
        onFinish={handleFinish}
        component="div"
      >
        <HiddenField name={["sourceObject", "label"]} />
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Source Object"
              rules={[{ required: true, message: "Field is mandatory." }]}
              name={["sourceObject", "name"]}
            >
              <SalesforceObjectPicker onChange={handleChange} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button onClick={form.submit} className="ant-btn-primary">
              Create Email Alert
            </Button>
          </Col>
        </Row>
      </Form>
    </Box>
  );
};
