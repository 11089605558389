import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type { CreateReportDto, ReportFilters, UpdateReportDto } from "@/api/reports/types";
import { fetchReport, fetchReports, saveReport } from "@/api/reports/index";
import { revalidateColumnSets } from "@/api/spreadsheets/hooks";

const key = "reports";

export const useReports = (filters: ReportFilters = {}) =>
  useSWR([key, filters], ([_, filters]) => fetchReports(filters), {
    fallbackData: { data: [] },
  });

export const useReport = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchReport(id), {
    fallbackData: null,
  });

export const useReportMutation = (id?: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreateReportDto | UpdateReportDto }) => saveReport(dto),
    { onSuccess: revalidateColumnSets }
  );
