import { Alert, Button, Col, Empty, Form, Row, Space } from "antd";
import { useState } from "react";
import { TemplateType } from "@/utils";
import { CopyFromTemplate } from "../../../common/CopyFromTemplate/CopyFromTemplate";
import { parseMappingItem } from "../../../common";
import classNames from "classnames";
import { requiredIfNotDraft } from "@/utils/validation";
import { MappingTemplatePicker } from "./MappingTemplatePicker";

const inputFormatField = ["mapping", "inputFormat"];
const outputFormatField = ["mapping", "outputFormat"];

const formatFields = [inputFormatField, outputFormatField];

export const FeedMappingTemplateSelector = ({ disabled }) => {
  const { setFieldValue } = Form.useFormInstance();
  const [template, setTemplate] = useState();
  const handleCreateMapping = () => {
    const [from, to] = template.split("_to_");
    setFieldValue(inputFormatField, from);
    setFieldValue(outputFormatField, to);
  };

  const operation = Form.useWatch("operation");
  const targetObject = Form.useWatch("targetObject");

  const handleCopyFromTemplate = (template) => {
    setFieldValue(inputFormatField, template.data.inputFormat);
    setFieldValue(outputFormatField, template.data.outputFormat);
    setFieldValue(["mapping", "data"], template.data.mappings.map(parseMappingItem));
  };

  return (
    <div className={classNames(disabled && "form-disabled")}>
      <Empty
        className="form-box"
        description="No mapping yet. In order to create mapping for the feed, please use one of the options below."
      />
      <Row className="form-box" gutter={[16, 16]}>
        <Form.Item noStyle dependencies={formatFields}>
          {({ getFieldsError }) => {
            const fieldErrors = getFieldsError(formatFields);

            const haveErrors = fieldErrors.some((fieldError) => fieldError.errors.length > 0);

            return (
              haveErrors && (
                <Col span={24}>
                  <Alert type="error" message="Please, create mapping for the feed using the options below" />
                </Col>
              )
            );
          }}
        </Form.Item>
        <Col flex="auto">
          <Form.Item
            label="Template"
            rules={[requiredIfNotDraft]}
            validateTrigger="onSubmit"
            offset={4}
            className="feed-mapping-main-page"
            name="template"
          >
            <MappingTemplatePicker
              value={template}
              onChange={setTemplate}
              operation={operation}
              targetObject={targetObject}
            />
          </Form.Item>
        </Col>
        <Col flex="0 0 auto">
          <div className="feed-mapping-main-page">
            <Space direction="horizontal">
              {operation && (
                <CopyFromTemplate
                  onCopy={handleCopyFromTemplate}
                  filter={{
                    type: TemplateType.Mapping,
                    operation,
                    targetObject,
                  }}
                />
              )}
              <Button disabled={!template} className="ant-btn-primary" onClick={handleCreateMapping}>
                Create Mapping
              </Button>
            </Space>
          </div>
        </Col>
      </Row>
    </div>
  );
};
