export enum StorageService {
  UCS = "ucs",
  S3 = "aws_s3",
  SFTP = "sftp",
  FTP = "ftp",
}

export const exportStorageServices = [StorageService.S3, StorageService.SFTP, StorageService.FTP];

export const importStorageServices = [
  StorageService.UCS,
  StorageService.S3,
  StorageService.SFTP,
  StorageService.FTP,
];

const labelLookup: Record<StorageService, string> = {
  [StorageService.S3]: "Amazon S3 Bucket",
  [StorageService.SFTP]: "sFTP",
  [StorageService.FTP]: "FTP",
  [StorageService.UCS]: "Universal Content Site",
};

export const getStorageServiceLabel = (service: StorageService) => labelLookup[service];
