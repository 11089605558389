import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { SelectProps } from "antd";
import { Form, Select } from "antd";
import type { SalesforceQuery } from "@/api/reports/types";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";

type Props = Omit<SelectProps, "options"> & {
  onlyWithOpenplayEquivalents?: boolean;
};

export const QueryPicker = (props: Props) => {
  const queries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const options = queries
    .filter(
      (entry) =>
        !!entry.from && (props.onlyWithOpenplayEquivalents ? !!getOpenPlayEquivalent(entry.from) : true)
    )
    .map((entry, index) => ({ value: entry.id, label: entry.name ?? `Query #${index + 1}` }));

  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Query"
      {...props}
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
      notFoundContent="No queries available"
    />
  );
};
