import type { Group } from "./utils";
import type { Config } from "@react-awesome-query-builder/core";
import { ExpressionContainer } from "./ExpressionContainer";

type Props = {
  group: Group;
  config: Config;
};

export const GroupContainer = ({ group, config }: Props) => {
  return (
    <div className="readonly-condition-group">
      <div className="readonly-condition-group__operators">
        {group.negated && <div>not</div>}
        {group.children.length > 1 && <div>{group.operator}</div>}
      </div>
      <div className="readonly-condition-group__expressions">
        {group.children.map((expression, i) => (
          <ExpressionContainer key={i} expression={expression} config={config} />
        ))}
      </div>
    </div>
  );
};
