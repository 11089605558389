import type { ColumnsType } from "antd/lib/table/interface";
import { Status } from "@/utils";
import type { ExportSchedule } from "@/api/export-schedules/types";
import { StatusTag } from "@/components/common";
import { getScheduleColumns } from "@/components/common/schedule-columns";
import { FeedCell } from "@/components/common/FeedCell";
import { PartnerCell } from "@/components/common/PartnerCell"; 

export const columns: ColumnsType<ExportSchedule> = [
  {
    title: "Status",
    dataIndex: "isActive",
    render: (_, entry) => <StatusTag status={entry.isActive ? Status.Active : Status.Inactive} />,
  },
  ...getScheduleColumns<ExportSchedule>((entry) => entry.schedule, "schedule"),
  {
    title: "Feed",
    key: "feed",
    render: (_, entry) => <FeedCell feed={entry.feed} />,
  },
  {
    title: "Partner",
    key: "partner",
    render: (_, entry) => <PartnerCell partner={entry.feed.partner} />,
  },
];
