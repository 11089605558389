import * as R from "ramda";
import { ORDER_BY_SORT, SORT_TYPE } from "./const";
import moment from "moment";
import { Status } from "@/utils/status";
import { DeliveryStatus } from "@/utils/delivery-status";

export const isEmpty = R.either(R.isNil, R.isEmpty);
export const isNotEmpty = R.compose(R.not, R.isEmpty);
export const isActive = R.equals(Status.Active);
export const isExist = R.compose(R.not, R.isNil);
export const isFailed = R.equals(DeliveryStatus.Failed);

export const DATE_FORMATTER = new Intl.DateTimeFormat("en-US");

export function prepareFilters(availableFilters, enabledFilters) {
  return R.pipe(
    R.filter(R.pipe(R.prop("name"), R.includes(R.__, enabledFilters))),
    R.sortBy(R.pipe(R.prop("name"), R.indexOf(R.__, enabledFilters)))
  )(availableFilters);
}

export const disabledStartDate = (current) => {
  // Can not select days before today
  return current && current < moment().startOf("day");
};

export const disabledEndDate = (startDate) => (current) => {
  // Can not select days before today if startDate in the past
  if (startDate && startDate < moment().startOf("day")) {
    return current && current < moment().startOf("day");
  }
  // Can not select days before startDate
  if (startDate) {
    return current && current < startDate;
  }
  // Can not select days before today
  return current && current < moment().startOf("day");
};

/**
 *
 * @param filters
 * @param pagination
 * @param sortParams
 * @returns {{limit: number, page: number, order: "ASC" | "DESC", sort_by: string}}
 */
export const prepareParams = (filters, pagination, sortParams) => ({
  page: pagination.page,
  limit: pagination.limit,
  ...(sortParams?.order && {
    ...sortParams,
    [ORDER_BY_SORT]: SORT_TYPE[sortParams.order],
  }),
  ...(!R.isEmpty(filters) && {
    ...filters,
  }),
});

export const validateEndDate = (_, value) => {
  if (value && value < moment().startOf("day")) {
    return Promise.reject("This date should not be in the past");
  }
  return Promise.resolve();
};

export { dictionaryToOptions } from "./dictionaryToOptions";

export const parseFileName = R.pipe(R.split(";"), R.last, R.split("="), R.last, R.replace(/^"|"$/g, ""));

export const upcFromCsv = R.pipe(
  R.split("\n"),
  R.map(R.split(",")),
  R.flatten,
  R.map(R.trim),
  R.filter(isNotEmpty),
  R.filter(R.test(/^[0-9]+$/))
);

export async function parseUpcFile(file) {
  const data = await readFile(file);
  return upcFromCsv(data);
}

export async function readFile(file) {
  const reader = new FileReader();
  reader.readAsText(file);
  return new Promise((resolve) => {
    reader.onload = (readerEvt) => {
      resolve(readerEvt.target.result);
    };
  });
}

export const getValidValue = (values) => (R.isNil(values) ? [] : values.filter((el) => isNotEmpty(el)));

export const truncate = (input, length = 20) =>
  input.length > length ? `${input.substring(0, length)}...` : input;

export const mapIds = (entities) => entities.map((entity) => entity.id);

export const capitalize = R.replace(/^./, R.toUpper);

export const isValidUPC = (upc) => /^\d{12,13}$/.test(upc);

export const isNotEmptyString = (str) => str?.trim().length > 0;

export const formatList = (items) =>
  new Intl.ListFormat("en", { style: "long", type: "conjunction" }).format(items);

export const mapFieldsToVariables = (fieldsByObject, objectName, withRelations = true, prefix = null) =>
  (fieldsByObject[objectName] ?? [])
    .filter((field) => withRelations || field.type !== "reference")
    .flatMap((field) => {
      const fieldName = prefix ? `${prefix}.${field.name}` : field.name;
      return field.type === "reference"
        ? mapFieldsToVariables(fieldsByObject, field.referenceTo, false, fieldName)
        : [fieldName];
    });

export const DATETIME_FORMATTER = new Intl.DateTimeFormat("en-US", {
  hour: "2-digit",
  minute: "2-digit",
  year: "numeric",
  month: "numeric",
  day: "numeric",
});
