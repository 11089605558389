import useUrlState from "@ahooksjs/use-url-state";
import { useMemo } from "react";
import { ORDER_BY_NAME, ORDER_BY_SORT } from "./const";

export function useOrderBy() {
  const [state, setState] = useUrlState({}, { navigateMode: "replace" });

  const name = state[ORDER_BY_NAME];
  const order = state[ORDER_BY_SORT];
  const preparedState = useMemo(
    () => ({
      [ORDER_BY_NAME]: name,
      [ORDER_BY_SORT]: order,
    }),
    [name, order]
  );

  return [preparedState, setState] as const;
}
