import { Popover, Space } from "antd";
import { StatusTag } from "@/components/common/index";
import { StatusBadge } from "@/components/common/StatusBadge/StatusBadge";
import type { Partner } from "@/api/partners/types";

type Props = {
  partner: Partner;
};

export const PartnerCell = ({ partner }: Props) => (
  <Popover
    title={<span style={{ fontSize: 16 }}>{partner.name}</span>}
    trigger="hover"
    content={
      <Space>
        <strong>Status:</strong>
        <StatusTag status={+partner.status} />
      </Space>
    }
  >
    <StatusBadge status={+partner.status} text={partner.name} />
  </Popover>
);
