import ct from "countries-and-timezones";
import * as moment from "moment-timezone";

export const getAllTimezones = () => Object.values(ct.getAllTimezones());

export const getUserTimezone = () => {
  try {
    const detectedZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zone = ct.getTimezone(detectedZone);
    return zone.deprecated ? zone.aliasOf : zone.name;
  } catch (e) {
    return null;
  }
};

export const getTimezoneDescription = (timezone: string) => {
  const momentWithTz = moment.tz(timezone);
  const offset = momentWithTz.format("Z");
  const abbr = momentWithTz.format("z");
  return abbr.match(/[+-]\d{2}(\d{2})?/) ? offset : `${offset} ${abbr}`;
};
