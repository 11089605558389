import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Select } from "antd";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import { useOpenplayCalculatedFields } from "@/api/spreadsheets/hooks";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  sourceObject: string;
};

export const CalculatedValuePicker = ({ value, onChange, sourceObject }: Props) => {
  const openplayEquivalent = getOpenPlayEquivalent(sourceObject);

  const { data: fields, isLoading } = useOpenplayCalculatedFields();

  const filteredFields = fields.filter(({ entity }) => entity === openplayEquivalent);

  return (
    <Select
      value={value}
      onChange={onChange}
      options={filteredFields}
      suffixIcon={<Icon component={DropdownArrow} />}
      placeholder="Calculated Value"
      loading={isLoading}
      fieldNames={{ label: "label", value: "key" }}
      notFoundContent="No options available for the selected object"
    />
  );
};
