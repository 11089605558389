import type { Logic } from "@/api/types";
import { parseLogic } from "./utils";
import { Alert } from "antd";
import "./ReadOnlyCondition.less";
import type { Config } from "@react-awesome-query-builder/core";
import { ExpressionContainer } from "./ExpressionContainer";

type Props = {
  value?: Logic;
  config: Config;
};

export const ReadOnlyCondition = ({ value, config }: Props) => {
  try {
    const expression = parseLogic(value);
    return expression ? (
      <ExpressionContainer expression={expression} config={config} />
    ) : (
      <Alert message="No condition specified" />
    );
  } catch (e) {
    console.error(e);
    return <Alert message="Cannot display the condition" type="error" />;
  }
};
