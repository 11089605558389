import { LogoColor } from "./constants";

const BackgroundLookup = {
  [LogoColor.Black]: "#000",
  [LogoColor.White]: "#D7D7D7",
  [LogoColor.WarmGrey]: "#A3978E",
};

const FontColorLookup = {
  [LogoColor.Black]: "#fff",
  [LogoColor.White]: "#000",
  [LogoColor.WarmGrey]: "#fff",
};

export const LogoSmall = ({ color, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 196 189" width={width} height={height}>
    <path
      fill={BackgroundLookup[color]}
      d="M.01 94.58C32.66 62.91 65.48 31.45 98.2 0c5.52 5.68 96.42 92.88 97.79 94.19-1.4 1.31-94.54 90.76-98.28 94.37 0 0-97.32-93.66-97.71-93.99l.01.01Z"
    />
    <path
      fill={FontColorLookup[color]}
      d="M140.11 109.73c-.15-.05-.21-.07-.28-.09-7.05-2.26-14.57-4.66-21.61-6.93-.12.26-.14.34-.29.65-.58 1.2-1.12 2.23-1.76 3.4-2.31 4.21-5.49 7.45-10.18 8.94-4.4 1.4-8.87 1.51-13.33.31-3.95-1.06-7.31-3.16-9.94-6.28-3.27-3.88-4.63-8.58-5.16-13.51-.39-3.59.23-7.1 1.44-10.5 1.46-4.09 3.63-7.62 7.39-10.06 5.23-3.39 10.87-4.32 16.9-3.15 3.91.76 7.35 2.52 9.67 5.83 1.47 2.11 2.51 4.52 3.74 6.8.18.33.32.69.5 1.07 7.49-2.42 14.93-4.84 22.37-7.25-.07-.26-.11-.44-.17-.62-2.18-7.82-6.23-14.43-12.72-19.44-3.78-2.91-7.79-5.34-12.35-6.82-7.11-2.32-14.4-2.93-21.83-2.22-4.22.4-8.33 1.34-12.23 2.97-16.32 6.82-25.44 19.12-27.47 36.64-.56 4.81-.14 9.63.77 14.4 1.94 10.24 7.14 18.55 15.11 25.11 8.49 6.98 18.45 9.96 29.31 10.22 4.57.11 9.09-.45 13.54-1.58 7.78-1.97 14.55-5.78 19.82-11.85 2.2-2.53 4.16-5.3 5.82-8.22 1.37-2.4 2.14-5.11 2.93-7.81l.01-.01Z"
    />
  </svg>
);
