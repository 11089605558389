import { Form } from "antd";
import { FieldGroup } from "./FieldGroup";
import { useFieldPath } from "./hooks";
import { joinFieldName } from "./utils";
import { FieldGroupType } from "./constants";
import type { FormListProps } from "antd/es/form";
import type { NamePath } from "rc-field-form/es/interface";
import type { ReactNode } from "react";

export const FieldList = ({ name, children, ...rest }: FormListProps) => {
  const { localPath } = useFieldPath();
  return (
    <FieldGroup prefix={name} type={FieldGroupType.List}>
      <Form.List name={joinFieldName(...localPath, name)} children={children} {...rest} />
    </FieldGroup>
  );
};

type FieldListItemProps = {
  name: NamePath;
  children: ReactNode;
};

export const FieldListItem = ({ name, children }: FieldListItemProps) => (
  <FieldGroup prefix={name} type={FieldGroupType.ListItem}>
    {children}
  </FieldGroup>
);
