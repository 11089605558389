import { useParams } from "react-router-dom";
import { Spinner } from "../../common/Spinner/Spinner";
import { TemplateForm } from "./TemplateForm";
import { useTemplate } from "@/api/templates/hooks";

export const TemplatePage = () => {
  const { id } = useParams<{ id: string }>();
  const { isLoading, data: template } = useTemplate(id);

  if (isLoading) {
    return <Spinner />;
  }

  // @ts-expect-error
  return <TemplateForm initialValues={template} />;
};
