import ButtonWithConfirm from "./ButtonWithConfirm";
import { Button, Space } from "antd";
import { Tooltip } from "@/components/common/Tooltip";

export const FormActions = ({
  isEditing,
  onCancel,
  isSaving,
  onSave,
  onEdit,
  canEdit = true,
  cannotEditMessage = null,
  cannotSaveMessage = null,
  canSave = true,
}) => {
  return isEditing ? (
    <Space size={8}>
      <ButtonWithConfirm key="cancel" onClick={onCancel} text="Cancel" />
      <Tooltip title={canSave ? null : cannotSaveMessage}>
        <Button
          key="save"
          type="primary"
          htmlType="submit"
          loading={isSaving}
          onClick={onSave}
          disabled={!canSave}
        >
          Save
        </Button>
      </Tooltip>
    </Space>
  ) : (
    <Tooltip title={canEdit ? null : cannotEditMessage}>
      <Button type="primary" key="edit" onClick={onEdit} disabled={!canEdit}>
        Edit
      </Button>
    </Tooltip>
  );
};
