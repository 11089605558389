import { IconButton } from "@/components/common/IconButton/IconButton";
import type { Editor } from "@tiptap/react";
import { Brackets } from "../icons";
import { buttonClassName } from "./utils";
import { useVariableModal } from "../Variables/hooks";
import { useCallback } from "react";
import type { Variables } from "../Variables/types";

type Props = {
  editor: Editor;
  variables: Variables;
};

export const VariableButton = ({ editor, variables }: Props) => {
  const { modal, open } = useVariableModal(variables);

  const handleOpenModal = useCallback(() => {
    open({ editor });
  }, [editor, open]);

  return (
    <>
      <IconButton icon={Brackets} onClick={handleOpenModal} className={buttonClassName} />
      {modal}
    </>
  );
};
