import type { Filter } from "@/components/common/FilterInput/FilterInput";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { DeliveryStatus, getDeliveryStatusLabel, getStatusLabel, Status as StatusCode } from "@/utils";

export const filtersConfig: Filter[] = [
  {
    name: "status",
    label: "Delivery status",
    type: FilterType.Select,
    options: toOptions(Object.values(DeliveryStatus), getDeliveryStatusLabel),
  },
  { name: "feedName", label: "Feed Name", type: FilterType.Text },
  { name: "partnerName", label: "Partner Name", type: FilterType.Text },
  {
    name: "partnerStatus",
    label: "Partner status",
    type: FilterType.Radio,
    options: toOptions([StatusCode.Active, StatusCode.Inactive].map(String), getStatusLabel),
  },
  { name: "deliveryDate", label: "Delivery date", type: FilterType.DateRange },
];
