import { Col, Form, Modal, Row } from "antd";
import { useBoolean } from "ahooks";
import { IconButton } from "@/components/common/IconButton/IconButton";
import type { Editor } from "@tiptap/react";
import { Link } from "../icons";
import { getButtonClassName } from "./utils";
import { TextFieldWithVariables } from "../TextFieldWithVariables";
import type { Variables } from "../Variables/types";

type Props = {
  editor: Editor;
  variables: Variables;
};

type FormValues = { url: string };

export const LinkButton = ({ editor, variables }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const handleCancel = () => {
    form.resetFields();
    close();
  };

  const handleFinish = async ({ url }: FormValues) => {
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: url.replaceAll('"', "'") })
      .run();
    close();
  };

  const handleOpen = () => {
    const previousUrl = editor?.getAttributes("link").href;
    form.setFieldsValue({ url: previousUrl });
    open();
  };

  return (
    <>
      <IconButton icon={Link} onClick={handleOpen} className={getButtonClassName(editor, "link")} />
      <Modal open={isOpen} title="Link" onCancel={handleCancel} onOk={form.submit}>
        <Form form={form} onFinish={handleFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="url"
                label="URL"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Field is mandatory" }]}
              >
                <TextFieldWithVariables variables={variables} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
