import React from "react";
import PropTypes from "prop-types";
import "./OperationTag.less";
import { Operation } from "@/utils";
import Icon from "@ant-design/icons";
import { ArrowDown, ArrowUp } from "../Icons";
import cn from "classnames";
import { Tag } from "../Tag/Tag";

export const OperationTag = ({ operation, showLabel = false, onClick }) => (
  <Tag className={cn("feed-operation-tag", `feed-operation-tag--${operation}`)} onClick={onClick}>
    <Icon
      className="feed-operation-tag__icon"
      component={operation === Operation.Export ? ArrowUp : ArrowDown}
    />
    {showLabel && <span className="feed-operation-tag__label">{operation}</span>}
  </Tag>
);

OperationTag.propTypes = {
  operation: PropTypes.oneOf([Operation.Export, Operation.Import]).isRequired,
};
