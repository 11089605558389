import { Switch } from "antd";
import "./StatusSwitch.less";
import type { ComponentProps } from "react";

type Props = ComponentProps<typeof Switch> & {
  checkedLabel?: string;
  uncheckedLabel?: string;
};

export const StatusSwitch = ({ checkedLabel = "Active", uncheckedLabel = "Inactive", ...rest }: Props) => {
  return (
    <div className="status-switch">
      <span>{uncheckedLabel}</span>
      <Switch {...rest} />
      <span>{checkedLabel}</span>
    </div>
  );
};
