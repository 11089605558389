import { Form, Input, InputNumber } from "antd";
import { Field } from "../../common/Form/Field";
import "./SpreadsheetColumn.less";
import { useFieldPath } from "@/components/common/Form/hooks";
import type * as Salesforce from "@/types/salesforce";
import { TimezoneSelect } from "@/components/common/TimezoneSelect/TimezoneSelect";
import { ColumnTypePicker } from "./ColumnTypePicker";
import { ColumnType } from "@/api/spreadsheets/types";
import { VariablePicker } from "./VariablePicker";
import { CalculatedValuePicker } from "./CalculatedValuePicker";
import { NumberFormatFieldHint, NumberFormatPicker } from "./NumberFormatPicker";
import { format } from "ssf";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { DateFormatFieldHint } from "@/components/common/DateFormatFieldHint";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker/SalesforceObjectPathPicker";
import { OpenplayObjectPathPicker } from "@/components/common/ObjectPathPicker/OpenplayObjectPathPicker";
import { getOpenPlayEquivalent } from "@/utils/getOpenPlayEquivalent";
import type { SimpleField } from "@/utils/dynamic-fields/types";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "@/components/common/Tooltip";
import { OpenplayQueryFieldPicker } from "@/components/OpenplayQueries/OpenplayQueryFieldPicker";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { TabSource } from "@/utils";

type SpreadsheetColumnProps = {
  source: TabSource;
  sourceObject: string;
  openplayQuery?: OpenplayQuery;
};

const numericFieldTypes = ["int", "double", "currency", "percent", "number"];

export const SpreadsheetColumn = ({ source, sourceObject, openplayQuery }: SpreadsheetColumnProps) => {
  const form = Form.useFormInstance();
  const { getAbsolutePath } = useFieldPath();
  const handleTypeChange = () => {
    form.setFields(
      ["dataType", "path", "value", "variable"].map((name) => ({
        name: getAbsolutePath(name),
        value: undefined,
      }))
    );
  };
  const handleSourceChange = (_: any, options = []) => {
    form.setFieldValue(getAbsolutePath("dataType"), options.at(-1)?.type ?? "string");
  };

  const handleVariableChange = (_: any, variable: SimpleField) => {
    form.setFieldValue(getAbsolutePath("dataType"), variable.type ?? "string");
  };
  const type = Form.useWatch<ColumnType>(getAbsolutePath("type"));
  const dataType = Form.useWatch<Salesforce.FieldType>(getAbsolutePath("dataType"));

  return (
    <div className="spreadsheet-column">
      <Field
        name="name"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Field is mandatory" }]}
        className="spreadsheet-column__name"
      >
        <Input placeholder="Name" />
      </Field>
      <Field
        name="type"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Field is mandatory" }]}
        className="spreadsheet-column__type"
        initialValue={ColumnType.Field}
      >
        <ColumnTypePicker onChange={handleTypeChange} source={source} />
      </Field>
      {type === ColumnType.StaticValue && (
        <Field
          name="value"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          className="spreadsheet-column__value"
        >
          <Input placeholder="Value" />
        </Field>
      )}
      {type === ColumnType.Field && (
        <Field
          name="path"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: "Field is mandatory" }]}
          className="spreadsheet-column__value"
        >
          {source === TabSource.Salesforce ? (
            <SalesforceObjectPathPicker obj={sourceObject} onChange={handleSourceChange} />
          ) : source === TabSource.OpenPlay ? (
            <OpenplayObjectPathPicker
              objectName={getOpenPlayEquivalent(sourceObject)}
              onChange={handleSourceChange}
            />
          ) : (
            <OpenplayQueryFieldPicker onChange={handleSourceChange} query={openplayQuery} />
          )}
        </Field>
      )}
      {type === ColumnType.Calculated && (
        <Field
          name="key"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: "Field is mandatory" }]}
          className="spreadsheet-column__value"
        >
          <CalculatedValuePicker sourceObject={sourceObject} />
        </Field>
      )}
      {type === ColumnType.Variable && (
        <Field
          name="variable"
          rules={[{ required: true, message: "Field is mandatory" }]}
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          className="spreadsheet-column__value"
        >
          <VariablePicker onChange={handleVariableChange} />
        </Field>
      )}
      <Field name="dataType" hidden>
        <Input />
      </Field>
      <Field
        name="width"
        labelCol={{ span: 0 }}
        wrapperCol={{ span: 24 }}
        rules={[{ type: "number", min: 0, max: 255, message: "Column width must be between 0 and 255" }]}
      >
        <InputNumber placeholder="Width (default: 20)" step={1} />
      </Field>
      {type === ColumnType.Field && (
        <div className="spreadsheet-column__template">
          <Field name="template" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
            <Input placeholder="Template" />
          </Field>
          <Tooltip title="Use * (asterisk) as a placeholder to insert a column value into the template string">
            <QuestionCircleOutlined className="spreadsheet-column__template-help" />
          </Tooltip>
        </div>
      )}
      {(dataType === "date" || dataType === "datetime") && (
        <Field
          name="dateFormat"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          className="spreadsheet-column__date-format"
          rules={[getFieldMaxLengthRule("Date format", 50)]}
          extra={<DateFormatFieldHint />}
        >
          <Input placeholder="Date Format (e.g. MM/DD/YYYY)" />
        </Field>
      )}
      {dataType === "datetime" && (
        <Field
          name="timezone"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          className="spreadsheet-column__timezone"
        >
          <TimezoneSelect placeholder="Timezone" allowClear />
        </Field>
      )}
      {numericFieldTypes.includes(dataType) && (
        <Field
          name="numberFormat"
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 24 }}
          className="spreadsheet-column__number-format"
          extra={<NumberFormatFieldHint />}
          rules={[
            {
              validator: (_, value) => {
                try {
                  format(value, 123.456);
                  return Promise.resolve();
                } catch (e) {
                  return Promise.reject(`Invalid number format (${e.message})`);
                }
              },
            },
          ]}
        >
          <NumberFormatPicker />
        </Field>
      )}
    </div>
  );
};
