import { api } from "@/api/core";
import type { Flow } from "@/api/flows/types";
import type {
  CreateOpenplayObjectFieldDto,
  ImportOpenplayObjectDto,
  OpenplayObject,
  OpenplayObjectField,
  UpdateOpenplayObjectFieldDto,
} from "./types";

const url = "/api/openplay-objects";

export const fetchOpenplayObjects = () => api.get<any, Omit<OpenplayObject, "root">[]>(url);

export const fetchOpenplayObject = (name: string) => api.get<any, OpenplayObject>(`${url}/${name}`);

export const importOpenplayObject = (params: ImportOpenplayObjectDto) =>
  api.post<any, Flow>(`${url}/import`, params);

export const createOpenplayObjectField = (params: CreateOpenplayObjectFieldDto) =>
  api.post<any, OpenplayObjectField>(`${url}/fields`, params);

export const updateOpenplayObjectField = (params: UpdateOpenplayObjectFieldDto) =>
  api.put<any, OpenplayObjectField>(`${url}/fields`, params);

export const deleteOpenplayObjectField = (id: string) =>
  api.delete<any, OpenplayObjectField>(`${url}/fields/${id}`);
