import * as R from "ramda";
import { ddex_3_8 } from "./ddex_3_8";
import { ddex_4_1 } from "./ddex_4_1";
import { ddex_4_2 } from "./ddex_4_2";
import { Format } from "@/utils/mappings";

const getXmlPaths = (shape, excludeArrays = false) => {
  if (!shape) {
    return [];
  }
  const resp = [];
  Object.entries(shape)
    .filter(([key]) => !R.startsWith("_", key))
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const children = value.map((c) => getXmlPaths(c, excludeArrays)).flat();
        resp.push({
          value: key,
          label: key,
          children: [...children],
        });
        if (!excludeArrays) {
          resp.push({
            value: `${key}[]`,
            label: `${key}[]`,
            children: [...children],
          });
        }
      } else if (R.is(Object, value)) {
        const children = getXmlPaths(value, excludeArrays);
        const opt = {
          value: key,
          label: key,
        };
        if (children.length > 0) {
          opt.children = children;
        }
        resp.push(opt);
      } else if (!value || typeof value === "string") {
        resp.push({
          value: key,
          label: key,
        });
      }
    });
  return resp;
};

export const getDDEXPaths = R.memoizeWith(
  (...args) => R.join("_", args),
  (format, excludeArrays) => {
    switch (format) {
      case Format.DDEX_3_8:
        return getXmlPaths(ddex_3_8, excludeArrays);
      case Format.DDEX_4_1:
        return getXmlPaths(ddex_4_1, excludeArrays);
      case Format.DDEX_4_2:
        return getXmlPaths(ddex_4_2, excludeArrays);
      default:
        return [];
    }
  }
);
