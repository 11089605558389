import type { MouseEvent, ReactElement } from "react";
import { cloneElement } from "react";
import { useFilters } from "@/utils";

type Props = {
  children: ReactElement;
  filters: Record<string, unknown>;
};

export const TagFilter = ({ children, filters, ...props }: Props) => {
  const { appendFilters } = useFilters();

  const handleClick = (event: MouseEvent) => {
    if (children.props.onClick) {
      children.props.onClick(event);
    }
    event.stopPropagation();
    appendFilters(filters);
  };

  return cloneElement(children, {
    ...props,
    onClick: handleClick,
  });
};
