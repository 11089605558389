import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useEmailAlerts } from "@/api/email-alerts/hooks";
import { RestrictedField } from "@/components/common/RestrictedField";

type Props = Omit<SelectProps, "options" | "loading"> & {
  sourceObject?: string;
};

export const EmailAlertPicker = (props: Props) => {
  const {
    isLoading,
    data: { data: entries },
    error,
  } = useEmailAlerts({ sourceObject: props.sourceObject });

  if (error?.status === 403) {
    return <RestrictedField message="You need 'Read - Email Alert' permission to view this field" />;
  }

  return (
    <Select
      {...props}
      loading={isLoading}
      options={entries.map((entry) => ({ value: entry.id, label: entry.name }))}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
