import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { useOnDemandReport } from "@/api/on-demand-reports/hooks";
import { OnDemandReportForm } from "./OnDemandReportForm";

export const OnDemandReportPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: entry, isLoading } = useOnDemandReport(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <OnDemandReportForm initialValues={entry} />;
};
