import type { OpenplayObjectType } from "@/types/common";

export type OpenplayObject = {
  name: OpenplayObjectType;
  label: string;
  root: OpenplayObjectField;
};

export const types = ["number", "string", "boolean", "date", "datetime", "object"] as const;

export type ObjectFieldType = typeof types[number];

export type OpenplayObjectField = {
  id: string;
  name: string;
  label?: string;
  type?: ObjectFieldType;
  isArray: boolean;
  hidden: boolean;
  parentId?: string;
  children: OpenplayObjectField[];
};

export type ImportOpenplayObjectDto = {
  name: string;
  id: string;
};

export type CreateOpenplayObjectFieldDto = {
  name: string;
  label: string;
  isArray?: boolean;
  hidden?: boolean;
  type?: ObjectFieldType;
  parentId: string;
};

export type UpdateOpenplayObjectFieldDto = Partial<Omit<CreateOpenplayObjectFieldDto, "parentId">> &
  Pick<OpenplayObjectField, "id">;
