import { Alert, Col, Row, Space } from "antd";
import { InfoItem } from "./InfoItem/InfoItem";
import { Deliveries } from "./Deliveries/Deliveries";
import * as R from "ramda";
import { Uploads } from "./Uploads/Uploads";
import { ProcessingStatus } from "@/utils/processing-status";

export const ActivityEntryDetails = ({ entry, showFeedInfo }) => {
  const infoItems = R.zip(["Feed", "Partner"], [entry.feed, entry.partner]).filter(([_, value]) => !!value);

  const hasDetails =
    infoItems.length > 0 || !!entry.reason || !!entry.deliveries || !!entry.previousDeliveries;

  if (!hasDetails) {
    return <Alert message="No details available" type="info" />;
  }

  const deliveries = entry.deliveries ?? [];
  const previousDeliveries = entry.previousDeliveries ?? [];
  const allDeliveries = [...deliveries, ...previousDeliveries];
  const uploads = entry.uploads ?? [];

  return (
    <Row gutter={[16, 16]}>
      {showFeedInfo && infoItems.length > 0 && (
        <Col span={24}>
          <Space size={16}>
            {infoItems.map(([label, value]) => (
              <InfoItem key={value.id} label={label} value={value.name} />
            ))}
          </Space>
        </Col>
      )}
      {allDeliveries.length === 0 && uploads.length === 0 && entry.reason && (
        <Col span={24}>
          <Alert message={entry.reason} type={entry.status === ProcessingStatus.Aborted ? "info" : "error"} />
        </Col>
      )}
      {allDeliveries.length > 0 && (
        <Col>
          <Deliveries deliveries={allDeliveries} counts={entry.deliveryCountByStatus} />
        </Col>
      )}
      {uploads.length > 0 && (
        <Col>
          <Uploads uploads={uploads} counts={entry.uploadCountByStatus} />
        </Col>
      )}
    </Row>
  );
};
