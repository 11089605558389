import { Field as FormField } from "@/components/common/Form/Field";
import "./FieldListBlock.less";
import { useBoolean } from "ahooks";
import { FieldListForm } from "./FieldListForm";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Filters } from "@/components/common/Icons";
import { atLeastOne } from "@/utils/validation";
import { ReadOnlyFields } from "./ReadOnlyFields";
import { DescriptionField } from "@/components/common/ContentBuilder/FieldListBlock/FieldListDescription";
import { Form } from "antd";
import { useFieldPath } from "@/components/common/Form/hooks";

export const FieldListBlock = () => {
  const [isOpen, { setFalse: close, setTrue: open }] = useBoolean(false);
  const form = Form.useFormInstance();
  const [innerForm] = Form.useForm();
  const { getAbsolutePath } = useFieldPath();

  const handleOpen = () => {
    innerForm.setFieldsValue(form.getFieldValue(getAbsolutePath()));
    open();
  };

  const handleFinish = (values) => {
    form.setFieldValue(getAbsolutePath("description"), values.description?.trim() || null);
    form.setFieldValue(getAbsolutePath("fields"), values.fields);
    close();
  };

  return (
    <>
      <div className="field-list-content">
        <div className="flex-col">
          <FormField name="description">
            <DescriptionField readOnly />
          </FormField>
          <FormField name="fields" rules={[atLeastOne("At least one field should be added")]}>
            <ReadOnlyFields />
          </FormField>
        </div>
        <IconButton onClick={handleOpen} icon={Filters} className="field-list-content__configure" />
      </div>
      <FieldListForm form={innerForm} open={isOpen} onCancel={close} onFinish={handleFinish} />
    </>
  );
};
