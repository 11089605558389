import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { useUser } from "@/api/users/hooks";
import { UserForm } from "@/components/Users/Details/UserForm";

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user, isLoading } = useUser(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <UserForm initialValues={user} />;
};
