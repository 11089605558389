import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Form, Modal, PageHeader, Row, Upload } from "antd";
import { useRequest } from "ahooks";
import { fetchFeed, manualExport } from "@/api/feeds";
import { TagInput } from "../../common/TagInput";
import ButtonWithConfirm from "../../common/ButtonWithConfirm";
import "./FeedManualExport.less";
import { FeedTab, isValidUPC } from "@/utils";
import Icon from "@ant-design/icons";
import { Info } from "../../common/Icons";
import { Box } from "../../common/Box/Box";
import { Spinner } from "../../common/Spinner/Spinner";
import { openNotification } from "../../common/CommonNotification";
import { ERROR_MESSAGES } from "@/api/messages";
import { isEmpty } from "ramda";
import { Tooltip } from "@/components/common/Tooltip";
import { OpenplayObjectType } from "@/types/common";

const UploadButton = ({ disabled, onChange, value }) => {
  const handleChange = (info) => {
    onChange(info.fileList);
  };
  return (
    <Upload
      accept=".csv"
      fileList={value}
      disabled={disabled}
      beforeUpload={() => false}
      onChange={handleChange}
      maxCount={1}
    >
      Upload from CSV
    </Upload>
  );
};

export function FeedManualExport() {
  const { feedId, partnerId } = useParams();

  const { loading: loadingFeed, data: feed } = useRequest(() => fetchFeed(feedId), { initialData: {} });

  const [form] = Form.useForm();
  const history = useHistory();

  const files = Form.useWatch("files", form) ?? [];
  const upcs = Form.useWatch("upcs", form) ?? [];
  const projectNumbers = Form.useWatch("projectNumbers", form) ?? [];

  const hasFiles = files.length > 0;
  const hasUPCs = upcs.length > 0;
  const hasProjectNumbers = projectNumbers.length > 0;

  const isUploadDisabled = hasUPCs || hasProjectNumbers;
  const isNumbersFieldDisabled = hasFiles;
  const isExportDisabled = !hasFiles && !hasUPCs && !hasProjectNumbers;

  const goBack = useCallback(
    () => history.push(`/partners/${partnerId}/feeds/${feedId}/${FeedTab.Summary}`),
    [history, feedId, partnerId]
  );

  const { run: sendExport, loading } = useRequest(manualExport, {
    manual: true,
    throwOnError: true,
  });

  const handleSuccess = () => {
    goBack();
    openNotification({
      description:
        "The export process is started. It could take some time so the result will be sent via email",
    });
  };

  const handleExport = async (feedId, formData, validate) => {
    try {
      if (validate) {
        const result = await sendExport(feedId, formData, true);
        if (isEmpty(result)) {
          handleSuccess();
        } else {
          Modal.confirm({
            title: "Confirm manual export",
            content: (
              <>
                <p>Do you want to proceed with errors?</p>
                {result?.notFound?.length > 0 && (
                  <p>
                    <strong>Not found on OpenPlay:</strong> {result.notFound.join(", ")}
                  </p>
                )}
                {result?.wrongFormat?.length > 0 && (
                  <p>
                    <strong>Wrong format:</strong> {result.wrongFormat.join(", ")}
                  </p>
                )}
              </>
            ),
            okText: "Proceed",
            onOk: () => handleExport(feedId, formData, false),
          });
        }
      } else {
        await sendExport(feedId, formData);
        handleSuccess();
      }
    } catch (error) {
      ERROR_MESSAGES.hasOwnProperty(error.errorCode)
        ? openNotification({
            message: ERROR_MESSAGES[error.errorCode],
            type: "error",
          })
        : console.error("unhandled error", error);
    }
  };

  const handleFinish = async (values) => {
    if (values.files.length > 0) {
      const fd = new FormData();
      fd.append("feedId", feedId);
      const file = values.files[0].originFileObj;
      fd.append("file", file, file.name);
      await handleExport(feedId, fd, true);
    } else {
      const dto = {};
      if (values.upcs) {
        dto.upcs = values.upcs;
      }
      if (values.projectNumbers) {
        dto.projectNumbers = values.projectNumbers;
      }
      await handleExport(feedId, dto, true);
    }
  };

  if (loadingFeed) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader title="Manual Export" />
      <Form
        form={form}
        className="manual-export-form"
        onFinish={handleFinish}
        initialValues={{ upcs: [], files: [], projectNumbers: [] }}
      >
        <Row gutter={[16, 16]}>
          <Col span="12">
            <Box>
              {feed.targetObject === OpenplayObjectType.Release && (
                <div className="manual-export-form__numbers">
                  <Tooltip title="Type UPC numbers that you want to export. UPC number should contain 12-13 digits. Or you can upload UPCs from CSV. Maximum amount of UPCs is 30">
                    <Icon className="manual-export-form__tooltip-icon" component={Info} />
                  </Tooltip>
                  <Form.Item
                    label="upcs"
                    name="upcs"
                    className="manual-export-form__number-field"
                    rules={[
                      {
                        type: "array",
                        max: 30,
                        message:
                          "You’re trying to add more than 30 UPCs. Please use upload via .csv file instead",
                      },
                      {
                        validator: (_, value) => {
                          if (value.every(isValidUPC)) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Some UPCs have invalid format");
                        },
                      },
                    ]}
                  >
                    <TagInput disabled={isNumbersFieldDisabled} checkValue={isValidUPC} />
                  </Form.Item>
                </div>
              )}
              {feed.targetObject === OpenplayObjectType.Project && (
                <div className="manual-export-form__numbers">
                  <Tooltip title="Type project numbers that you want to export. Or you can upload project numbers from CSV. Maximum amount is 30">
                    <Icon className="manual-export-form__tooltip-icon" component={Info} />
                  </Tooltip>
                  <Form.Item
                    label="Project Numbers"
                    name="projectNumbers"
                    className="manual-export-form__number-field"
                    rules={[
                      {
                        type: "array",
                        max: 30,
                        message:
                          "You’re trying to add more than 30 project numbers. Please use upload via .csv file instead",
                      },
                    ]}
                  >
                    <TagInput disabled={isNumbersFieldDisabled} />
                  </Form.Item>
                </div>
              )}
            </Box>
          </Col>
          <Col span="12">
            <Box>
              <Form.Item
                name="files"
                tooltip="Upload from CSV couldn't be completed when there are values manually typed in"
              >
                <UploadButton disabled={isUploadDisabled} />
              </Form.Item>
            </Box>
          </Col>
          <Col span="24">
            <div className="manual-export-form__actions">
              <ButtonWithConfirm key="cancel" onClick={goBack} text="Cancel" />
              <Button htmlType="submit" disabled={isExportDisabled} type="primary" loading={loading}>
                Export
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
