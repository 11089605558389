import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import type {
  CreateExportScheduleDto,
  ExportScheduleFilters,
  UpdateExportScheduleDto,
} from "@/api/export-schedules/types";
import { fetchExportSchedule, fetchExportSchedules, saveExportSchedule } from "@/api/export-schedules/index";

const key = "export-schedules";

export const useExportSchedules = (filters: ExportScheduleFilters) =>
  useSWR([key, filters], ([_, params]) => fetchExportSchedules(params), { fallbackData: { data: [] } });

export const useExportSchedule = (id: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchExportSchedule(id));

export const useExportScheduleMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreateExportScheduleDto | UpdateExportScheduleDto }) => saveExportSchedule(dto)
  );
