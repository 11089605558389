import { api } from "@/api/core";
import {
  CHECK_ACKNOWLEDGEMENT_SWEEP_STATUS_URL,
  DELIVERY_DOWNLOAD_URL,
  DELIVERY_RESEND_URL,
  PROCESS_ACKNOWLEDGEMENT_URL,
} from "@/api/urls";
import type { AcknowledgementSweepStatus } from "@/api/deliveries/types";

export async function resendDeliveries(ids: string[]) {
  return api.post(DELIVERY_RESEND_URL, { ids });
}

export async function processAcknowledgements() {
  return api.post(PROCESS_ACKNOWLEDGEMENT_URL);
}

export async function checkAcknowledgementSweepStatus() {
  return api.post<any, AcknowledgementSweepStatus>(CHECK_ACKNOWLEDGEMENT_SWEEP_STATUS_URL);
}

export async function downloadDelivery(outputFilename: string) {
  return api.get(DELIVERY_DOWNLOAD_URL(outputFilename), {
    responseType: "blob",
  });
}
