import { capitalize } from "@/utils";
import { Button, Col, Form, Input, Popconfirm, Row, Space } from "antd";
import Icon from "@ant-design/icons";
import { Plus, Remove } from "@/components/common/Icons";
import { Field } from "@/components/common/Form/Field";
import { IconButton } from "@/components/common/IconButton/IconButton";
import * as R from "ramda";
import { getFieldMaxLengthRule, requiredIfNotDraft } from "@/utils/validation";

const getVariableLabel = (variable: string) => variable.split("_").map(capitalize).join(" ");

export const VariationVariables = () => {
  const outerForm = Form.useFormInstance();
  const [form] = Form.useForm();
  const variables = Form.useWatch("variables") ?? [];

  const handleAddVariable = (variable: string) => {
    const existingVariables = outerForm.getFieldValue("variables") ?? [];
    outerForm.setFieldValue("variables", R.uniq([...existingVariables, variable]));
    form.setFieldValue("variable", "");
  };

  const handleRemoveVariable = (variableToRemove: string) => {
    const existingVariables = outerForm.getFieldValue("variables") ?? [];
    outerForm.setFieldValue(
      "variables",
      existingVariables.filter((variable: string) => variable !== variableToRemove)
    );
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form component={false} form={form} onFinish={({ variable }) => handleAddVariable(variable)}>
          <Space size={8} align="start">
            <Form.Item
              name="variable"
              rules={[
                { required: true, message: "Please, enter a name for a variable" },
                {
                  pattern: /^[a-z_]+$/,
                  message: "Variable name should consist only of lowercase letters and underscores",
                },
                {
                  validator: (_, value) => {
                    const existingVariables = outerForm.getFieldValue("variables") ?? [];
                    return existingVariables.includes(value)
                      ? Promise.reject("Variable with given name already exists")
                      : Promise.resolve();
                  },
                },
                getFieldMaxLengthRule("Variable name", 30),
              ]}
              normalize={(value) => value?.trim()}
              style={{ minWidth: 400 }}
              className="form-item--sm"
            >
              <Input
                placeholder="Type in the variable name and press 'Enter'"
                onPressEnter={form.submit}
                size="small"
              />
            </Form.Item>
            <Button size="small" icon={<Icon component={Plus} />} onClick={form.submit}>
              Add Variable
            </Button>
          </Space>
        </Form>
      </Col>
      {variables.map((variable: string) => (
        <Col span={12} key={variable}>
          <Row gutter={[8, 8]}>
            <Col flex="auto">
              <Field
                name={["variables", variable]}
                label={getVariableLabel(variable)}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                colon={false}
                style={{ flexGrow: 1 }}
                rules={[
                  (form) => ({
                    ...requiredIfNotDraft(form),
                    message: "Please, specify a value for the variable",
                  }),
                  getFieldMaxLengthRule("Value", 50),
                ]}
              >
                <Input />
              </Field>
            </Col>
            <Col style={{ paddingTop: 4 }}>
              <Popconfirm
                okButtonProps={{ size: "small" }}
                cancelButtonProps={{ size: "small" }}
                title="Remove the variable?"
                onConfirm={() => handleRemoveVariable(variable)}
                okText="Yes"
                cancelText="No"
              >
                <IconButton icon={Remove} />
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};
