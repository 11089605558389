import * as R from "ramda";
import type { Func, Funcs } from "@react-awesome-query-builder/ui";

type RelativeDateMetadata = { name: string; label: string; withParams: boolean };

const getRelativeDateFunc = (
  { label, name, withParams }: RelativeDateMetadata,
  returnType: "date" | "datetime" = "date"
): Func => ({
  label,
  returnType,
  jsonLogic: name,
  renderBrackets: withParams ? [":", ""] : ["", ""],
  args: withParams
    ? {
        n: {
          type: "number",
          fieldSettings: { min: 1 },
          valueSources: ["value"],
        },
      }
    : {},
});

export const relativeDates: RelativeDateMetadata[] = [
  { name: "TODAY", label: "Today", withParams: false },
  { name: "YESTERDAY", label: "Yesterday", withParams: false },
  { name: "TOMORROW", label: "Tomorrow", withParams: false },
  { name: "LAST_WEEK", label: "Last Week", withParams: false },
  { name: "THIS_WEEK", label: "This Week", withParams: false },
  { name: "NEXT_WEEK", label: "Next Week", withParams: false },
  { name: "LAST_MONTH", label: "Last Month", withParams: false },
  { name: "THIS_MONTH", label: "This Month", withParams: false },
  { name: "NEXT_MONTH", label: "Next Month", withParams: false },
  { name: "LAST_90_DAYS", label: "Last 90 Days", withParams: false },
  { name: "NEXT_90_DAYS", label: "Next 90 Days", withParams: false },
  { name: "LAST_N_DAYS", label: "Last {n} Days", withParams: true },
  { name: "NEXT_N_DAYS", label: "Next {n} Days", withParams: true },
  { name: "N_DAYS_AGO", label: "{n} Days Ago", withParams: true },
  { name: "NEXT_N_WEEKS", label: "Next {n} Weeks", withParams: true },
  { name: "LAST_N_WEEKS", label: "Last {n} Weeks", withParams: true },
  { name: "N_WEEKS_AGO", label: "{n} Weeks Ago", withParams: true },
  { name: "NEXT_N_MONTHS", label: "Next {n} Months", withParams: true },
  { name: "LAST_N_MONTHS", label: "Last {n} Months", withParams: true },
  { name: "N_MONTHS_AGO", label: "{n} Months Ago", withParams: true },
  { name: "THIS_QUARTER", label: "This Quarter", withParams: false },
  { name: "LAST_QUARTER", label: "Last Quarter", withParams: false },
  { name: "NEXT_QUARTER", label: "Next Quarter", withParams: false },
  { name: "NEXT_N_QUARTERS", label: "Next {n} Quarters", withParams: true },
  { name: "LAST_N_QUARTERS", label: "Last {n} Quarters", withParams: true },
  { name: "N_QUARTERS_AGO", label: "{n} Quarters Ago", withParams: true },
  { name: "THIS_YEAR", label: "This Year", withParams: false },
  { name: "LAST_YEAR", label: "Last Year", withParams: false },
  { name: "NEXT_YEAR", label: "Next Year", withParams: false },
  { name: "NEXT_N_YEARS", label: "Next {n} Years", withParams: true },
  { name: "LAST_N_YEARS", label: "Last {n} Years", withParams: true },
  { name: "N_YEARS_AGO", label: "{n} Years Ago", withParams: true },
  { name: "NEXT_N_BUSINESS_DAYS", label: "Next {n} Business Days", withParams: true },
  { name: "LAST_N_BUSINESS_DAYS", label: "Last {n} Business Days", withParams: true },
  { name: "N_BUSINESS_DAYS_AGO", label: "{n} Business Days Ago", withParams: true },
];

type RelativeDateMapper = (dateMetadata: RelativeDateMetadata[]) => Funcs;

const getFuncs = (returnType: "date" | "datetime"): RelativeDateMapper =>
  R.pipe(
    R.indexBy((value) => `${value.name}_${returnType}`),
    R.mapObjIndexed((value) => getRelativeDateFunc(value, returnType))
  );

export const relativeDateFuncs = {
  ...getFuncs("date")(relativeDates),
  ...getFuncs("datetime")(relativeDates),
};
