import { api } from "@/api/core";
import type { PaginatedResponse } from "@/api/types";
import type { SalesforceObject, SalesforceObjectFilters, SalesforceObjectRelation } from "./types";

const url = "/api/salesforce-objects";

export const fetchSalesforceObjects = (params: SalesforceObjectFilters) =>
  api.get<any, PaginatedResponse<SalesforceObject>>(url, { params });

export const importSalesforceObject = (name: string) => api.post<any, void>(`${url}/import`, { name });

export const deleteSalesforceObject = (name: string) => api.delete<any, void>(`${url}/${name}`);

export const fetchSalesforceObject = (name: string) => api.get<any, SalesforceObject>(`${url}/${name}`);

export const fetchSalesforceObjectRelations = (name: string) =>
  api.get<any, SalesforceObjectRelation[]>(`${url}/${name}/relations`);
