import { Col, Form, Row, Select } from "antd";
import { Box } from "@/components/common/Box/Box";
import { Field } from "@/components/common/Form/Field";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import {
  AddSenderToBCC,
  ContactFieldPicker,
  CustomSender,
  Recipients,
  SubjectVariations,
} from "@/components/common/EmailMessageForm";
import { FieldGroup } from "@/components/common/Form/FieldGroup";
import { useFieldPath } from "@/components/common/Form/hooks";
import { useMemo } from "react";
import { atLeastOne } from "@/utils/validation";
import { EmailMessageContextProvider } from "@/components/common/EmailMessageForm/context";
import { ContentBuilder } from "@/components/common/ContentBuilder/ContentBuilder";
import { getFieldsForQueryBuilder } from "@/utils";
import { useEmailAlertVariables } from "@/components/EmailAlerts/Details/hooks";

type Props = {
  readOnly: boolean;
};

export const EmailAlertEmailForm = ({ readOnly }: Props) => {
  const from = Form.useWatch(["sourceObject", "name"]);

  const variables = useEmailAlertVariables();
  const fieldsForConditions = useMemo(() => getFieldsForQueryBuilder(variables), [variables]);

  const { getAbsolutePath } = useFieldPath();

  const senderType = Form.useWatch(getAbsolutePath(["email", "from", "type"]));

  return (
    <EmailMessageContextProvider
      variables={variables}
      fieldsForConditions={fieldsForConditions}
      sourceObject={from}
      parentType="email-alert"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FieldGroup prefix="email">
            <Box readOnly={readOnly}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Field label="From" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <Row gutter={[8, 8]}>
                      <Col span={6}>
                        <Field name={["from", "type"]} initialValue="custom">
                          <Select
                            options={[
                              { value: "custom", label: "Custom" },
                              { value: "field", label: "Object Field" },
                            ]}
                            suffixIcon={<Icon component={DropdownArrow} />}
                          />
                        </Field>
                      </Col>
                      {senderType === "custom" && (
                        <Col span={18}>
                          <CustomSender />
                        </Col>
                      )}
                      {senderType === "field" && (
                        <Col span={18}>
                          <Field
                            name={["from", "field"]}
                            rules={[{ required: true, message: "The field is mandatory" }]}
                          >
                            <ContactFieldPicker sourceObject={from} />
                          </Field>
                        </Col>
                      )}
                    </Row>
                  </Field>
                </Col>
                <Col span={24}>
                  <SubjectVariations />
                </Col>
                <Col span={24}>
                  <Recipients
                    label="To"
                    name="to"
                    rules={[atLeastOne("At least one recipient should be added")]}
                  />
                </Col>
                <Col span={24}>
                  <Recipients label="CC" name="cc" />
                </Col>
                <Col span={24}>
                  <AddSenderToBCC />
                </Col>
                <Col span={24}>
                  <Form.Item label="Body" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                    <ContentBuilder maxNesting={2} name="body" />
                  </Form.Item>
                </Col>
              </Row>
            </Box>
          </FieldGroup>
        </Col>
      </Row>
    </EmailMessageContextProvider>
  );
};
