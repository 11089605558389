import { WarningOutlined } from "@ant-design/icons";
import { Tag } from "./Tag/Tag";
import type { ComponentProps } from "react";

import {
  getProcessingStatusColor,
  getProcessingStatusLabel,
  ProcessingStatus,
} from "@/utils/processing-status";

interface Props extends Pick<ComponentProps<typeof Tag>, "closable" | "onClose" | "onClick"> {
  status: ProcessingStatus;
}

export const ProcessingStatusTag = ({ status, closable, onClose, onClick }: Props) => {
  return (
    <Tag
      icon={status === ProcessingStatus.FinishedWithWarnings && <WarningOutlined />}
      color={getProcessingStatusColor(status)}
      closable={closable}
      onClose={onClose}
      onClick={onClick}
    >
      <span>{getProcessingStatusLabel(status)}</span>
    </Tag>
  );
};
