import { Col, Form, Input, Row, Select } from "antd";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../Icons";
import { CampaignSelect } from "./CampaignSelect";
import { Field } from "../../Form/Field";
import { useFieldPath } from "../../Form/hooks";
import { RecipientType } from "@/api/email-alerts/types";
import { ContactFieldPicker } from "../ContactFieldPicker";
import { RecipientGroup } from "./RecipientGroup";
import { HiddenField } from "@/components/common/HiddenField";
import { CampaignFilters } from "./CampaignFilters";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

export const getRecipientTypeLabel = (type: RecipientType) => {
  const lookup = {
    [RecipientType.Single]: "Single Contact",
    [RecipientType.Campaign]: "Campaign",
    [RecipientType.Field]: "Object Field",
  };
  return lookup[type];
};

type Props = {
  className?: string;
};

export const Recipient = ({ className }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const type = Form.useWatch(getAbsolutePath("type")) ?? RecipientType.Single;

  const { sourceObject } = useEmailMessageContext();

  const availableRecipientTypes = Object.values(RecipientType).filter(
    (type) => type !== RecipientType.Group && (sourceObject ? true : type !== RecipientType.Field)
  );

  return (
    <div className={className}>
      <Row gutter={[16, 16]}>
        {type === RecipientType.Group ? (
          <HiddenField name="type" initialValue={RecipientType.Group} />
        ) : (
          <Col span={8}>
            <Field name="type" initialValue={RecipientType.Single}>
              <Select
                options={toOptions(availableRecipientTypes, getRecipientTypeLabel)}
                suffixIcon={<Icon component={DropdownArrow} />}
              />
            </Field>
          </Col>
        )}
        {type === RecipientType.Single && (
          <>
            <Col span={8}>
              <Field
                name="email"
                rules={[
                  { required: true, message: "Field is mandatory" },
                  { type: "email", message: "Invalid email format" },
                ]}
              >
                <Input placeholder="Email" />
              </Field>
            </Col>
            <Col span={8}>
              <Field name="name">
                <Input placeholder="Name (optional)" />
              </Field>
            </Col>
          </>
        )}
        {type === RecipientType.Campaign && (
          <>
            <Col span={8}>
              <Field name="campaignId" rules={[{ required: true, message: "Field is mandatory" }]}>
                <CampaignSelect />
              </Field>
            </Col>
            <Col span={24}>
              <CampaignFilters sourceObject={sourceObject} />
            </Col>
          </>
        )}
        {type === RecipientType.Field && sourceObject && (
          <Col span={8}>
            <Field name="field" rules={[{ required: true, message: "The field is mandatory" }]}>
              <ContactFieldPicker sourceObject={sourceObject} />
            </Field>
          </Col>
        )}
        {type === RecipientType.Group && sourceObject && (
          <Col span={24}>
            <RecipientGroup />
          </Col>
        )}
      </Row>
    </div>
  );
};
