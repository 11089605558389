import { useSalesforceObjectRelations } from "@/api/salesforce-objects/hooks";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";
import { ContentBuilder } from "@/components/common/ContentBuilder/ContentBuilder";
import { Col, Form, Row, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { uniqBy } from "ramda";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useFieldPath } from "@/components/common/Form/hooks";
import { LoopContextProvider } from "@/components/common/ContentBuilder/LoopBlock/context";

type Props = {
  maxNesting: number;
};

export const LoopBlock = ({ maxNesting }: Props) => {
  const { sourceObject } = useEmailMessageContext();
  const { data: relations } = useSalesforceObjectRelations(sourceObject);
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();

  const relatedObjects = uniqBy(
    (x) => x.name,
    relations.map((x) => x.object)
  );
  const selectedObject = Form.useWatch<string>(getAbsolutePath("object")) ?? null;

  return (
    <LoopContextProvider sourceObject={selectedObject}>
      <div className="loop-block">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Field
              name="object"
              label="Related Object"
              rules={[{ required: true, message: "Please, select related objects" }]}
            >
              <Select
                options={relatedObjects.map(({ name, label }) => ({ value: name, label }))}
                suffixIcon={<Icon component={DropdownArrow} />}
                showSearch
                onChange={() => form.setFieldValue(getAbsolutePath("field"), null)}
                placeholder="Select Related Object"
              />
            </Field>
          </Col>
          {selectedObject && (
            <Col span={12}>
              <Field
                name="field"
                label="Foreign Field"
                rules={[{ required: true, message: "Please, select foreign field" }]}
              >
                <Select
                  options={relations
                    .filter((x) => x.object.name === selectedObject)
                    .map(({ field }) => ({ value: field.name, label: field.label }))}
                  suffixIcon={<Icon component={DropdownArrow} />}
                  showSearch
                  placeholder="Select Foreign Field"
                />
              </Field>
            </Col>
          )}
          <Col span={24}>
            <ContentBuilder name="content" maxNesting={maxNesting} />
          </Col>
        </Row>
      </div>
    </LoopContextProvider>
  );
};
