import { Button, Form, Space } from "antd";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { Condition } from "./Condition";
import "./ConditionBlock.less";
import { ContentBuilder } from "@/components/common/ContentBuilder/ContentBuilder";
import { atLeastOne } from "@/utils/validation";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

type Props = {
  maxNesting: number;
};

export const ConditionBlock = ({ maxNesting }: Props) => {
  const { parentType } = useEmailMessageContext();
  return (
    <div className="condition-block">
      <Form.Item label="Conditions" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <FieldList name="ifs" rules={[atLeastOne("At least one condition should be added")]}>
          {(fields, { add, remove }, { errors }) => (
            <div>
              <div className="condition-block__conditions">
                {fields.map((field) => (
                  <FieldListItem key={field.key} name={field.name}>
                    <Condition maxNesting={maxNesting} onRemove={() => remove(field.name)} />
                  </FieldListItem>
                ))}
                <Space>
                  <Button
                    size="small"
                    style={{ alignSelf: "flex-start" }}
                    icon={<Icon component={Plus} />}
                    onClick={() => add({ type: "basic", condition: null, content: [] })}
                  >
                    Add Condition
                  </Button>
                  {parentType === "report" && (
                    <Button
                      size="small"
                      style={{ alignSelf: "flex-start" }}
                      icon={<Icon component={Plus} />}
                      onClick={() => add({ type: "query", mode: "some", condition: null, content: [] })}
                    >
                      Add Query Condition
                    </Button>
                  )}
                </Space>
              </div>
              {errors.length > 0 && <Form.ErrorList errors={errors} />}
            </div>
          )}
        </FieldList>
      </Form.Item>
      <Form.Item
        label="Else"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        tooltip="Content to display if none of the conditions are met"
      >
        <ContentBuilder name="else" maxNesting={maxNesting} />
      </Form.Item>
    </div>
  );
};
