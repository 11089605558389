import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { saveAsTemplate } from "@/api/templates";
import { openNotification } from "../common/CommonNotification";
import { Close } from "../common/Icons";
import { isUniqueTemplateName } from "@/components/Templates/Details/utils";

export const SaveAsTemplateForm = ({ isVisible, onClose, getTemplateFields }) => {
  const [form] = Form.useForm();

  const handleSave = async (dto) => {
    try {
      await saveAsTemplate(dto);
      form.resetFields();
      onClose();
      openNotification({
        description: `The template ${dto.name} is saved successfully`,
      });
    } catch (e) {
      console.error("unhandled error", e);
    }
  };

  const [saving, setSaving] = useState(false);

  const handleFinish = async (values) => {
    const dto = {
      name: values.name,
      ...getTemplateFields(),
    };

    try {
      setSaving(true);
      await handleSave(dto);
    } catch (e) {
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      open={isVisible}
      title="Save as template"
      onCancel={onClose}
      className="save-as-popup"
      footer={
        <>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={saving} onClick={form.submit}>
            Save
          </Button>
        </>
      }
      closeIcon={<Close />}
    >
      <Form
        form={form}
        onFinish={handleFinish}
        colon={false}
        labelAlign="left"
        wrapperCol={{ span: 15, offset: 1 }}
        labelCol={{ span: 8 }}
      >
        <Form.Item
          name="name"
          label="New template name"
          validateTrigger={["onChange", "onBlur"]}
          rules={[{ required: true, message: "Field is mandatory." }, isUniqueTemplateName]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
