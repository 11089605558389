export const ErrorCode = {
  // auth error codes
  Auth0Unauthorized: "Auth0Unauthorized",
  Auth0AccountBlocked: "Auth0AccountBlocked",
  Auth0AccountDoesNotExist: "Auth0AccountDoesNotExist",
  Auth0RoleNotFound: "Auth0RoleNotFound",
  ChangePassword: "ChangePassword",
  CreateAccount: "CreateAccount",
  RoleNotFound: "RoleNotFound",
  RoleNotAssigned: "RoleNotAssigned",
  AccountVerification: "AccountVerification",
  UpdateAuth0User: "UpdateAuth0User",
  // partner error codes
  PartnerNameConflict: "PartnerNameConflict",
  PartnerDDEXPartyIdConflict: "PartnerDDEXPartyIdConflict",
  PartnerContactEmailConflict: "PartnerContactEmailConflict",
  // user error codes
  UserIdInvalid: "UserIdInvalid",
  UserNotFound: "UserNotFound",
  UserNameConflict: "UserNameConflict",
  UserEmailConflict: "UserEmailConflict",
  DataValidation: "DataValidation",
  // feed error codes
  FeedNameConflict: "FeedNameConflict",
  IncorrectStorageType: "IncorrectStorageType",
  TestStorageFailed: "TestStorageFailed",
  CsvIsEmptyOrInvalidUpcs: "CsvIsEmptyOrInvalidUpcs",
  CsvIsEmptyOrInvalidProjectNumbers: "CsvIsEmptyOrInvalidProjectNumbers",
  // templates error codes
  TemplateNameConflict: "TemplateNameConflict",
  // salesforce
  SalesforceRequestFailed: "SalesforceRequestFailed",
};
