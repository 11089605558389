export enum ImageQuality {
  Tiny = "tiny",
  Small = "small",
  Default = "default",
  Large = "large",
}

export const imageQualities = [
  ImageQuality.Tiny,
  ImageQuality.Small,
  ImageQuality.Default,
  ImageQuality.Large,
];
