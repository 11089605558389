export enum Status {
  Inactive = 0,
  Active = 1,
  Draft = 2,
}

const labelLookup: Record<Status, string> = {
  [Status.Inactive]: "Inactive",
  [Status.Active]: "Active",
  [Status.Draft]: "Draft",
};

export const statuses = [Status.Active, Status.Inactive, Status.Draft];

export const getStatusLabel = (status: Status) => labelLookup[status];
