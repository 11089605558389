import { useMemo } from "react";
import { Variable } from "./Variables";
import { useEditor } from "./hooks";
import { EditorContent } from "@tiptap/react";
import "./TextFieldWithVariables.less";
import { Document } from "@tiptap/extension-document";
import { Text } from "@tiptap/extension-text";
import { Extension } from "@tiptap/core";
import { Form } from "antd";
import cn from "classnames";
import { useVariableModal } from "./Variables/hooks";
import type { Variables } from "./Variables/types";
import { getEmptyArray } from "@/utils/empty";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  variables?: Variables;
  placeholder?: string;
  onBlur?: () => void;
};

const Backspace = Extension.create({
  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ state, tr }) => {
          const { selection } = state;
          const { empty, from, to, anchor } = selection;
          if (empty && from === 0) {
            return true;
          }
          if (!empty && anchor === 0) {
            tr.insertText("", from, to);
            return true;
          }
          return false;
        }),
    };
  },
});

export const TextFieldWithVariables = ({
  value: content,
  onChange,
  variables = getEmptyArray(),
  onBlur,
}: Props) => {
  const { modal, open } = useVariableModal(variables);

  const extensions = useMemo(
    () => [
      Document.extend({ content: "inline*" }),
      Text,
      Variable.configure({
        variables,
        onClick: open,
      }),
      Backspace,
    ],
    [open, variables]
  );

  const editor = useEditor({
    extensions,
    content,
    onChange,
  });

  const { status } = Form.Item.useStatus();

  return (
    <div
      className={cn(
        "text-field-with-variables-2",
        status === "error" && "text-field-with-variables-2--error"
      )}
    >
      <EditorContent editor={editor} onBlur={onBlur} />
      {modal}
    </div>
  );
};
