import { SQLEditor } from "@/components/common/SQLEditor";
import type { ComponentProps } from "react";
import { useMemo } from "react";
import { useOpenplayDatabaseSchema } from "@/api/openplay-queries/hooks";
import type { Schema, Table } from "@/api/openplay-queries/types";
import type { SQLNamespace } from "@codemirror/lang-sql";
import { fromPairs } from "ramda";

const getNamespaceFromTables = (tables: Table[]): SQLNamespace =>
  fromPairs(
    tables.map((table) => [
      table.name,
      {
        self: { label: table.name, type: "table" },
        children: table.columns.map((column) => ({ label: column, type: "column" })),
      },
    ])
  );

const getNamespaceFromSchema = (schema: Schema): SQLNamespace => {
  return {
    platform: {
      self: {
        label: "platform",
        type: "schema",
      },
      children: getNamespaceFromTables(schema.tables.platform),
    },
    ...fromPairs(
      [{ code: "tenant", name: "Tenant Placeholder" }, ...schema.tenants].map((tenant) => [
        tenant.code,
        {
          self: {
            label: tenant.code,
            detail: tenant.name,
            type: "schema",
          },
          children: getNamespaceFromTables(schema.tables.tenant),
        },
      ])
    ),
  };
};

const useOpenplayNamespace = (): SQLNamespace => {
  const { data: schema } = useOpenplayDatabaseSchema();

  return useMemo(() => {
    if (!schema) {
      return [];
    }
    return getNamespaceFromSchema(schema);
  }, [schema]);
};

type Props = Omit<ComponentProps<typeof SQLEditor>, "schema">;

export const OpenplaySQLEditor = (props: Props) => {
  const namespace = useOpenplayNamespace();
  return <SQLEditor {...props} schema={namespace} />;
};
