import type { ColumnsType } from "antd/lib/table/interface";
import type { SpreadsheetColumnSet } from "@/api/spreadsheets/types";
import type { Report } from "@/api/reports/types";
import { uniqBy } from "ramda";
import { Space } from "antd";
import type { EmailAlert } from "@/api/email-alerts/types";
import type { OnDemandReport } from "@/api/on-demand-reports/types";
import type { RelatedEntity } from "../../../common/RelatedEntityTag";
import { RelatedEntityTag } from "../../../common/RelatedEntityTag";
import { getTabSourceLabel } from "@/utils";

type ColumnSetListItem = SpreadsheetColumnSet & {
  tabs: {
    spreadsheet: {
      report: Pick<Report, "id" | "name" | "status">;
      alert: Pick<EmailAlert, "id" | "name">;
      onDemandReport: Pick<OnDemandReport, "id" | "name">;
    };
  }[];
};

export const columns: ColumnsType<ColumnSetListItem> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Source",
    dataIndex: "source",
    sorter: true,
    render: (_, entry) => getTabSourceLabel(entry.source),
  },
  {
    title: "Source Object",
    dataIndex: ["sourceObject", "label"],
    sorter: true,
  },
  {
    title: "Used In",
    key: "usedIn",
    render: (_, entry) => {
      const reports: RelatedEntity[] = entry.tabs.flatMap((tab) => {
        const report = tab.spreadsheet.report;
        if (!report) {
          return [];
        }
        return { type: "report", ...report };
      });
      const alerts: RelatedEntity[] = entry.tabs.flatMap((tab) => {
        const alert = tab.spreadsheet.alert;
        if (!alert) {
          return [];
        }
        return { type: "alert", ...alert };
      });
      const onDemandReports: RelatedEntity[] = entry.tabs.flatMap((tab) => {
        const report = tab.spreadsheet.onDemandReport;
        if (!report) {
          return [];
        }
        return { type: "on-demand-report", ...report };
      });
      const entities = uniqBy((entity) => `${entity.type}-${entity.id}`, [
        ...reports,
        ...alerts,
        ...onDemandReports,
      ]);
      return (
        <Space size={8} wrap>
          {entities.map((entity) => (
            <RelatedEntityTag key={`${entity.type}-${entity.id}`} entity={entity} />
          ))}
        </Space>
      );
    },
  },
];
