import { Tooltip } from "@/components/common/Tooltip";

const prepareList = (data, key) => {
  if (data.length <= 5) {
    return data.map((p) => p[key]);
  }
  return [...data.map((p) => p[key]).slice(0, 5), `and ${data.length - 5} more`];
};

export const ListTooltip = ({ src, data, dataKey }) => {
  return data.length > 0 ? (
    <Tooltip title={<span style={{ whiteSpace: "pre" }}>{prepareList(data, dataKey).join("\n")}</span>}>
      {src}
    </Tooltip>
  ) : (
    ""
  );
};
