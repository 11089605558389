import { api } from "@/api/core";
import type { PaginatedResponse } from "@/api/types";
import { stripUndefined } from "@/utils";
import type {
  CreateExportScheduleDto,
  ExportSchedule,
  ExportScheduleFilters,
  UpdateExportScheduleDto,
} from "@/api/export-schedules/types";

const url = "/api/export-schedules";

export const fetchExportSchedules = (params: ExportScheduleFilters) =>
  api.get<any, PaginatedResponse<ExportSchedule>>(url, { params });

export const fetchExportSchedule = (id: string) => api.get<any, ExportSchedule>(`${url}/${id}`);

export const createExportSchedule = (fields: CreateExportScheduleDto) =>
  api.post<any, ExportSchedule>(url, fields);

export const updateExportSchedule = (fields: UpdateExportScheduleDto) =>
  api.put<any, ExportSchedule>(url, fields);

export const deleteExportSchedule = (id: string) => api.delete<any, void>(`${url}/${id}`);

export const saveExportSchedule = (fields: CreateExportScheduleDto | UpdateExportScheduleDto) => {
  const cleanedUpFields = stripUndefined(fields);
  return "id" in cleanedUpFields
    ? updateExportSchedule(cleanedUpFields)
    : createExportSchedule(cleanedUpFields);
};
