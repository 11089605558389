import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import type { SuggestionListProps } from "./SuggestionList";
import { SuggestionList } from "./SuggestionList";
import type { SuggestionOptions } from "@tiptap/suggestion";
import type { ComponentRef } from "react";
import { PluginKey } from "@tiptap/pm/state";
import Fuse from "fuse.js";
import type { Variables } from "./types";
import { flattenVariables } from "@/utils/dynamic-fields";

export const VariablePluginKey = new PluginKey("variable");

export const getSuggestionOptions = (variables: Variables): Omit<SuggestionOptions, "editor"> => {
  const flattened = flattenVariables(variables);
  const keys = ["label"];
  const index = Fuse.createIndex(keys, flattened);
  const fuse = new Fuse(
    flattened,
    {
      includeMatches: true,
      threshold: 0.3,
      location: 500,
      keys,
    },
    index
  );
  return {
    char: "{",
    pluginKey: VariablePluginKey,
    allowedPrefixes: null,
    items: ({ query }) =>
      query.length > 0
        ? fuse.search(query, { limit: 100 })
        : flattened.slice(0, 30).map((item) => ({ item, matches: [] })),
    render: () => {
      let component: ReactRenderer<ComponentRef<typeof SuggestionList>, SuggestionListProps>;
      let popup: ReturnType<typeof tippy>;

      return {
        onStart: (props) => {
          component = new ReactRenderer(SuggestionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "auto-start",
            maxWidth: 400,
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }

          return component.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
    command: ({ editor, range, props }) => {
      const nodeBefore = editor.view.state.selection.$from.nodeBefore;

      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: "variable",
            attrs: props,
            marks: nodeBefore?.toJSON().marks ?? [],
          },
        ])
        .run();

      window.getSelection()?.collapseToEnd();
    },
    allow: ({ state, range }) => {
      const $from = state.doc.resolve(range.from);
      const type = state.schema.nodes["variable"];
      return !!$from.parent.type.contentMatch.matchType(type);
    },
  };
};
