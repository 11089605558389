import type {
  CreateSpreadsheetColumnSetDto,
  SpreadsheetColumnSetFilters,
  UpdateSpreadsheetColumnSetDto,
} from "@/api/spreadsheets/types";
import useSWR, { mutate } from "swr";
import {
  fetchColumnSet,
  fetchColumnSets,
  fetchSpreadsheetTemplates,
  getOpenplayCalculatedFields,
  saveColumnSet,
} from "@/api/spreadsheets/index";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";

const key = "spreadsheet-column-sets";

export const useSpreadsheetColumnSets = (filters: SpreadsheetColumnSetFilters) =>
  useSWR([key, filters], ([_, params]) => fetchColumnSets(params), { fallbackData: { data: [] } });

export const useSpreadsheetColumnSet = (id: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchColumnSet(id));

export const useSpreadsheetColumnSetMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : [key],
    (_, { arg: dto }: { arg: CreateSpreadsheetColumnSetDto | UpdateSpreadsheetColumnSetDto }) =>
      saveColumnSet(dto)
  );

export const revalidateColumnSets = () =>
  mutate((args) => (Array.isArray(args) && args.at(0) === key ? args : null));

export const useOpenplayCalculatedFields = () =>
  useSWR("openplay-calculated-fields", getOpenplayCalculatedFields, {
    fallbackData: [],
  });

export const useSpreadsheetTemplates = () =>
  useSWRImmutable("spreadsheet-templates", fetchSpreadsheetTemplates);
