import { Modal } from "antd";
import { useCallback, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { LayoutContext } from "../contexts/layout";

export function useNavigationConfirm() {
  const { isNavigateAllowed, preventNavigation, allowNavigation } = useContext(LayoutContext);
  const res = useMemo(() => ({ isNavigateAllowed, preventNavigation, allowNavigation }), [
    isNavigateAllowed,
    preventNavigation,
    allowNavigation,
  ]);
  return res;
}

export function LinkWithConfirmation({ to, children, onClick, ...rest }) {
  const { isNavigateAllowed, allowNavigation } = useNavigationConfirm();
  const history = useHistory();
  const confirm = useCallback(
    (e) => {
      if (!isNavigateAllowed) {
        e.preventDefault();
        showNavigationConfirmation(() => {
          allowNavigation();
          onClick?.();
          history.push(to);
        });
      } else {
        onClick?.();
      }
    },
    [isNavigateAllowed, allowNavigation, to, history, onClick]
  );
  return (
    <Link to={to} onClick={confirm} {...rest}>
      {children}
    </Link>
  );
}

export function showNavigationConfirmation(action) {
  Modal.confirm({
    closable: true,
    icon: null,
    title: "Cancel Confirmation",
    content: "All changes will be lost. Do you want to proceed?",
    okText: "Confirm",
    onOk: action,
  });
}
