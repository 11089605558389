import { Col, Form, Row } from "antd";
import { Field } from "@/components/common/Form/Field";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import "./FieldToUpdate.less";
import { Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import type { SalesforceObjectField } from "@/api/salesforce-objects/types";
import { FieldValue } from "@/components/Reports/Details/ActionsList/FieldToUpdate/FieldValue";

type Props = {
  sourceObject: string;
  onRemove: () => void;
};

const findField = (fields: SalesforceObjectField[], path: string[]) => {
  if (path?.length === 0) {
    return null;
  }
  if (path?.length > 0) {
    const parentField = fields.find((field) => field.name === path[0]);
    if (!parentField) {
      return null;
    }
    if (path.length === 1) {
      return parentField;
    }
    if (parentField.referenceTo?.fields) {
      return findField(parentField.referenceTo?.fields, path.slice(1));
    }
    return null;
  }
  return null;
};

const useField = (sourceObject: string, path: string[]) => {
  const { obj } = useSalesforceObject(sourceObject);
  if (!obj || !path?.length) {
    return null;
  }
  return findField(obj.fields, path);
};

export const FieldToUpdate = ({ sourceObject, onRemove }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();
  const handlePathChange = () => {
    form.setFieldValue(getAbsolutePath("value"), undefined);
  };
  const selectedPath = Form.useWatch<string[]>(getAbsolutePath("path"));
  const selectedField = useField(sourceObject, selectedPath);
  return (
    <div className="field-to-update">
      <div className="field-to-update__content">
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Field name="path" rules={[{ required: true, message: "Please, select field" }]}>
              <SalesforceObjectPathPicker
                obj={sourceObject}
                nesting={1}
                updatableOnly
                onChange={handlePathChange}
              />
            </Field>
          </Col>
          <Col span={12}>
            <Field name="value" rules={[{ required: true, message: "Please, provide value" }]}>
              <FieldValue field={selectedField} placeholder="Value" />
            </Field>
          </Col>
        </Row>
      </div>
      <IconButton className="field-to-update__remove" icon={Remove} onClick={onRemove} />
    </div>
  );
};
