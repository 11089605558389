export const ddex_4_2 = {
  MessageHeader: {
    MessageThreadId: "string",
    MessageId: "string",
    MessageFileName: "string",
    MessageSender: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    SentOnBehalfOf: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    MessageRecipient: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    MessageCreatedDateTime: "2013-11-23T16:44:07",
    MessageAuditTrail: {
      MessageAuditTrailEvent: {
        MessagingPartyDescriptor: {
          PartyId: "string",
          PartyName: {
            FullName: "string",
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: "string",
            NamesBeforeKeyName: "string",
            KeyName: "string",
            NamesAfterKeyName: "string",
            AbbreviatedName: "string",
          },
          TradingName: "string",
        },
        DateTime: "2014-06-09T18:15:04+03:00",
      },
    },
    MessageControlType: "TestMessage",
  },
  ReleaseAdmin: {
    ReleaseAdminId: "string",
    PersonnelDescription: "string",
    SystemDescription: "string",
  },
  PartyList: [
    {
      Party: {
        PartyReference: "string",
        PartyId: {
          ISNI: "string",
          DPID: "string",
          IpiNameNumber: "string",
          IPN: "string",
          CisacSocietyId: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
        },
        PartyName: {
          FullName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FullNameAsciiTranscribed: "string",
          FullNameIndexed: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          NamesBeforeKeyName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          KeyName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          NamesAfterKeyName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          AbbreviatedName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _IsNickname: "false",
          _IsStageName: "true",
          _IsLegalName: "true",
          _ApplicableTerritoryCode: "LT",
          _IsDefault: "true",
        },
        Affiliation: {
          CompanyName: "string",
          PartyAffiliateReference: "string",
          Type: "MusicRightsSociety",
          TerritoryCode: {
            _IdentifierType: "TIS",
            __text: "NE",
          },
          ExcludedTerritoryCode: {
            _IdentifierType: "TIS",
            __text: "RE",
          },
          ValidityPeriod: {
            StartDate: {
              _IsApproximate: "true",
              _IsBefore: "true",
              _IsAfter: "false",
              _ApplicableTerritoryCode: "196",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            EndDate: {
              _IsApproximate: "true",
              _IsBefore: "true",
              _IsAfter: "true",
              _ApplicableTerritoryCode: "804",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RightsType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "MakeAvailableRight",
          },
          PercentageOfRightsAssignment: "1000.00",
        },
        RelatedParty: {
          PartyRelatedPartyReference: "string",
          PartyRelationshipType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            _MayBeShared: "true",
            __text: "IsChildOf",
          },
        },
        ArtistProfilePage: "string",
      },
    },
  ],
  CueSheetList: [
    {
      CueSheet: {
        CueSheetId: {
          _Namespace: "string",
          __text: "string",
        },
        CueSheetReference: "string",
        CueSheetType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "AverageCueSheet",
        },
        Cue: [
          {
            CueUseType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "FilmTheme",
            },
            CueThemeType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "ClosingTheme",
            },
            CueVocalType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            CueVisualPerceptionType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Visual",
            },
            CueOrigin: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "LibraryMusic",
            },
            ResourceId: {
              ISRC: "string",
              ISMN: "string",
              ISAN: "string",
              VISAN: "string",
              ISBN: "string",
              ISSN: "string",
              SICI: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "true",
            },
            WorkId: {
              ISWC: "string",
              OpusNumber: "string",
              ComposerCatalogNumber: "string",
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "false",
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "222",
              _IsDefault: "false",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "MA",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "2121",
              _TitleType: "GroupingTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "false",
            },
            Contributor: {
              ContributorPartyReference: "string",
              Role: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Facsimilist",
              },
              InstrumentType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "PiccoloFlute",
              },
              HasMadeFeaturedContribution: "true",
              HasMadeContractedContribution: "true",
              IsCredited: {
                _MayBeShared: "true",
                __text: "false",
              },
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "203",
                _IsDefault: "false",
              },
              _SequenceNumber: "100",
            },
            IsDance: "false",
            HasMusicalContent: "true",
            PLine: {
              Year: "2011",
              PLineCompany: "string",
              PLineText: "string",
              _LanguageAndScriptCode: "string",
              _PLineType: "OriginalPLine",
            },
            CLine: {
              Year: "2018+02:00",
              CLineCompany: "string",
              CLineText: "string",
              _LanguageAndScriptCode: "string",
            },
            StartTime: "P1Y2M2DT18H5M8S",
            Duration: "P8M8DT21H51M5S",
            EndTime: "P1Y4M5DT17H10M59S",
          },
        ],
      },
    },
  ],
  ResourceList: [
    {
      SoundRecording: [
        {
          ResourceReference: "string",
          Type: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "MusicalWorkReadalongSoundRecording",
          },
          ResourceId: {
            ISRC: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "true",
          },
          WorkId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "true",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "140",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "32",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "732",
            _TitleType: "OriginalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          VersionType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "StereoVersion",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "BT",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Artist",
            },
            ArtisticRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "ScientificAdvisor",
            },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "true",
              Prefix: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          Contributor: [
            {
              ContributorPartyReference: "string",
              Role: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Narrator",
              },
              InstrumentType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "JewsHarp",
              },
              HasMadeFeaturedContribution: "false",
              HasMadeContractedContribution: "false",
              IsCredited: {
                _MayBeShared: "false",
                __text: "true",
              },
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "DE",
                _IsDefault: "true",
              },
              _SequenceNumber: "100",
            },
          ],
          Character: {
            CharacterPartyReference: "string",
            Performer: {
              ContributorPartyReference: "string",
              Role: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "LightingDirector",
              },
              InstrumentType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Ganga",
              },
              HasMadeFeaturedContribution: "false",
              HasMadeContractedContribution: "true",
              IsCredited: {
                _MayBeShared: "true",
                __text: "true",
              },
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "466",
                _IsDefault: "false",
              },
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "LocalPayee",
            RightShareUnknown: "false",
            RightSharePercentage: {
              _HasMaxValueOfOne: "false",
              __text: "1000.00",
            },
            PartyName: {
              FullName: { _LanguageAndScriptCode: "string", __text: "string" },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RightsController",
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "DubForMusicOnHold",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "IE",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "2108",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "720",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2001-08-29T22:58:16",
                },
                EndDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "PY",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2005-05-11T09:55:50+03:00",
                },
              },
              TerritoryOfRightsDelegation: {
                _IdentifierType: "ISO",
                __text: "PA",
              },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "RightsController",
            RightsControllerType: "OriginalOwner",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: {
              _IdentifierType: "ISO",
              __text: "KH",
            },
            StartDate: "string",
            EndDate: "string",
          },
          PLine: {
            Year: "2010+03:00",
            PLineCompany: "string",
            PLineText: "string",
            _ApplicableTerritoryCode: "SN",
            _IsDefault: "true",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "TN",
            _IsDefault: "true",
            __text: "string",
          },
          Duration: "P1Y2M8DT19H31M3S",
          CreationDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "188",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          MasteredDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "703",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          RemasteredDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "MA",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            _ApplicableTerritoryCode: "HR",
            _IsDefault: "false",
            __text: "string",
          },
          LocationAndDateOfSession: {
            SessionType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Mastering",
            },
            Period: {
              StartDate: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "false",
                _ApplicableTerritoryCode: "KN",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              EndDate: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "false",
                _ApplicableTerritoryCode: "2109",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartDateTime: {
                _IsApproximate: "false",
                _IsBefore: "false",
                _IsAfter: "false",
                _TerritoryCode: "JM",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2015-08-31T15:22:22+03:00",
              },
              EndDateTime: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "false",
                _TerritoryCode: "MC",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2000-06-26T09:41:48+03:00",
              },
            },
            Venue: {
              VenueName: "string",
              VenueAddress: "string",
              TerritoryCode: {
                _IdentifierType: "TIS",
                __text: "188",
              },
              LocationCode: "string",
              VenueRoom: "string",
            },
            Comment: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "CD",
              _IsDefault: "false",
              _Format: "ASCII",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "string",
            },
            Contributor: {
              ISNI: "string",
              DPID: "string",
              IpiNameNumber: "string",
              IPN: "string",
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              PartyName: {
                FullName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                FullNameAsciiTranscribed: "string",
                FullNameIndexed: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesBeforeKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                KeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesAfterKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                AbbreviatedName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
                _IsNickname: "true",
                _IsStageName: "true",
                _IsLegalName: "true",
                _ApplicableTerritoryCode: "ES",
                _IsDefault: "false",
              },
              Role: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "DialogueCoach",
              },
            },
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "LI",
            _UserDefinedValue: "string",
            _IsDefault: "false",
            __text: "Explicit",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "HasArtistFromSameEnsemble",
            },
            ReleaseId: {
              GRid: "string",
              ICPN: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "858",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "UA",
              _IsDefault: "false",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "ES-CE",
              _TitleType: "GroupingTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "true",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "AO",
              _IsDefault: "false",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "false",
              _LabelType: "DisplayLabel",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "64",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "MF",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "false",
              _ApplicableTerritoryCode: "2125",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "IsDifferentEncoding",
            ResourceRelatedResourceReference: "string",
            ResourceId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              ISMN: "string",
              ISAN: "string",
              VISAN: "string",
              ISBN: "string",
              ISSN: "string",
              SICI: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "true",
            },
            Timing: {
              StartPoint: "P1Y2M8DT11H50M",
              DurationUsed: "P9M4DT13H24M49S",
            },
          },
          CompositeMusicalWorkType: "Potpourri",
          IsCover: "false",
          IsInstrumental: "false",
          ContainsHiddenContent: "false",
          IsRemastered: "false",
          IsHiResMusic: "true",
          DisableCrossfade: "true",
          DisableSearch: "true",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "UZ",
            _IsDefault: "false",
          },
          LanguageOfPerformance: "string",
          AudioChannelConfiguration: "BinauralAudio",
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            EncodingId: {
              ISRC: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "true",
            },
            AudioCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "RealAudio",
            },
            BitRate: {
              _UnitOfMeasure: "Mbps",
              __text: "1000.00",
            },
            OriginalBitRate: {
              _UnitOfMeasure: "Gbps",
              __text: "1000.00",
            },
            NumberOfChannels: "100",
            SamplingRate: {
              _UnitOfMeasure: "Hz",
              __text: "1000.00",
            },
            OriginalSamplingRate: {
              _UnitOfMeasure: "kHz",
              __text: "1000.00",
            },
            BitsPerSample: "100",
            Duration: "P9M1DT12H7M58S",
            BitDepth: "100",
            IsPreview: "true",
            PreviewDetails: {
              StartPoint: "1000.00",
              EndPoint: "1000.00",
              Duration: "P1Y6M3DT18H44M8S",
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Instructive",
            },
            File: {
              URI: "string",
              HashSum: {
                Algorithm: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "SHA2",
                },
                Version: "string",
                Parameter: "string",
                DataType: "HexBinary",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            IsProvidedInDelivery: "false",
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "SHA-512",
                  },
                  Version: "string",
                  Parameter: "string",
                  DataType: "HexBinary",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "Binary64",
              FingerprintValue: "string",
            },
            EncodingDescription: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "392",
            _IsDefault: "true",
          },
          Raga: {
            _ApplicableTerritoryCode: "659",
            _IsDefault: "true",
            __text: "string",
          },
          Tala: {
            _ApplicableTerritoryCode: "124",
            _IsDefault: "false",
            __text: "string",
          },
          Deity: {
            _ApplicableTerritoryCode: "710",
            _IsDefault: "true",
            __text: "string",
          },
          AudioChapterReference: "string",
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "false",
        },
      ],
      Video: [
        {
          ResourceReference: "string",
          Type: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Magazine",
          },
          ResourceId: {
            ISRC: "string",
            ISAN: "string",
            VISAN: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            EIDR: "string",
            _IsReplaced: "true",
          },
          WorkId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "true",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "AG",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "LI",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "NA",
            _TitleType: "AlternativeTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          VersionType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "AlbumVersion",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "120",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Artist",
            },
            ArtisticRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "OriginalArtist",
            },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "ArtistVocalEngineer",
            },
            InstrumentType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Gamelan",
            },
            HasMadeFeaturedContribution: "false",
            HasMadeContractedContribution: "false",
            IsCredited: {
              _MayBeShared: "false",
              __text: "true",
            },
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "266",
              _IsDefault: "false",
            },
            _SequenceNumber: "100",
          },
          Character: {
            CharacterPartyReference: "string",
            Performer: {
              ContributorPartyReference: "string",
              Role: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Pyrotechnician",
              },
              InstrumentType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Bouzouki",
              },
              HasMadeFeaturedContribution: "true",
              HasMadeContractedContribution: "false",
              IsCredited: {
                _MayBeShared: "false",
                __text: "true",
              },
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "VI",
                _IsDefault: "false",
              },
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "RightsController",
            RightShareUnknown: "false",
            RightSharePercentage: {
              _HasMaxValueOfOne: "true",
              __text: "1000.00",
            },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "124",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "524",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _TerritoryCode: "583",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2008-06-25T23:46:03+03:00",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "BI",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2018-07-25T21:36:18+03:00",
                },
              },
              TerritoryOfRightsDelegation: {
                _IdentifierType: "DeprecatedISO",
                __text: "NL",
              },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "Unknown",
            RightsControllerType: "ExclusiveLicensee",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: {
              _IdentifierType: "ISO",
              __text: "CM",
            },
            StartDate: "string",
            EndDate: "string",
          },
          PLine: {
            Year: "2009+03:00",
            PLineCompany: "string",
            PLineText: "string",
            _ApplicableTerritoryCode: "450",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          CLine: {
            Year: "2014",
            CLineCompany: "string",
            CLineText: "string",
            _ApplicableTerritoryCode: "GQ",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "296",
            _IsDefault: "true",
            __text: "string",
          },
          Duration: "P10M1DT1H16M38S",
          CreationDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "96",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          MasteredDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "2101",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          RemasteredDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "208",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
            _ApplicableTerritoryCode: "776",
            _IsDefault: "true",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "566",
            _UserDefinedValue: "string",
            _IsDefault: "false",
            __text: "UserDefined",
          },
          AvRating: {
            Rating: "string",
            Agency: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "CNC",
            },
            Reason: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Drugs",
            },
            _ApplicableTerritoryCode: "EE",
            _IsDefault: "false",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "HasSameRecordingProject",
            },
            ReleaseId: {
              GRid: "string",
              ICPN: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "624",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "364",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "32",
              _TitleType: "AlternativeTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "true",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "EG",
              _IsDefault: "true",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "true",
              _LabelType: "DisplayLabel",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "MR",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "736",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "50",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "ContainsSamplesFrom",
            ResourceRelatedResourceReference: "string",
            ResourceId: {
              ISRC: "string",
              ISMN: "string",
              ISAN: "string",
              VISAN: "string",
              ISBN: "string",
              ISSN: "string",
              SICI: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "true",
            },
            Timing: {
              StartPoint: "P1Y7M8DT11M34S",
              DurationUsed: "P9M5DT17H3M26S",
            },
          },
          CompositeMusicalWorkType: "Potpourri",
          VideoCueSheetReference: "string",
          ReasonForCueSheetAbsence: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          IsCover: "false",
          IsInstrumental: "true",
          ContainsHiddenContent: "false",
          IsRemastered: "true",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "800",
            _IsDefault: "true",
          },
          LanguageOfPerformance: "string",
          LanguageOfDubbing: "string",
          SubTitleLanguage: "string",
          ResourceContainedResourceReferenceList: [
            {
              ResourceContainedResourceReference: {
                ResourceContainedResourceReference: "string",
                DurationUsed: "P9M3DT4H39M34S",
                StartPoint: "1000.00",
                Purpose: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "TrailerMusic",
                },
              },
            },
          ],
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            EncodingId: {
              ISRC: "string",
              ISAN: "string",
              VISAN: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              EIDR: "string",
              _IsReplaced: "true",
            },
            OverallBitRate: {
              _UnitOfMeasure: "Gbps",
              __text: "1000.00",
            },
            ContainerFormat: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "AIFF",
            },
            VideoCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "H.261",
            },
            VideoBitRate: {
              _UnitOfMeasure: "kbps",
              __text: "1000.00",
            },
            FrameRate: {
              _UnitOfMeasure: "Hz(interlaced)",
              __text: "1000.00",
            },
            ImageHeight: {
              _UnitOfMeasure: "cm",
              __text: "1000.00",
            },
            ImageWidth: {
              _UnitOfMeasure: "Inch",
              __text: "1000.00",
            },
            AspectRatio: {
              _AspectRatioType: "PAR",
              __text: "1000.00",
            },
            CoreArea: {
              TopLeftCorner: "string",
              BottomRightCorner: "string",
            },
            ColorDepth: "100",
            VideoDefinitionType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "HighDefinition",
            },
            AudioCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "MuLaw",
            },
            AudioBitRate: {
              _UnitOfMeasure: "Mbps",
              __text: "1000.00",
            },
            NumberOfAudioChannels: "100",
            AudioSamplingRate: {
              _UnitOfMeasure: "GHz",
              __text: "1000.00",
            },
            AudioBitsPerSample: "100",
            Duration: "P10M4DT12H16M17S",
            BitDepth: "100",
            IsPreview: "true",
            PreviewDetails: {
              StartPoint: "1000.00",
              EndPoint: "1000.00",
              Duration: "P1Y1M7DT19H32M20S",
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Informative",
            },
            File: {
              URI: "string",
              HashSum: {
                Algorithm: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "SHA-224",
                },
                Version: "string",
                Parameter: "string",
                DataType: "Binary64",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            IsProvidedInDelivery: "true",
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "SHA2",
                  },
                  Version: "string",
                  Parameter: "string",
                  DataType: "HexBinary",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "Binary64",
              FingerprintValue: "string",
            },
            EncodingDescription: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "524",
            _IsDefault: "false",
          },
          Raga: "string",
          Tala: "string",
          Deity: "string",
          VideoChapterReference: "string",
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "false",
        },
      ],
      Image: [
        {
          ResourceReference: "string",
          Type: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Poster",
          },
          ResourceId: {
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "true",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2134",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "TH",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2122",
            _TitleType: "UserDefined",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          VersionType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "DemoVersion",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "222",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Artist",
            },
            ArtisticRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "TransfersAndSafetiesSecondEngineer",
            },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Librettist",
            },
            InstrumentType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "SpectrasonicsOmnisphere",
            },
            HasMadeFeaturedContribution: "false",
            HasMadeContractedContribution: "true",
            IsCredited: {
              _MayBeShared: "true",
              __text: "true",
            },
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "AZ",
              _IsDefault: "false",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "RightsController",
            RightShareUnknown: "false",
            RightSharePercentage: {
              _HasMaxValueOfOne: "false",
              __text: "1000.00",
            },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "DubForMusicOnHold",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "IT",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _ApplicableTerritoryCode: "PY",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "RS",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2009-03-10T03:17:16+02:00",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _TerritoryCode: "504",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2011-07-11T04:54:36",
                },
              },
              TerritoryOfRightsDelegation: {
                _IdentifierType: "DeprecatedISO",
                __text: "PF",
              },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControlType: "RoyaltyAdministrator",
            RightsControllerType: "SuccessorInTitle",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: {
              _IdentifierType: "TIS",
              __text: "792",
            },
            StartDate: "string",
            EndDate: "string",
          },
          CLine: {
            Year: "2003+03:00",
            CLineCompany: "string",
            CLineText: "string",
            _ApplicableTerritoryCode: "600",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "MM",
            _IsDefault: "true",
            __text: "string",
          },
          CreationDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "242",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
            _ApplicableTerritoryCode: "2128",
            _IsDefault: "true",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "470",
            _UserDefinedValue: "string",
            _IsDefault: "true",
            __text: "ExplicitContentEdited",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "IsEquivalentToAudio",
            },
            ReleaseId: {
              GRid: "string",
              ICPN: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "BA",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "FO",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "670",
              _TitleType: "GroupingTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "true",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "826",
              _IsDefault: "true",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "true",
              _LabelType: "UserDefined",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "SD",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "false",
              _ApplicableTerritoryCode: "SK",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "2119",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "IsCoveredBy",
            ResourceRelatedResourceReference: "string",
            ResourceId: {
              ISRC: "string",
              ISMN: "string",
              ISAN: "string",
              VISAN: "string",
              ISBN: "string",
              ISSN: "string",
              SICI: "string",
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "false",
            },
            Timing: {
              StartPoint: "P1Y5M3DT13H2M18S",
              DurationUsed: "P1Y7M8DT6H1M21S",
            },
          },
          ContainsHiddenContent: "true",
          Description: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "492",
            _IsDefault: "false",
            __text: "string",
          },
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            ImageCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "GIF",
            },
            ImageHeight: {
              _UnitOfMeasure: "PercentOfScreen",
              __text: "1000.00",
            },
            ImageWidth: {
              _UnitOfMeasure: "Inch",
              __text: "1000.00",
            },
            AspectRatio: {
              _AspectRatioType: "PAR",
              __text: "1000.00",
            },
            ColorDepth: "100",
            ImageResolution: "100",
            BitDepth: "100",
            IsPreview: "false",
            PreviewDetails: {
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Instructive",
            },
            File: {
              URI: "string",
              HashSum: {
                Algorithm: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "SHA-224",
                },
                Version: "string",
                Parameter: "string",
                DataType: "HexBinary",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            IsProvidedInDelivery: "false",
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "MD2",
                  },
                  Version: "string",
                  Parameter: "string",
                  DataType: "Binary64",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "HexBinary",
              FingerprintValue: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "388",
            _IsDefault: "true",
          },
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "false",
        },
      ],
      Text: {
        ResourceReference: "string",
        Type: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "Unknown",
        },
        ResourceId: {
          ISBN: "string",
          ISSN: "string",
          SICI: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "false",
        },
        WorkId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "true",
        },
        DisplayTitleText: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "ML",
          _IsDefault: "false",
          __text: "string",
        },
        DisplayTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "false",
            _SubTitleType: "Version",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "862",
          _IsDefault: "true",
        },
        AdditionalTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "false",
            _SubTitleType: "Location",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "120",
          _TitleType: "FormalTitle",
          _Namespace: "string",
          _UserDefinedValue: "string",
          _IsDefault: "true",
        },
        VersionType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "RemixVersion",
        },
        DisplayArtistName: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "2134",
          _IsDefault: "true",
          __text: "string",
        },
        DisplayArtist: {
          ArtistPartyReference: "string",
          DisplayArtistRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Artist",
          },
          ArtisticRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Dubber",
          },
          TitleDisplayInformation: {
            IsDisplayedInTitle: "true",
            Prefix: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _SequenceNumber: "100",
          },
          _SequenceNumber: "100",
        },
        Contributor: {
          ContributorPartyReference: "string",
          Role: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "StudioPersonnel",
          },
          InstrumentType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Leona",
          },
          HasMadeFeaturedContribution: "true",
          HasMadeContractedContribution: "true",
          IsCredited: {
            _MayBeShared: "false",
            __text: "false",
          },
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "TF",
            _IsDefault: "false",
          },
          _SequenceNumber: "100",
        },
        ResourceRightsController: {
          RightsControllerPartyReference: "string",
          RightsControlType: "LocalPayee",
          RightShareUnknown: "false",
          RightSharePercentage: {
            _HasMaxValueOfOne: "false",
            __text: "1000.00",
          },
          DelegatedUsageRights: {
            UseType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UseAsScreensaver",
            },
            PeriodOfRightsDelegation: {
              StartDate: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "40",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              EndDate: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "OM",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartDateTime: {
                _IsApproximate: "false",
                _IsBefore: "false",
                _IsAfter: "true",
                _TerritoryCode: "AI",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2008-04-10T11:07:16+03:00",
              },
              EndDateTime: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "false",
                _TerritoryCode: "SI",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2006-05-20T04:45:43+03:00",
              },
            },
            TerritoryOfRightsDelegation: {
              _IdentifierType: "ISO",
              __text: "EG",
            },
          },
          _SequenceNumber: "100",
        },
        WorkRightsController: {
          RightsControllerPartyReference: "string",
          RightsControlType: "RoyaltyAdministrator",
          RightsControllerType: "SuccessorInTitle",
          RightShareUnknown: "false",
          RightSharePercentage: "1000.00",
          Territory: {
            _IdentifierType: "DeprecatedISO",
            __text: "499",
          },
          StartDate: "string",
          EndDate: "string",
        },
        CLine: {
          Year: "2017+02:00",
          CLineCompany: "string",
          CLineText: "string",
          _ApplicableTerritoryCode: "76",
          _IsDefault: "true",
          _LanguageAndScriptCode: "string",
        },
        CourtesyLine: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "52",
          _IsDefault: "true",
          __text: "string",
        },
        CreationDate: {
          _IsApproximate: "true",
          _ApplicableTerritoryCode: "174",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FirstPublicationDate: {
          FulfillmentDate: "string",
          ResourceReleaseReference: "string",
          _ApplicableTerritoryCode: "SC",
          _IsDefault: "false",
        },
        ParentalWarningType: {
          _Namespace: "string",
          _ApplicableTerritoryCode: "GS",
          _UserDefinedValue: "string",
          _IsDefault: "false",
          __text: "NotExplicit",
        },
        RelatedRelease: {
          ReleaseRelationshipType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "HasSimilarContent",
          },
          ReleaseId: {
            GRid: "string",
            ICPN: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "IL",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "CN",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "320",
            _TitleType: "OriginalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2114",
            _IsDefault: "true",
            __text: "string",
          },
          ReleaseLabelReference: {
            _LanguageAndScriptCode: "string",
            _IsDefault: "true",
            _LabelType: "DisplayLabel",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _ApplicableTerritoryCode: "178",
            __text: "string",
          },
          ReleaseDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "804",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          OriginalReleaseDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "SA",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
        },
        RelatedResource: {
          ResourceRelationshipType: "HasContentFrom",
          ResourceRelatedResourceReference: "string",
          ResourceId: {
            ISRC: "string",
            ISMN: "string",
            ISAN: "string",
            VISAN: "string",
            ISBN: "string",
            ISSN: "string",
            SICI: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "false",
          },
          Timing: {
            StartPoint: "P9M4DT12H7M53S",
            DurationUsed: "P1Y6DT13H13M29S",
          },
        },
        ContainsHiddenContent: "false",
        ResourceContainedResourceReferenceList: [
          {
            ResourceContainedResourceReference: {
              ResourceContainedResourceReference: "string",
              DurationUsed: "P10M1DT10H6M5S",
              StartPoint: "1000.00",
              Purpose: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
            },
          },
        ],
        TechnicalDetails: {
          TechnicalResourceDetailsReference: "string",
          TextCodecType: {
            _Version: "string",
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "XHTML",
          },
          BitDepth: "100",
          IsPreview: "true",
          PreviewDetails: {
            TopLeftCorner: "string",
            BottomRightCorner: "string",
            ExpressionType: "Informative",
          },
          File: {
            URI: "string",
            HashSum: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "MD2",
              },
              Version: "string",
              Parameter: "string",
              DataType: "Binary64",
              HashSumValue: "string",
            },
            FileSize: "1000.00",
          },
          IsProvidedInDelivery: "false",
          Fingerprint: {
            Algorithm: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            Version: "string",
            Parameter: "string",
            File: {
              URI: "string",
              HashSum: {
                Algorithm: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "SHA3",
                },
                Version: "string",
                Parameter: "string",
                DataType: "Binary64",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            DataType: "HexBinary",
            FingerprintValue: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "SS",
          _IsDefault: "true",
        },
        _LanguageAndScriptCode: "string",
        _IsSupplemental: "false",
      },
      SheetMusic: {
        ResourceReference: "string",
        Type: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "UserDefined",
        },
        ResourceId: {
          ISMN: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "false",
        },
        WorkId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "true",
        },
        DisplayTitleText: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "WF",
          _IsDefault: "true",
          __text: "string",
        },
        DisplayTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "false",
            _SubTitleType: "Location",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "PE",
          _IsDefault: "true",
        },
        AdditionalTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "true",
            _SubTitleType: "Location",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "72",
          _TitleType: "UserDefined",
          _Namespace: "string",
          _UserDefinedValue: "string",
          _IsDefault: "false",
        },
        VersionType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "SingleVersion",
        },
        DisplayArtistName: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "2123",
          _IsDefault: "false",
          __text: "string",
        },
        DisplayArtist: {
          ArtistPartyReference: "string",
          DisplayArtistRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "FeaturedArtist",
          },
          ArtisticRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "OverdubEngineer",
          },
          TitleDisplayInformation: {
            IsDisplayedInTitle: "true",
            Prefix: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _SequenceNumber: "100",
          },
          _SequenceNumber: "100",
        },
        Contributor: {
          ContributorPartyReference: "string",
          Role: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Draughtsman",
          },
          InstrumentType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Heckelphone",
          },
          HasMadeFeaturedContribution: "true",
          HasMadeContractedContribution: "true",
          IsCredited: {
            _MayBeShared: "true",
            __text: "false",
          },
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "470",
            _IsDefault: "true",
          },
          _SequenceNumber: "100",
        },
        ResourceRightsController: {
          RightsControllerPartyReference: "string",
          RightsControlType: "LocalPayee",
          RightShareUnknown: "false",
          RightSharePercentage: {
            _HasMaxValueOfOne: "true",
            __text: "1000.00",
          },
          DelegatedUsageRights: {
            UseType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "PerformAsMusicOnHold",
            },
            PeriodOfRightsDelegation: {
              StartDate: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "508",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              EndDate: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "BI",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartDateTime: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "true",
                _TerritoryCode: "NI",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2000-11-10T17:15:09",
              },
              EndDateTime: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "false",
                _TerritoryCode: "104",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2000-10-14T18:47:53",
              },
            },
            TerritoryOfRightsDelegation: {
              _IdentifierType: "TIS",
              __text: "HR",
            },
          },
          _SequenceNumber: "100",
        },
        WorkRightsController: {
          RightsControllerPartyReference: "string",
          RightsControlType: "Unknown",
          RightsControllerType: "OriginalOwner",
          RightShareUnknown: "true",
          RightSharePercentage: "1000.00",
          Territory: {
            _IdentifierType: "DeprecatedISO",
            __text: "BR",
          },
          StartDate: "string",
          EndDate: "string",
        },
        CLine: {
          Year: "2000",
          CLineCompany: "string",
          CLineText: "string",
          _ApplicableTerritoryCode: "KZ",
          _IsDefault: "false",
          _LanguageAndScriptCode: "string",
        },
        CourtesyLine: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "ST",
          _IsDefault: "true",
          __text: "string",
        },
        CreationDate: {
          _IsApproximate: "true",
          _ApplicableTerritoryCode: "UM",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FirstPublicationDate: {
          FulfillmentDate: "string",
          ResourceReleaseReference: "string",
          _ApplicableTerritoryCode: "616",
          _IsDefault: "false",
        },
        ParentalWarningType: {
          _Namespace: "string",
          _ApplicableTerritoryCode: "686",
          _UserDefinedValue: "string",
          _IsDefault: "true",
          __text: "NotExplicit",
        },
        RelatedRelease: {
          ReleaseRelationshipType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "IsFromVideo",
          },
          ReleaseId: {
            GRid: "string",
            ICPN: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "IS",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "TR",
            _IsDefault: "false",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "FO",
            _TitleType: "OriginalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "50",
            _IsDefault: "true",
            __text: "string",
          },
          ReleaseLabelReference: {
            _LanguageAndScriptCode: "string",
            _IsDefault: "false",
            _LabelType: "DisplayLabel",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _ApplicableTerritoryCode: "AI",
            __text: "string",
          },
          ReleaseDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "ZM",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          OriginalReleaseDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "ES-CN",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
        },
        RelatedResource: {
          ResourceRelationshipType: "ContainsSamplesFrom",
          ResourceRelatedResourceReference: "string",
          ResourceId: {
            ISRC: "string",
            ISMN: "string",
            ISAN: "string",
            VISAN: "string",
            ISBN: "string",
            ISSN: "string",
            SICI: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "false",
          },
          Timing: {
            StartPoint: "P6M6DT7H21M32S",
            DurationUsed: "P8MT11H22M29S",
          },
        },
        ContainsHiddenContent: "false",
        LanguageOfLyrics: "string",
        ResourceContainedResourceReferenceList: [
          {
            ResourceContainedResourceReference: {
              ResourceContainedResourceReference: "string",
              DurationUsed: "P1Y1M7DT29M59S",
              StartPoint: "1000.00",
              Purpose: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "TrailerMusic",
              },
            },
          },
        ],
        TechnicalDetails: {
          TechnicalResourceDetailsReference: "string",
          SheetMusicCodecType: {
            _Version: "string",
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "UserDefined",
          },
          BitDepth: "100",
          IsPreview: "false",
          PreviewDetails: {
            TopLeftCorner: "string",
            BottomRightCorner: "string",
            ExpressionType: "Instructive",
          },
          File: {
            URI: "string",
            HashSum: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "SHA-224",
              },
              HashSumValue: "string",
            },
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "AX",
          _IsDefault: "false",
        },
        _LanguageAndScriptCode: "string",
        _IsSupplemental: "false",
      },
    },
  ],
  ReleaseList: [
    {
      Release: [
        {
          ReleaseReference: "Release57410",
          ReleaseType: "Album",
          ReleaseId: { ICPN: "1", ISRC: "1", CatalogNumber: "string", GRid: "" },
          DisplayTitleText: "string",
          DisplayTitle: { TitleText: "string" },
          AdditionalTitle: { TitleText: "string" },
          DisplayArtistName: "string",
          DisplayArtist: { ArtistPartyReference: "string", DisplayArtistRole: "string" },
          ReleaseLabelReference: "string",
          PLine: { Year: "2020", PLineCompany: "string", PLineText: "℗ 2020Loma Vista Recordings." },
          CLine: { Year: "2020", CLineCompany: "string", CLineText: "℗ 2020Loma Vista Recordings." },
          Duration: "string",
          Genre: { GenreText: "string" },
          ReleaseDate: "string",
          ParentalWarningType: "string",
          IsMultiArtistCompilation: false,
          ResourceGroup: [
            {
              ResourceGroup: {
                SequenceNumber: "1",
                ResourceGroupContentItem: {
                  SequenceNumber: "1",
                  ReleaseResourceReference: "string",
                  IsInstantGratificationResource: false,
                  IsPreOrderIncentiveResource: false,
                },
              },
              LinkedReleaseResourceReference: "string",
            },
          ],
          ReleaseResourceReferenceList: [
            {
              ReleaseResourceReference: {
                _ReleaseResourceType: "PrimaryResource",
                __text: "ATrack268647",
              },
            },
          ],
          ReleaseDetailsByTerritory: {
            TerritoryCode: "Worldwide",
            DisplayArtistName: "Sylvan Esso",
            LabelName: "Loma Vista Recordings",
            Title: {
              TitleText: "WITH",
              _TitleType: "FormalTitle",
            },
            DisplayArtist: {
              PartyName: {
                FullName: "string",
              },
              ArtistRole: "MainArtist",
              _SequenceNumber: "1",
            },
            ParentalWarningType: "NotExplicit",
            ResourceGroup: [
              {
                ResourceGroup: {
                  Title: {
                    TitleText: "Disc 1",
                    _TitleType: "GroupingTitle",
                  },
                  SequenceNumber: "1",
                  ResourceGroupContentItem: {
                    SequenceNumber: "1",
                    ResourceType: "SoundRecording",
                    ReleaseResourceReference: {
                      _ReleaseResourceType: "PrimaryResource",
                      __text: "string",
                    },
                  },
                },
                ResourceGroupContentItem: {
                  ResourceType: "Image",
                  ReleaseResourceReference: {
                    _ReleaseResourceType: "SecondaryResource",
                    __text: "ACover30255",
                  },
                },
              },
            ],
            Genre: {
              GenreText: "Alternative",
              SubGenre: "",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              __text: "2020-04-24",
            },
          },
          GlobalOriginalReleaseDate: {
            _IsApproximate: "true",
            __text: "2020-04-24",
          },
          OriginalReleaseDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "862",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _IsMainRelease: "true",
        },
      ],
      TrackRelease: {
        ReleaseReference: "string",
        ReleaseId: { ISRC: "string", ProprietaryId: "string" },
        DisplayTitleText: "string",
        DisplayTitle: { TitleText: "string", SubTitle: "" },
        AdditionalTitle: { TitleText: "string", SubTitle: "" },
        ReleaseResourceReference: "string",
        ReleaseLabelReference: "string",
        Genre: { GenreText: "string" },
      },
    },
  ],
  DealList: [
    {
      ReleaseDeal: [
        {
          DealReleaseReference: "string",
          Deal: {
            DealTerms: {
              TerritoryCode: "string",
              ValidityPeriod: { StartDate: "string" },
              CommercialModelType: "string",
              UseType: "string",
              ReleaseDisplayStartDate: "string",
              TrackListingPreviewStartDate: "string",
              CoverArtPreviewStartDate: "string",
              ClipPreviewStartDate: "string",
            },
          },
        },
      ],
      ReleaseVisibility: {
        VisibilityReference: "string",
        ReleaseDisplayStartDateTime: "string",
        CoverArtPreviewStartDateTime: "string",
      },
      TrackReleaseVisibility: {
        VisibilityReference: "string",
        TrackListingPreviewStartDateTime: "string",
        ClipPreviewStartDateTime: "string",
      },
    },
  ],
};
