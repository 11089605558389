import { useCallback } from "react";
import { columns } from "./columns";
import { TableWithPaginationAndSorting } from "../../common";
import { useGridParams } from "@/utils/useGridParams";
import { prepareParams } from "@/utils";
import { useHistory } from "react-router-dom";
import { useTemplates } from "@/api/templates/hooks";

function TemplatesTable() {
  const history = useHistory();
  const { filters, pagination, sort: sortParams } = useGridParams();
  const { isLoading, data: paginatedTemplates } = useTemplates(
    prepareParams(filters, pagination, sortParams)
  );

  const handleEdit = useCallback(({ id }) => history.push(`/templates/${id}`), [history]);

  return (
    <>
      <TableWithPaginationAndSorting
        onRowClick={handleEdit}
        loading={isLoading}
        columns={columns}
        data={paginatedTemplates}
        scroll={{ x: true }}
      />
    </>
  );
}

export default TemplatesTable;
