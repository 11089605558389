import { Field } from "@/components/common/Form/Field";
import { Col, Input, Row } from "antd";
import { StatusSwitch } from "@/components/common/StatusSwitch/StatusSwitch";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import { RecurrencyForm } from "@/components/common/RecurrencyForm";
import { getSalesforceEquivalent } from "@/utils/getSalesforceEquivalent";
import type { OpenplayObjectType } from "@/types/common";

type Props = {
  targetObject: OpenplayObjectType;
};

export const ExportScheduleFields = ({ targetObject }: Props) => {
  const salesforceObject = getSalesforceEquivalent(targetObject);
  return (
    <>
      <Field hidden name="id">
        <Input />
      </Field>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field label="Status" name="isActive" valuePropName="checked">
            <StatusSwitch disabled={false} />
          </Field>
        </Col>
        <Col span={24}>
          <Field
            label="Query"
            name="query"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "At least one of the rules should be configured",
              },
            ]}
          >
            <SalesforceQueryBuilder from={salesforceObject} />
          </Field>
        </Col>
        <Col span={24}>
          <RecurrencyForm name="schedule" />
        </Col>
      </Row>
    </>
  );
};
