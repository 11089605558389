import { Field } from "../../../../common/Form/Field";
import { ColorPicker } from "../../../../common/ColorPicker/ColorPicker";
import "./FormattingRule.less";
import type { ObjectField } from "@/utils/dynamic-fields/types";
import { useMemo } from "react";
import { CustomAntdConfig } from "@/components/common/QueryBuilder/config";
import { getFieldsForQueryBuilder } from "@/utils";
import { QueryBuilder } from "@/components/common/QueryBuilder/QueryBuilder";
import { Spinner } from "@/components/common/Spinner/Spinner";

type FormattingRuleProps = {
  fields: ObjectField[];
  isLoadingFields: boolean;
};

export const FormattingRule = ({ fields, isLoadingFields }: FormattingRuleProps) => {
  const config = useMemo(() => ({ ...CustomAntdConfig, fields: getFieldsForQueryBuilder(fields) }), [fields]);
  return (
    <div className="formatting-rule">
      <Field
        name="condition"
        rules={[{ required: true, message: "At least one condition should be specified" }]}
        className="formatting-rule__condition"
      >
        {isLoadingFields ? <Spinner height={70} /> : <QueryBuilder config={config} />}
      </Field>
      <Field
        label="Background"
        name="background"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="formatting-rule__color"
      >
        <ColorPicker />
      </Field>
      <Field
        label="Text Color"
        name="color"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="formatting-rule__color"
      >
        <ColorPicker />
      </Field>
    </div>
  );
};
