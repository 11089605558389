import { CampaignFilterType } from "@/api/email-alerts/types";

export const getFilterLabel = (type: CampaignFilterType) => {
  const lookup: Record<CampaignFilterType, string> = {
    [CampaignFilterType.Condition]: "Condition",
    [CampaignFilterType.LabelGroup]: "Label Group",
    [CampaignFilterType.AdditionalLabelRecipients]: "Additional Label Recipients",
    [CampaignFilterType.RestrictedLabelRecipients]: "Restricted Label Recipients"
  };
  return lookup[type];
};