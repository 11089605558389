import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { ExportSchedule } from "@/components/Feeds/Details/ExportSchedulesForm/ExportSchedule";
import { Col, Row } from "antd";

type Props = {
  disabled?: boolean;
};

export const ExportSchedulesList = ({ disabled }: Props) => {
  return (
    <FieldList name="exportSchedules">
      {(fields, { add, remove }) => (
        <Row gutter={[16, 16]}>
          {fields.map((field) => (
            <FieldListItem name={field.name}>
              <Col span={24}>
                <ExportSchedule
                  title={`Schedule #${field.name + 1}`}
                  disabled={disabled}
                  onRemove={() => remove(field.name)}
                />
              </Col>
            </FieldListItem>
          ))}
          <Col span={24}>
            <FullWidthButton label="Add Schedule" icon={Plus} onClick={() => add()} disabled={disabled} />
          </Col>
        </Row>
      )}
    </FieldList>
  );
};
