import { Field } from "@/components/common/Form/Field";
import { Form, Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { FlowEvent } from "@/api/flows/types";

type Props = {
  loading?: boolean;
  flowEvents: FlowEvent[];
};

export const FlowEventPickerField = ({ flowEvents, loading }: Props) => {
  const actions = Form.useWatch("actions");
  const cannotChange = actions?.length > 0;
  return (
    <Field
      name="eventName"
      label="Event"
      tooltip={cannotChange ? "Flow event cannot be changed if one or more actions are added" : null}
    >
      <Select
        placeholder="Select event"
        options={flowEvents.map((event) => ({ value: event.name, label: event.label }))}
        suffixIcon={<Icon component={DropdownArrow} />}
        disabled={cannotChange || loading}
      />
    </Field>
  );
};
