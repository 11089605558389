import useSWR, { mutate } from "swr";
import type {
  CreateOpenplayQuery,
  OpenplayQueryFilters,
  UpdateOpenplayQuery,
} from "@/api/openplay-queries/types";
import {
  fetchDatabaseSchema,
  fetchOpenplayQueries,
  fetchOpenplayQuery,
  fetchTenants,
  saveOpenplayQuery,
} from "@/api/openplay-queries/index";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";

const key = "openplay-queries";

export const useOpenplayQueries = (filters?: OpenplayQueryFilters) =>
  useSWR(filters ? [key, filters] : [key], ([_, filters]) => fetchOpenplayQueries(filters ?? {}), {
    fallbackData: { data: [] },
  });

export const useOpenplayQuery = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => fetchOpenplayQuery(id), {
    fallbackData: null,
  });

export const useOpenplayQueryMutation = (id?: string) =>
  useSWRMutation(
    id ? [key, id] : [key],
    (_, { arg: dto }: { arg: CreateOpenplayQuery | UpdateOpenplayQuery }) => saveOpenplayQuery(dto),
    { onSuccess: () => mutate([key]) }
  );

export const useOpenplayDatabaseSchema = () => useSWRImmutable("openplay-schema", fetchDatabaseSchema);
export const useOpenplayTenants = () => useSWRImmutable("openplay-tenants", fetchTenants);
