import { TemplateType } from "@/utils";
import { parseMappingItem } from "../../common";
import { parseAssetNaming, prepareAssetNaming, prepareMappingItems } from "../../common/CommonMappings/utils";
import { isUniqueName } from "@/utils/validation";
import { checkTemplateExistence } from "@/api/templates";

export const parseTemplateFields = (template) => {
  const fields = {
    id: template.id,
    name: template.name,
    description: template.description,
    type: template.type,
  };

  if (template.type === TemplateType.Mapping) {
    fields.mapping = {
      inputFormat: template.data.inputFormat,
      outputFormat: template.data.outputFormat,
      data: template.data.mappings.map(parseMappingItem),
    };
    fields.operation = template.data.operation;
    fields.targetObject = template.data.targetObject;
  }

  if (template.type === TemplateType.Filters) {
    fields.tenantCode = template.data.tenantCode;
    fields.filter = {
      configuration: template.data.configuration,
    };
  }

  if (template.type === TemplateType.AssetNaming) {
    const { assetType, delimiter = "_", segments } = template.data;
    Object.assign(fields, parseAssetNaming({ assetType, delimiter, segments }));
  }

  if (template.type === TemplateType.SalesforceQuery) {
    fields.query = template.data;
  }

  if (template.type === TemplateType.SpreadsheetColumnSet) {
    fields.sourceObject = template.data.sourceObject;
    fields.source = template.data.source;
    fields.columns = template.data.columns;
  }

  return fields;
};

export const prepareTemplateDto = (values) => {
  const dto = {
    id: values.id,
    name: values.name,
    description: values.description,
    type: values.type,
  };

  if (values.type === TemplateType.Mapping) {
    const mapping = values.mapping;
    dto.data = {
      operation: values.operation,
      targetObject: values.targetObject,
      inputFormat: mapping.inputFormat,
      outputFormat: mapping.outputFormat,
      mappings: prepareMappingItems(mapping.data || []),
    };
  }

  if (values.type === TemplateType.Filters) {
    dto.data = {
      tenantCode: values.tenantCode,
      configuration: values.filter.configuration,
    };
  }

  if (values.type === TemplateType.AssetNaming) {
    const { delimiter, customDelimiter, segments, assetType } = values;
    const preparedNaming = prepareAssetNaming({
      delimiter,
      customDelimiter,
      segments,
      assetType,
    });
    dto.data = {
      delimiter: preparedNaming.delimiter,
      assetType: preparedNaming.assetType,
      segments: preparedNaming.segments,
    };
  }

  if (values.type === TemplateType.SalesforceQuery) {
    dto.data = values.query;
  }

  if (values.type === TemplateType.SpreadsheetColumnSet) {
    dto.data = {
      sourceObject: values.sourceObject,
      source: values.source,
      columns: values.columns,
    };
  }

  return dto;
};

export const isUniqueTemplateName = isUniqueName(
  checkTemplateExistence,
  `A template with entered name already exists in the system`
);
