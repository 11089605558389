import { OpenplayObjectType } from "@/types/common";
import { DynamicPathFormItem } from "./DynamicPathFormItem";
import { Form, Input } from "antd";
import React from "react";

export const PathField = ({ name }) => {
  const targetObject = Form.useWatch("targetObject");
  return targetObject === OpenplayObjectType.Project ? (
    <DynamicPathFormItem name={name} label="Path" />
  ) : (
    <Form.Item name={name} label="Path">
      <Input placeholder="Path" />
    </Form.Item>
  );
};
