import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { FlowForm } from "@/components/Flows/Details/FlowForm";
import { useFlow } from "@/api/flows/hooks";

export const FlowPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: flow, isLoading } = useFlow(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <FlowForm initialValues={flow} />;
};
