import { TabErrorIcon } from "@/components/common/TabErrorIcon/TabErrorIcon";
import type { ReactNode } from "react";
import type { TabsProps } from "antd";

type Options = {
  key: string;
  label: ReactNode;
  hasErrors?: boolean;
  content: ReactNode;
  closable?: boolean;
};

export const tab = ({
  key,
  label,
  hasErrors = false,
  content,
  closable = false,
}: Options): TabsProps["items"][number] => ({
  key,
  label: (
    <>
      <span>{label}</span>
      {hasErrors && <TabErrorIcon />}
    </>
  ),
  forceRender: true,
  children: content,
  closable,
});
