import { Col, Form, Row, Select } from "antd";
import { Field } from "@/components/common/Form/Field";
import { toOptions } from "@/utils/toOptions";
import { capitalize } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { QueryParamType } from "@/api/openplay-queries/types";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";

const types = Object.values(QueryParamType);

type Props = { name: string };

export const defaultValuesByType: Record<QueryParamType, any> = {
  [QueryParamType.String]: "",
  [QueryParamType.Boolean]: false,
  [QueryParamType.Date]: void 0,
  [QueryParamType.DateTime]: void 0,
  [QueryParamType.Array]: [],
  [QueryParamType.Number]: void 0,
};

export const QueryParameterField = ({ name }: Props) => {
  const form = Form.useFormInstance();
  const type = Form.useWatch<QueryParamType>(["parameters", name, "type"]);

  const handleTypeChange = (type: QueryParamType) => {
    form.setFieldValue(["parameters", name, "defaultValue"], type ? defaultValuesByType[type] : void 0);
  };

  const label = name.replaceAll("_", " ");

  return (
    <Form.Item colon={false} label={label} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Field
            name={["parameters", name, "type"]}
            preserve={false}
            rules={[{ required: true, message: "Please, select value type for parameter" }]}
          >
            <Select
              placeholder="Value Type"
              options={toOptions(types, capitalize)}
              suffixIcon={<Icon component={DropdownArrow} />}
              onChange={handleTypeChange}
            />
          </Field>
        </Col>
        {type && (
          <Col span={12}>
            <Field
              name={["parameters", name, "defaultValue"]}
              preserve={false}
              rules={[{ required: true, message: "Please, provide default value" }]}
            >
              <QueryParameterValueInput type={type} placeholder="Default Value" />
            </Field>
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};
