import type { Key } from "react";
import { useEffect, useMemo, useState } from "react";
import type { TablePaginationConfig, TableProps } from "antd";
import { Table } from "antd";
import * as R from "ramda";
import {
  Order,
  ORDER_BY_NAME,
  ORDER_BY_SORT,
  PAGINATION_PAGE_NUMBER,
  PAGINATION_PAGE_SIZE,
  useOrderBy,
  usePagination,
} from "@/utils";
import { useMount } from "ahooks";
import type { PaginatedResponse } from "@/api/types";

type Props<T extends object> = {
  data: PaginatedResponse<T>;
  onPageChange?: TablePaginationConfig["onChange"];
  defaultOrder?: Order;
  defaultSortColumn?: string;
  onRowClick?: (record: T, index: number) => void;
} & Pick<
  TableProps<T>,
  "columns" | "loading" | "scroll" | "rowKey" | "tableLayout" | "rowSelection" | "className"
> & {
    expandable?: Omit<TableProps<T>["expandable"], "expandedRowKeys" | "onExpandedRowsChange">;
  };

export const TableWithPaginationAndSorting = <T extends object>({
  data,
  columns,
  onRowClick,
  defaultSortColumn = "name",
  defaultOrder = Order.ASC,
  onPageChange = () => {},
  ...rest
}: Props<T>) => {
  const [pagination, setPagination] = usePagination();
  const [sorter, setSortParams] = useOrderBy();
  const total = data?.totalCount;
  const current = pagination[PAGINATION_PAGE_NUMBER];
  const pageSize = pagination[PAGINATION_PAGE_SIZE];

  const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);

  useEffect(() => {
    setExpandedRowKeys([]);
  }, [data]);

  useMount(() => {
    if (!sorter[ORDER_BY_NAME]) {
      setSortParams({
        [ORDER_BY_NAME]: defaultSortColumn,
        [ORDER_BY_SORT]: defaultOrder,
      });
    }
  });

  const columnsAddSorted = useMemo(
    () =>
      R.map(
        R.ifElse(
          R.propEq("key", sorter[ORDER_BY_NAME]),
          R.assoc("sortOrder", sorter[ORDER_BY_SORT]),
          (item) => item
        )
        // @ts-ignore
      )(columns),
    [columns, sorter]
  );

  return (
    <Table
      pagination={{
        total,
        current,
        pageSize,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["20", "40", "50"],
        onChange: onPageChange,
        showTotal: (total, range) => (
          <div>
            Showing{" "}
            <span>
              {range[0]}-{range[1]}
            </span>{" "}
            of <span>{total}</span>
            {` Results`}
          </div>
        ),
      }}
      dataSource={data?.data}
      onRow={(record, rowIndex) => {
        return {
          onClick: (_) => onRowClick?.(record, rowIndex),
        };
      }}
      onChange={(page, _, sorter, { action }) => {
        if (action === "paginate") {
          setPagination({
            [PAGINATION_PAGE_NUMBER]: page.current,
            [PAGINATION_PAGE_SIZE]: page.pageSize,
          });
        } else if (action === "sort" && !Array.isArray(sorter)) {
          setSortParams({
            [ORDER_BY_NAME]: sorter.order
              ? Array.isArray(sorter.field)
                ? sorter.field.join(".")
                : sorter.field
              : undefined,
            [ORDER_BY_SORT]: sorter.order,
          });
        }
      }}
      rowKey="id"
      columns={columnsAddSorted}
      rowClassName={(_, i) => (i % 2 === 0 ? "even" : "odd")}
      {...rest}
      expandable={{
        ...(rest?.expandable ?? {}),
        expandedRowKeys,
        onExpandedRowsChange: (keys) => setExpandedRowKeys(keys),
      }}
    />
  );
};
