import { useGridParams } from "@/utils/useGridParams";
import { useRequest } from "ahooks";
import { fetchSalesforceMessages } from "@/api/webhooks";
import { Order, prepareParams } from "@/utils";
import { TableWithPaginationAndSorting } from "@/components/common";
import { columns } from "@/components/Webhooks/Activity/columns";
import type { SalesforceMessage } from "@/api/webhooks/types";
import { MessageType } from "@/api/webhooks/types";
import { SalesforceMessageDetails } from "@/components/Webhooks/Activity/SalesforceMessageDetails";
import FilterableContent from "@/components/Layout/FilterableContent";
import { getProcessingStatusFullLabel, ProcessingStatus } from "@/utils/processing-status";
import { useMemo } from "react";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { toOptions } from "@/utils/toOptions";
import { getSalesforceMessageTypeLabel } from "@/utils/salesforce-message-type";
import { ProcessingStatusTag } from "@/components/common/ProcessingStatusTag";

type Props = {
  messageType?: MessageType;
};

export const WebhookActivityTable = ({ messageType }: Props) => {
  const filterConfig = useMemo(
    () =>
      [
        {
          name: "statuses[]",
          label: "Status",
          type: FilterType.MultiSelect,
          options: toOptions(
            [ProcessingStatus.Finished, ProcessingStatus.FinishedWithWarnings, ProcessingStatus.Failed],
            getProcessingStatusFullLabel
          ),
          tagRender: ({ value: status, closable, onClose }) => (
            <ProcessingStatusTag status={status} onClose={onClose} closable={closable} />
          ),
        },
        !messageType && {
          name: "messageType",
          label: "Action",
          type: FilterType.Select,
          options: Object.values(MessageType).map((type) => ({
            value: type,
            label: getSalesforceMessageTypeLabel(type),
          })),
        },
        { name: "createdAt", label: "Started At", type: FilterType.DateRange },
      ].filter(Boolean),
    [messageType]
  );

  const { filters, pagination, sort } = useGridParams();

  const { data, loading } = useRequest(
    () =>
      fetchSalesforceMessages(
        prepareParams(messageType ? { ...filters, messageType } : filters, pagination, sort)
      ),
    {
      refreshDeps: [filters, pagination, sort, messageType],
      initialData: {
        data: [],
      },
    }
  );

  return (
    <FilterableContent
      formConfig={filterConfig}
      filterName="webhook-activity-filter"
      isFiltersConfigurable={false}
    >
      <TableWithPaginationAndSorting
        rowKey="id"
        columns={columns}
        data={data}
        loading={loading}
        defaultSortColumn="createdAt"
        defaultOrder={Order.DESC}
        expandable={{
          expandedRowRender: (entry: SalesforceMessage) => <SalesforceMessageDetails entry={entry} />,
          rowExpandable: () => true,
        }}
      />
    </FilterableContent>
  );
};
