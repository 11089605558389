import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { Button, Col, Dropdown, Form, Row, Space } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { CampaignFilter } from "./CampaignFilter";
import type { CampaignFilter as TCampaignFilter } from "@/api/email-alerts/types";
import { CampaignFilterType, CampaignFilterValueSource } from "@/api/email-alerts/types";
import { useFieldPath } from "@/components/common/Form/hooks";
import { getFilterLabel } from "./utils";

type ActionsProps = {
  onAdd: (value: Partial<TCampaignFilter>) => void;
};

const Actions = ({ onAdd }: ActionsProps) => {
  const { getAbsolutePath } = useFieldPath();
  const filters = Form.useWatch<TCampaignFilter[]>(getAbsolutePath(["filters"])) ?? [];

  const appliedFilters = new Set(filters.map((filter) => filter.type));

  if (appliedFilters.size === Object.values(CampaignFilterType).length) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        onClick: (item) => {
          switch (item.key) {
            case CampaignFilterType.Condition:
              return onAdd({ type: CampaignFilterType.Condition });
            case CampaignFilterType.LabelGroup:
              return onAdd({
                type: CampaignFilterType.LabelGroup,
                source: CampaignFilterValueSource.Static,
              });
            case CampaignFilterType.AdditionalLabelRecipients:
              return onAdd({
                type: CampaignFilterType.AdditionalLabelRecipients,
                source: CampaignFilterValueSource.Static,
              });
            case CampaignFilterType.RestrictedLabelRecipients:
              return onAdd({
                type: CampaignFilterType.RestrictedLabelRecipients,
                source: CampaignFilterValueSource.Static,
              });
            default:
              return;
          }
        },
        items: Object.values(CampaignFilterType)
          .map(
            (type) =>
              !appliedFilters.has(type) && {
                key: type,
                label: (
                  <Space>
                    <Icon component={Plus} />
                    <span>{getFilterLabel(type)}</span>
                  </Space>
                ),
              }
          )
          .filter(Boolean),
      }}
      placement="top"
      arrow
      overlayStyle={{ minWidth: 200 }}
    >
      <Button icon={<Icon component={Plus} />} size="small">
        Filter
      </Button>
    </Dropdown>
  );
};

type Props = {
  sourceObject?: string;
};

export const CampaignFilters = ({ sourceObject }: Props) => {
  return (
    <FieldList name="filters">
      {(fields, { add, remove }) => (
        <Row gutter={[12, 12]}>
          {fields.map((field) => (
            <FieldListItem key={field.key} name={field.name}>
              <Col span={24}>
                <CampaignFilter sourceObject={sourceObject} onRemove={() => remove(field.name)} />
              </Col>
            </FieldListItem>
          ))}
          <Actions onAdd={add} />
        </Row>
      )}
    </FieldList>
  );
};
