import { Form, PageHeader } from "antd";
import { useNavigationConfirm } from "@/utils/useNavigationConfirm";
import { ProfileForm } from "./ProfileForm";
import { useBoolean, useRequest } from "ahooks";
import { updateUser } from "@/api/users";
import { useAuth } from "@/utils";
import { FormActions } from "@/components/common/FormActions";
import { useUser } from "@/api/users/hooks";
import { Spinner } from "@/components/common/Spinner/Spinner";

export const UserProfile = () => {
  const {
    user: { userId: id },
    refresh: refreshAuth,
  } = useAuth();

  const [form] = Form.useForm();
  const { allowNavigation, preventNavigation } = useNavigationConfirm();

  const [isEditing, { setFalse: disableEditing, setTrue: enableEditing }] = useBoolean(false);

  const { isLoading, data: user } = useUser(id);

  const { run: saveChanges, loading: saving } = useRequest(updateUser, {
    manual: true,
    throwOnError: true,
    onSuccess: async () => {
      allowNavigation();
      await refreshAuth();
      disableEditing();
    },
  });

  const handleValuesChange = () => {
    if (isEditing) {
      preventNavigation();
    }
  };

  const handleCancel = () => {
    allowNavigation();
    form.resetFields();
    disableEditing();
  };

  const handleSave = (values) => saveChanges(id, values);

  if (isLoading) {
    return <Spinner height={100} />;
  }

  return (
    <Form
      form={form}
      onFinish={handleSave}
      onValuesChange={handleValuesChange}
      initialValues={user}
      colon={false}
      labelAlign="left"
      wrapperCol={{ span: 15, offset: 1 }}
      labelCol={{ span: 8 }}
    >
      <PageHeader
        title={user.name}
        extra={
          <FormActions
            isEditing={isEditing}
            onEdit={enableEditing}
            onCancel={handleCancel}
            onSave={form.submit}
            isSaving={saving}
          />
        }
      />
      <ProfileForm disabled={!isEditing} initialData={user} />
    </Form>
  );
};
