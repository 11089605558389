import { useRequest } from "ahooks";
import { MultiSelect } from "../../common/MultiSelect/MultiSelect";
import { BaseTag } from "../../common/BaseTag/BaseTag";
import { getPartners } from "@/api/partners";
import { Status } from "@/utils";

type Props = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
};

export const PartnerSelect = ({ value, onChange, disabled, placeholder }: Props) => {
  const {
    data: { data: partners },
  } = useRequest(() => getPartners({ status: Status.Active }), {
    initialData: { data: [] },
  });

  return (
    <MultiSelect
      value={value}
      onChange={onChange}
      options={partners}
      fieldNames={{ value: "id", label: "name" }}
      tagRender={({ label, closable, onClose }) => (
        <BaseTag closable={closable} onClose={onClose}>
          {label}
        </BaseTag>
      )}
      notFoundContent="No active partners found"
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
