import { useSpreadsheetTemplates } from "@/api/spreadsheets/hooks";
import { Form, Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Field } from "@/components/common/Form/Field";

export const SpreadsheetTemplateField = () => {
  const { data: templates = [] } = useSpreadsheetTemplates();

  const sourceObjectName = Form.useWatch<string>(["sourceObject", "name"]) ?? null;

  const options = templates
    .filter((template) => template.sourceObject === sourceObjectName)
    .map((template) => ({ value: template.key, label: template.label }));

  const disabled = options.length === 0;

  return (
    <Field
      label="Template"
      tooltip={disabled ? "No templates are available for selected source object" : null}
      name={["spreadsheet", "template"]}
    >
      <Select
        disabled={disabled}
        options={options}
        allowClear
        suffixIcon={<Icon component={DropdownArrow} />}
        placeholder="Select template"
      />
    </Field>
  );
};
