import { PageHeader, Table } from "antd";
import { columns } from "./columns";
import { useOpenplayObjects } from "@/api/openplay-objects/hooks";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import type { OpenplayObject } from "@/api/openplay-objects/types";

export const OpenplayObjectList = () => {
  const { data: openplayObjects } = useOpenplayObjects();

  const history = useHistory();

  const handleEdit = useCallback(
    ({ name }: Omit<OpenplayObject, "root">) => history.push(`/openplay-objects/${name}`),
    [history]
  );

  return (
    <>
      <PageHeader style={{ marginBottom: 16 }} title={<h1 style={{ margin: 0 }}>OpenPlay Objects</h1>} />
      <Table
        columns={columns}
        dataSource={openplayObjects}
        rowKey="name"
        onRow={(obj) => ({ onClick: () => handleEdit(obj) })}
      />
    </>
  );
};
