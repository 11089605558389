import type { ColumnsType } from "antd/lib/table/interface";
import type { EntityProcessingResult } from "@/api/webhooks/types";
import { ProcessingStatusTag } from "@/components/common/ProcessingStatusTag";
import { getEntityProcessingStatus } from "./utils";
import { SalesforceObject } from "@/types/salesforce";
import moment from "moment/moment";

type EntityDetailsProps = {
  entry: EntityProcessingResult;
};

const EntityDetails = ({ entry }: EntityDetailsProps) => {
  switch (entry.entityType) {
    case SalesforceObject.Planner:
    case SalesforceObject.RequestedRelease:
      return (
        <div className="salesforce-message-details-table__entity">
          {entry.entity.UPC__c && (
            <div>
              <strong>UPC:</strong> {entry.entity.UPC__c}
            </div>
          )}
          {entry.entity.Artist__c && entry.entity.Title__c && (
            <div>
              {entry.entity.Artist__c} - {entry.entity.Title__c}
            </div>
          )}
        </div>
      );
    case SalesforceObject.PlannerProject:
      return (
        <div className="salesforce-message-details-table__entity">
          <div>
            <strong>Project Number:</strong> {entry.entity.Project_Number__c}
          </div>
          {entry.entity.Name && <div>{entry.entity.Name}</div>}
          {entry.entity.A_R__c && (
            <div>
              <strong>A&R:</strong> {entry.entity.A_R__c}
            </div>
          )}
          {entry.entity.Product_Manager__c && (
            <div>
              <strong>Product Manager:</strong> {entry.entity.Product_Manager__c}
            </div>
          )}
        </div>
      );
    default:
      return "-";
  }
};

export const columns: ColumnsType<EntityProcessingResult> = [
  {
    title: "Status",
    render: (_, entry) => <ProcessingStatusTag status={getEntityProcessingStatus(entry)} />,
  },
  { title: "Type", dataIndex: ["entityType"] },
  { title: "Details", render: (_, entry) => <EntityDetails entry={entry} /> },
  { title: "Started At", render: (_, entry) => moment.utc(entry.startDate).format("LTS") },
  { title: "Finished At", render: (_, entry) => moment.utc(entry.endDate).format("LTS") },
];
