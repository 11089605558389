import React, { useCallback } from "react";
import TemplatesTable from "./TemplatesTable";
import FilterableContent from "../../Layout/FilterableContent";
import { filtersConfig as filtersFormConfig } from "./config";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import { Plus } from "../../common/Icons";
import { useAuth } from "@/utils";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export function TemplatesList() {
  const history = useHistory();
  const handleNew = useCallback(() => history.push("/templates/new"), [history]);
  const { Can } = useAuth();
  return (
    <>
      <h1>Templates</h1>
      <FilterableContent
        formConfig={filtersFormConfig}
        filterName="release-delivery-filter"
        isFiltersConfigurable={false}
        buttons={
          <Can I={PermissionAction.Create} a={PermissionSubject.Template}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "auto" }}
              key="new-partner"
              onClick={handleNew}
              icon={<Icon component={Plus} />}
            >
              New Template
            </Button>
          </Can>
        }
      >
        <TemplatesTable />
      </FilterableContent>
    </>
  );
}
