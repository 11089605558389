import PropTypes from "prop-types";
import { getFormatName } from "@/utils";

export default function OverrideConfirmation({ targetPath, form, fieldIndex }) {
  const sourcePath =
    form.getFieldValue(["mapping", "data", fieldIndex, "source"])?.join("/") || "Not selected yet";
  const inputFormat = form.getFieldValue(["mapping", "inputFormat"]);
  const outputFormat = form.getFieldValue(["mapping", "outputFormat"]);
  return (
    <div className="popup-override__content">
      <div className="popup-override__warning">You are trying to override the default mapping value.</div>
      <div className="popup-override__sub-heading">Source: {getFormatName(inputFormat)}</div>
      <div className="popup-override__text">{sourcePath}</div>
      <div className="popup-override__sub-heading">Target: {getFormatName(outputFormat)}</div>
      <div className="popup-override__text">{targetPath}</div>
    </div>
  );
}

OverrideConfirmation.propTypes = {
  targetPath: PropTypes.string.isRequired,
};
