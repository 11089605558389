import { Col, Input, Radio, Row } from "antd";
import { Box } from "../../common/Box/Box";
import { isUniqueName } from "@/utils/validation";
import { checkReportExistence } from "@/api/reports";
import { toOptions } from "@/utils/toOptions";
import { getStatusLabel, statuses } from "@/utils";
import { RecurrencyForm } from "../../common/RecurrencyForm";
import { Field } from "@/components/common/Form/Field";

const { TextArea } = Input;

type Props = {
  readOnly?: boolean;
};

export const ReportSummaryForm = ({ readOnly }: Props) => (
  <Row gutter={[16, 16]}>
    <Col span={24}>
      <Box readOnly={readOnly}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Field
              name="name"
              label="Name"
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                { required: true },
                isUniqueName(checkReportExistence, "A report with entered name already exists in the system"),
              ]}
            >
              <Input placeholder="Report Name" />
            </Field>
          </Col>
          <Col span={12}>
            <Field name="description" label="Description">
              <TextArea placeholder="Description" rows={2} />
            </Field>
          </Col>
          <Col span={12}>
            <Field name="status" label="Status">
              <Radio.Group
                buttonStyle="solid"
                options={toOptions(statuses, getStatusLabel)}
                optionType="button"
              />
            </Field>
          </Col>
        </Row>
      </Box>
    </Col>
    <Col span={24}>
      <Box readOnly={readOnly} title="Schedule">
        <RecurrencyForm name="recurrency" />
      </Box>
    </Col>
  </Row>
);
