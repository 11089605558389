import { AssetNamingFields } from "../../common/AssetNamingFields/AssetNamingFields";
import React from "react";
import { Form } from "antd";
import { Box } from "../../common/Box/Box";

export const AssetNamingTemplateForm = ({ disabled }) => {
  const assetType = Form.useWatch("assetType");
  const title = `${assetType} naming`;
  return (
    <Box title={title} readOnly={disabled}>
      <AssetNamingFields />
    </Box>
  );
};
