import type { SelectProps } from "antd";
import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { useSalesforceObjects } from "@/api/salesforce-objects/hooks";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import { RestrictedField } from "@/components/common/RestrictedField";
import React from "react";

type Props = Omit<SelectProps, "options"> & {
  filter?: (obj: SalesforceObject) => boolean;
};

export const SalesforceObjectPicker = ({ filter = () => true, ...props }: Props) => {
  const {
    data: { data: entries },
    isLoading,
    error,
  } = useSalesforceObjects();

  if (error?.status === 403) {
    return <RestrictedField message="You need 'Read - Salesforce Object' permission to view this field" />;
  }

  return (
    <Select
      style={{ minWidth: 200 }}
      placeholder="Select Salesforce Object"
      {...props}
      loading={isLoading}
      options={entries.filter(filter).map((entry) => ({ value: entry.name, label: entry.label }))}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
