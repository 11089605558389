import React from "react";
import { Space } from "antd";
import { DATE_FORMATTER } from "@/utils";
import { OperationTag } from "../../common/OperationTag/OperationTag";
import { TemplateNameCell } from "./TemplateNameCell/TemplateNameCell";
import { TargetObjectTag } from "../../common/TargetObjectTag/TargetObjectTag";
import { DescriptionCell } from "../../common/DescriptionCell";

export const columns = [
  {
    title: "Template Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    className: "template-name-column",
    render: (_, template) => (
      <Space>
        <span>{template.name}</span>
        {template.data.targetObject && <TargetObjectTag targetObject={template.data.targetObject} />}
        {template.data.operation && <OperationTag operation={template.data.operation} />}
      </Space>
    ),
  },
  {
    title: "Template type",
    dataIndex: "type",
    key: "type",
    className: "type-column",
    sorter: true,
    render: (_, template) => <TemplateNameCell template={template} />,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    className: "description-template-column",
    render: (description) => <DescriptionCell description={description} />,
  },
  {
    title: "Last updated by",
    dataIndex: "updatedBy.name",
    key: "updatedBy.name",
    sorter: true,
    render: (_, allData) => allData.updatedBy?.name,
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    sorter: true,
    render: (date) => (date ? DATE_FORMATTER.format(new Date(date)) : null),
  },
];
