import React from "react";
import { useParams } from "react-router-dom";
import { FeedForm } from "./FeedForm";
import { Spinner } from "../../common/Spinner/Spinner";
import { useFeed } from "@/api/feeds/hooks";

export function FeedPage() {
  const { feedId } = useParams();
  const { isLoading, data: feed } = useFeed(feedId);

  if (isLoading) {
    return <Spinner />;
  }

  return <FeedForm initialValues={feed} />;
}
