import { TableWithPaginationAndSorting } from "@/components/common";
import FilterableContent from "@/components/Layout/FilterableContent";
import { Order, prepareParams } from "@/utils";
import { useGridParams } from "@/utils/useGridParams";
import { useReportLogs } from "@/api/report-logs/hooks";
import { ReportLogColumn } from "./columns";
import type { Filter } from "@/components/common/FilterInput/FilterInput";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { ReportLogStatus } from "@/api/report-logs/types";
import { ReportPicker } from "@/components/Reports/ReportPicker";
import { ReportLogDetails } from "./ReportLogDetails";
import { useMemo } from "react";

type Props = {
  reportId?: string;
};

export const ReportLogTable = ({ reportId }: Props) => {
  const { filters, pagination, sort } = useGridParams();
  const { data, isLoading } = useReportLogs(
    prepareParams(reportId ? { ...filters, reportId } : filters, pagination, sort)
  );

  const columns = useMemo(
    () =>
      [
        ReportLogColumn.Status,
        ReportLogColumn.Date,
        !reportId && ReportLogColumn.Report,
        ReportLogColumn.Variation,
        ReportLogColumn.Actions,
      ].filter(Boolean),
    [reportId]
  );

  const filterConfig: Filter[] = useMemo(
    () =>
      [
        !reportId && {
          type: FilterType.CustomInput,
          name: "reportId",
          label: "Report",
          input: <ReportPicker />,
        },
        {
          type: FilterType.Radio,
          name: "status",
          label: "Status",
          options: [
            { value: ReportLogStatus.Sent, label: "Sent" },
            { value: ReportLogStatus.Failed, label: "Failed" },
          ],
        },
        { name: "createdAt", label: "Date", type: FilterType.DateRange },
      ].filter(Boolean),
    [reportId]
  );

  return (
    <FilterableContent formConfig={filterConfig} filterName="report-log-filter" isFiltersConfigurable={false}>
      <TableWithPaginationAndSorting
        rowKey="id"
        columns={columns}
        data={data}
        loading={isLoading}
        scroll={{ x: true }}
        defaultOrder={Order.DESC}
        defaultSortColumn="createdAt"
        expandable={{
          expandedRowRender: (log) => <ReportLogDetails log={log} />,
        }}
      />
    </FilterableContent>
  );
};
