import { Aggregation, VariableType } from "@/types/variables";
import { useCallback, useMemo, useRef } from "react";
import { Form, Modal } from "antd";
import type { FormValues } from "./VariableForm";
import { VariableForm } from "./VariableForm";
import { useBoolean } from "ahooks";
import type { Editor } from "@tiptap/react";
import { isNil } from "ramda";
import type { VariableClickHandler, Variables } from "./types";
import { escape, escaped, unescape } from "./utils";

const getFormValuesFromVariablePath = (path: string): FormValues => {
  const [type, ...rest] = path.split(".");
  if (type === VariableType.Query) {
    const queryId = rest.at(0);
    const aggregation = rest.at(1) as Aggregation;
    if (aggregation === Aggregation.Total) {
      return { type: VariableType.Query, queryId, aggregation };
    }
    return { type: VariableType.Query, queryId, aggregation, field: rest.slice(2) };
  }
  return { type: type as VariableType, name: rest };
};

export const getFormValuesFromVariableName = (name: string): FormValues => {
  const [path, ...transformations] = name.split(escaped("|"));

  const values = getFormValuesFromVariablePath(path);

  if (transformations.length > 0) {
    for (const transformation of transformations) {
      const [name, args] = transformation.split(escaped(":"));
      if (name === "offset") {
        const [value, unit] = args.split(escaped(","));
        values.offset = { value: parseInt(value), unit };
      }
      if (name === "format") {
        values.format = unescape(args);
      }
      if (name === "timezone") {
        values.timezone = unescape(args);
      }
    }
  }

  return values;
};

export const getVariableNameFromFormValues = ({
  type,
  name,
  queryId,
  aggregation,
  field,
  offset,
  format,
  timezone,
}: FormValues) => {
  const path = [type, name?.join("."), queryId, aggregation, field?.join(".")].filter(Boolean).join(".");
  const transformations = [];
  if (format?.trim()) {
    transformations.push(`format:${escape(format)}`);
  }
  if (timezone) {
    transformations.push(`timezone:${escape(timezone)}`);
  }
  if (offset && offset.value !== 0) {
    transformations.push(`offset:${offset.value},${offset.unit}`);
  }
  return [path, ...transformations].join("|");
};

export const useVariableModal = (variables: Variables) => {
  const [form] = Form.useForm<FormValues>();
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);
  const positionRef = useRef<number>(null);
  const editorRef = useRef<Editor>(null);

  const handleCancel = useCallback(() => {
    form.resetFields();
    close();
  }, [form, close]);

  const handleFinish = useCallback(
    async (values: FormValues) => {
      form.resetFields();
      const position = positionRef.current;
      const editor = editorRef.current;

      const attrs = { name: getVariableNameFromFormValues(values) };

      if (editor) {
        if (isNil(position)) {
          editor.chain().focus().insertContent({ type: "variable", attrs }).run();
        } else {
          editor.view.dispatch(editor.view.state.tr.setNodeMarkup(position, undefined, attrs));
          editor.commands.focus();
        }
      }

      positionRef.current = null;
      close();
    },
    [close, form]
  );

  const handleOpen: VariableClickHandler = useCallback(
    ({ name, position, editor }) => {
      if (name) {
        form.setFieldsValue(getFormValuesFromVariableName(name));
      }
      positionRef.current = position;
      editorRef.current = editor;
      open();
    },
    [form, open]
  );

  const modal = useMemo(
    () => (
      <Modal open={isOpen} title="Variable" onCancel={handleCancel} onOk={form.submit}>
        <VariableForm variables={variables} handleFinish={handleFinish} form={form} />
      </Modal>
    ),
    [isOpen, variables, form, handleCancel, handleFinish]
  );

  return { open: handleOpen, modal };
};
