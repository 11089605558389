const getExactMatchScore = (options: string[], inputValue: string): number => {
  const tailLabel = options.at(-1);
  const firstWordRegex = new RegExp(`^${inputValue}\\b`, "i");
  const startOfStringRegex = new RegExp(`^${inputValue}`, "i");
  const anyWordRegex = new RegExp(`\\b${inputValue}\\b`, "i");
  const anyWordStartRegex = new RegExp(`\\b${inputValue}`, "i");

  if (firstWordRegex.test(tailLabel)) {
    return 4;
  }
  if (anyWordRegex.test(tailLabel)) {
    return 3;
  }
  if (startOfStringRegex.test(tailLabel)) {
    return 2;
  }
  if (anyWordStartRegex.test(tailLabel)) {
    return 1;
  }
  return 0;
};

export const sortFieldPathSearchResults = (a: string[], b: string[], inputValue: string): number => {
  const byFieldDepth = a.length - b.length;
  const byExactMatch = getExactMatchScore(b, inputValue) - getExactMatchScore(a, inputValue);
  if (byExactMatch === 0) {
    return byFieldDepth;
  }
  return byExactMatch;
};

type OptionType = {
  label: string;
  value: string;
};

export const sortCascaderSearchResults = (a: OptionType[], b: OptionType[], inputValue: string): number =>
  sortFieldPathSearchResults(
    a.map(({ label }) => label),
    b.map(({ label }) => label),
    inputValue
  );
