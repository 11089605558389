import { HiddenField } from "@/components/common/HiddenField";
import { Form } from "antd";
import { useFieldPath } from "@/components/common/Form/hooks";
import { BlockType } from "@/api/types";
import { TextBlock } from "../TextBlock";
import "./ContentBlock.less";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import { ConditionBlock } from "../ConditionBlock";
import { FieldListBlock } from "../FieldListBlock/FieldListBlock";
import { LoopBlock } from "@/components/common/ContentBuilder/LoopBlock/LoopBlock";

type Props = {
  maxNesting: number;
  onRemove: () => void;
  onMove: (from: number, to: number) => void;
};

const nameLookup: Record<BlockType, string> = {
  [BlockType.Text]: "Text Block",
  [BlockType.Condition]: "Condition Block",
  [BlockType.FieldList]: "Field List",
  [BlockType.Loop]: "Relation Loop",
};

export const ContentBlock = ({ maxNesting, onRemove }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const type = Form.useWatch(getAbsolutePath("type")) ?? null;
  return (
    <div className="content-block">
      {type && <div className="content-block__name">{nameLookup[type]}</div>}
      <div className="content-block__body">
        <HiddenField name="type" />
        {type === BlockType.Text && <TextBlock />}
        {type === BlockType.Condition && <ConditionBlock maxNesting={maxNesting} />}
        {type === BlockType.FieldList && <FieldListBlock />}
        {type === BlockType.Loop && <LoopBlock maxNesting={maxNesting} />}
      </div>
      <div className="content-block__actions">
        <IconButton className="content-block__remove" icon={Remove} onClick={onRemove} danger />
      </div>
    </div>
  );
};
