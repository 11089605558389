import useSWR from "swr";
import type { CreateEmailAlertDto, EmailAlertFilters, UpdateEmailAlertDto } from "./types";
import { fetchEmailAlert, fetchEmailAlerts, saveEmailAlert } from "./index";
import useSWRMutation from "swr/mutation";
import { revalidateColumnSets } from "@/api/spreadsheets/hooks";

const key = "email-alerts";

export const useEmailAlerts = (filters: EmailAlertFilters) =>
  useSWR([key, filters], ([_, params]) => fetchEmailAlerts(params), { fallbackData: { data: [] } });

export const useEmailAlert = (id: string) => useSWR([key, id], ([_, id]) => fetchEmailAlert(id));

export const useEmailAlertMutation = (id: string) =>
  useSWRMutation(
    id ? [key, id] : key,
    (_, { arg: dto }: { arg: CreateEmailAlertDto | UpdateEmailAlertDto }) => saveEmailAlert(dto),
    { onSuccess: revalidateColumnSets }
  );
