import { useReports } from "@/api/reports/hooks";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { SelectProps } from "antd";
import { Select } from "antd";

export const ReportPicker = ({ value, onChange }: Pick<SelectProps, "value" | "onChange">) => {
  const {
    data: { data: entries },
    isLoading,
  } = useReports();

  return (
    <Select
      value={value}
      onChange={onChange}
      style={{ minWidth: 200 }}
      placeholder="Select Report"
      options={entries.map((entry) => ({ value: entry.id, label: entry.name }))}
      suffixIcon={<Icon component={DropdownArrow} />}
      allowClear
      disabled={isLoading}
      showSearch
      optionFilterProp="label"
    />
  );
};
