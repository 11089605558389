import {
  AssetsFormat,
  DeliveryType,
  ERNChoreography,
  ImageQuality,
  isEmpty,
  Operation,
  Status,
  StorageService,
} from "@/utils";
import { OpenplayObjectType } from "@/types/common";
import * as R from "ramda";
import moment from "moment/moment";
import {
  parseAssetNaming,
  parseMappingItem,
  prepareAssetNaming,
  prepareMapping,
} from "@/components/common/CommonMappings/utils";
import { parseRecurrencyFields, prepareRecurrencyDto } from "@/utils/recurrency";

export const parseExportScheduleFields = (entry) => ({
  ...entry,
  schedule: parseRecurrencyFields(entry.schedule),
});

export const prepareExportScheduleDto = (values) => ({
  ...values,
  schedule: prepareRecurrencyDto(values.schedule),
});

export const parseFeedFields = (feed, operation, partnerId) => {
  const values = {
    assetsFormat: AssetsFormat.Original,
    status: Status.Draft,
    deliveryType: DeliveryType.AssetsAndData,
    ...(feed ?? {}),
    startDate: R.isNil(feed.startDate) ? null : moment.utc(feed.startDate),
    endDate: R.isNil(feed.endDate) ? null : moment.utc(feed.endDate),
    storage: feed?.storage ?? {
      type: operation === Operation.Import ? StorageService.UCS : StorageService.S3,
      imageQuality: ImageQuality.Default,
      configuration: {
        folderStructure: ERNChoreography.Release,
      },
    },
    assetNamings: feed.assetNamings?.map(parseAssetNaming) ?? [],
    partnerId,
    exportSchedules: feed.exportSchedules?.map(parseExportScheduleFields) ?? [],
  };

  if (!isEmpty(feed.mapping)) {
    values.mapping = {
      ...feed.mapping,
      data: feed.mapping.data?.map(parseMappingItem) ?? [],
    };
  }

  if (!isEmpty(feed.filter)) {
    values.filter = feed.filter;
  }

  return values;
};

export const prepareFeedDto = (values) => {
  const dto = { ...values };

  dto.mapping = prepareMapping(values.mapping);

  if (values.operation === Operation.Import) {
    dto.assetNamings = values.assetNamings?.map(prepareAssetNaming);
  }

  if (values.operation === Operation.Export && values.targetObject === OpenplayObjectType.Release) {
    dto.filter = {
      ...values.filter,
      configuration: values.filter.configuration ?? null,
    };
  }

  dto.exportSchedules = values.exportSchedules?.map(prepareExportScheduleDto) ?? [];

  return dto;
};
