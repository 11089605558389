import { RecipientList } from "../RecipientList/RecipientList";
import { atLeastOne } from "@/utils/validation";
import { ConditionBox } from "@/components/common/ConditionBox";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";

export const RecipientGroup = () => {
  const { fieldsForConditions } = useEmailMessageContext();
  return (
    <ConditionBox fields={fieldsForConditions}>
      <RecipientList
        name="items"
        canAddGroups={false}
        rules={[atLeastOne("At least one recipient should be added")]}
      />
    </ConditionBox>
  );
};
