import Icon from "@ant-design/icons";
import "./FullWidthButton.less";
import cn from "classnames";
import type { ComponentProps } from "react";

type Props = {
  label: string;
  icon?: ComponentProps<typeof Icon>["component"];
  disabled?: boolean;
  onClick?: () => void;
  small?: boolean;
};

export const FullWidthButton = ({ label, onClick, icon, disabled, small = false }: Props) => (
  <div
    role="button"
    onClick={onClick}
    className={cn("full-width-button", disabled && "full-width-button--disabled", small && "full-width-button--small")}
  >
    {icon && <Icon component={icon} />}
    <span>{label}</span>
  </div>
);
