import { Button } from "antd";
import type { ConjsProps } from "@react-awesome-query-builder/ui";

export const Conjunctions = ({
  readonly,
  disabled,
  not,
  conjunctionOptions,
  config,
  setConjunction,
  notLabel,
  showNot,
  setNot,
}: ConjsProps) => {
  const handleNot = () => {
    if (setNot) {
      setNot(!not);
    }
  };

  const conjunctionCount = Object.keys(conjunctionOptions).length;
  const lessThenTwo = disabled;
  const { forceShowConj } = config.settings;
  const showConjunctions = forceShowConj || (conjunctionCount > 1 && !lessThenTwo);

  return (
    <Button.Group size="small">
      {showNot && (readonly ? not : true) && (
        <Button key="group-not" onClick={handleNot} type={not ? "primary" : null} disabled={readonly}>
          {notLabel}
        </Button>
      )}
      {showConjunctions &&
        Object.values(conjunctionOptions).map((item) => {
          if ((readonly || disabled) && !item.checked) {
            return null;
          }
          return (
            <Button
              key={item.id}
              disabled={disabled || readonly}
              type={item.checked ? "primary" : null}
              onClick={() => setConjunction(item.key)}
            >
              {item.label}
            </Button>
          );
        })}
    </Button.Group>
  );
};
