import { Alert, Button, Col, Divider, Form, Modal, Row, Tooltip } from "antd";
import { useDownload } from "@/utils/useDownload";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { downloadSpreadsheet } from "@/api/on-demand-reports";
import { IconButton } from "@/components/common/IconButton/IconButton";
import type { DynamicFieldsByQuery, OnDemandReport } from "@/api/on-demand-reports/types";
import { useBoolean } from "ahooks";
import { Close } from "@/components/common/Icons";
import React, { Fragment, useCallback } from "react";
import type { OpenplayQuery } from "@/api/openplay-queries/types";
import { Field } from "@/components/common/Form/Field";
import { TenantPicker } from "@/components/OpenplayQueries/TenantPicker";
import { QueryParameterValueInput } from "@/components/OpenplayQueries/QueryParameterValueInput";
import { defaultValuesByType } from "@/components/OpenplayQueries/QueryParameterField";

type Props = {
  report: OnDemandReport;
  iconOnly?: boolean;
};

const hasDynamicFields = (query: OpenplayQuery) =>
  !!query.defaultTenant || Object.keys(query.parameters ?? {}).length > 0;

export const DownloadSpreadsheetButton = ({ report, iconOnly }: Props) => {
  const { start: download, loading: downloading } = useDownload(downloadSpreadsheet, {
    message: "The spreadsheet is being generated. Download will start automatically",
    noContentMessage: "The are no records to include into the file",
  });

  const [form] = Form.useForm<DynamicFieldsByQuery>();

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const queriesWithParameters = report.openplayQueries.filter((query) =>
    hasDynamicFields(query.openplayQuery)
  );

  const handleOpen = () => {
    if (queriesWithParameters.length > 0) {
      return showModal();
    }
    download(report.id);
  };

  const handleDownload = (values: DynamicFieldsByQuery) => {
    hideModal();
    form.resetFields();
    return download(report.id, values);
  };

  const handleCancel = useCallback(() => {
    hideModal();
    form.resetFields();
  }, [form, hideModal]);

  return (
    <>
      {iconOnly ? (
        <Tooltip title="Generate Report">
          <IconButton onClick={handleOpen} disabled={downloading}>
            {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
          </IconButton>
        </Tooltip>
      ) : (
        <Button icon={<DownloadOutlined />} onClick={handleOpen} loading={downloading}>
          Generate Report
        </Button>
      )}
      <Modal
        open={isModalOpen}
        title="Generate Report"
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" loading={downloading} onClick={form.submit}>
              Download
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
      >
        <Form form={form} onFinish={handleDownload}>
          <Alert message="Please, specify dynamic parameters for OpenPlay queries" type="info" />
          {queriesWithParameters.map((query) => (
            <Fragment key={query.id}>
              <Divider>
                {query.name?.trim() ||
                  `Query #${report.openplayQueries.indexOf(query) + 1} (${query.openplayQuery.name})`}
              </Divider>
              <Row gutter={[16, 16]}>
                {query.openplayQuery.defaultTenant && (
                  <Col span={12}>
                    <Field
                      label="Tenant"
                      name={[query.id, "tenant"]}
                      rules={[{ required: true, message: "Please, select tenant" }]}
                      wrapperCol={{ span: 24 }}
                      labelCol={{ span: 24 }}
                    >
                      <TenantPicker />
                    </Field>
                  </Col>
                )}
                {Object.entries(query.openplayQuery.parameters).map(([name, { type }]) => (
                  <Col span={12} key={name}>
                    <Field
                      label={name.replaceAll("_", " ")}
                      name={[query.id, "parameters", name]}
                      initialValue={defaultValuesByType[type]}
                      wrapperCol={{ span: 24 }}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please, provide value for the parameter",
                        },
                      ]}
                    >
                      <QueryParameterValueInput type={type} placeholder="Parameter Value" />
                    </Field>
                  </Col>
                ))}
              </Row>
            </Fragment>
          ))}
        </Form>
      </Modal>
    </>
  );
};
