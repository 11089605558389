import cron from "cron-validate";
import * as cronstrue from "cronstrue";
import type { CronFields } from "cron-parser";
import { fieldsToExpression, parseExpression } from "cron-parser";
import { TIME_FORMAT } from "./recurrency";
import type { Recurrency } from "@/api/types";
import { RecurrenceInterval } from "@/api/types";
import * as moment from "moment";

export const validateCronExpression = (expression: string) =>
  cron(expression, {
    preset: "npm-node-cron",
    override: {
      useSeconds: false,
    },
  });

export const isValidCronExpression = (expression: string) => validateCronExpression(expression).isValid();

export const getCronDescription = (expression: string, verbose = true) =>
  cronstrue.toString(expression, { verbose });

export const simplifyCronExpression = (expression: string) => parseExpression(expression).stringify();

type MutableCronFields = { -readonly [Key in keyof CronFields]: CronFields[Key] };

export const getCronExpressionFromRecurrency = ({
  cronExpression,
  daysOfMonth,
  daysOfWeek,
  interval,
  timeOfDay,
}: Recurrency) => {
  if (interval === RecurrenceInterval.Custom && !!cronExpression) {
    return isValidCronExpression(cronExpression) ? simplifyCronExpression(cronExpression) : null;
  }
  const timeOfDayParsed = timeOfDay ? moment(timeOfDay, TIME_FORMAT) : null;

  if (timeOfDayParsed) {
    const minute = timeOfDayParsed.minutes();
    const hour = timeOfDayParsed.hours();

    const defaultFields = { ...parseExpression("* * * * *").fields } as MutableCronFields;
    // @ts-expect-error
    defaultFields.minute = [minute];
    // @ts-expect-error
    defaultFields.hour = [hour];

    if (interval === RecurrenceInterval.Daily) {
      return fieldsToExpression(defaultFields).stringify();
    }

    if (interval === RecurrenceInterval.Weekly && daysOfWeek?.length > 0) {
      // @ts-expect-error
      defaultFields.dayOfWeek = daysOfWeek;
      return fieldsToExpression(defaultFields).stringify();
    }

    if (interval === RecurrenceInterval.Monthly && daysOfMonth?.length > 0) {
      // @ts-expect-error
      defaultFields.dayOfMonth = daysOfMonth;
      return fieldsToExpression(defaultFields).stringify();
    }
  }

  return null;
};

export const getRecurrencyDescription = (recurrency: Recurrency) => {
  const expression = getCronExpressionFromRecurrency(recurrency);
  return expression ? getCronDescription(expression, false) : null;
};
