import { Button } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { showNavigationConfirmation } from "../../utils/useNavigationConfirm";

export default function ButtonWithConfirm({ disabled = false, onClick, text }) {
  return (
    <Button
      onClick={() => showNavigationConfirmation(onClick)}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}

ButtonWithConfirm.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
