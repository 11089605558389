import { PageHeader, Table } from "antd";
import { columns } from "./columns";
import { useHistory } from "react-router-dom";
import type { MessageType } from "@/api/webhooks/types";
import { useWebhooks } from "@/api/webhooks/hooks";

export const WebhookList = () => {
  const history = useHistory();
  const { data, isLoading } = useWebhooks();

  const handleEdit = (type: MessageType) => history.push(`/webhooks/${type}`);

  return (
    <>
      <PageHeader title={<h1>Webhooks</h1>} />
      <Table
        loading={isLoading}
        dataSource={data}
        rowKey={(entry) => entry.messageType}
        pagination={false}
        columns={columns}
        onRow={(entry) => {
          return {
            onClick: () => handleEdit(entry.messageType),
          };
        }}
      />
    </>
  );
};
