import type { ColumnsType } from "antd/lib/table/interface";
import { DescriptionCell } from "@/components/common/DescriptionCell";
import type { OnDemandReport } from "@/api/on-demand-reports/types";
import { DownloadSpreadsheetButton } from "@/components/OnDemandReports/DownloadSpreadsheetButton";

export const columns: ColumnsType<OnDemandReport> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Description",
    dataIndex: "source",
    render: (_, entry) => <DescriptionCell description={entry.description} />,
  },
  {
    dataIndex: "actions",
    render: (_, entry) => (
      <div onClick={(e) => e.stopPropagation()} style={{ width: "max-content" }}>
        <DownloadSpreadsheetButton report={entry} iconOnly />
      </div>
    ),
  },
];
