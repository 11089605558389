import { Field } from "@/components/common/Form/Field";
import { OrderDirectionPicker } from "./OrderDirectionPicker";
import { Col, Row } from "antd";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker/SalesforceObjectPathPicker";

type Props = {
  sourceObject: string;
};

export const OrderByListItem = ({ sourceObject }: Props) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Field
          name="field"
          label="Field"
          normalize={(value) => value?.join(".") ?? ""}
          getValueProps={(value) => ({ value: value?.split(".") })}
          rules={[{ required: true }]}
        >
          <SalesforceObjectPathPicker obj={sourceObject} excludeFieldTypes={["multipicklist"]} />
        </Field>
      </Col>
      <Col span={8}>
        <Field name="direction" label="Direction" rules={[{ required: true }]}>
          <OrderDirectionPicker />
        </Field>
      </Col>
    </Row>
  );
};
