import { Recipient } from "../Recipient";
import { IconButton } from "../../../IconButton/IconButton";
import { Remove } from "../../../Icons";
import "./RecipientListItem.less";

type Props = {
  number: number;
  onRemove: () => void;
};

export const RecipientListItem = ({ number, onRemove }: Props) => {
  return (
    <div className="recipient-list-item">
      <div className="recipient-list-item__number">{number}</div>
      <Recipient className="recipient-list-item__fields" />
      <IconButton className="recipient-list-item__remove" icon={Remove} onClick={onRemove} danger />
    </div>
  );
};
