import type { AutoCompleteProps } from "antd";
import { AutoComplete } from "antd";
import useSWR from "swr";
import { useState } from "react";
import { toOptions } from "@/utils/toOptions";
import { useDebounceFn } from "ahooks";

type Props = {
  getSuggestions: (query: string) => Promise<string[]>;
  filterName: string;
} & Pick<AutoCompleteProps, "placeholder" | "disabled" | "value" | "onChange">;

export const AutocompleteFilter = ({ filterName, getSuggestions, ...props }: Props) => {
  const [query, setQuery] = useState<string>("");
  const { data: suggestions } = useSWR(
    query.length >= 3 ? ["suggestions", filterName, query] : null,
    ([, , query]) => getSuggestions(query),
    { fallbackData: [] }
  );
  const { run: handleSearch } = useDebounceFn(
    (value: string) => {
      setQuery(value);
    },
    { wait: 300 }
  );
  return <AutoComplete {...props} onSearch={handleSearch} options={toOptions(suggestions)} />;
};
