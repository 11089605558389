import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { fetchFeed, fetchFeeds, saveFeed } from "@/api/feeds/index";

const key = "feeds";

export const useFeeds = (partnerId, filters) =>
  useSWR([key, partnerId, filters], ([_, partnerId, filters]) => fetchFeeds(partnerId, filters), {
    // @ts-expect-error: types to be refined
    fallbackData: { data: [] },
  });

export const useFeed = (id?: string) => useSWR(id ? [key, id] : null, ([, id]) => fetchFeed(id));

export const useFeedMutation = (id?: string) =>
  useSWRMutation(id ? [key, id] : key, (_, { arg: dto }: { arg }) => saveFeed(dto));
