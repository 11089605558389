import { Col, Input, Pagination, Row } from "antd";
import { useMemo, useState } from "react";
import * as R from "ramda";
import { slice } from "ramda";
import classNames from "classnames";
import "./DefaultMappings.less";
import { SearchOutlined } from "@ant-design/icons";
import { Box } from "../Box/Box";
import { Tooltip } from "@/components/common/Tooltip";

const PAGE_SIZE = 10;

const dataPreparation = (data, search) => {
  return R.filter(
    ({ target, source }) =>
      target.toLowerCase().includes(search.toLowerCase()) ||
      source.toLowerCase().includes(search.toLowerCase())
  )(data);
};

export function DefaultMappings({ data }) {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const prepared = useMemo(() => dataPreparation(data, search), [data, search]);
  const pagedData = useMemo(() => slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)(prepared), [
    prepared,
    page,
  ]);

  const sourceList = useMemo(
    () =>
      pagedData.map(({ source, target }) => (
        <li key={`${source}-${target}`}>
          <Tooltip title={source}>{source}</Tooltip>
        </li>
      )),
    [pagedData]
  );
  const targetList = useMemo(
    () =>
      pagedData.map(({ source, target }) => (
        <li key={`${source}-${target}`}>
          <Tooltip title={target}>{target}</Tooltip>
        </li>
      )),
    [pagedData]
  );

  return (
    <Box>
      <div className="feed-default-mapping__heading-search">
        <div className="feed-default-mapping__heading">Default</div>
        <div
          className={classNames({
            "input-search-wrapper": true,
            "input-search--not-empty": search?.length > 0,
          })}
        >
          <SearchOutlined className="input-search__icon" />
          <Input
            value={search}
            onChange={({ target }) => setSearch(target.value)}
            className="input-search"
            placeholder="Search"
          />
        </div>
      </div>
      <Row className="default-mappings" gutter={16}>
        <Col span={12}>
          <ul>{sourceList}</ul>
        </Col>
        <Col span={12}>
          <ul>{targetList}</ul>
        </Col>
      </Row>
      <Row align="end">
        <Pagination
          current={page}
          onChange={setPage}
          total={prepared.length}
          defaultPageSize={PAGE_SIZE}
          showSizeChanger={false}
        />
      </Row>
    </Box>
  );
}
