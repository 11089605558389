export enum FeedScheduleStatus {
  Ongoing = "ongoing",
  Obsolete = "obsolete",
  Future = "future",
}

const labelLookup: Record<FeedScheduleStatus, string> = {
  [FeedScheduleStatus.Ongoing]: "Ongoing",
  [FeedScheduleStatus.Obsolete]: "Obsolete",
  [FeedScheduleStatus.Future]: "Future",
};

export const getFeedScheduleStatusLabel = (status: FeedScheduleStatus) => labelLookup[status];
