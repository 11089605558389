import React from "react";
import { ListTooltip, StatusTag } from "../../common";
import { getUserRoleLabel } from "@/utils";
import { Cloud } from "../../common/Icons";
import Icon from "@ant-design/icons";
import type { ColumnsType } from "antd/lib/table/interface";
import type { User } from "@/api/users/types";

export const columns: ColumnsType<User> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Role",
    dataIndex: "role",
    render: (_, entry) => getUserRoleLabel(entry.role),
  },
  {
    title: "Assigned To",
    dataIndex: "partners",
    className: "assigned-partners",
    render: (_, entry) => (
      <ListTooltip
        data={entry.partners}
        dataKey="name"
        src={<Icon className="partners-icon" component={Cloud} />}
      />
    ),
  },
  {
    title: " Status",
    dataIndex: "status",
    className: "status-user-column",
    render: (_, entry) => <StatusTag status={entry.status} />,
  },
];
