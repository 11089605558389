import { DetailsTable } from "../DetailsTable/DetailsTable";
import { getUploadStatusColor, getUploadStatusLabel } from "./UploadStatus/utils";
import { UploadStatusTag } from "./UploadStatus/UploadStatusTag";
import { Truncated } from "../../Truncated";

const columns = [
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, entry) => <UploadStatusTag status={entry.status} />,
  },
  {
    title: "Asset Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Details",
    dataIndex: "details",
    key: "details",
    render: (_, entry) => {
      const { discNumber, trackNumber, artist, title } = entry.details;
      const text = `${discNumber}-${trackNumber} - ${artist} - ${title}`;
      return <Truncated text={text} size={50} />;
    },
  },
  {
    title: "File",
    dataIndex: "filename",
    key: "filename",
    render: (_, entry) => <Truncated text={entry.details.filename} />,
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
    render: (_, entry) => (entry.size ? `${(entry.size / 1024 ** 2).toFixed(2)} MB` : "-"),
  },
];

export const Uploads = ({ uploads, counts }) => (
  <DetailsTable
    records={uploads}
    counts={counts}
    columns={columns}
    getStatusColor={getUploadStatusColor}
    getStatusLabel={getUploadStatusLabel}
    title="Uploads"
  />
);
