import { QueryPicker } from "@/components/Reports/QueryPicker";
import { Field } from "@/components/common/Form/Field";
import { Form } from "antd";
import type { SpreadsheetColumn } from "@/api/spreadsheets/types";
import { useFieldPath } from "@/components/common/Form/hooks";

type Props = {
  onlyWithOpenplayEquivalents?: boolean;
};

export const QueryPickerField = ({ onlyWithOpenplayEquivalents }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const columns = Form.useWatch<SpreadsheetColumn[]>(getAbsolutePath(["columnSet", "columns"])) ?? [];
  const isDisabled = columns.length > 0;

  return (
    <Field
      name="query"
      label="Query"
      validateFirst
      rules={[{ required: true, message: "Please, select a query" }]}
      tooltip={isDisabled ? "Query cannot be changed if one or more columns are added" : null}
    >
      <QueryPicker
        disabled={isDisabled}
        onlyWithOpenplayEquivalents={onlyWithOpenplayEquivalents}
        allowClear={!isDisabled}
      />
    </Field>
  );
};
