export const ERNChoreography = {
  Release: "release",
  Batch: "batch",
};

export enum Order {
  ASC = "ascend",
  DESC = "descend",
}

export const ORDER_BY_NAME = "sort_by";
export const ORDER_BY_SORT = "order";

export const SORT_TYPE = {
  [Order.DESC]: "DESC",
  [Order.ASC]: "ASC",
};

export const DATE_FORMAT = "MM/DD/YYYY";

export const AssetsFormat = {
  Original: "original",
  MP3: "mp3",
};

export const GUID_PATTERN = "[\\w]{8}-[\\w]{4}-[\\w]{4}-[\\w]{4}-[\\w]{12}";

export const ERN_CHOREOGRAPHY_LIST = [
  { value: ERNChoreography.Release, label: "Release Profile" },
  { value: ERNChoreography.Batch, label: "Batch Profile" },
];

export const Operation = {
  Export: "export",
  Import: "import",
};

export const FeedTab = {
  Summary: "summary",
  Filters: "filters",
  Mapping: "mapping",
  AssetNaming: "asset-naming",
  Destination: "destination",
  Source: "source",
  ExportSchedules: "export-schedules",
};

export const PartnerTab = {
  Summary: "summary",
  Feeds: "feeds",
};

export enum ReportTab {
  Summary = "summary",
  Query = "query",
  Variations = "variations",
  Email = "email",
  Spreadsheet = "spreadsheet",
  Actions = "actions",
}

export const assetNamingDelimiters = ["_", "-", "."];
