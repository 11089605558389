export enum Color {
  Active = "#2fbf00",
  Inactive = "#8fa4bf",
  Tooltip = "#202226",
  Success = "#2fbf00",
  Accent = "#2eb8e6",
  SecondaryAccent = "#8b70f6",
  Failed = "#FF6666",
  Warning = "#FAAD14",
}
