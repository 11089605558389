import { useParams } from "react-router-dom";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { useOpenplayQuery } from "@/api/openplay-queries/hooks";
import { OpenplayQueryForm } from "./OpenplayQueryForm";

export const OpenplayQueryPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: entry, isLoading } = useOpenplayQuery(id);

  if (isLoading) {
    return <Spinner />;
  }

  return <OpenplayQueryForm initialValues={entry} />;
};
