import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import Icon from "@ant-design/icons";
import { Dots, DropdownArrow } from "@/components/common/Icons";
import "./ExtraActions.less";

type Props = {
  items: MenuProps["items"];
};

export const ExtraActions = ({ items }: Props) => (
  <Dropdown menu={{ items }} trigger={["click"]} key="dot-overlay" className="extra-actions">
    <Button htmlType="button">
      <Dots className="extra-actions__dots" />
      <Icon className="extra-actions__arrow" component={DropdownArrow} />
    </Button>
  </Dropdown>
);
