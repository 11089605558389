import { DeliveryStatus, Status } from "@/utils";

export const canBeResent = (delivery) =>
  Number(delivery.feed?.status) !== Status.Draft && delivery.status !== DeliveryStatus.Delayed;

export const hasFeedTenantChanged = (delivery) => {
  if (!delivery.feed?.tenant?.code) {
    return false;
  }
  return delivery[delivery.targetObject]?.tenant?.code !== delivery.feed?.tenant?.code;
};
