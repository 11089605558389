import React from "react";
import { Radio } from "antd";
import type { ValueSourcesProps } from "@react-awesome-query-builder/ui";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export const ValueSources = ({
  config,
  valueSources,
  valueSrc,
  readonly,
  setValueSrc,
}: ValueSourcesProps) => {
  const onChange = (e) => {
    setValueSrc(e.target.value);
  };
  return (
    <RadioGroup
      value={valueSrc || "value"}
      size={config.settings.renderSize === "medium" ? "middle" : config.settings.renderSize}
      onChange={onChange}
      disabled={readonly}
    >
      {/* @ts-expect-error */}
      {valueSources.map(([srcKey, info]) => (
        <RadioButton key={srcKey} value={srcKey}>
          {info.label}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};
