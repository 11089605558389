import type { Config } from "@react-awesome-query-builder/ui";
import { Builder, Query, Utils as QbUtils } from "@react-awesome-query-builder/ui";
import { emptyTree } from "../SalesforceQuery/constants";
import type { ComponentProps } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "antd";
import cn from "classnames";
import "@react-awesome-query-builder/ui/css/styles.css";
import "./QueryBuilder.less";
import type { JsonLogicTree } from "@react-awesome-query-builder/core";

const renderBuilder = (props) => {
  return (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );
};

type QueryBuilderProps = {
  value?: JsonLogicTree;
  onChange?: (value: JsonLogicTree) => void;
  onBlur?: ComponentProps<"div">["onBlur"];
  config: Config;
};

export const QueryBuilder = ({ value, onChange, onBlur, config }: QueryBuilderProps) => {
  const getTree = useCallback(
    (value) => QbUtils.checkTree(value ? QbUtils.loadFromJsonLogic(value, config) : emptyTree, config),
    [config]
  );

  const getDefaultValue = () => getTree(value);

  const [tree, setTree] = useState(getDefaultValue);
  const { status } = Form.Item.useStatus();

  const handleChange = (tree) => {
    updateValue(tree);
  };

  const previousTreeRef = useRef(null);

  useEffect(() => {
    // Synchronize value
    if (previousTreeRef.current && tree === previousTreeRef.current) {
      setTree(getTree(value));
    }
    previousTreeRef.current = tree;
  }, [value, tree, getTree]);

  const updateValue = (tree) => {
    setTree(tree);
    const jsonLogic = QbUtils.jsonLogicFormat(tree, config).logic;
    if (!value && !jsonLogic) {
      return;
    }
    onChange?.(jsonLogic ?? null);
  };

  return (
    <div
      className={cn("query-builder-wrapper", status === "error" && "query-builder-wrapper--error")}
      tabIndex={0}
      onBlur={onBlur}
    >
      <Query {...config} value={tree} onChange={handleChange} renderBuilder={renderBuilder} />
    </div>
  );
};
