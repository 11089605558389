import { SpreadsheetForm } from "@/components/Spreadsheets/SpreadsheetForm";
import { VariableType } from "@/types/variables";
import type { ObjectField, SimpleField } from "@/utils/dynamic-fields/types";

type Props = {
  readOnly?: boolean;
};

const variables: [ObjectField<VariableType.General, SimpleField>] = [
  {
    name: VariableType.General,
    label: "General",
    type: "object",
    children: [{ name: "current_date", label: "Current Date", type: "datetime" }],
  },
];

export const OnDemandReportSpreadsheetForm = ({ readOnly }: Props) => {
  return (
    <SpreadsheetForm readOnly={readOnly} entity="on-demand-report" variables={variables} removable={false} />
  );
};
