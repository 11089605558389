import { Alert, Button, Col, Form, Input, Radio, Row, Select, Skeleton } from "antd";
import { assetNamingDelimiters } from "../../../utils";
import { toOptions } from "../../../utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow, Plus, Remove } from "../Icons";
import React from "react";
import * as R from "ramda";
import "./AssetNamingFields.less";
import { useRequest } from "ahooks";
import { getAssetNamingSegments } from "../../../api/feeds";
import { IconButton } from "../IconButton/IconButton";
import { atLeastOne } from "@/utils/validation";

export const AssetNamingFields = ({ getFieldName = R.identity, getAbsoluteFieldName = R.identity }) => {
  const { loading, data: segmentsByType } = useRequest(getAssetNamingSegments, {
    cacheKey: "asset-naming-segments",
    staleTime: 60000,
    initialData: {},
  });
  const assetType = Form.useWatch(getAbsoluteFieldName("assetType"));
  const delimiter = Form.useWatch(getAbsoluteFieldName("delimiter"));
  const customDelimiter = Form.useWatch(getAbsoluteFieldName("customDelimiter"));
  const segments = Form.useWatch(getAbsoluteFieldName("segments")) || [];

  const realDelimiter = delimiter === "custom" ? customDelimiter : delimiter;
  const availableParts = segmentsByType[assetType] || {};
  const existingSegments = segments.filter((segment) => !!segment);

  return (
    <>
      <Form.Item name={getFieldName("assetType")} noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row gutter={16} align="middle">
        <Col flex="none">
          <Form.Item
            name={getFieldName("delimiter")}
            label="Delimiter"
            className="asset-naming-fields__delimiter"
          >
            <Radio.Group
              buttonStyle="solid"
              options={[...assetNamingDelimiters, "custom"]}
              optionType="button"
            />
          </Form.Item>
        </Col>
        {delimiter === "custom" && (
          <Col flex="auto">
            <Form.Item
              name={getFieldName("customDelimiter")}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true },
                { pattern: /^[^<>:;,?"*|/]+$/, message: "Delimiter contains invalid characters" },
              ]}
            >
              <Input placeholder="Custom delimiter" />
            </Form.Item>
          </Col>
        )}
      </Row>
      {loading ? (
        <Row gutter={[16, 16]}>
          {segments.map((_, index) => (
            <Col span={24} key={index}>
              <Skeleton.Input block />
            </Col>
          ))}
        </Row>
      ) : (
        <Form.List
          name={getFieldName("segments")}
          rules={[atLeastOne("At least one name segment should be set")]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <div>
                {existingSegments.length > 0 ? (
                  <div className="asset-naming-fields__sample">{`${existingSegments
                    .map((part) => availableParts[part].sample)
                    .join(realDelimiter || "")}.{extension}`}</div>
                ) : (
                  errors.length > 0 && (
                    <Row gutter={[16, 16]} className="asset-naming-fields__errors">
                      {errors.map((error, index) => (
                        <Col span={24} key={index}>
                          <Alert message={error} type="error" />
                        </Col>
                      ))}
                    </Row>
                  )
                )}
                <ol className="asset-naming-fields__parts">
                  {fields.map((field) => (
                    <li key={field.key}>
                      <Form.Item
                        name={field.name}
                        wrapperCol={{ span: 24 }}
                        rules={[{ required: true, message: "Field is mandatory" }]}
                      >
                        <Select
                          options={toOptions(
                            Object.keys(availableParts),
                            (key) => availableParts[key]?.label
                          )}
                          notFoundContent={<span>Under Development</span>}
                          suffixIcon={<Icon component={DropdownArrow} />}
                        />
                      </Form.Item>
                      <IconButton
                        className="asset-naming-fields__remove-part"
                        onClick={() => remove(field.name)}
                        icon={Remove}
                      />
                    </li>
                  ))}
                </ol>
                <Button
                  type="dashed"
                  htmlType="button"
                  onClick={() => add()}
                  icon={<Icon component={Plus} />}
                  className="btn-new-field"
                  block
                >
                  Add Segment
                </Button>
              </div>
            );
          }}
        </Form.List>
      )}
    </>
  );
};
