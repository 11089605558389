import type { SalesforceMessage } from "@/api/webhooks/types";
import { Alert } from "antd";
import { DetailsTable } from "./DetailsTable";

type Props = {
  entry: SalesforceMessage;
};

export const SalesforceMessageDetails = ({ entry }: Props) => {
  if (entry.transferResult.success === false) {
    return <Alert type="error" message={entry.transferResult.reason} />;
  }
  return <DetailsTable entities={entry.transferResult.entities} />;
};
