import { api } from "@/api/core";
import type { PaginatedResponse } from "@/api/types";
import type { ReportLog, ReportLogFilters } from "@/api/report-logs/types";

export const url = "/api/report-logs";

export const fetchReportLogs = (params: ReportLogFilters) =>
  api.get<any, PaginatedResponse<ReportLog>>(url, { params });

export const downloadSpreadsheet = (id: string) =>
  api.get<void, Blob>(`${url}/${id}/download`, { responseType: "blob" });
