import type { Pagination } from "@/api/types";
import type { OnDemandReport } from "@/api/on-demand-reports/types";
import type { Report } from "@/api/reports/types";

export type OnDemandReportOpenplayQuery = {
  id: string;
  onDemandReport: Pick<OnDemandReport, "id" | "name">;
};

export type QueryParameterSource = {
  query: string;
  field: string[];
  parameter: string;
};

export type ReportOpenplayQuery = {
  id: string;
  report: Pick<Report, "id" | "name">;
  parameterSources?: QueryParameterSource[];
};

export enum QueryParamType {
  String = "string",
  Number = "number",
  Boolean = "boolean",
  Date = "date",
  DateTime = "datetime",
  Array = "array",
}

export type QueryParameterValue = string | number | boolean | string[];

export type QueryParam = {
  type: QueryParamType;
  defaultValue?: QueryParameterValue;
};

export type OpenplayQuery = {
  id: string;
  name: string;
  description?: string;
  sql: string;
  fields: string[];
  onDemandReportOpenplayQueries: OnDemandReportOpenplayQuery[];
  reportOpenplayQueries: ReportOpenplayQuery[];
  defaultTenant?: string;
  parameters?: Record<string, QueryParam>;
};

export type CreateOpenplayQuery = Omit<OpenplayQuery, "id">;
export type UpdateOpenplayQuery = Pick<OpenplayQuery, "id"> & Partial<CreateOpenplayQuery>;
export type OpenplayQueryFilters = Pagination & {
  name?: string;
  nameContains?: string;
  descriptionContains?: string;
};

export type QueryWithParameters = {
  sql: string;
  tenant?: string;
  parameters?: Record<string, QueryParam>;
};

export type CheckQueryResult =
  | { success: true; count: number; fields: string[] }
  | { success: false; reason: string };

export type Tenant = { code: string; name: string };

export type Table = { name: string; columns: string[] };

export type Schema = {
  tenants: Tenant[];
  tables: {
    platform: Table[];
    tenant: Table[];
  };
};
