import type { Moment } from "moment/moment";
import * as moment from "moment/moment";
import { DatePicker } from "antd";

type DateInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  showTime?: boolean;
};

export const DateInput = ({ value, onChange, showTime }: DateInputProps) => {
  const outputFormat = showTime ? "YYYY-MM-DDTHH:mm:ssZ" : "YYYY-MM-DD";
  const displayFormat = showTime ? "YYYY/DD/MM hh:mmA" : "L";

  const handleChange = (date: Moment) => {
    onChange(date?.format(outputFormat));
  };

  return (
    <DatePicker
      format={displayFormat}
      value={value ? moment(value, outputFormat) : undefined}
      onChange={handleChange}
      showTime={showTime}
      allowClear
    />
  );
};
