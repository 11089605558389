import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { VariableType } from "@/types/variables";
import { getFieldsFromSalesforceObject } from "@/utils/dynamic-fields";
import { getFieldsForQueryBuilder } from "@/utils";
import type { ObjectField } from "@/utils/dynamic-fields/types";

type LoopContextType = {
  isInsideOfLoop: boolean;
  sourceObject: string | null;
};

const LoopContext = createContext<LoopContextType>({ isInsideOfLoop: false, sourceObject: null });

export const LoopContextProvider = ({
  children,
  sourceObject,
}: PropsWithChildren<{ sourceObject: string }>) => (
  <LoopContext.Provider value={{ isInsideOfLoop: true, sourceObject }}>{children}</LoopContext.Provider>
);

export const useLoopContext = () => useContext(LoopContext);

export const useLoopItemVariables = (): {
  variables: ObjectField<VariableType.LoopItem>[];
  isLoading: boolean;
} => {
  const { isInsideOfLoop, sourceObject } = useContext(LoopContext);
  const { obj: salesforceObject, isLoading } = useSalesforceObject(sourceObject);
  const variables: ObjectField<VariableType.LoopItem>[] = useMemo(
    () =>
      isInsideOfLoop && salesforceObject
        ? [
            {
              name: VariableType.LoopItem,
              label: "Loop / Current Item",
              type: "object",
              children: getFieldsFromSalesforceObject(salesforceObject),
            },
          ]
        : [],
    [isInsideOfLoop, salesforceObject]
  );
  return { variables, isLoading };
};

export const useLoopItemFieldsForQueryBuilder = () => {
  const { variables, isLoading } = useLoopItemVariables();
  const fields = useMemo(() => getFieldsForQueryBuilder(variables), [variables]);
  return { fields, isLoading };
};
