import { api } from "../core";
import type { PaginatedResponse, Pagination } from "../types";
import { SALESFORCE_MESSAGES_URL, WEBHOOK_INFO_URL, WEBHOOK_LOG_URL, WEBHOOKS_INFO_URL } from "../urls";
import type { MessageType, SalesforceMessage, UpdateWebhookDto, Webhook, WebhookWithSettings } from "./types";

export const fetchSalesforceMessages = (params: Pagination) =>
  api.get<any, PaginatedResponse<SalesforceMessage>>(SALESFORCE_MESSAGES_URL, { params });

export const fetchWebhooks = () => api.get<any, Webhook[]>(WEBHOOKS_INFO_URL);

export const fetchWebhook = (type: MessageType) => api.get<any, WebhookWithSettings>(WEBHOOK_INFO_URL(type));

export const updateWebhook = (params: UpdateWebhookDto) => api.put<any, void>(WEBHOOKS_INFO_URL, params);

export const downloadWebhookLog = (type: MessageType) =>
  api.get<any, Blob>(WEBHOOK_LOG_URL(type), { responseType: "blob" });
