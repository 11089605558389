import { api } from "../core";
import type { Dictionaries } from "@/api/dictionaries/types";

export async function getDictionaries() {
  return api.get<any, Dictionaries>("/api/dictionaries");
}

export async function updateDictionaries() {
  return api.post<any, Dictionaries>("/api/dictionaries/update");
}
