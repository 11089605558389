import { Form } from "antd";
import { useBoolean } from "ahooks";
import { useCallback, useState } from "react";
import { TemplateType } from "@/utils";
import { SaveAsTemplateButton } from "../Templates/SaveAsTemplateButton";
import { SaveAsTemplateForm } from "../Templates/SaveAsTemplateForm";
import { useFieldPath } from "@/components/common/Form/hooks";
import { ReportStatus } from "@/api/reports/types";

export const SaveAsSalesforceQueryTemplate = ({ disabled }) => {
  const outerForm = Form.useFormInstance();
  const reportStatus = Form.useWatch(["status"]);
  const { getAbsolutePath } = useFieldPath();

  const [isFormVisible, { setTrue: openForm, setFalse: closeForm }] = useBoolean(false);

  const getTemplateFields = useCallback(() => {
    return {
      type: TemplateType.SalesforceQuery,
      data: outerForm.getFieldValue(getAbsolutePath()),
    };
  }, [getAbsolutePath, outerForm]);

  const [loading, setLoading] = useState(false);

  const handleOpenForm = async () => {
    setLoading(true);
    const fieldsToValidate = [getAbsolutePath("from"), getAbsolutePath("where")];

    try {
      const errors = outerForm.getFieldsError(fieldsToValidate).flatMap(({ errors }) => errors);
      if (errors.length > 0) {
        return;
      }
      await outerForm.validateFields(fieldsToValidate);
      openForm();
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SaveAsTemplateButton
        onClick={handleOpenForm}
        disabled={disabled}
        buttonType="text"
        loading={loading}
        unavailable={reportStatus === ReportStatus.Draft}
        unavailableTooltip={
          reportStatus === ReportStatus.Draft
            ? "Cannot save as template if the report status is 'Draft'"
            : null
        }
      />
      <SaveAsTemplateForm
        isVisible={isFormVisible}
        onClose={closeForm}
        getTemplateFields={getTemplateFields}
      />
    </>
  );
};
