import { Col, Form, Row, Select } from "antd";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { ReportActionType } from "@/api/reports/types";
import { Field } from "@/components/common/Form/Field";
import { useFieldPath } from "@/components/common/Form/hooks";
import { UpdateRecordsActionFields } from "@/components/Reports/Details/ActionsList/UpdateRecordsActionFields";

const typeLabelLookup: Record<ReportActionType, string> = {
  [ReportActionType.UpdateRecords]: "Update Records",
};

const getTypeLabel = (type: ReportActionType) => typeLabelLookup[type];

export const ReportAction = () => {
  const { getAbsolutePath } = useFieldPath();
  const type = Form.useWatch<ReportActionType>(getAbsolutePath("type"));
  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Field label="Type" rules={[{ required: true, message: "Please, select action type" }]} name="type">
          <Select
            options={toOptions(Object.values(ReportActionType), getTypeLabel)}
            placeholder="Select action type"
            suffixIcon={<Icon component={DropdownArrow} />}
          />
        </Field>
      </Col>
      {type === ReportActionType.UpdateRecords && <UpdateRecordsActionFields />}
    </Row>
  );
};
