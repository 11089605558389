import React, { useCallback } from "react";
import { isEmptyField } from "../../utils";
import { Form, Input } from "antd";
import { StorageService } from "../../../../utils";
import PropTypes from "prop-types";
import { isNotDraft, requiredIf, requiredIfNotDraft } from "../../../../utils/validation";
import { PathField } from "./PathField";

export const FTPFields = ({ fieldName }) => {
  const validateSftpPassword = useCallback(
    (form) =>
      requiredIf(
        form,
        [[isNotDraft], [isEmptyField, [fieldName, "configuration", "privateKey"]]],
        "The password or private key should be specified"
      ),
    [fieldName]
  );

  const validateSshPrivateKey = useCallback(
    (form) =>
      requiredIf(
        form,
        [[isNotDraft], [isEmptyField, [fieldName, "configuration", "password"]]],
        "The password or private key should be specified"
      ),
    [fieldName]
  );

  return (
    <>
      <Form.Item name={[fieldName, "configuration", "ip"]} label="IP" rules={[requiredIfNotDraft]}>
        <Input placeholder="IP Address" />
      </Form.Item>
      <Form.Item
        name={[fieldName, "configuration", "port"]}
        label="Port"
        rules={[requiredIfNotDraft, { pattern: /^\d+$/, message: "Port should be a number" }]}
      >
        <Input placeholder="Port" inputMode="numeric" />
      </Form.Item>
      <PathField name={[fieldName, "configuration", "path"]} />
      <Form.Item
        name={[fieldName, "configuration", "userName"]}
        label="userName"
        rules={[requiredIfNotDraft]}
      >
        <Input placeholder="User Name" />
      </Form.Item>
      <Form.Item dependencies={[[fieldName, "configuration", "privateKey"]]} noStyle>
        {(form) => (
          <Form.Item
            name={[fieldName, "configuration", "password"]}
            label="Password"
            rules={[validateSftpPassword]}
            dependencies={[[fieldName, "configuration", "privateKey"]]}
          >
            <Input.Password
              placeholder="Password"
              disabled={!isEmptyField(form, [fieldName, "configuration", "privateKey"])}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        noStyle
        dependencies={[
          [fieldName, "type"],
          [fieldName, "configuration", "password"],
        ]}
      >
        {(form) =>
          form.getFieldValue([fieldName, "type"]) === StorageService.SFTP && (
            <>
              <Form.Item
                name={[fieldName, "configuration", "privateKey"]}
                label="SSH private key"
                dependencies={[[fieldName, "configuration", "password"]]}
                rules={[validateSshPrivateKey]}
              >
                <Input.TextArea disabled={!isEmptyField(form, [fieldName, "configuration", "password"])} />
              </Form.Item>
              <Form.Item name={[fieldName, "configuration", "passphrase"]} label="passphrase">
                <Input
                  placeholder="Passphrase"
                  disabled={!isEmptyField(form, [fieldName, "configuration", "password"])}
                />
              </Form.Item>
            </>
          )
        }
      </Form.Item>
    </>
  );
};

FTPFields.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
