import useSWRImmutable from "swr/immutable";
import { getDictionaries, updateDictionaries } from "@/api/dictionaries/index";
import useSWRMutation from "swr/mutation";

const key = "dictionaries";

export const useDictionaries = () => {
  const { data: dictionaries, isLoading } = useSWRImmutable(key, getDictionaries, {
    revalidateOnMount: true,
    fallbackData: {
      openplay: {
        tenants: [],
        genres: [],
        configurations: [],
        parentalAdvisoryOptions: [],
        releaseStatuses: [],
        releaseTypes: [],
        resolutions: [],
      },
      labelcamp: {
        companies: [],
      },
    },
  });
  return { dictionaries, isLoading };
};

export const useUpdateDictionaries = () => useSWRMutation(key, updateDictionaries);
