import { Export } from "../Icons";
import { Upload } from "antd";
import { parseUpcFile } from "../../../utils";
import { useCallback } from "react";
import { IconButton } from "../IconButton/IconButton";

export const CSVUploadButton = ({ onChange, className }) => {
  const parseFile = useCallback(
    async (file) => {
      const csv = await parseUpcFile(file);
      onChange(csv);
    },
    [onChange]
  );
  return (
    <Upload
      className={className}
      accept=".csv"
      action={parseFile}
      showUploadList={false}
      customRequest={() => {}}
    >
      <IconButton icon={Export} />
    </Upload>
  );
};
