import { PageHeader, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { fetchFeed } from "../../../api/feeds";
import { Summary } from "../../common/Summary/Summary";
import React from "react";
import { FeedDeliveries } from "./FeedDeliveries";
import { Operation } from "../../../utils";
import { FeedImports } from "./FeedImports";

export const FeedSummary = ({ feed }) => (
  <Summary
    items={[
      { label: "Feed", value: feed?.name },
      { label: "Partner", value: feed?.partner?.name },
      { label: "Tenant", value: feed?.tenant?.name },
    ]}
  />
);

export const FeedActivity = () => {
  const { feedId } = useParams();

  const { loading, data: feed } = useRequest(() => fetchFeed(feedId), {
    cacheKey: `feed-${feedId}`,
  });

  return (
    <>
      <PageHeader
        title="Feed Activity"
        extra={
          <Spin spinning={loading}>
            <FeedSummary feed={feed} />
          </Spin>
        }
      />
      {feed?.operation === Operation.Export && (
        <FeedDeliveries feedId={feedId} targetObject={feed?.targetObject} />
      )}
      {feed?.operation === Operation.Import && <FeedImports feedId={feedId} />}
    </>
  );
};
