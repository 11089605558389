import type { ColumnsType } from "antd/lib/table/interface";
import { DescriptionCell } from "@/components/common/DescriptionCell";
import type { Flow } from "@/api/flows/types";
import { StatusTag } from "@/components/common";
import { Space } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { Color } from "@/utils";
import { Tooltip } from "@/components/common/Tooltip";
import { Truncated } from "@/components/Activity/Truncated";

export const columns: ColumnsType<Flow> = [
  {
    title: "Name",
    dataIndex: "name",
    render: (name) => <Truncated text={name} />,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, entry) => <StatusTag status={entry.status} tooltipMessage={entry.deactivationReason} />,
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (description) => <DescriptionCell description={description} />,
  },
  {
    title: "Event",
    dataIndex: ["event", "label"],
    render: (_, entry) =>
      entry.eventName ? (
        <Space>
          <span>{entry.event?.label ?? entry.eventName}</span>
          {!entry.event && (
            <Tooltip title="The event is removed">
              <WarningOutlined style={{ color: Color.Warning }} />
            </Tooltip>
          )}
        </Space>
      ) : (
        "-"
      ),
  },
];
