import { OpenplayQueryList } from "../../OpenplayQueries/OpenplayQueryList";
import { Alert, Col, Row } from "antd";

type Props = {
  readOnly?: boolean;
};

export const OnDemandReportQueriesForm = ({ readOnly }: Props) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Alert
          showIcon
          message={
            <>
              Editing existing OpenPlay query <strong>may impact</strong> other on-demand reports that
              reference it. If you want to use it as a starting point, consider using{" "}
              <strong>Duplicate & Edit</strong>.
            </>
          }
        />
      </Col>
      <Col span={24}>
        <OpenplayQueryList readOnly={readOnly} hideDynamicParameters />
      </Col>
    </Row>
  );
};
