import React, { useCallback } from "react";
import FilterableContent from "../../Layout/FilterableContent";
import { getFilters } from "./filters";
import PartnersTable from "./PartnersTable";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import { PartnerTab, useAuth } from "@/utils";
import { Plus } from "../../common/Icons";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export function PartnersList() {
  const history = useHistory();
  const handleNew = useCallback(() => history.push(`/partners/new/${PartnerTab.Summary}`), [history]);
  const { ability, Can } = useAuth();

  return (
    <>
      <h1>Partners</h1>
      <FilterableContent
        formConfig={getFilters(ability)}
        filterName="partners-filter"
        isFiltersConfigurable={false}
        // @ts-expect-error need to migrate to TS
        buttons={
          <Can I={PermissionAction.Create} a={PermissionSubject.Partner}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: "auto" }}
              key="new-partner"
              onClick={handleNew}
              icon={<Icon component={Plus} />}
            >
              New Partner
            </Button>
          </Can>
        }
      >
        <PartnersTable />
      </FilterableContent>
    </>
  );
}
