import type { CreateEmailAlertDto, EmailAlert, UpdateEmailAlertDto } from "@/api/email-alerts/types";

export type FormValues = (CreateEmailAlertDto | UpdateEmailAlertDto) & {
  hasSpreadsheet: boolean;
};

export const parseEmailAlertFields = (fields: Partial<EmailAlert>) => ({
  ...fields,
  hasSpreadsheet: !!fields.spreadsheet,
});

export const prepareEmailAlertDto = ({ hasSpreadsheet, ...values }: FormValues) => ({
  ...values,
  spreadsheet: hasSpreadsheet ? values.spreadsheet : null,
});