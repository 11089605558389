import type { ReactNode } from "react";
import { useMemo } from "react";
import { Field } from "@/components/common/Form/Field";
import "./ConditionBox.less";
import type { Fields } from "@react-awesome-query-builder/core";
import { CustomAntdConfig } from "@/components/common/QueryBuilder/config";
import { ReadOnlyCondition } from "./ReadOnlyCondition/ReadOnlyCondition";
import { Form, Modal } from "antd";
import { QueryBuilder } from "@/components/common/QueryBuilder/QueryBuilder";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Filters } from "@/components/common/Icons";
import { useBoolean } from "ahooks";
import { FormProvider } from "@/components/common/Form/FormProvider";
import type { Logic } from "@/api/types";
import { useFieldPath } from "@/components/common/Form/hooks";

type Props = {
  fields: Fields;
  children: ReactNode;
};

export const ConditionBox = ({ fields, children }: Props) => {
  const config = useMemo(() => ({ ...CustomAntdConfig, fields }), [fields]);
  const form = Form.useFormInstance();
  const { getAbsolutePath } = useFieldPath();
  const [innerForm] = Form.useForm<{ condition: Logic }>();
  const [isOpen, { setTrue: open, setFalse: close }] = useBoolean(false);

  const handleOpen = () => {
    innerForm.setFieldValue("condition", form.getFieldValue(getAbsolutePath("condition")));
    open();
  };
  const handleFinish = (values: { condition: Logic }) => {
    form.setFieldValue(getAbsolutePath("condition"), values.condition);
    innerForm.resetFields();
    close();
  };
  const handleCancel = () => {
    innerForm.resetFields();
    close();
  };
  return (
    <div className="condition-box">
      <div className="flex-row condition-box__condition">
        <Field
          name="condition"
          className="flex-grow"
          rules={[{ required: true, message: "At least one of the rules should be configured" }]}
        >
          <ReadOnlyCondition config={config} />
        </Field>
        <IconButton icon={Filters} onClick={handleOpen} />
      </div>
      <div className="condition-box__body">{children}</div>
      <Modal
        open={isOpen}
        title="Edit Condittion"
        width={1200}
        onCancel={handleCancel}
        onOk={innerForm.submit}
      >
        <Form component={false} form={innerForm} onFinish={handleFinish}>
          <FormProvider>
            <Field
              name="condition"
              rules={[{ required: true, message: "At least one of the rules should be configured" }]}
            >
              <QueryBuilder config={config} />
            </Field>
          </FormProvider>
        </Form>
      </Modal>
    </div>
  );
};
