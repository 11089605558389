import { Token } from "./Token";

export const Wrapper = ({ children }) => (
  <pre aria-hidden className="dynamic-path-field__highlight">
    <code>{children}</code>
  </pre>
);

export const Highlight = ({ value, tokens, caret, variables, onSelectVariable }) => {
  if (tokens.length === 0) {
    return <Wrapper>{value}</Wrapper>;
  }

  const result = [];
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    result.push(value.slice(i === 0 ? 0 : tokens[i - 1].indices.end, token.indices.start));

    result.push(
      <Token
        token={token}
        caret={caret - token.indices.start}
        variables={variables}
        onSelectVariable={onSelectVariable}
      />
    );

    if (i === tokens.length - 1) {
      result.push(value.slice(token.indices.end));
    }
  }
  return <Wrapper>{result}</Wrapper>;
};
