import type { EmailMessage, Logic, Pagination, Recurrency } from "@/api/types";
import type { Spreadsheet } from "@/api/spreadsheets/types";
import type { OpenplayQuery } from "@/api/openplay-queries/types";

export type Variation = {
  name?: string;
  query: Pick<SalesforceQuery, "where">;
  email: Partial<Pick<EmailMessage, "to" | "cc">>;
  variables: Record<string, string>;
};

export enum ReportStatus {
  Inactive = 0,
  Active = 1,
  Draft = 2,
}

export class SalesforceQuery {
  id: string;
  name?: string;
  from: string;
  where?: Logic;
}

export enum ReportActionType {
  UpdateRecords = "update-records",
}

export enum ActionQueryType {
  Salesforce = "salesforce",
  Openplay = "openplay",
}

export type UpdateRecordsAction = {
  type: ReportActionType.UpdateRecords;
  queryType?: ActionQueryType;
  queryId?: string;
  objectToUpdate?: string;
  matchField?: string[];
  queryField?: string[];
  fields: { path: string[]; value: any }[];
};

export type ReportAction = UpdateRecordsAction;

export type ReportOpenplayQuery = {
  id: string;
  name?: string;
  openplayQuery: OpenplayQuery;
};

export type Report = {
  id: string;
  name: string;
  status?: ReportStatus;
  recurrency: Recurrency;
  email: EmailMessage;
  queries: SalesforceQuery[];
  openplayQueries: ReportOpenplayQuery[];
  variations: Variation[];
  spreadsheet?: Spreadsheet;
  spreadsheetId?: string;
  variables: string[];
  actions: {
    pre: ReportAction[];
    post: ReportAction[];
  };
};

export type CreateReportDto = Omit<Report, "id" | "spreadsheet">;
export type UpdateReportDto = Pick<Report, "id"> & Partial<CreateReportDto>;

export type ReportFilters = Pagination & {
  fields?: string[];
  name?: string;
};
