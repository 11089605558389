import { useRequest } from "ahooks";
import { resendDeliveries } from "@/api/deliveries";
import { openNotification } from "@/components/common/CommonNotification";
import { useCallback } from "react";

export const useResendDeliveries = () => {
  const { run, loading } = useRequest(resendDeliveries, {
    manual: true,
    throwOnError: true,
    onSuccess: () =>
      openNotification({
        description: "The resend process is started",
      }),
  });

  const resendSingle = useCallback((id) => run([id]), [run]);

  return { resendSingle, resendMany: run, loading };
};
