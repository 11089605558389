import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { fetchWebhook, fetchWebhooks, updateWebhook } from "@/api/webhooks/index";
import type { MessageType, UpdateWebhookDto } from "@/api/webhooks/types";

export const useWebhooks = () =>
  useSWR("webhooks", fetchWebhooks, {
    fallbackData: [],
  });

export const useWebhook = (type: MessageType) => useSWR(["webhooks", type], ([_, key]) => fetchWebhook(key));

export const useWebhookMutation = (type: MessageType) =>
  useSWRMutation(type ? ["webhooks", type] : "webhooks", (_, { arg: dto }: { arg: UpdateWebhookDto }) =>
    updateWebhook(dto)
  );
