import { useGridParams } from "@/utils/useGridParams";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { prepareParams, useAuth } from "@/utils";
import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { TableWithPaginationAndSorting } from "@/components/common";
import { columns } from "./columns";
import { useEmailAlerts } from "@/api/email-alerts/hooks";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

export const EmailAlertsList = () => {
  const { filters, pagination, sort } = useGridParams();

  const history = useHistory();
  const handleEdit = useCallback(({ id }) => history.push(`/email-alerts/${id}`), [history]);

  const { data, isLoading } = useEmailAlerts(prepareParams(filters, pagination, sort));

  const handleCreate = () => {
    history.push(`/email-alerts/new`);
  };

  const { ability } = useAuth();

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Email Alerts</h1>}
        extra={
          ability.can(PermissionAction.Create, PermissionSubject.EmailAlert) ? (
            <Button type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
              New Email Alert
            </Button>
          ) : null
        }
      />
      <TableWithPaginationAndSorting
        rowKey="id"
        onRowClick={handleEdit}
        columns={columns}
        data={data}
        loading={isLoading}
      />
    </>
  );
};
