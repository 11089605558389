import type { SelectProps } from "antd";
import { Select } from "antd";
import { useOpenplayTenants } from "@/api/openplay-queries/hooks";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";

export const TenantPicker = ({ value, onChange }: Pick<SelectProps, "value" | "onChange">) => {
  const { data: tenants, isLoading } = useOpenplayTenants();
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Tenant"
      options={(tenants ?? []).map((tenant) => ({ value: tenant.code, label: tenant.name }))}
      suffixIcon={<Icon component={DropdownArrow} />}
      disabled={isLoading}
    />
  );
};
