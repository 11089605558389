import { useMemo } from "react";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { Form } from "antd";
import type { SalesforceObject } from "@/api/salesforce-objects/types";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";
import { VariableType } from "@/types/variables";
import { getFieldsFromSalesforceObject } from "@/utils/dynamic-fields";

const getEmailAlertDynamicFields = (obj: SalesforceObject): Variables => [
  {
    name: VariableType.General,
    label: "General",
    type: "object",
    children: [{ name: "current_date", label: "Current Date", type: "datetime" }],
  },
  {
    name: VariableType.Record,
    label: "Record",
    type: "object",
    children: getFieldsFromSalesforceObject(obj),
  },
];

export const useEmailAlertVariables = () => {
  const from = Form.useWatch(["sourceObject", "name"]);
  const { obj: salesforceObject } = useSalesforceObject(from);
  return useMemo(() => getEmailAlertDynamicFields(salesforceObject), [salesforceObject]);
};
