import type { Moment } from "moment";
import moment from "moment";
import { getUserTimezone } from "./timezones";
import type { Recurrency } from "@/api/types";
import { RecurrenceInterval } from "@/api/types";

export const TIME_FORMAT = "HH:mm:ss";

export type RecurrencyFields = Omit<Recurrency, "timeOfDay"> & { timeOfDay: Moment };

export const parseRecurrencyFields = ({
  cronExpression,
  daysOfMonth,
  daysOfWeek,
  interval,
  timeOfDay,
  timezone,
}: Partial<Recurrency> = {}): RecurrencyFields => ({
  cronExpression: cronExpression,
  interval: interval ?? RecurrenceInterval.Daily,
  timeOfDay: timeOfDay ? moment(timeOfDay, TIME_FORMAT) : null,
  daysOfWeek: daysOfWeek ?? [],
  daysOfMonth: daysOfMonth ?? [],
  timezone: timezone ?? getUserTimezone(),
});

export const prepareRecurrencyDto = ({
  cronExpression,
  daysOfMonth,
  daysOfWeek,
  interval,
  timeOfDay,
  timezone,
}: Partial<RecurrencyFields>): Recurrency => ({
  interval,
  timeOfDay: timeOfDay ? timeOfDay.format(TIME_FORMAT) : null,
  daysOfWeek: daysOfWeek ?? [],
  daysOfMonth: daysOfMonth ?? [],
  cronExpression: cronExpression ?? null,
  timezone,
});
