import { useAuth } from "@/utils";
import { CaretDownOutlined, CaretUpOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import "./Header.less";
import { useCallback, useState } from "react";
import { Layout, Popover } from "antd";
import { LinkWithConfirmation } from "@/utils/useNavigationConfirm";
import cn from "classnames";
import { IconButton } from "../../common/IconButton/IconButton";

const { Header: AntdHeader } = Layout;

export const Header = ({ className, isMenuCollapsed, onCollapse }) => {
  const [isPopupOpen, toggle] = useState(false);
  const { user, logout } = useAuth();

  const togglePopupOpen = useCallback(() => {
    toggle(!isPopupOpen);
  }, [toggle, isPopupOpen]);

  const MenuTriggerIcon = isMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined;

  if (!user) {
    return null;
  }

  return (
    <AntdHeader className={cn("header", className)}>
      <IconButton className="menu-trigger" onClick={onCollapse} icon={MenuTriggerIcon} />
      <div className="user-actions">
        <Popover
          placement="bottomRight"
          content={
            <div>
              <div className="user-links">
                <LinkWithConfirmation to="/profile" onClick={togglePopupOpen}>
                  Profile
                </LinkWithConfirmation>
              </div>
              <div className="user-links">
                <LinkWithConfirmation to="#" id="qsLogoutBtn" onClick={logout}>
                  Log Out
                </LinkWithConfirmation>
              </div>
            </div>
          }
          trigger="click"
          onOpenChange={togglePopupOpen}
          open={isPopupOpen}
          overlayClassName="nav-bar__popover"
        >
          <span className="user-name">{user.name}</span>
          {isPopupOpen ? (
            <CaretUpOutlined style={{ color: "white" }} />
          ) : (
            <CaretDownOutlined style={{ color: "white" }} />
          )}
        </Popover>
      </div>
    </AntdHeader>
  );
};
