import { useFieldListSourceObject } from "./hooks";
import type { SalesforceObject, SalesforceObjectField } from "@/api/salesforce-objects/types";
import type { Field } from "@/api/types";
import { getTimezoneDescription } from "@/utils/timezones";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { Alert, Typography } from "antd";
import { getEmptyArray } from "@/utils/empty";

const { Text } = Typography;

type SalesforceFieldWithParents = SalesforceObjectField & { parents: SalesforceObjectField[] };
type FieldTagProps = {
  object: SalesforceObject;
  field: Field;
};
export const FieldTag = ({ object, field }: FieldTagProps) => {
  const fieldInfo = getField(object, field.path);
  const originalLabel = fieldInfo
    ? [...fieldInfo.parents, fieldInfo].map((x) => x.label).join(" / ")
    : field.path.join(".");
  const customLabel = field.label?.trim();

  const extras = [];

  if (customLabel) {
    extras.push(customLabel);
  }

  if (field.type === "date" || field.type === "datetime") {
    if (field.format) {
      extras.push(field.format);
    }
    if (field.timezone) {
      extras.push(getTimezoneDescription(field.timezone));
    }
  }
  const label =
    extras.length > 0 ? (
      <span>
        {originalLabel} <Text type="secondary">({extras.join(" | ")})</Text>
      </span>
    ) : (
      originalLabel
    );
  return <BaseTag>{label}</BaseTag>;
};
export const getField = (
  obj: SalesforceObject,
  path: string[],
  parents: SalesforceObjectField[] = []
): SalesforceFieldWithParents => {
  if (!obj || path.length === 0) {
    return null;
  }
  if (path.length >= 1) {
    const found = obj.fields.find((field) => field.name === path[0]);
    if (!found) {
      return null;
    }
    return path.length === 1
      ? { ...found, parents }
      : getField(found.referenceTo, path.slice(1), [...parents, found]);
  }
};
export const ReadOnlyFields = ({ value }: { value?: Field[] }) => {
  const obj = useFieldListSourceObject();
  const { obj: salesforceObject, isLoading } = useSalesforceObject(obj);
  const fields = value?.filter((field) => !!field?.path) ?? getEmptyArray();
  if (isLoading) {
    return <Spinner height={50} />;
  }
  if (fields.length === 0) {
    return <Alert message="No fields added" />;
  }
  return (
    <div className="field-list-content__fields">
      {fields.map((field, index) => (
        <FieldTag key={index} field={field} object={salesforceObject} />
      ))}
    </div>
  );
};
