import { MessageType } from "@/api/webhooks/types";
import type { ReactNode } from "react";

const getExternalActionLabel = (service: string, action: string) => (
  <>
    <strong>{service}:</strong> {action}
  </>
);

const actionLabelLookup: Record<MessageType, ReactNode> = {
  [MessageType.PlannerDelivered]: "Run Release Export",
  [MessageType.PlannerLabelcampProductCreation]: getExternalActionLabel("Labelcamp", "Create Product"),
  [MessageType.PlannerShopifyProductCreation]: getExternalActionLabel("Shopify", "Create Product"),
  [MessageType.PlannerProjectActivation]: getExternalActionLabel("OpenPlay", "Create Project"),
  [MessageType.PlannerProjectUpdate]: getExternalActionLabel("OpenPlay", "Update Project"),
  [MessageType.PlannerProjectRoutingNotification]: getExternalActionLabel(
    "SharePoint",
    "Create Directory Structure"
  ),
  [MessageType.SharepointProjectLibrarySync]: getExternalActionLabel(
    "SharePoint",
    "Sync Directory Structure"
  ),
  [MessageType.ReleaseCreation]: getExternalActionLabel("OpenPlay", "Create Release"),
};

export const getSalesforceMessageTypeLabel = (type: MessageType) => actionLabelLookup[type];
