import { Alert, Col, Form, Input, Row, Select, TimePicker } from "antd";
import { toOptions } from "@/utils/toOptions";
import { capitalize } from "@/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "./Icons";
import { MultiSelect } from "./MultiSelect/MultiSelect";
import * as R from "ramda";
import { getCronDescription, getCronExpressionFromRecurrency, validateCronExpression } from "@/utils/cron";
import { requiredIfNotDraft } from "@/utils/validation";
import { TimezoneSelect } from "./TimezoneSelect/TimezoneSelect";
import { prepareRecurrencyDto } from "@/utils/recurrency";
import { Field } from "@/components/common/Form/Field";
import { useFieldPath } from "@/components/common/Form/hooks";
import { RecurrenceInterval } from "@/api/types";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

type Props = {
  name: string;
};

export const RecurrencyForm = ({ name }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const recurrency = Form.useWatch(getAbsolutePath(name)) ?? {};

  const cronExpression = getCronExpressionFromRecurrency(prepareRecurrencyDto(recurrency));

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Field name={[name, "interval"]} label="Interval" initialValue={RecurrenceInterval.Daily}>
          <Select
            options={toOptions(Object.values(RecurrenceInterval), capitalize)}
            suffixIcon={<Icon component={DropdownArrow} />}
          />
        </Field>
      </Col>
      {recurrency.interval === RecurrenceInterval.Weekly && (
        <Col span={12}>
          <Field
            name={[name, "daysOfWeek"]}
            dependencies={["status"]}
            label="Days of Week"
            rules={[requiredIfNotDraft]}
          >
            <MultiSelect
              options={daysOfWeek.map((day, index) => ({
                value: index,
                label: day,
              }))}
            />
          </Field>
        </Col>
      )}
      {recurrency.interval === RecurrenceInterval.Monthly && (
        <Col span={12}>
          <Field
            name={[name, "daysOfMonth"]}
            dependencies={["status"]}
            label="Days of Month"
            rules={[requiredIfNotDraft]}
          >
            <MultiSelect options={toOptions(R.range(1, 32))} />
          </Field>
        </Col>
      )}
      {recurrency.interval === RecurrenceInterval.Custom && (
        <Col span={12}>
          <Field
            name={[name, "cronExpression"]}
            dependencies={["status"]}
            label="Cron Expression"
            validateFirst
            rules={[
              requiredIfNotDraft,
              {
                validator: (_, value) => {
                  const validation = validateCronExpression(value);
                  if (validation.isError()) {
                    return Promise.reject("The value must be a valid cron expression");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input style={{ fontFamily: "Fira Code, monospace", fontSize: 16 }} />
          </Field>
        </Col>
      )}
      {recurrency.interval !== RecurrenceInterval.Custom && (
        <Col span={12}>
          <Field
            name={[name, "timeOfDay"]}
            dependencies={["status"]}
            label="Time of Day"
            rules={[requiredIfNotDraft]}
          >
            <TimePicker use12Hours format="hh:mm A" />
          </Field>
        </Col>
      )}
      <Col span={12}>
        <Field
          name={[name, "timezone"]}
          label="Timezone"
          dependencies={["status"]}
          rules={[requiredIfNotDraft]}
        >
          <TimezoneSelect placeholder="Select timezone" />
        </Field>
      </Col>
      {cronExpression && (
        <Col span={24}>
          <Alert message={getCronDescription(cronExpression)} type="info" />
        </Col>
      )}
    </Row>
  );
};
