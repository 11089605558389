export enum DeliveryType {
  AssetsAndData = "assets_and_data",
  DataOnly = "data_only",
}

export const labelLookup: Record<DeliveryType, string> = {
  [DeliveryType.AssetsAndData]: "Assets+Data",
  [DeliveryType.DataOnly]: "Data Only",
};

export const getDeliveryTypeLabel = (deliveryType: DeliveryType) => labelLookup[deliveryType];
