import { Button, Col, Form, Row, Select } from "antd";
import type { ReportOpenplayQuery, SalesforceQuery } from "@/api/reports/types";
import { ActionQueryType } from "@/api/reports/types";
import { Field } from "@/components/common/Form/Field";
import { QueryPicker } from "@/components/Reports/QueryPicker";
import { useFieldPath } from "@/components/common/Form/hooks";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import Icon from "@ant-design/icons";
import { DropdownArrow, Plus } from "@/components/common/Icons";
import { FieldToUpdate } from "@/components/Reports/Details/ActionsList/FieldToUpdate/FieldToUpdate";
import { notInTheList, OpenplayQueryPicker } from "@/components/Reports/Details/OpenplayQueryPicker";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker";
import { OpenplayQueryFieldPicker } from "@/components/OpenplayQueries/OpenplayQueryFieldPicker";
import { useOpenplayQuery } from "@/api/openplay-queries/hooks";
import { getEmptyArray } from "@/utils/empty";

const useSourceObject = () => {
  const { getAbsolutePath } = useFieldPath();
  const queries = Form.useWatch<SalesforceQuery[]>("queries");
  const queryType = Form.useWatch<ActionQueryType>(getAbsolutePath("queryType"));
  const objectToUpdate = Form.useWatch<string>(getAbsolutePath("objectToUpdate"));
  const queryId = Form.useWatch<string>(getAbsolutePath("queryId"));
  return queryType === ActionQueryType.Salesforce
    ? queries?.find((query) => query.id === queryId)?.from
    : objectToUpdate;
};

export const UpdateRecordsActionFields = () => {
  const form = Form.useFormInstance();
  const { getAbsolutePath } = useFieldPath();
  const sourceObject = useSourceObject();
  const queryType = Form.useWatch<ActionQueryType>(getAbsolutePath("queryType"));
  const queryId = Form.useWatch<string>(getAbsolutePath("queryId"));
  const reportOpenplayQueries = Form.useWatch<ReportOpenplayQuery[]>("openplayQueries") ?? getEmptyArray();
  const selectedOpenplayQueryId =
    queryType === ActionQueryType.Openplay
      ? reportOpenplayQueries.find((query) => query.id === queryId)?.openplayQuery?.id
      : null;

  const { data: openplayQuery } = useOpenplayQuery(selectedOpenplayQueryId);

  const handleQueryTypeChange = () => {
    form.setFieldValue(getAbsolutePath("queryId"), void 0);
  };

  const handleObjectChange = () => {
    form.setFieldValue(getAbsolutePath("matchField"), void 0);
  };

  return (
    <>
      <Col span={12}>
        <Field
          label="Query Type"
          name="queryType"
          rules={[{ required: true, message: "Please, select query type" }]}
        >
          <Select
            options={[
              { value: ActionQueryType.Salesforce, label: "Salesforce" },
              { value: ActionQueryType.Openplay, label: "OpenPlay" },
            ]}
            suffixIcon={<Icon component={DropdownArrow} />}
            onChange={handleQueryTypeChange}
          />
        </Field>
      </Col>
      {queryType === ActionQueryType.Salesforce && (
        <Col span={12}>
          <Field label="Query" name="queryId" rules={[{ required: true, message: "Please, select query" }]}>
            <QueryPicker />
          </Field>
        </Col>
      )}
      {queryType === ActionQueryType.Openplay && (
        <>
          <Col span={12}>
            <Field
              label="Query"
              name="queryId"
              rules={[{ required: true, message: "Please, select query" }, notInTheList]}
            >
              <OpenplayQueryPicker />
            </Field>
          </Col>
          <Col span={12}>
            <Field
              label="Object to Update"
              name="objectToUpdate"
              rules={[{ required: true, message: "Please, select object" }]}
            >
              <SalesforceObjectPicker onChange={handleObjectChange} />
            </Field>
          </Col>
          {sourceObject && openplayQuery && (
            <Col span={24}>
              <Form.Item label="Match By" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Field name="matchField" rules={[{ required: true, message: "Please, select field" }]}>
                      <SalesforceObjectPathPicker
                        includeFieldTypes={["string"]}
                        obj={sourceObject}
                        nesting={0}
                        placeholder="Object Field"
                      />
                    </Field>
                  </Col>
                  <Col span={12}>
                    <Field name="queryField" rules={[{ required: true, message: "Please, specify field" }]}>
                      <OpenplayQueryFieldPicker query={openplayQuery} placeholder="Query Result Field" />
                    </Field>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          )}
        </>
      )}
      {sourceObject && (
        <Col span={24}>
          <Form.Item label="Fields to Update" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <FieldList name="fields">
              {(fields, { add, remove }) => (
                <div className="flex-col">
                  {fields.map((field) => (
                    <FieldListItem key={field.key} name={field.name}>
                      <FieldToUpdate sourceObject={sourceObject} onRemove={() => remove(field.name)} />
                    </FieldListItem>
                  ))}
                  <Button
                    size="small"
                    onClick={() => add()}
                    icon={<Icon component={Plus} />}
                    style={{ alignSelf: "flex-start" }}
                  >
                    Add Field
                  </Button>
                </div>
              )}
            </FieldList>
          </Form.Item>
        </Col>
      )}
    </>
  );
};
