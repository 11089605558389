import { SpreadsheetForm } from "@/components/Spreadsheets/SpreadsheetForm";
import { useEmailAlertVariables } from "@/components/EmailAlerts/Details/hooks";

type Props = {
  readOnly: boolean;
};

export const EmailAlertSpreadsheet = ({ readOnly }: Props) => {
  const variables = useEmailAlertVariables();
  return <SpreadsheetForm readOnly={readOnly} entity="email-alert" variables={variables} />;
};
