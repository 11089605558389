import React from "react";
import { useDownload } from "@/utils/useDownload";
import { IconButton } from "../IconButton/IconButton";
import { Import } from "../Icons";
import { downloadDelivery } from "@/api/deliveries";

const DownloadDeliveryButton = ({ outputFilename }) => {
  const { start: downloadRelease } = useDownload(downloadDelivery, {
    message: "The download process is started",
  });
  const handleDownload = () => {
    downloadRelease(outputFilename);
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={handleDownload} icon={Import} disabled={!outputFilename} />
    </div>
  );
};

export default DownloadDeliveryButton;
