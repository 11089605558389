import type { FormInstance } from "antd";
import { Cascader, Col, Form, Input, InputNumber, Row, Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Aggregation, VariableType } from "@/types/variables";
import { indexBy, values } from "ramda";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";
import { sortCascaderSearchResults } from "@/utils/autocomplete";
import type { Field } from "@/utils/dynamic-fields/types";
import { toOptions } from "@/utils/toOptions";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { TimezoneSelect } from "@/components/common/TimezoneSelect/TimezoneSelect";

export type FormValues = {
  type: VariableType;
  name?: string[];
  aggregation?: Aggregation;
  queryId?: string;
  field?: string[];
  offset?: {
    value: number;
    unit: string;
  };
  format?: string;
  timezone?: string;
};

type Props = {
  variables: Variables;
  handleFinish: (values: FormValues) => void;
  form?: FormInstance<FormValues>;
};

const fieldLayout = { labelCol: { span: 24 }, wrapperCol: { span: 24 } };

type VariableByType<Type extends VariableType> = Extract<Variables[number], { name: Type }>;

type VariablesByType = Partial<{ [Key in VariableType]: VariableByType<Key> }>;

const indexByName = (variables: Variables) =>
  indexBy((variable) => variable.name, variables) as VariablesByType;

const findVariableByPath = (variables: Field[], path: string[]) => {
  if (!path || path.length === 0) {
    return null;
  }
  const found = variables.find((x) => x.name === path.at(0));
  if (!found) {
    return null;
  }
  if (path.length === 1) {
    return found;
  }
  if (found.type !== "object") {
    return null;
  }
  return findVariableByPath(found.children, path.slice(1));
};

export const VariableForm = ({ variables, handleFinish, form: outsideForm }: Props) => {
  const [form] = Form.useForm<FormValues>(outsideForm);

  const variablesByName = indexByName(variables);
  const availableGroups = values(variablesByName);

  const type = Form.useWatch<VariableType>("type", form);
  const name = Form.useWatch<string>("name", form);

  const handleTypeChange = () => {
    form.setFieldValue("name", undefined);
    form.setFieldValue("query", undefined);
  };

  const selectedVariable = type && name ? findVariableByPath(variables, [type, ...name]) : null;
  const isDateOnlyVariableSelected = selectedVariable?.type === "date";
  const isDatetimeVariableSelected = selectedVariable?.type === "datetime";

  const isDateVariableSelected = isDateOnlyVariableSelected || isDatetimeVariableSelected;

  return (
    <Form form={form} onFinish={handleFinish}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="type"
            label="Type"
            {...fieldLayout}
            rules={[{ required: true, message: "Please, select the variable type" }]}
            initialValue={VariableType.General}
          >
            <Select
              options={availableGroups.map(({ name, label }) => ({ value: name, label }))}
              onChange={handleTypeChange}
              suffixIcon={<Icon component={DropdownArrow} />}
              placeholder="Select the type"
            />
          </Form.Item>
        </Col>
        {type && variablesByName[type] && (type === VariableType.General || type === VariableType.Variation) && (
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please, select the variable" }]}
              label="Variable"
              {...fieldLayout}
            >
              <Cascader
                placeholder="Select variable"
                options={variablesByName[type].children}
                suffixIcon={<Icon component={DropdownArrow} />}
                showSearch={{ limit: 200, sort: sortCascaderSearchResults }}
                placement="bottomLeft"
                fieldNames={{ label: "label", value: "name" }}
              />
            </Form.Item>
          </Col>
        )}
        {type && variablesByName[type] && (type === VariableType.Record || type === VariableType.LoopItem) && (
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please, select the record field" }]}
              label="Field"
              {...fieldLayout}
            >
              <Cascader
                placeholder="Select field"
                options={variablesByName[type].children}
                suffixIcon={<Icon component={DropdownArrow} />}
                showSearch={{ limit: 200, sort: sortCascaderSearchResults }}
                placement="bottomLeft"
                fieldNames={{ label: "label", value: "name" }}
              />
            </Form.Item>
          </Col>
        )}
        {isDateVariableSelected && (
          <Col span={12}>
            <Form.Item
              label="Date Format"
              name="format"
              rules={[getFieldMaxLengthRule("Date format", 50)]}
              {...fieldLayout}
            >
              <Input placeholder="Date Format (e.g. MM/DD/YYYY)" />
            </Form.Item>
          </Col>
        )}
        {isDatetimeVariableSelected && (
          <Col span={12}>
            <Form.Item label="Timezone" name="timezone" {...fieldLayout}>
              <TimezoneSelect placeholder="Timezone" allowClear />
            </Form.Item>
          </Col>
        )}
        {isDateVariableSelected && <DateOffsetFields />}
        {type && variablesByName[type] && type === VariableType.Query && (
          <QueryVariableFields variable={variablesByName[type]} />
        )}
      </Row>
    </Form>
  );
};

type QueryVariableFieldsProps = {
  variable: VariableByType<VariableType.Query>;
};

export const QueryVariableFields = ({ variable }: QueryVariableFieldsProps) => {
  const form = Form.useFormInstance<FormValues>();

  const queryId = Form.useWatch<string>("queryId");
  const aggregation = Form.useWatch<Aggregation>("aggregation");

  if (variable.children.length === 0) {
    return null;
  }

  const handleQueryChange = () => {
    form.setFieldValue("aggregation", Aggregation.Total);
    form.setFieldValue("field", undefined);
  };

  const variablesByQuery = indexBy((x) => x.name, variable.children);
  const availableQueries = values(variablesByQuery);

  const selectedQuery = queryId ? variablesByQuery[queryId] : null;
  const selectedAggregation = selectedQuery?.children.find((x) => x.name === aggregation);

  return (
    <>
      <Col span={12}>
        <Form.Item
          name="queryId"
          rules={[{ required: true, message: "Please, select the query" }]}
          label="Query"
          {...fieldLayout}
          initialValue={availableQueries[0].name}
        >
          <Select
            options={availableQueries.map((variable) => ({
              value: variable.name,
              label: variable.label,
            }))}
            suffixIcon={<Icon component={DropdownArrow} />}
            showSearch
            dropdownMatchSelectWidth={false}
            optionFilterProp="label"
            onChange={handleQueryChange}
            placeholder="Select query"
          />
        </Form.Item>
      </Col>
      {selectedQuery && (
        <Col span={12}>
          <Form.Item
            name="aggregation"
            rules={[{ required: true, message: "Please, select the aggregation" }]}
            label="Aggregation"
            {...fieldLayout}
            initialValue={Aggregation.Total}
          >
            <Select
              options={selectedQuery.children.map((aggregation) => ({
                value: aggregation.name,
                label: aggregation.label,
              }))}
              suffixIcon={<Icon component={DropdownArrow} />}
              placeholder="Select aggregation"
            />
          </Form.Item>
        </Col>
      )}
      {selectedQuery && selectedAggregation && selectedAggregation.name !== Aggregation.Total && (
        <Col span={12}>
          <Form.Item
            name="field"
            rules={[{ required: true, message: "Please, select the aggregation field" }]}
            label="Field"
            {...fieldLayout}
          >
            <Cascader
              placeholder="Field"
              options={selectedAggregation.children}
              suffixIcon={<Icon component={DropdownArrow} />}
              showSearch={{ limit: 200, sort: sortCascaderSearchResults }}
              placement="bottomLeft"
              fieldNames={{ label: "label", value: "name" }}
            />
          </Form.Item>
        </Col>
      )}
    </>
  );
};

const DateOffsetFields = () => {
  return (
    <Col span={12}>
      <Form.Item label="Date Offset" {...fieldLayout}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item name={["offset", "value"]} initialValue={0}>
              <InputNumber step={1} precision={0} placeholder="Value" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["offset", "unit"]} initialValue="day">
              <Select
                options={toOptions(["day", "week", "month", "year"], (value) => value + "s")}
                placeholder="Unit"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </Col>
  );
};
