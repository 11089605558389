import { Color } from "@/utils";

export const UploadStatus = {
  Queued: "queued",
  Started: "started",
  Failed: "failed",
  Succeeded: "succeeded",
};

const colorLookup = {
  [UploadStatus.Queued]: Color.Inactive,
  [UploadStatus.Started]: Color.Accent,
  [UploadStatus.Failed]: Color.Failed,
  [UploadStatus.Succeeded]: Color.Success,
};

export const getUploadStatusColor = (status) => colorLookup[status];

const labelLookup = {
  [UploadStatus.Queued]: "Queued",
  [UploadStatus.Started]: "Started",
  [UploadStatus.Failed]: "Failed",
  [UploadStatus.Succeeded]: "Succeeded",
};

export const getUploadStatusLabel = (status) => labelLookup[status];
