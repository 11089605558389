import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import type { SelectProps } from "antd";
import { Form, Select } from "antd";
import { getEmptyArray } from "@/utils/empty";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { ReportOpenplayQuery } from "@/api/reports/types";
import type { RuleRender } from "rc-field-form/lib/interface";

type Props = Pick<SelectProps, "value" | "onChange" | "disabled">;

export const notInTheList: RuleRender = (form) => ({
  validator: (_, value) => {
    const availableQueries: ReportOpenplayQuery[] =
      form.getFieldValue("openplayQueries")?.filter((query) => !!query.openplayQuery?.id) ?? [];
    const isIn = availableQueries.some((query) => query.id === value);
    if (isIn) {
      return Promise.resolve();
    }
    return Promise.reject("Selected query is not in the list of report queries");
  },
});

export const OpenplayQueryPicker = ({ value, onChange, disabled }: Props) => {
  const {
    data: { data: openplayQueries },
    isLoading,
  } = useOpenplayQueries();

  const options = (Form.useWatch<ReportOpenplayQuery[]>("openplayQueries") ?? getEmptyArray())
    .filter((query) => !!query.openplayQuery?.id)
    .map((query) => ({
      value: query.id,
      label: query.name?.trim() || openplayQueries.find((opq) => opq.id === query.openplayQuery.id)?.name,
    }));

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder="Select Query"
      loading={isLoading}
      allowClear={!disabled}
      disabled={isLoading || disabled}
      options={options}
      suffixIcon={<Icon component={DropdownArrow} />}
      notFoundContent="No queries available"
    />
  );
};
