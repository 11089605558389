import type { ComponentProps, PropsWithChildren } from "react";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { ContentBlock } from "./ContentBlock";
import { Dropdown, Form, Space } from "antd";
import { BlockType } from "@/api/types";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";
import "./ContentBuilder.less";
import { useLoopContext } from "@/components/common/ContentBuilder/LoopBlock/context";

export type ContentProps = {
  maxNesting: number;
} & Pick<ComponentProps<typeof FieldList>, "name" | "rules">;

const Button = ({ children }: PropsWithChildren) => (
  <Space>
    <Icon component={Plus} />
    <span>{children}</span>
  </Space>
);

export const ContentBuilder = ({ name, rules, maxNesting }: ContentProps) => {
  const { fieldsForConditions, sourceObject } = useEmailMessageContext();
  const { isInsideOfLoop } = useLoopContext();
  return (
    <FieldList name={name} rules={rules}>
      {(fields, { add, remove, move }, { errors }) => (
        <div className="content-builder">
          <div className="content-builder__blocks">
            {fields.map((field) => (
              <FieldListItem key={field.key} name={field.name}>
                <ContentBlock onRemove={() => remove(field.name)} maxNesting={maxNesting - 1} onMove={move} />
              </FieldListItem>
            ))}
          </div>
          <Dropdown
            menu={{
              onClick: (item) => {
                switch (item.key) {
                  case BlockType.Text:
                    return add({ type: BlockType.Text, text: "" });
                  case BlockType.Condition:
                    return add({ type: BlockType.Condition, ifs: [], else: null });
                  case BlockType.FieldList:
                    return add({ type: BlockType.FieldList, fields: [] });
                  case BlockType.Loop:
                    return add({ type: BlockType.Loop, content: [] });
                  default:
                    return;
                }
              },
              items: [
                {
                  key: BlockType.Text,
                  label: <Button>Add Text Block</Button>,
                },
                maxNesting > 0 &&
                  !!fieldsForConditions && {
                    key: BlockType.Condition,
                    label: <Button>Add Condition Block</Button>,
                  },
                !!sourceObject && {
                  key: BlockType.FieldList,
                  label: <Button>Add Field List</Button>,
                },
                !isInsideOfLoop &&
                  !!sourceObject && {
                    key: BlockType.Loop,
                    label: <Button>Add Relation Loop</Button>,
                  },
              ].filter(Boolean),
            }}
            placement="top"
            arrow
            overlayStyle={{ minWidth: 200 }}
          >
            <div>
              <FullWidthButton label="Add Content" icon={Plus} small />
            </div>
          </Dropdown>
          {errors.length > 0 && <Form.ErrorList errors={errors} />}
        </div>
      )}
    </FieldList>
  );
};
