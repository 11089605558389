import useSWR, { useSWRConfig } from "swr";
import {
  fetchAvailableFlowEvents,
  fetchFlow,
  fetchFlows,
  fetchScript,
  fetchScripts,
  pullCustomEvent,
  saveFlow,
  updateScript,
} from "@/api/flows/index";
import useSWRMutation from "swr/mutation";
import type {
  CreateFlowDto,
  ImportPlatformEventParams,
  ScriptDescriptionUpdateDto,
  ScriptFilters,
  UpdateFlowDto,
} from "@/api/flows/types";
import type { Pagination } from "@/api/types";

export const useFlowEvents = () =>
  useSWR("flow-events", fetchAvailableFlowEvents, {
    fallbackData: [],
  });

export const useFlowEventImport = () => {
  const { mutate } = useSWRConfig();
  return useSWRMutation("flow-events", async (_, { arg: params }: { arg: ImportPlatformEventParams }) => {
    await pullCustomEvent(params);
    await mutate(["salesforce-object", params.name]);
  });
};

export const useScripts = (filters: ScriptFilters = {}) =>
  useSWR(["scripts", filters], ([_, filters]) => fetchScripts(filters), {
    fallbackData: [],
  });

export const useScript = (key: string) => useSWR(["scripts", key], ([_, key]) => fetchScript(key));

export const useScriptMutation = (key: string) =>
  useSWRMutation(key ? ["scripts", key] : "scripts", (_, { arg: dto }: { arg: ScriptDescriptionUpdateDto }) =>
    updateScript(dto)
  );

export const useFlows = (filters: Pagination) =>
  useSWR(["flows", filters], ([_, filters]) => fetchFlows(filters), {
    fallbackData: { data: [] },
  });

export const useFlow = (id: string) => useSWR(["flows", id], ([_, key]) => fetchFlow(key));

export const useFlowMutation = (id: string) =>
  useSWRMutation(id ? ["flows", id] : "flows", (_, { arg: dto }: { arg: CreateFlowDto | UpdateFlowDto }) =>
    saveFlow(dto)
  );
