export const ddex_4_1 = {
  MessageHeader: {
    MessageThreadId: "string",
    MessageId: "string",
    MessageFileName: "string",
    MessageSender: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    SentOnBehalfOf: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    MessageRecipient: {
      PartyId: "string",
      PartyName: {
        FullName: "string",
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: "string",
        NamesBeforeKeyName: "string",
        KeyName: "string",
        NamesAfterKeyName: "string",
        AbbreviatedName: "string",
      },
      TradingName: "string",
    },
    MessageCreatedDateTime: "2013-11-23T16:44:07",
    MessageAuditTrail: {
      MessageAuditTrailEvent: {
        MessagingPartyDescriptor: {
          PartyId: "string",
          PartyName: {
            FullName: "string",
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: "string",
            NamesBeforeKeyName: "string",
            KeyName: "string",
            NamesAfterKeyName: "string",
            AbbreviatedName: "string",
          },
          TradingName: "string",
        },
        DateTime: "2014-06-09T18:15:04+03:00",
      },
    },
    MessageControlType: "TestMessage",
  },
  PartyList: [
    {
      Party: {
        PartyReference: "string",
        PartyId: {
          ISNI: "string",
          DPID: "string",
          IpiNameNumber: "string",
          IPN: "string",
          CisacSocietyId: "string",
          ProprietaryId: { _Namespace: "string", __text: "string" },
        },
        PartyName: {
          FullName: { _LanguageAndScriptCode: "string", __text: "string" },
          FullNameAsciiTranscribed: "string",
          FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
          NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
          KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
          NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
          AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
          _LanguageAndScriptCode: "string",
          _IsNickname: "false",
          _IsStageName: "true",
          _IsLegalName: "true",
          _ApplicableTerritoryCode: "LT",
        },
        Affiliation: {
          CompanyName: "string",
          PartyAffiliateReference: "string",
          Type: "RecordCompany",
          TerritoryCode: { _IdentifierType: "TIS", __text: "ZM" },
          ExcludedTerritoryCode: { _IdentifierType: "TIS", __text: "756" },
          ValidityPeriod: {
            StartDate: {
              _IsApproximate: "false",
              _IsBefore: "true",
              _IsAfter: "true",
              _ApplicableTerritoryCode: "EG",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            EndDate: {
              _IsApproximate: "true",
              _IsBefore: "true",
              _IsAfter: "true",
              _ApplicableTerritoryCode: "626",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RightsType: { _Namespace: "string", _UserDefinedValue: "string", __text: "UserDefined" },
          PercentageOfRightsAssignment: "1000.00",
        },
        RelatedParty: {
          PartyRelatedPartyReference: "string",
          PartyRelationshipType: { _Namespace: "string", _UserDefinedValue: "string", __text: "IsParentOf" },
        },
        ArtistProfilePage: "string",
      },
    },
  ],
  CueSheetList: [
    {
      CueSheet: {
        CueSheetId: { _Namespace: "string", __text: "string" },
        CueSheetReference: "string",
        CueSheetType: { _Namespace: "string", _UserDefinedValue: "string", __text: "StandardCueSheet" },
        Cue: {
          CueUseType: { _Namespace: "string", _UserDefinedValue: "string", __text: "Bumper" },
          CueThemeType: { _Namespace: "string", _UserDefinedValue: "string", __text: "MainTheme" },
          CueVocalType: { _Namespace: "string", _UserDefinedValue: "string", __text: "UserDefined" },
          CueVisualPerceptionType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Background",
          },
          CueOrigin: { _Namespace: "string", _UserDefinedValue: "string", __text: "PreexistingMusic" },
          ResourceId: {
            ISRC: "string",
            ISMN: "string",
            ISAN: "string",
            VISAN: "string",
            ISBN: "string",
            ISSN: "string",
            SICI: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
            _IsReplaced: "true",
          },
          WorkId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: { _Namespace: "string", __text: "string" },
            _IsReplaced: "false",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "630",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "222",
            _IsDefault: "false",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "PH",
            _TitleType: "GroupingTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "ProductionSecretary" },
            InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "Tumbi" },
            HasMadeFeaturedContribution: "false",
            HasMadeContractedContribution: "true",
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "20",
              _IsDefault: "true",
            },
            _SequenceNumber: "100",
          },
          IsDance: "true",
          HasMusicalContent: "false",
          PLine: {
            Year: "2009",
            PLineCompany: "string",
            PLineText: "string",
            _LanguageAndScriptCode: "string",
            _PLineType: "RemasteringPLine",
          },
          CLine: {
            Year: "2000",
            CLineCompany: "string",
            CLineText: "string",
            _LanguageAndScriptCode: "string",
          },
          StartTime: "P3M7DT14H4M46S",
          Duration: "P1Y4M6DT7H16M38S",
          EndTime: "P1Y2M2DT18H5M8S",
        },
      },
    },
  ],
  ResourceList: [
    {
      SoundRecording: [
        {
          ResourceReference: "string",
          Type: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "MusicalWorkReadalongSoundRecording",
          },
          ResourceId: {
            ISRC: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
            _IsReplaced: "false",
          },
          WorkId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: { _Namespace: "string", __text: "string" },
            _IsReplaced: "true",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "72",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "TK",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "32",
            _TitleType: "GroupingTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "EditedVersion" },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "PL",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "MainArtist" },
            ArtisticRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "Causeur" },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          Contributor: [
            {
              ContributorPartyReference: "string",
              Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "CastingDirector" },
              InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "Rolmo" },
              HasMadeFeaturedContribution: "true",
              HasMadeContractedContribution: "true",
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "454",
                _IsDefault: "false",
              },
              _SequenceNumber: "100",
            },
          ],
          Character: {
            CharacterPartyReference: "string",
            Performer: {
              ContributorPartyReference: "string",
              Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "Reporter" },
              InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "HohnerGuitaret" },
              HasMadeFeaturedContribution: "false",
              HasMadeContractedContribution: "true",
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "DE",
                _IsDefault: "true",
              },
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            PartyId: {
              ISNI: "string",
              DPID: "string",
              IpiNameNumber: "string",
              IPN: "string",
              CisacSocietyId: "string",
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            PartyName: {
              FullName: { _LanguageAndScriptCode: "string", __text: "string" },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RightsController",
            RightShareUnknown: "true",
            RightSharePercentage: { _HasMaxValueOfOne: "false", __text: "1000.00" },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "PrivateCopy",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "TW",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _ApplicableTerritoryCode: "598",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _TerritoryCode: "IE",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2010-01-27T07:39:05",
                },
                EndDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "120",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2016-10-25T06:28:36+03:00",
                },
              },
              TerritoryOfRightsDelegation: { _IdentifierType: "ISO", __text: "504" },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControllerRole: "Unknown",
            RightsControllerType: "ExclusiveLicensee",
            RightShareUnknown: "true",
            RightSharePercentage: "1000.00",
            Territory: { _IdentifierType: "TIS", __text: "2108" },
            StartDate: "string",
            EndDate: "string",
          },
          PLine: {
            Year: "2011+02:00",
            PLineCompany: "string",
            PLineText: "string",
            _ApplicableTerritoryCode: "478",
            _IsDefault: "true",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "706",
            _IsDefault: "false",
            __text: "string",
          },
          Duration: "P11M4DT18H27M8S",
          CreationDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "2113",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          MasteredDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "XK",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          RemasteredDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "UA",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: { _ApplicableTerritoryCode: "890", _IsDefault: "false", __text: "string" },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "320",
            _UserDefinedValue: "string",
            _IsDefault: "false",
            __text: "UserDefined",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "HasArtistFromSameEnsemble",
            },
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "AN",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "HR",
              _IsDefault: "false",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "FK",
              _TitleType: "GroupingTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "true",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "230",
              _IsDefault: "true",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "false",
              _LabelType: "UserDefined",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "268",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "50",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "862",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "IsCoverOf",
            ResourceRelatedResourceReference: "string",
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            Timing: { StartPoint: "P9M3DT9H36M10S", DurationUsed: "P8M4DT34M55S" },
          },
          CompositeMusicalWorkType: "Medley",
          IsInstrumental: "false",
          ContainsHiddenContent: "true",
          IsRemastered: "false",
          IsHiResMusic: "false",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "KW",
            _IsDefault: "true",
          },
          LanguageOfPerformance: "xh",
          AudioChannelConfiguration: "Mono",
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            AudioCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "MP2",
            },
            BitRate: { _UnitOfMeasure: "bps", __text: "1000.00" },
            OriginalBitRate: { _UnitOfMeasure: "Gbps", __text: "1000.00" },
            NumberOfChannels: "100",
            SamplingRate: { _UnitOfMeasure: "kHz", __text: "1000.00" },
            OriginalSamplingRate: { _UnitOfMeasure: "kHz", __text: "1000.00" },
            BitsPerSample: "100",
            Duration: "P1Y3MT6H9M41S",
            BitDepth: "100",
            IsPreview: "true",
            PreviewDetails: {
              StartPoint: "1000.00",
              EndPoint: "1000.00",
              Duration: "P1Y4M8DT19H27M46S",
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Instructive",
            },
            File: {
              URI: "string",
              HashSum: {
                Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "MD5" },
                Version: "string",
                Parameter: "string",
                DataType: "Binary64",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "MD4" },
                  Version: "string",
                  Parameter: "string",
                  DataType: "HexBinary",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "Binary64",
              FingerprintValue: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "398",
            _IsDefault: "false",
          },
          Raga: { _ApplicableTerritoryCode: "AO", _IsDefault: "false", __text: "string" },
          Tala: { _ApplicableTerritoryCode: "52", _IsDefault: "true", __text: "string" },
          Deity: { _ApplicableTerritoryCode: "64", _IsDefault: "true", __text: "string" },
          AudioChapterReference: "string",
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "true",
        },
      ],
      Image: [
        {
          ResourceReference: "string",
          Type: { _Namespace: "string", _UserDefinedValue: "string", __text: "Poster" },
          ResourceId: { ProprietaryId: { _Namespace: "string", __text: "string" }, _IsReplaced: "true" },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "MR",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "PH",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "SN",
            _TitleType: "UserDefined",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "Runner" },
            InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "SleighBells" },
            HasMadeFeaturedContribution: "true",
            HasMadeContractedContribution: "true",
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "524",
              _IsDefault: "false",
            },
            _SequenceNumber: "100",
          },
          CLine: {
            Year: "2014",
            CLineCompany: "string",
            CLineText: "string",
            _ApplicableTerritoryCode: "KR",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "TN",
            _UserDefinedValue: "string",
            _IsDefault: "false",
            __text: "NotExplicit",
          },
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            File: {
              URI: "string",
              HashSum: {
                Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "MDC2" },
                HashSumValue: "string",
                Version: "string",
                Parameter: "string",
                DataType: "HexBinary",
              },
              FileSize: "1000.00",
            },
            ImageCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "GIF",
            },
            ImageHeight: { _UnitOfMeasure: "Inch", __text: "1000.00" },
            ImageWidth: { _UnitOfMeasure: "cm", __text: "1000.00" },
            AspectRatio: { _AspectRatioType: "PAR", __text: "1000.00" },
            ColorDepth: "100",
            ImageResolution: "100",
            BitDepth: "100",
            IsPreview: "false",
            PreviewDetails: {
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Instructive",
            },
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA-256" },
                  Version: "string",
                  Parameter: "string",
                  DataType: "Binary64",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "HexBinary",
              FingerprintValue: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2125",
            _IsDefault: "true",
          },
          VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "DemoVersion" },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "YE",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "FeaturedArtist",
            },
            ArtisticRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "TapeOperator" },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            PartyId: {
              ISNI: "string",
              DPID: "string",
              IpiNameNumber: "string",
              IPN: "string",
              CisacSocietyId: "string",
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            PartyName: {
              FullName: { _LanguageAndScriptCode: "string", __text: "string" },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RoyaltyAdministrator",
            RightShareUnknown: "true",
            RightSharePercentage: { _HasMaxValueOfOne: "false", __text: "1000.00" },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Print",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "2119",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "BJ",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "KI",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2013-04-06T19:52:20",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "690",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2008-06-15T09:48:24",
                },
              },
              TerritoryOfRightsDelegation: { _IdentifierType: "TIS", __text: "226" },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControllerRole: "Unknown",
            RightsControllerType: "OriginalOwner",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: { _IdentifierType: "ISO", __text: "BE" },
            StartDate: "string",
            EndDate: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "AN",
            _IsDefault: "true",
            __text: "string",
          },
          CreationDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "616",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
            _ApplicableTerritoryCode: "IO",
            _IsDefault: "false",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "288",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "174",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "GI",
              _TitleType: "AlternativeTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "false",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "2109",
              _IsDefault: "true",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "false",
              _LabelType: "UserDefined",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "CC",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "false",
              _ApplicableTerritoryCode: "PY",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "RO",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "IsCoveredBy",
            ResourceRelatedResourceReference: "string",
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            Timing: { StartPoint: "P1Y4M4DT11H55M24S", DurationUsed: "P10M9DT3H28M29S" },
          },
          ContainsHiddenContent: "false",
          Description: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "203",
            _IsDefault: "true",
            __text: "string",
          },
          Keywords: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "LR",
            _IsDefault: "false",
            __text: "string",
          },
          Synopsis: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "662",
            _IsDefault: "true",
            _IsShortSynopsis: "true",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "true",
        },
      ],
      Video: [
        {
          ResourceReference: "string",
          Type: { _Namespace: "string", _UserDefinedValue: "string", __text: "UserDefined" },
          ResourceId: {
            ISRC: "string",
            ISAN: "string",
            VISAN: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
            EIDR: "string",
            _IsReplaced: "false",
          },
          WorkId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: { _Namespace: "string", __text: "string" },
            _IsReplaced: "false",
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "266",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "MK",
            _IsDefault: "false",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "100",
            _TitleType: "OriginalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "CleanVersion" },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "SY",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "FeaturedArtist",
            },
            ArtisticRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "BackgroundVocalist" },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "MasteringEngineer" },
            InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "SynthBass" },
            HasMadeFeaturedContribution: "false",
            HasMadeContractedContribution: "false",
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "807",
              _IsDefault: "true",
            },
            _SequenceNumber: "100",
          },
          Character: {
            CharacterPartyReference: "string",
            Performer: {
              ContributorPartyReference: "string",
              Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "ProjectEngineer" },
              InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "BanjoGuitar" },
              HasMadeFeaturedContribution: "false",
              HasMadeContractedContribution: "true",
              DisplayCredits: {
                DisplayCreditText: "string",
                DisplayCreditParty: "string",
                NameUsedInDisplayCredit: "string",
                _LanguageAndScriptCode: "string",
                _ApplicableTerritoryCode: "BB",
                _IsDefault: "false",
              },
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            PartyId: {
              ISNI: "string",
              DPID: "string",
              IpiNameNumber: "string",
              IPN: "string",
              CisacSocietyId: "string",
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            PartyName: {
              FullName: { _LanguageAndScriptCode: "string", __text: "string" },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RightsController",
            RightShareUnknown: "false",
            RightSharePercentage: { _HasMaxValueOfOne: "false", __text: "1000.00" },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Webcast",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _ApplicableTerritoryCode: "2118",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _ApplicableTerritoryCode: "148",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "TN",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2011-09-16T22:36:10+03:00",
                },
                EndDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _TerritoryCode: "528",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2002-02-11T23:11:43",
                },
              },
              TerritoryOfRightsDelegation: { _IdentifierType: "ISO", __text: "694" },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControllerRole: "AdministratingRecordCompany",
            RightsControllerType: "ExclusiveLicensee",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: { _IdentifierType: "TIS", __text: "710" },
            StartDate: "string",
            EndDate: "string",
          },
          PLine: {
            Year: "2001+03:00",
            PLineCompany: "string",
            PLineText: "string",
            _ApplicableTerritoryCode: "CR",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          CLine: {
            Year: "2016+03:00",
            CLineCompany: "string",
            CLineText: "string",
            _ApplicableTerritoryCode: "586",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "417",
            _IsDefault: "false",
            __text: "string",
          },
          Duration: "P8M6DT13H26M4S",
          CreationDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "512",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          MasteredDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "GM",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          RemasteredDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "268",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
            _ApplicableTerritoryCode: "2114",
            _IsDefault: "true",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "890",
            _UserDefinedValue: "string",
            _IsDefault: "true",
            __text: "NotExplicit",
          },
          AvRating: {
            Rating: "string",
            Agency: { _Namespace: "string", _UserDefinedValue: "string", __text: "RDCQ" },
            Reason: "Crime",
            _ApplicableTerritoryCode: "JO",
            _IsDefault: "true",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "IsParentRelease",
            },
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "2131",
              _IsDefault: "false",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "BM",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Location",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "SY",
              _TitleType: "FormalTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "true",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "IR",
              _IsDefault: "false",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "false",
              _LabelType: "DisplayLabel",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "20",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "104",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "false",
              _ApplicableTerritoryCode: "FXFR",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "HasContentFrom",
            ResourceRelatedResourceReference: "string",
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            Timing: { StartPoint: "P3M3DT21H28M47S", DurationUsed: "P9M8DT8H41M59S" },
          },
          CompositeMusicalWorkType: "Potpourri",
          VideoCueSheetReference: "string",
          ReasonForCueSheetAbsence: { _LanguageAndScriptCode: "string", __text: "string" },
          IsInstrumental: "false",
          ContainsHiddenContent: "false",
          IsRemastered: "true",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "AF",
            _IsDefault: "true",
          },
          LanguageOfPerformance: "sco",
          LanguageOfDubbing: "ewe",
          SubTitleLanguage: "tut",
          ResourceContainedResourceReferenceList: [
            {
              ResourceContainedResourceReference: {
                ResourceContainedResourceReference: "string",
                DurationUsed: "P9MT17H35M10S",
                StartPoint: "1000.00",
                Purpose: { _Namespace: "string", _UserDefinedValue: "string", __text: "Extract" },
              },
            },
          ],
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            OverallBitRate: { _UnitOfMeasure: "Gbps", __text: "1000.00" },
            ContainerFormat: { _Namespace: "string", _UserDefinedValue: "string", __text: "AIFF" },
            VideoCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "WMV",
            },
            VideoBitRate: { _UnitOfMeasure: "Gbps", __text: "1000.00" },
            FrameRate: { _UnitOfMeasure: "Hz(non-interlaced)", __text: "1000.00" },
            ImageHeight: { _UnitOfMeasure: "Pixel", __text: "1000.00" },
            ImageWidth: { _UnitOfMeasure: "Pixel", __text: "1000.00" },
            AspectRatio: { _AspectRatioType: "DAR", __text: "1000.00" },
            CoreArea: { TopLeftCorner: "string", BottomRightCorner: "string" },
            ColorDepth: "100",
            VideoDefinitionType: "StandardDefinition",
            AudioCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "WMA",
            },
            AudioBitRate: { _UnitOfMeasure: "Mbps", __text: "1000.00" },
            NumberOfAudioChannels: "100",
            AudioSamplingRate: { _UnitOfMeasure: "GHz", __text: "1000.00" },
            AudioBitsPerSample: "100",
            Duration: "P1Y5MT1H36M13S",
            BitDepth: "100",
            IsPreview: "false",
            PreviewDetails: {
              StartPoint: "1000.00",
              EndPoint: "1000.00",
              Duration: "P2M5DT4H51M2S",
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Informative",
            },
            File: {
              URI: "string",
              HashSum: {
                Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA-224" },
                Version: "string",
                Parameter: "string",
                DataType: "HexBinary",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA2" },
                  Version: "string",
                  Parameter: "string",
                  DataType: "HexBinary",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "HexBinary",
              FingerprintValue: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "SG",
            _IsDefault: "false",
          },
          Raga: "string",
          Tala: "string",
          Deity: "string",
          VideoChapterReference: "string",
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "false",
        },
      ],
      Text: {
        ResourceReference: "string",
        Type: { _Namespace: "string", _UserDefinedValue: "string", __text: "LinerNotes" },
        ResourceId: {
          ISBN: "string",
          ISSN: "string",
          SICI: "string",
          ProprietaryId: { _Namespace: "string", __text: "string" },
          _IsReplaced: "true",
        },
        WorkId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: { _Namespace: "string", __text: "string" },
          _IsReplaced: "false",
        },
        DisplayTitleText: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "VN",
          _IsDefault: "true",
          __text: "string",
        },
        DisplayTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "true",
            _SubTitleType: "Location",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "VG",
          _IsDefault: "true",
        },
        AdditionalTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "false",
            _SubTitleType: "Version",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "578",
          _TitleType: "GroupingTitle",
          _Namespace: "string",
          _UserDefinedValue: "string",
          _IsDefault: "false",
        },
        VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "UserDefined" },
        DisplayArtistName: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "NC",
          _IsDefault: "false",
          __text: "string",
        },
        DisplayArtist: {
          ArtistPartyReference: "string",
          DisplayArtistRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "Artist" },
          ArtisticRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "RightsControllerOnProduct",
          },
          TitleDisplayInformation: {
            IsDisplayedInTitle: "true",
            Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
            _LanguageAndScriptCode: "string",
            _SequenceNumber: "100",
          },
          _SequenceNumber: "100",
        },
        Contributor: {
          ContributorPartyReference: "string",
          Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "ChiefLightingTechnician" },
          InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "AltoSaxophone" },
          HasMadeFeaturedContribution: "false",
          HasMadeContractedContribution: "true",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "SY",
            _IsDefault: "false",
          },
          _SequenceNumber: "100",
        },
        ResourceRightsController: {
          PartyId: {
            ISNI: "string",
            DPID: "string",
            IpiNameNumber: "string",
            IPN: "string",
            CisacSocietyId: "string",
            ProprietaryId: { _Namespace: "string", __text: "string" },
          },
          PartyName: {
            FullName: { _LanguageAndScriptCode: "string", __text: "string" },
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
            NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
            _LanguageAndScriptCode: "string",
          },
          RightsControllerRole: "Unknown",
          RightShareUnknown: "false",
          RightSharePercentage: { _HasMaxValueOfOne: "true", __text: "1000.00" },
          DelegatedUsageRights: {
            UseType: { _Namespace: "string", _UserDefinedValue: "string", __text: "Dub" },
            PeriodOfRightsDelegation: {
              StartDate: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "MQ",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              EndDate: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "true",
                _ApplicableTerritoryCode: "214",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartDateTime: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "776",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2019-10-01T07:02:45+03:00",
              },
              EndDateTime: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "64",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2009-04-29T03:45:45+03:00",
              },
            },
            TerritoryOfRightsDelegation: { _IdentifierType: "TIS", __text: "2102" },
          },
          _SequenceNumber: "100",
        },
        WorkRightsController: {
          RightsControllerPartyReference: "string",
          RightsControllerRole: "RightsAdministrator",
          RightsControllerType: "ExclusiveLicensee",
          RightShareUnknown: "false",
          RightSharePercentage: "1000.00",
          Territory: { _IdentifierType: "ISO", __text: "76" },
          StartDate: "string",
          EndDate: "string",
        },
        CLine: {
          Year: "2003+03:00",
          CLineCompany: "string",
          CLineText: "string",
          _ApplicableTerritoryCode: "GQ",
          _IsDefault: "true",
          _LanguageAndScriptCode: "string",
        },
        CourtesyLine: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "858",
          _IsDefault: "true",
          __text: "string",
        },
        CreationDate: {
          _IsApproximate: "true",
          _ApplicableTerritoryCode: "688",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FirstPublicationDate: {
          FulfillmentDate: "string",
          ResourceReleaseReference: "string",
          _ApplicableTerritoryCode: "GT",
          _IsDefault: "false",
        },
        ParentalWarningType: {
          _Namespace: "string",
          _ApplicableTerritoryCode: "ES-CN",
          _UserDefinedValue: "string",
          _IsDefault: "false",
          __text: "Explicit",
        },
        RelatedRelease: {
          ReleaseRelationshipType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "IsReleaseFromRelease",
          },
          ReleaseId: {
            GRid: "string",
            ISRC: "string",
            ICPN: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "678",
            _IsDefault: "false",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "NE",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "BV",
            _TitleType: "OriginalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "158",
            _IsDefault: "false",
            __text: "string",
          },
          ReleaseLabelReference: {
            _LanguageAndScriptCode: "string",
            _IsDefault: "true",
            _LabelType: "UserDefined",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _ApplicableTerritoryCode: "SK",
            __text: "string",
          },
          ReleaseDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "WKUM",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          OriginalReleaseDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "CW",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
        },
        RelatedResource: {
          ResourceRelationshipType: "IsCoverOf",
          ResourceRelatedResourceReference: "string",
          ReleaseId: {
            GRid: "string",
            ISRC: "string",
            ICPN: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
          },
          Timing: { StartPoint: "P8MT1H53M56S", DurationUsed: "P1Y4M1DT22H55M50S" },
        },
        ContainsHiddenContent: "false",
        ResourceContainedResourceReferenceList: [
          {
            ResourceContainedResourceReference: {
              ResourceContainedResourceReference: "string",
              DurationUsed: "P10M1DT15H53M53S",
              StartPoint: "1000.00",
              Purpose: { _Namespace: "string", _UserDefinedValue: "string", __text: "UserDefined" },
            },
          },
        ],
        Keywords: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "TO",
          _IsDefault: "false",
          __text: "string",
        },
        Synopsis: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "280",
          _IsDefault: "true",
          _IsShortSynopsis: "true",
          __text: "string",
        },
        TechnicalDetails: {
          TechnicalResourceDetailsReference: "string",
          TextCodecType: {
            _Version: "string",
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "ASCII",
          },
          BitDepth: "100",
          IsPreview: "false",
          PreviewDetails: {
            TopLeftCorner: "string",
            BottomRightCorner: "string",
            ExpressionType: "Informative",
          },
          File: {
            URI: "string",
            HashSum: {
              Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA" },
              Version: "string",
              Parameter: "string",
              DataType: "HexBinary",
              HashSumValue: "string",
            },
            FileSize: "1000.00",
          },
          Fingerprint: {
            Algorithm: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            Version: "string",
            Parameter: "string",
            File: {
              URI: "string",
              HashSum: {
                Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA-512" },
                Version: "string",
                Parameter: "string",
                DataType: "Binary64",
                HashSumValue: "string",
              },
              FileSize: "1000.00",
            },
            DataType: "Binary64",
            FingerprintValue: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "PH",
          _IsDefault: "false",
        },
        _LanguageAndScriptCode: "string",
        _IsSupplemental: "false",
      },
      SheetMusic: {
        ResourceReference: "string",
        Type: { _Namespace: "string", _UserDefinedValue: "string", __text: "string" },
        ResourceId: {
          ISMN: "string",
          ProprietaryId: { _Namespace: "string", __text: "string" },
          _IsReplaced: "false",
        },
        WorkId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: { _Namespace: "string", __text: "string" },
          _IsReplaced: "false",
        },
        DisplayTitleText: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "578",
          _IsDefault: "true",
          __text: "string",
        },
        DisplayTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "false",
            _SubTitleType: "Location",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "SM",
          _IsDefault: "true",
        },
        AdditionalTitle: {
          TitleText: "string",
          SubTitle: {
            _SequenceNumber: "100",
            _IsDisplayedInTitle: "true",
            _SubTitleType: "Version",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "729",
          _TitleType: "GroupingTitle",
          _Namespace: "string",
          _UserDefinedValue: "string",
          _IsDefault: "true",
        },
        VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "StereoVersion" },
        DisplayArtistName: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "SS",
          _IsDefault: "false",
          __text: "string",
        },
        DisplayArtist: {
          ArtistPartyReference: "string",
          DisplayArtistRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "Artist" },
          ArtisticRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "AuthorInQuotations" },
          TitleDisplayInformation: {
            IsDisplayedInTitle: "false",
            Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
            _LanguageAndScriptCode: "string",
            _SequenceNumber: "100",
          },
          _SequenceNumber: "100",
        },
        Contributor: {
          ContributorPartyReference: "string",
          Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "PlaybackSinger" },
          InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "Guache" },
          HasMadeFeaturedContribution: "true",
          HasMadeContractedContribution: "true",
          DisplayCredits: {
            DisplayCreditText: "string",
            DisplayCreditParty: "string",
            NameUsedInDisplayCredit: "string",
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "276",
            _IsDefault: "false",
          },
          _SequenceNumber: "100",
        },
        ResourceRightsController: {
          PartyId: {
            ISNI: "string",
            DPID: "string",
            IpiNameNumber: "string",
            IPN: "string",
            CisacSocietyId: "string",
            ProprietaryId: { _Namespace: "string", __text: "string" },
          },
          PartyName: {
            FullName: { _LanguageAndScriptCode: "string", __text: "string" },
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
            NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
            AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
            _LanguageAndScriptCode: "string",
          },
          RightsControllerRole: "RightsController",
          RightShareUnknown: "true",
          RightSharePercentage: { _HasMaxValueOfOne: "true", __text: "1000.00" },
          DelegatedUsageRights: {
            UseType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UseAsWallpaper",
            },
            PeriodOfRightsDelegation: {
              StartDate: {
                _IsApproximate: "false",
                _IsBefore: "false",
                _IsAfter: "false",
                _ApplicableTerritoryCode: "646",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              EndDate: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "false",
                _ApplicableTerritoryCode: "760",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartDateTime: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "2121",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2013-01-17T13:57:06",
              },
              EndDateTime: {
                _IsApproximate: "false",
                _IsBefore: "false",
                _IsAfter: "false",
                _TerritoryCode: "188",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "2016-08-10T20:37:54+03:00",
              },
            },
            TerritoryOfRightsDelegation: { _IdentifierType: "TIS", __text: "703" },
          },
          _SequenceNumber: "100",
        },
        WorkRightsController: {
          RightsControllerPartyReference: "string",
          RightsControllerRole: "AdministratingRecordCompany",
          RightsControllerType: "OriginalOwner",
          RightShareUnknown: "true",
          RightSharePercentage: "1000.00",
          Territory: { _IdentifierType: "ISO", __text: "230" },
          StartDate: "string",
          EndDate: "string",
        },
        CLine: {
          Year: "2007",
          CLineCompany: "string",
          CLineText: "string",
          _ApplicableTerritoryCode: "SZ",
          _IsDefault: "true",
          _LanguageAndScriptCode: "string",
        },
        CourtesyLine: {
          _LanguageAndScriptCode: "string",
          _ApplicableTerritoryCode: "414",
          _IsDefault: "false",
          __text: "string",
        },
        CreationDate: {
          _IsApproximate: "false",
          _ApplicableTerritoryCode: "499",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FirstPublicationDate: {
          FulfillmentDate: "string",
          ResourceReleaseReference: "string",
          _ApplicableTerritoryCode: "TK",
          _IsDefault: "true",
        },
        ParentalWarningType: {
          _Namespace: "string",
          _ApplicableTerritoryCode: "862",
          _UserDefinedValue: "string",
          _IsDefault: "false",
          __text: "Explicit",
        },
        RelatedRelease: {
          ReleaseRelationshipType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "IsDigitalEquivalentToPhysical",
          },
          ReleaseId: {
            GRid: "string",
            ISRC: "string",
            ICPN: "string",
            CatalogNumber: { _Namespace: "string", __text: "string" },
            ProprietaryId: { _Namespace: "string", __text: "string" },
          },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2116",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "886",
            _IsDefault: "false",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "true",
              _SubTitleType: "Version",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "MR",
            _TitleType: "FormalTitle",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "false",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "GQ",
            _IsDefault: "false",
            __text: "string",
          },
          ReleaseLabelReference: {
            _LanguageAndScriptCode: "string",
            _IsDefault: "true",
            _LabelType: "DisplayLabel",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _ApplicableTerritoryCode: "ES-CN",
            __text: "string",
          },
          ReleaseDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "804",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          OriginalReleaseDate: {
            _IsApproximate: "false",
            _ApplicableTerritoryCode: "DZ",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
        },
        _LanguageAndScriptCode: "string",
        _IsSupplemental: "true",
      },
    },
  ],
  ReleaseList: [
    {
      Release: [
        {
          ReleaseReference: "Release57410",
          ReleaseType: "Album",
          ReleaseId: { ICPN: "1", ISRC: "1", CatalogNumber: "string", GRid: "" },
          DisplayTitleText: "string",
          DisplayTitle: { TitleText: "string" },
          AdditionalTitle: { TitleText: "string" },
          DisplayArtistName: "string",
          DisplayArtist: { ArtistPartyReference: "string", DisplayArtistRole: "string" },
          ReleaseLabelReference: "string",
          PLine: { Year: "2020", PLineCompany: "string", PLineText: "℗ 2020Loma Vista Recordings." },
          CLine: { Year: "2020", CLineCompany: "string", CLineText: "℗ 2020Loma Vista Recordings." },
          Duration: "string",
          Genre: { GenreText: "string" },
          ReleaseDate: "string",
          ParentalWarningType: "string",
          IsMultiArtistCompilation: false,
          ResourceGroup: [
            {
              ResourceGroup: {
                SequenceNumber: "1",
                ResourceGroupContentItem: {
                  SequenceNumber: "1",
                  ReleaseResourceReference: "string",
                  IsInstantGratificationResource: false,
                  IsPreOrderIncentiveResource: false,
                },
              },
              LinkedReleaseResourceReference: "string",
            },
          ],
          ReferenceTitle: { TitleText: "WITH" },
          ReleaseResourceReferenceList: [
            {
              ReleaseResourceReference: {
                _ReleaseResourceType: "PrimaryResource",
                __text: "ATrack268647",
              },
            },
          ],
          ReleaseDetailsByTerritory: {
            TerritoryCode: "Worldwide",
            DisplayArtistName: "Sylvan Esso",
            LabelName: "Loma Vista Recordings",
            Title: { TitleText: "WITH", _TitleType: "FormalTitle" },
            DisplayArtist: {
              PartyName: { FullName: "string" },
              ArtistRole: "MainArtist",
              _SequenceNumber: "1",
            },
            ParentalWarningType: "NotExplicit",
            ResourceGroup: [
              {
                ResourceGroup: {
                  Title: { TitleText: "Disc 1", _TitleType: "GroupingTitle" },
                  SequenceNumber: "1",
                  ResourceGroupContentItem: {
                    SequenceNumber: "1",
                    ResourceType: "SoundRecording",
                    ReleaseResourceReference: { _ReleaseResourceType: "PrimaryResource", __text: "string" },
                  },
                },
                ResourceGroupContentItem: {
                  ResourceType: "Image",
                  SequenceNumber: "string",
                  ReleaseResourceReference: {
                    _ReleaseResourceType: "SecondaryResource",
                    __text: "ACover30255",
                  },
                },
              },
            ],
            Genre: { GenreText: "Alternative", SubGenre: "" },
            ReleaseDate: { _IsApproximate: "true", __text: "2020-04-24" },
          },
          OriginalReleaseDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "862",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          GlobalOriginalReleaseDate: { _IsApproximate: "true", __text: "2020-04-24" },
          _IsMainRelease: "true",
        },
      ],
      TrackRelease: {
        ReleaseReference: "string",
        ReleaseId: { ISRC: "string", ProprietaryId: "string" },
        DisplayTitleText: "string",
        DisplayTitle: { TitleText: "string", SubTitle: "" },
        AdditionalTitle: { TitleText: "string", SubTitle: "" },
        ReleaseResourceReference: "string",
        ReleaseLabelReference: "string",
        Genre: { GenreText: "string" },
      },
    },
  ],
  DealList: [
    {
      ReleaseDeal: [
        {
          DealReleaseReference: "string",
          Deal: {
            DealTerms: {
              TerritoryCode: "string",
              ValidityPeriod: { StartDate: "string" },
              CommercialModelType: "string",
              UseType: "string",
              ReleaseDisplayStartDate: "string",
              TrackListingPreviewStartDate: "string",
              CoverArtPreviewStartDate: "string",
              ClipPreviewStartDate: "string",
            },
          },
        },
      ],
    },
  ],
};
