import { Col, Popover, Row, Space } from "antd";
import { StatusTag } from "@/components/common/index";
import { DATE_FORMATTER, truncate } from "@/utils";
import { StatusBadge } from "@/components/common/StatusBadge/StatusBadge";
import type { Feed } from "@/api/feeds/types";

type Props = {
  feed: Feed;
};

export const FeedCell = ({ feed }: Props) => {
  const startDate = feed.startDate && new Date(feed.startDate);
  const endDate = feed.endDate && new Date(feed.endDate);

  return (
    <Popover
      title={<span style={{ fontSize: 16 }}>{feed.name}</span>}
      content={
        <Row gutter={[8, 8]} style={{ maxWidth: 400 }}>
          <Col span={24}>
            <Space>
              <strong>Status:</strong>
              <StatusTag status={+feed.status} />
            </Space>
          </Col>
          {feed.tenant?.code && (
            <Col span={24}>
              <Space>
                <strong>Tenant:</strong>
                <span>{feed.tenant.name}</span>
              </Space>
            </Col>
          )}
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Space>
                  <strong>Start Date:</strong>
                  <span>{startDate ? DATE_FORMATTER.format(startDate) : "-"}</span>
                </Space>
              </Col>
              <Col span={12}>
                <Space>
                  <strong>End Date:</strong>
                  <span>{endDate ? DATE_FORMATTER.format(endDate) : "-"}</span>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <StatusBadge status={+feed.status} text={truncate(feed.name, 20)} />
    </Popover>
  );
};
