import { WarningOutlined } from "@ant-design/icons";
import { Space } from "antd";
import { Color, DATE_FORMATTER, isFailed } from "@/utils";
import { Tooltip } from "@/components/common/Tooltip";
import type { ColumnsType } from "antd/lib/table/interface";
import type { Release } from "@/api/releases/types";

export const columns: ColumnsType<Release> = [
  {
    title: "Release Date",
    dataIndex: "release_date",
    key: "release_date",
    className: "release-date-column",
    sorter: true,
    render: (releaseDate) => DATE_FORMATTER.format(new Date(releaseDate)),
  },
  {
    title: "UPC",
    dataIndex: "upc",
    key: "upc",
    className: "upc-column",
    sorter: true,
    render: (upc, allData) => {
      return (
        <Space>
          {isFailed(allData?.status) && <WarningOutlined style={{ color: Color.Failed }} />}
          <Tooltip title={allData.tenant_name}>
            <span className="upc-wrapper">{upc}</span>
          </Tooltip>
        </Space>
      );
    },
  },
  {
    title: "Artist",
    dataIndex: "artist",
    key: "artist",
    className: "artist-column",
    sorter: true,
    render: (artist) => <Tooltip title={artist}>{artist}</Tooltip>,
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    sorter: true,
    className: "title-column",
    ellipsis: {
      showTitle: false,
    },
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    title: "Version",
    dataIndex: "version",
    className: "version-column",
    key: "version",
    sorter: true,
    render: (version) => <Tooltip title={version}>{version}</Tooltip>,
  },
  {
    title: "Configuration",
    dataIndex: "configuration",
    key: "configuration",
    className: "configuration-column",
    sorter: true,
    render: (configuration) => {
      return <Tooltip title={configuration}>{configuration}</Tooltip>;
    },
  },
  {
    title: "Label",
    dataIndex: "label",
    className: "label-column",
    key: "label",
    sorter: true,
    render: (label) => {
      return <Tooltip title={label}>{label}</Tooltip>;
    },
  },
];
