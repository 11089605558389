import type { RecurrencyFields } from "@/utils/recurrency";
import { parseRecurrencyFields, prepareRecurrencyDto } from "@/utils/recurrency";
import type {
  CreateExportScheduleDto,
  ExportSchedule,
  UpdateExportScheduleDto,
} from "@/api/export-schedules/types";

export type FormValues = Omit<CreateExportScheduleDto | UpdateExportScheduleDto, "schedule"> & {
  schedule: RecurrencyFields;
};

export const parseExportScheduleFields = (fields: Partial<ExportSchedule>) => ({
  ...fields,
  schedule: parseRecurrencyFields(fields.schedule),
});

export const prepareExportScheduleDto = (values: FormValues) => ({
  ...values,
  schedule: prepareRecurrencyDto(values.schedule),
});
