import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import rehypeStringify from "rehype-stringify";
import rehypePrettyCode from "rehype-pretty-code";
import { Spinner } from "@/components/common/Spinner/Spinner";
import { useRequest } from "ahooks";

async function markdownToHtml(md: string) {
  const file = await unified()
    .use(remarkParse)
    .use(remarkRehype)
    .use(rehypePrettyCode, {
      keepBackground: true,
      theme: "one-dark-pro",
    })
    .use(rehypeStringify)
    .process(md);

  return String(file);
}

type Props = {
  markdown: string;
};

export const Markdown = ({ markdown }: Props) => {
  const { data: html, loading } = useRequest(() => markdownToHtml(markdown));
  if (loading) {
    return <Spinner height={100} />;
  }
  return <section dangerouslySetInnerHTML={{ __html: html }} />;
};
