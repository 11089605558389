import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "antd";
import { Close } from "@/components/common/Icons";
import { MappingTemplatePicker } from "@/components/Feeds/Details/MappingsForm/MappingTemplatePicker";

type FormValues = {
  template: string;
};

export const ChangeTemplateButton = () => {
  const outerForm = Form.useFormInstance();
  const [form] = Form.useForm<FormValues>();

  const [isVisible, setVisible] = useState(false);

  const operation = Form.useWatch("operation");
  const targetObject = Form.useWatch("targetObject");
  const inputFormat = Form.useWatch(["mapping", "inputFormat"]);
  const outputFormat = Form.useWatch(["mapping", "outputFormat"]);
  const extraMappings = Form.useWatch(["mapping", "data"]) ?? [];

  const disabledTemplates = inputFormat && outputFormat ? [[inputFormat, outputFormat].join("_to_")] : [];

  const close = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    close();
  };

  const handleSubmit = ({ template }: FormValues) => {
    const [from, to] = template.split("_to_");
    outerForm.setFieldValue(["mapping", "data"], []);
    outerForm.setFieldValue(["mapping", "inputFormat"], from);
    outerForm.setFieldValue(["mapping", "outputFormat"], to);
    close();
  };

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <>
      <Button htmlType="button" onClick={handleOpen}>
        Change Template
      </Button>
      <Modal
        open={isVisible}
        title="Change Mapping Template"
        footer={
          <>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button type="primary" onClick={form.submit}>
              Change Template
            </Button>
          </>
        }
        onCancel={handleCancel}
        closeIcon={<Close />}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={[16, 16]}>
            {extraMappings.length > 0 && (
              <Col span={24}>
                <Alert
                  type="warning"
                  message="After setting new mapping template all extra mappings will be lost"
                />
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label="Template"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[{ required: true, message: "Please, select mapping template" }]}
                name="template"
              >
                <MappingTemplatePicker
                  operation={operation}
                  targetObject={targetObject}
                  disabledKeys={disabledTemplates}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
