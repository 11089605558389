import useSWR from "swr";
import { getCampaigns, getCompanies, getLabelGroups } from "@/api/salesforce-api/index";

export const useCampaigns = () =>
  useSWR(["salesforce", "campaigns"], getCampaigns, {
    fallbackData: [],
    revalidateOnReconnect: false,
    revalidateOnFocus: false,
  });

export const useLabelGroups = () =>
  useSWR(["salesforce", "label-groups"], getLabelGroups, { fallbackData: [] });
export const useCompanies = () => useSWR(["salesforce", "companies"], getCompanies, { fallbackData: [] });
