import { Col, Form, Input, Row } from "antd";
import { Field } from "@/components/common/Form/Field";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker/SalesforceObjectPathPicker";
import { HiddenField } from "@/components/common/HiddenField";
import { useFieldPath } from "@/components/common/Form/hooks";
import { getFieldMaxLengthRule } from "@/utils/validation";
import type * as Salesforce from "@/types/salesforce";
import { TimezoneSelect } from "@/components/common/TimezoneSelect/TimezoneSelect";
import { useFieldListSourceObject } from "./hooks";

export const FieldListBlockField = () => {
  const form = Form.useFormInstance();
  const sourceObject = useFieldListSourceObject()
  const { getAbsolutePath } = useFieldPath();

  const type = Form.useWatch<Salesforce.FieldType>(getAbsolutePath("type"));

  const handleFieldChange = (_: any, options = []) => {
    form.setFieldValue(getAbsolutePath("type"), options.at(-1)?.type ?? "string");
  };

  return (
    <Row gutter={[8, 8]}>
      <HiddenField name="type" />
      <Col span={8}>
        <Field name="path" rules={[{ required: true, message: "Please, select a field" }]}>
          <SalesforceObjectPathPicker obj={sourceObject} onChange={handleFieldChange} />
        </Field>
      </Col>
      <Col span={8}>
        <Field name="label">
          <Input placeholder="Custom Label" />
        </Field>
      </Col>
      {(type === "date" || type === "datetime") && (
        <Col span={8}>
          <Field name="format" rules={[getFieldMaxLengthRule("Date format", 50)]}>
            <Input placeholder="Date Format (e.g. MM/DD/YYYY)" />
          </Field>
        </Col>
      )}
      {type === "datetime" && (
        <Col span={8}>
          <Field name="timezone">
            <TimezoneSelect placeholder="Timezone" allowClear />
          </Field>
        </Col>
      )}
    </Row>
  );
};
