import { Input, InputNumber, Select, Switch } from "antd";
import type { SalesforceObjectField } from "@/api/salesforce-objects/types";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";
import { DateInput } from "@/components/common/DateInput";

type FieldValueInputProps = {
  field?: SalesforceObjectField;
  value?: any;
  onChange?: (value: any) => void;
  placeholder?: string;
};

export const FieldValueInput = ({ field, value, onChange, placeholder }: FieldValueInputProps) => {
  switch (field?.type) {
    case "currency":
    case "double":
    case "percent":
    case "int":
      return (
        <InputNumber
          value={value}
          onChange={onChange}
          step={field.type === "int" ? 1 : 0.01}
          placeholder={placeholder}
        />
      );
    case "boolean":
      return <Switch checked={value} onChange={onChange} />;
    case "multipicklist":
      return (
        <MultiSelect
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          options={field.options}
          fieldNames={{ value: "value", label: "title" }}
        />
      );
    case "picklist":
      return (
        <Select
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          options={field.options}
          fieldNames={{ value: "value", label: "title" }}
        />
      );
    case "datetime":
    case "date":
      return <DateInput value={value} onChange={onChange} showTime={field.type === "datetime"} />;
    default:
      return <Input value={value} onChange={onChange} placeholder={placeholder} />;
  }
};

const CURRENT_DATE = "@current_date";
const SET_NULL = "@set_null";

const isSpecialValue = (value: string) => [CURRENT_DATE, SET_NULL].includes(value);

export const FieldValue = ({ value, onChange, placeholder, field }: FieldValueInputProps) => {
  const isDateField = field?.type === "date" || field?.type === "datetime";
  const handleValueTypeChange = (value: string) => {
    onChange(isSpecialValue(value) ? value : undefined);
  };
  const showValueType = field?.nullable || isDateField;
  return (
    <div className="flex-row">
      {showValueType && (
        <Select
          value={isSpecialValue(value) ? value : "value"}
          options={[
            { value: "value", label: "Set Value" },
            field?.nullable && { value: SET_NULL, label: "Set to Null" },
            isDateField && { value: CURRENT_DATE, label: "Set to Current Date" },
          ].filter(Boolean)}
          onChange={handleValueTypeChange}
        />
      )}
      {!isSpecialValue(value) && (
        <FieldValueInput value={value} onChange={onChange} placeholder={placeholder} field={field} />
      )}
    </div>
  );
};
