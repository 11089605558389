import React from "react";
import { WarningOutlined } from "@ant-design/icons";
import { getUploadStatusColor, getUploadStatusLabel } from "./utils";
import "./UploadStatusTag.less";
import { Tag } from "../../../../common/Tag/Tag";
import { ProcessingStatus } from "@/utils/processing-status";

export const UploadStatusTag = ({ status }) => (
  <Tag
    icon={status === ProcessingStatus.FinishedWithWarnings && <WarningOutlined />}
    className="upload-status"
    color={getUploadStatusColor(status)}
  >
    <span>{getUploadStatusLabel(status)}</span>
  </Tag>
);
