import { IconButton } from "../IconButton/IconButton";
import { Remove } from "../Icons";
import cn from "classnames";
import "./BaseTag.less";
import { WarningOutlined } from "@ant-design/icons";
import type { ReactNode } from "react";

export type BaseTagProps = {
  children: ReactNode;
  closable?: boolean;
  onClose?: () => void;
  className?: string;
  bodyClassName?: string;
  onClick?: () => void;
  hasWarning?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
};

export const BaseTag = ({
  children,
  closable,
  onClose,
  className,
  bodyClassName,
  onClick,
  hasWarning,
  ...rest
}: BaseTagProps) => {
  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const clickable = !!onClick;
  return (
    <div
      className={cn("base-tag", className, clickable && "base-tag--clickable")}
      onMouseDown={handleMouseDown}
      onClick={onClick}
      {...rest}
    >
      {hasWarning && <WarningOutlined className="base-tag__warning" />}
      <div className={cn("base-tag__body", bodyClassName)}>{children}</div>
      {closable && <IconButton className="base-tag__remove" icon={Remove} onClick={onClose} />}
    </div>
  );
};
