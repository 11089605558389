import type { ColumnsType } from "antd/lib/table/interface";
import type { Webhook } from "@/api/webhooks/types";
import { getSalesforceMessageTypeLabel } from "@/utils/salesforce-message-type";
import { Typography } from "antd";
import { DescriptionCell } from "@/components/common/DescriptionCell";

const { Text } = Typography;

export const columns: ColumnsType<Webhook> = [
  { title: "Object", dataIndex: ["object"] },
  { title: "Action", render: (_, entry) => getSalesforceMessageTypeLabel(entry.messageType) },
  { title: "Endpoint", render: (_, entry) => <Text code>{entry.endpoint}</Text> },
  {
    title: "Description",
    dataIndex: ["description"],
    render: (description) => <DescriptionCell description={description} />,
  },
];
