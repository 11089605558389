import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { getPartner, getPartners, savePartner } from "@/api/partners/index";

const key = "partners";

export const usePartners = (filters) =>
  useSWR([key, filters], ([_, filters]) => getPartners(filters), {
    fallbackData: { data: [] },
  });

export const usePartner = (id?: string) =>
  useSWR(id ? [key, id] : null, ([, id]) => getPartner(id), {
    fallbackData: null,
  });

export const usePartnerMutation = (id?: string) =>
  useSWRMutation(id ? [key, id] : key, (_, { arg: dto }: { arg }) =>
    savePartner(dto)
  );
