import { TagInput } from "../../TagInput";
import { CSVUploadButton } from "../CSVUploadButton";
import "./UpcList.less";
import { isValidUPC } from "@/utils";

type Props = {
  placeholder?: string;
  disabled?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
};

export const UpcList = ({ onChange, ...props }: Props) => {
  return (
    <div className="upc-list">
      <TagInput {...props} value={props.value ?? []} onChange={onChange} checkValue={isValidUPC} />
      {!props.disabled && <CSVUploadButton className="upc-list__button" onChange={onChange} />}
    </div>
  );
};
