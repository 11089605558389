import { useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import { fetchReleaseDeliveries } from "@/api/releases";
import { DeliveryTable, DeliveryTableColumns } from "../../common/DeliveryTable";
import { PageHeader, Spin } from "antd";
import { useGridParams } from "@/utils/useGridParams";
import { prepareParams } from "@/utils";
import { filtersConfig } from "./filters";
import { Summary } from "../../common/Summary/Summary";
import { useRelease } from "@/api/releases/hooks";

export const columns = [
  DeliveryTableColumns.Status,
  DeliveryTableColumns.Feed,
  DeliveryTableColumns.Partner,
  DeliveryTableColumns.FirstDelivery,
  DeliveryTableColumns.LastDelivery,
  DeliveryTableColumns.Download,
  DeliveryTableColumns.Resend,
];

export const ReleaseSummary = ({ id }) => {
  const { isLoading, data: release } = useRelease(id);

  return (
    <Spin spinning={isLoading}>
      <Summary
        items={[
          { label: "UPC", value: release?.upc },
          { label: "Tenant", value: release?.tenant_name },
          { label: "Artist", value: release?.artist },
          { label: "Title", value: release?.title },
          { label: "Version", value: release?.version },
          { label: "Configuration", value: release?.configuration },
        ]}
      />
    </Spin>
  );
};

export const ReleaseDeliveries = () => {
  const { id } = useParams<{ id: string }>();
  const { pagination, sort, filters } = useGridParams();

  const { loading, data: deliveries } = useRequest(
    () => fetchReleaseDeliveries(id, prepareParams(filters, pagination, sort)),
    { refreshDeps: [id, filters, pagination, sort] }
  );

  return (
    <>
      <PageHeader title="Release Delivery" extra={<ReleaseSummary id={id} />} />
      <DeliveryTable deliveries={deliveries} loading={loading} columns={columns} filters={filtersConfig} />
    </>
  );
};
