import { HexColorInput, HexColorPicker } from "react-colorful";
import { Button, Popover } from "antd";
import "./ColorPicker.less";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import { useState } from "react";

type ColorPickerProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSetColor = () => {
    onChange("#ffffff");
    setIsOpen(true);
  };

  const handleClear = () => {
    onChange(null);
  };

  return (
    <div className="color-picker">
      {value ? (
        <>
          <Popover
            overlayClassName="color-picker__popover"
            content={<HexColorPicker color={value} onChange={onChange} />}
            trigger="click"
            open={isOpen}
            onOpenChange={setIsOpen}
          >
            <div role="button" className="color-picker__button">
              <div className="color-picker__swatch" style={{ background: value }} />
            </div>
          </Popover>
          <HexColorInput className="color-picker__input ant-input" color={value} onChange={onChange} />
          <IconButton icon={Remove} onClick={handleClear} />
        </>
      ) : (
        <Button size="small" onClick={handleSetColor}>
          Set Color
        </Button>
      )}
    </div>
  );
};
