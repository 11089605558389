import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import FilterableContent from "@/components/Layout/FilterableContent";
import { TableWithPaginationAndSorting } from "@/components/common";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { columns } from "./columns";
import { useSpreadsheetColumnSets } from "@/api/spreadsheets/hooks";
import { useGridParams } from "@/utils/useGridParams";
import { getTabSourceLabel, prepareParams, TabSource, useAuth } from "@/utils";
import { toOptions } from "@/utils/toOptions";
import { SalesforceObjectPicker } from "@/components/SalesforceObjects/SalesforceObjectPicker";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const filterConfig = [
  {
    name: "source",
    label: "Source",
    type: FilterType.Select,
    options: toOptions(Object.values(TabSource), getTabSourceLabel),
  },
  {
    name: "sourceObject",
    label: "Source Object",
    type: FilterType.CustomInput,
    input: <SalesforceObjectPicker />,
  },
  { name: "name", label: "Name", type: FilterType.Text },
];

export const ColumnSetList = () => {
  const history = useHistory();

  const { filters, pagination, sort } = useGridParams();

  const { data, isLoading } = useSpreadsheetColumnSets(prepareParams(filters, pagination, sort));

  const handleEdit = useCallback(({ id }) => history.push(`/column-sets/${id}`), [history]);

  const handleCreate = () => {
    history.push(`/column-sets/new`);
  };

  const { ability } = useAuth();

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>Spreadsheet Column Sets</h1>}
        extra={
          ability.can(PermissionAction.Create, PermissionSubject.SpreadsheetColumnSet)
            ? [
                <Button key="new" type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
                  New Column Set
                </Button>,
              ]
            : []
        }
      />
      <FilterableContent
        formConfig={filterConfig}
        filterName="column-set-filter"
        isFiltersConfigurable={false}
      >
        <TableWithPaginationAndSorting
          rowKey="id"
          onRowClick={handleEdit}
          columns={columns}
          data={data}
          loading={isLoading}
        />
      </FilterableContent>
    </>
  );
};
