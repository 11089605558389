import React, { useCallback } from "react";
import { Logo, LogoColor, LogoSize } from "../Layout/Logo";
import "./ResetPassword.less";
import { Button, Form, Input, Spin } from "antd";
import { Link } from "react-router-dom";
import { resetPassword } from "../../api/auth";
import { useRequest } from "ahooks";

function ResetPassword() {
  const [form] = Form.useForm();
  const { loading, run: runResetPassword, data: isRequestFinished, mutate: setRequestFinished } = useRequest(
    resetPassword,
    {
      manual: true,
      formatResult: () => true,
    }
  );
  const onResetPassword = useCallback(({ username }) => runResetPassword(username), [runResetPassword]);
  const resetRequestFinished = () => {
    if (isRequestFinished) {
      setRequestFinished(false);
    }
  };
  return (
    <div className="login-screen">
      <Spin spinning={loading}>
        <Form form={form} name="reset-password" className="reset-password-form" onFinish={onResetPassword}>
          <Logo size={LogoSize.Large} color={LogoColor.Black} width={200} height={60} />
          {isRequestFinished ? (
            <div className="login-description">
              {`The email with steps to reset your password is sent to ${form.getFieldValue("username")}`}
            </div>
          ) : (
            <div className="login-description">
              Please enter your email address. We will send you an email to reset your password.
            </div>
          )}
          <Form.Item
            name="username"
            onChange={resetRequestFinished}
            rules={[
              {
                required: true,
                message: "Please enter your email.",
              },
              {
                type: "email",
                message: "Invalid email format",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="reset-password-form-button"
              disabled={isRequestFinished}
            >
              Reset Password
            </Button>
            <div className="link-wrapper">
              <span>or</span>
              <Link to={"/login"} className="login">
                Login
              </Link>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default ResetPassword;
