export enum UserRole {
  Operational = 1,
  Admin = 2,
  Superadmin = 3,
}

export const roles = [UserRole.Admin, UserRole.Operational, UserRole.Superadmin];

const labelLookup: Record<UserRole, string> = {
  [UserRole.Operational]: "Operational",
  [UserRole.Admin]: "Admin",
  [UserRole.Superadmin]: "Superadmin",
};

export const getUserRoleLabel = (role: UserRole) => labelLookup[role];
