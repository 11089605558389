import { Button, PageHeader } from "antd";
import Icon from "@ant-design/icons";
import { Plus } from "@/components/common/Icons";
import FilterableContent from "@/components/Layout/FilterableContent";
import { TableWithPaginationAndSorting } from "@/components/common";
import { FilterType } from "@/components/common/FilterInput/FilterInput";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { columns } from "./columns";
import { useGridParams } from "@/utils/useGridParams";
import { prepareParams, useAuth } from "@/utils";
import { useOpenplayQueries } from "@/api/openplay-queries/hooks";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

const filterConfig = [
  { name: "nameContains", label: "Name", type: FilterType.Text },
  { name: "descriptionContains", label: "Description", type: FilterType.Text },
];

export const OpenplayQueryList = () => {
  const history = useHistory();

  const { filters, pagination, sort } = useGridParams();

  const { data, isLoading } = useOpenplayQueries(prepareParams(filters, pagination, sort));

  const handleEdit = useCallback(({ id }) => history.push(`/openplay-queries/${id}`), [history]);

  const handleCreate = () => {
    history.push(`/openplay-queries/new`);
  };

  const { ability } = useAuth();

  return (
    <>
      <PageHeader
        style={{ marginBottom: 16 }}
        title={<h1 style={{ margin: 0 }}>OpenPlay Queries</h1>}
        extra={
          ability.can(PermissionAction.Create, PermissionSubject.OpenplayQuery)
            ? [
                <Button key="new" type="primary" icon={<Icon component={Plus} />} onClick={handleCreate}>
                  New OpenPlay Query
                </Button>,
              ]
            : []
        }
      />
      <FilterableContent
        formConfig={filterConfig}
        filterName="openplay-query-filter"
        isFiltersConfigurable={false}
      >
        <TableWithPaginationAndSorting
          rowKey="id"
          onRowClick={handleEdit}
          columns={columns}
          data={data}
          loading={isLoading}
        />
      </FilterableContent>
    </>
  );
};
