import "./ParameterSourceList.less";
import { Col, Form, Row, Select } from "antd";
import type { SalesforceQuery } from "@/api/reports/types";
import { Field } from "@/components/common/Form/Field";
import Icon, { ArrowRightOutlined } from "@ant-design/icons";
import { DropdownArrow, Remove } from "@/components/common/Icons";
import { useFieldPath } from "@/components/common/Form/hooks";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker";
import { toOptions } from "@/utils/toOptions";
import { IconButton } from "@/components/common/IconButton/IconButton";

type Props = {
  parameters: string[];
  salesforceQueries: SalesforceQuery[];
  onRemove: () => void;
};

export const ParameterSourceListItem = ({ parameters, salesforceQueries, onRemove }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const form = Form.useFormInstance();
  const queryId = Form.useWatch(getAbsolutePath("query"));
  const selectedQuery = queryId ? salesforceQueries.find((query) => query.id === queryId) : null;

  const sourceObjectNotSelected = selectedQuery && !selectedQuery.from;

  const handleQueryChange = () => {
    form.setFieldValue(getAbsolutePath("field"), void 0);
  };

  return (
    <div className="parameter-source-list-item">
      <Row className="parameter-source-list-item__body" gutter={[12, 12]}>
        <Col span={8}>
          <Field name="query" rules={[{ required: true, message: "Please, select Salesforce query" }]}>
            <Select
              options={salesforceQueries.map((query, index) => ({
                value: query.id,
                label: query.name ?? `Query #${index + 1}`,
              }))}
              suffixIcon={<Icon component={DropdownArrow} />}
              notFoundContent="No queries available"
              placeholder="Select Salesforce query"
              onChange={handleQueryChange}
            />
          </Field>
        </Col>
        <Col span={8}>
          <Field name="field" rules={[{ required: true, message: "Please, select source field" }]}>
            <SalesforceObjectPathPicker
              obj={selectedQuery?.from}
              placeholder={
                sourceObjectNotSelected ? "Specify source object for selected query" : "Select source field"
              }
            />
          </Field>
        </Col>
        <Col span={1} className="parameter-source-list-item__arrow-col">
          <ArrowRightOutlined />
        </Col>
        <Col span={7}>
          <Field name="parameter" rules={[{ required: true, message: "Please, select parameter" }]}>
            <Select
              options={toOptions(parameters)}
              suffixIcon={<Icon component={DropdownArrow} />}
              placeholder="Select parameter"
            />
          </Field>
        </Col>
      </Row>
      <IconButton icon={Remove} onClick={onRemove} />
    </div>
  );
};
