import { SortableList, SortableListItem } from "@/components/common/SortableList";
import { FieldList, FieldListItem } from "@/components/common/Form/FieldList";
import { FullWidthButton } from "@/components/common/FullWidthButton/FullWidthButton";
import { Plus } from "@/components/common/Icons";
import { Form } from "antd";
import { PermissionListItem } from "./PermissionListItem";
import { PermissionAction, PermissionType } from "@/api/users/types";
import "./PermissionList.less";

export const PermissionList = () => {
  return (
    <FieldList name="permissions">
      {(fields, { add, remove, move }, { errors }) => (
        <div className="permission-list">
          <SortableList onMove={move}>
            {fields.map((field) => (
              <SortableListItem key={field.key} index={field.name} onRemove={() => remove(field.name)}>
                <FieldListItem name={field.name}>
                  <PermissionListItem />
                </FieldListItem>
              </SortableListItem>
            ))}
          </SortableList>
          <FullWidthButton
            small
            label="Add Permission"
            icon={Plus}
            onClick={() => add({ type: PermissionType.Can, action: PermissionAction.Read })}
          />
          <Form.ErrorList errors={errors} />
        </div>
      )}
    </FieldList>
  );
};
