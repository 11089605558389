import { Col, Input, Row, Select } from "antd";
import Icon from "@ant-design/icons";
import { Close, DropdownArrow } from "@/components/common/Icons";
import type { ComponentProps } from "react";
import { IconButton } from "@/components/common/IconButton/IconButton";

type Props = {
  value?: string;
  onChange?: (key: string) => void;
};

const options = [
  { value: "#,##0.00", label: "Number" },
  { value: "0.00%", label: "Percentage" },
  { value: '"$"#,##0.00', label: "Currency" },
];

export const NumberFormatPicker = ({ value, onChange }: Props) => {
  const handleInputChange: ComponentProps<typeof Input>["onChange"] = (e) => {
    onChange?.(e.target.value);
  };

  const isCustomValue = !options.some((option) => option.value === value);

  const selectValue = value
    ? isCustomValue
      ? {
          value,
          label: "Custom",
        }
      : value
    : null;

  const handleClear = () => {
    onChange?.("");
  };

  return (
    <Row gutter={[8, 8]} style={{ alignItems: "center" }}>
      <Col span={8}>
        <Select
          value={selectValue}
          onChange={onChange}
          options={options}
          suffixIcon={<Icon component={DropdownArrow} />}
          placeholder="Select number format"
          allowClear
        />
      </Col>
      <Col span={12}>
        <Input value={value} onChange={handleInputChange} placeholder="... or enter a custom value" />
      </Col>
      {!!value && (
        <Col>
          <IconButton icon={Close} onClick={handleClear} />
        </Col>
      )}
    </Row>
  );
};

export const NumberFormatFieldHint = () => (
  <>
    See{" "}
    <a href="https://customformats.com/" target="_blank" rel="noreferrer">
      here
    </a>{" "}
    for available number formats
  </>
);
