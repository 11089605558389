import { api } from "./core";
import { LOG_OUT_URL, RESET_PASSWORD_URL, SIGN_IN_URL } from "./urls";

export const login = async (email: string, password: string) =>
  api.post<any, void>(SIGN_IN_URL, {
    email,
    password,
  });

export const resetPassword = async (email: string) => api.post<any, void>(RESET_PASSWORD_URL, { email });

export const logout = async () => api.post(LOG_OUT_URL);
