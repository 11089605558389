import { useCallback } from "react";
import { IconButton } from "../IconButton/IconButton";
import { Refresh } from "../Icons";
import { canBeResent, hasFeedTenantChanged } from "./utils";
import { useResendDeliveries } from "@/components/common/DeliveryTable/hooks";
import { Modal } from "antd";

const getDifferentTenantConfirmationText = (delivery) => {
  const targetObjectTenant = delivery[delivery.targetObject]?.tenant?.name;
  return (
    <>
      The tenant for the feed <strong>{delivery.feed.name}</strong> has been changed from{" "}
      <strong>{targetObjectTenant}</strong> to <strong>{delivery.feed?.tenant?.name}</strong>. Do you wish to
      send the delivery with the updated tenant?
    </>
  );
};

const ResendDeliveryButton = ({ delivery }) => {
  const { resendSingle, loading } = useResendDeliveries();
  const handleResend = useCallback(async () => {
    if (hasFeedTenantChanged(delivery)) {
      Modal.confirm({
        title: "Resend delivery to different tenant?",
        content: getDifferentTenantConfirmationText(delivery),
        onOk: () => resendSingle(delivery.id),
      });
    } else {
      await resendSingle(delivery.id);
    }
  }, [delivery, resendSingle]);

  return <IconButton onClick={handleResend} disabled={!canBeResent(delivery) || loading} icon={Refresh} />;
};

export default ResendDeliveryButton;
