import { createContext } from "react";
import type { PathPiece } from "@/components/common/Form/types";
import type { FieldData } from "rc-field-form/es/interface";

type FieldGroupContextType = {
  path: PathPiece[];
};

export const FieldGroupContext = createContext<FieldGroupContextType>({ path: [] });

type FormErrorsContextType = {
  fieldsWithErrors: FieldData[];
};

export const FormErrorsContext = createContext<FormErrorsContextType>({
  fieldsWithErrors: [],
});
