import { Col, Row } from "antd";
import { Box } from "@/components/common/Box/Box";
import { ReportActionList } from "@/components/Reports/Details/ActionsList/ReportActionList";

type Props = {
  readOnly?: boolean;
};

export const ReportActions = ({ readOnly }: Props) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Box title="Pre Actions" readOnly={readOnly}>
          <ReportActionList name={["actions", "pre"]} />
        </Box>
      </Col>
      <Col span={24}>
        <Box title="Post Actions" readOnly={readOnly}>
          <ReportActionList name={["actions", "post"]} />
        </Box>
      </Col>
    </Row>
  );
};
