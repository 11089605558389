import { Table } from "antd";
import type { EntityProcessingResult } from "@/api/webhooks/types";
import { columns } from "./columns";
import { StatusCounters } from "@/components/common/StatusCounters/StatusCounters";
import "./DetailsTable.less";
import { countBy } from "ramda";
import { getEntityProcessingStatus } from "./utils";
import type { ProcessingStatus } from "@/utils/processing-status";
import { getProcessingStatusColor, getProcessingStatusLabel } from "@/utils/processing-status";
import { EntityProcessingTimeline } from "./EntityProcessingTimeline";

type Props = {
  entities: EntityProcessingResult[];
};

export const DetailsTable = ({ entities }: Props) => {
  return (
    <div className="salesforce-message-details-table">
      <div className="salesforce-message-details-table__header">
        <h4 className="salesforce-message-details-table__title">Entities</h4>
        <StatusCounters<ProcessingStatus>
          counts={countBy(getEntityProcessingStatus, entities) as Record<ProcessingStatus, number>}
          getStatusColor={getProcessingStatusColor}
          getStatusLabel={(status) => getProcessingStatusLabel(status, true)}
        />
      </div>
      <Table
        rowKey={(record) => `${record.entity.Id}`}
        dataSource={entities}
        columns={columns}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <EntityProcessingTimeline logs={record.log} />,
          rowExpandable: (record) => record.log.length > 0,
        }}
      />
    </div>
  );
};
