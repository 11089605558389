import { Col, Form, Input, Modal, Row } from "antd";
import type { SalesforceQueryTemplateData, Template } from "@/api/templates/types";
import { SalesforceQueryForm } from "@/components/common/SalesforceQuery/SalesforceQueryForm";
import { SaveAsSalesforceQueryTemplate } from "@/components/Reports/SaveAsSalesforceQueryTemplate";
import { CopyFromTemplate } from "@/components/common/CopyFromTemplate/CopyFromTemplate";
import { TemplateType, useAuth } from "@/utils";
import { Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { useFieldPath } from "@/components/common/Form/hooks";
import { HiddenField } from "@/components/common/HiddenField";
import { Field } from "@/components/common/Form/Field";
import { Box } from "@/components/common/Box/Box";
import type { SpreadsheetTab } from "@/api/spreadsheets/types";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { PermissionAction, PermissionSubject } from "@/api/users/types";

type Props = {
  disabled?: boolean;
  onRemove?: () => void;
};

export const SalesforceQueryListItem = ({ disabled, onRemove }: Props) => {
  const form = Form.useFormInstance();
  const { getAbsolutePath } = useFieldPath();

  const id = Form.useWatch<string>(getAbsolutePath("id"));
  const from = Form.useWatch<string>(getAbsolutePath("from"));
  const tabs = Form.useWatch<SpreadsheetTab[]>(["spreadsheet", "tabs"]) ?? [];

  const applyTemplate = (template: Template<SalesforceQueryTemplateData>) => {
    form.setFieldValue(getAbsolutePath("where"), template.data.where);
  };

  const hasQueryValue = () => !!form.getFieldValue(getAbsolutePath("where"));

  const handleCopyFromTemplate = (template: Template<SalesforceQueryTemplateData>) => {
    if (hasQueryValue()) {
      Modal.confirm({
        title: "Copy from a custom template?",
        content: "Are you sure you want to override current settings?",
        onOk: () => {
          applyTemplate(template);
        },
      });
    } else {
      applyTemplate(template);
    }
  };

  const cannotUpdateFrom = tabs.some((tab) => tab.query === id);

  const { ability } = useAuth();

  return (
    <>
      <HiddenField name="id" />
      <Box
        readOnly={disabled}
        actions={[
          ability.can(PermissionAction.Create, PermissionSubject.Template) && (
            <SaveAsSalesforceQueryTemplate disabled={disabled} key="save-as-template" />
          ),
          <CopyFromTemplate
            filter={from ? { type: TemplateType.SalesforceQuery, sourceObject: from } : null}
            onCopy={handleCopyFromTemplate}
            buttonType="text"
            key="copy-from-template"
            disabled={disabled}
          />,
          !!onRemove && <IconButton key="remove" icon={Remove} onClick={onRemove} />,
        ].filter(Boolean)}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Field name="name" label="Name" rules={[getFieldMaxLengthRule("Query name", 30)]}>
              <Input placeholder="Name (optional)" />
            </Field>
          </Col>
          <Col span={24}>
            <SalesforceQueryForm
              fromFieldProps={{
                disabled: cannotUpdateFrom,
                tooltip: cannotUpdateFrom
                  ? "Cannot update the source object for the query that is used in the spreadsheet"
                  : null,
              }}
            />
          </Col>
        </Row>
      </Box>
    </>
  );
};
