import { DeliveryStatus, getDeliveryStatusColor, getDeliveryStatusLabel } from "@/utils";
import { Fragment } from "react";
import { Tag } from "./Tag/Tag";
import { WarningOutlined } from "@ant-design/icons";
import cn from "classnames";
import { Tooltip } from "@/components/common/Tooltip";
import { TagFilter } from "@/components/common/TagFilter";

const AcknowledgementMissingTooltip = ({ children }) => (
  <Tooltip
    title={
      <>
        The acknowledgement file is missing on the partner's storage for more than 2 hours after the release
        was delivered
      </>
    }
  >
    {children}
  </Tooltip>
);

export const DeliveryStatusTag = ({ status, className }) => {
  const isMissingAcknowledgement = status === DeliveryStatus.AcknowledgementMissing;
  const Wrapper = isMissingAcknowledgement ? AcknowledgementMissingTooltip : Fragment;

  return (
    <Wrapper>
      <TagFilter filters={{ status }}>
        <Tag
          className={cn("delivery-status-tag", className)}
          color={getDeliveryStatusColor(status)}
          icon={isMissingAcknowledgement && <WarningOutlined />}
        >
          {getDeliveryStatusLabel(status)}
        </Tag>
      </TagFilter>
    </Wrapper>
  );
};
