import { ExtraActions } from "@/components/common/ExtraActions/ExtraActions";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import type { Webhook } from "@/api/webhooks/types";
import { useDownload } from "@/utils/useDownload";
import { downloadWebhookLog } from "@/api/webhooks";

type Props = {
  webhook: Webhook;
};

export const WebhookActions = ({ webhook }: Props) => {
  const { start: startPartnerLogDownload } = useDownload(downloadWebhookLog, {
    message: "Webhook log is being generated. Download will start automatically",
  });
  const menuItems: MenuProps["items"] = [
    {
      key: "log",
      onClick: () => startPartnerLogDownload(webhook.messageType),
      label: "Webhook Log",
    },
    {
      key: "activity",
      label: <Link to={`/webhooks/${webhook.messageType}/activity`}>Webhook Activity</Link>,
    },
  ];

  return <ExtraActions items={menuItems} />;
};
