import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import cn from "classnames";
import "./SuggestionList.less";
import type { SuggestionProps } from "@tiptap/suggestion";
import type { Variable } from "@/types/variables";
import { VariableType } from "@/types/variables";
import type { FuseResult, FuseResultMatch } from "fuse.js";

type Ref = {
  onKeyDown: (args: { event: KeyboardEvent }) => boolean;
};

export type SuggestionListProps = Omit<SuggestionProps<Variable>, "items"> & {
  items: FuseResult<Variable>[];
};

const highlightLabel = (label: string, matches: readonly FuseResultMatch[]): string => {
  const match = matches.at(0);
  if (!match) {
    return label;
  }
  const textBefore = label.slice(0, match.indices[0][0]);
  const nodes = match.indices.flatMap(([from, to], index, array) => {
    const isLast = index === array.length - 1;
    const slice = label.slice(from, to + 1);
    const mark = slice ? `<mark>${slice}</mark>` : "";
    const textAfter = isLast ? label.slice(to + 1) : label.slice(to + 1, array[index + 1][0]);
    return [mark, textAfter];
  });
  return [textBefore, ...nodes].join("");
};

const getVariableType = (variable: Variable) => variable.name.split(".").at(0) as VariableType;

export const SuggestionList = forwardRef<Ref, SuggestionListProps>(({ items, command }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedItemRef = useRef<HTMLButtonElement | null>(null);

  const selectItem = (index: number) => {
    const variable = items[index]?.item;

    if (variable) {
      command(variable);
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    selectedItemRef.current?.scrollIntoView({ block: "nearest" });
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % items.length);
    selectedItemRef.current?.scrollIntoView({ block: "nearest" });
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    !!items.length && (
      <div className="suggestion-list">
        {items.map(({ item: variable, matches }, index) => (
          <button
            className={cn(
              "suggestion-list-item",
              index === selectedIndex && "suggestion-list-item--selected"
            )}
            key={index}
            ref={index === selectedIndex ? selectedItemRef : null}
            onClick={() => selectItem(index)}
          >
            {getVariableType(variable) !== VariableType.General && (
              <span className="suggestion-list-item__type">
                {getVariableType(variable).slice(0, 1).toUpperCase()}
              </span>
            )}
            <span
              className="suggestion-list-item__label"
              dangerouslySetInnerHTML={{ __html: highlightLabel(variable.label, matches) }}
            />
          </button>
        ))}
      </div>
    )
  );
});
