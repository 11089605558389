import { useCompanies } from "@/api/salesforce-api/hooks";
import { toOptions } from "@/utils/toOptions";
import type { ComponentProps } from "react";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";

type Props = Omit<ComponentProps<typeof MultiSelect>, "options">;

export const CompanyPicker = (props: Props) => {
  const { data, isLoading } = useCompanies();
  return <MultiSelect options={toOptions(data)} {...props} disabled={isLoading || props.disabled} />;
};
