import { Button, Typography } from "antd";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";
import { useLoopItemVariables } from "../LoopBlock/context";
import { useMemo } from "react";
import { isNil } from "ramda";
import Icon from "@ant-design/icons";
import { Plus, Remove } from "@/components/common/Icons";
import { Field as FormField } from "@/components/common/Form/Field";
import { RichTextEditor } from "@/components/common/RichTextEditor";
import { IconButton } from "@/components/common/IconButton/IconButton";

const { Text } = Typography;

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
};

export const DescriptionField = ({ value, onChange, readOnly }: Props) => {
  const { variables: emailMessageVariables } = useEmailMessageContext();
  const { variables: loopVariables } = useLoopItemVariables();
  const variables = useMemo(() => [...emailMessageVariables, ...loopVariables], [
    emailMessageVariables,
    loopVariables,
  ]);

  const handleAdd = () => {
    onChange("");
  };

  const handleRemove = () => {
    onChange(null);
  };

  if (readOnly) {
    if (isNil(value)) {
      return <Text type="secondary">No description</Text>;
    }
    return (
      <FormField name="description">
        <RichTextEditor value={value} variables={variables} readOnly />
      </FormField>
    );
  }

  return isNil(value) ? (
    <Button
      className="field-list-modal__add"
      size="small"
      icon={<Icon component={Plus} />}
      onClick={handleAdd}
    >
      Add Description
    </Button>
  ) : (
    <div className="field-list-modal__description">
      <RichTextEditor value={value} onChange={onChange} variables={variables} />
      <IconButton icon={Remove} onClick={handleRemove} danger />
    </div>
  );
};
