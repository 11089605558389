import { ContentBuilder } from "../ContentBuilder";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { Remove } from "@/components/common/Icons";
import "./Condition.less";
import { atLeastOne } from "@/utils/validation";
import { ConditionBox } from "@/components/common/ConditionBox";
import { useEmailMessageContext } from "@/components/common/EmailMessageForm/context";
import { useLoopItemFieldsForQueryBuilder } from "@/components/common/ContentBuilder/LoopBlock/context";
import { useMemo } from "react";
import type { Fields } from "@react-awesome-query-builder/core";
import { useFieldPath } from "@/components/common/Form/hooks";
import { Alert, Col, Form, Radio, Row } from "antd";
import type { SalesforceQuery } from "@/api/reports/types";
import { useSalesforceObject } from "@/api/salesforce-objects/hooks";
import { getSalesforceObjectQueryBuilderFields } from "@/utils/query-builder";
import { Field } from "@/components/common/Form/Field";
import { HiddenField } from "@/components/common/HiddenField";
import { QueryPicker } from "@/components/Reports/QueryPicker";
import { Spinner } from "@/components/common/Spinner/Spinner";

type Props = {
  maxNesting: number;
  onRemove: () => void;
};

const BasicConditionBox = ({ maxNesting }: Pick<Props, "maxNesting">) => {
  const { fieldsForConditions } = useEmailMessageContext();
  const { fields: loopFieldsForConditions, isLoading } = useLoopItemFieldsForQueryBuilder();

  const fields: Fields = useMemo(
    () => ({
      ...fieldsForConditions,
      ...loopFieldsForConditions,
    }),
    [fieldsForConditions, loopFieldsForConditions]
  );

  return isLoading ? (
    <Spinner height={50} />
  ) : (
    <ConditionBox fields={fields}>
      <ContentBuilder
        name="content"
        maxNesting={maxNesting}
        rules={[atLeastOne("At least one content block should be added")]}
      />
    </ConditionBox>
  );
};

const QueryConditionBox = ({ maxNesting }: Pick<Props, "maxNesting">) => {
  const { getAbsolutePath } = useFieldPath();
  const queries = Form.useWatch<SalesforceQuery[]>("queries") ?? [];
  const queryId = Form.useWatch<string>(getAbsolutePath("queryId"));
  const selectedQuery = queries.find((query) => query.id === queryId);
  const { obj } = useSalesforceObject(selectedQuery?.from);
  const fields = obj ? getSalesforceObjectQueryBuilderFields(obj, 2) : {};

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Field name="mode" label="Mode" initialValue="some">
            <Radio.Group
              buttonStyle="solid"
              options={[
                { value: "some", label: "Some" },
                { value: "all", label: "All" },
                { value: "none", label: "None" },
              ]}
              optionType="button"
            />
          </Field>
        </Col>
        <Col span={8}>
          <Field
            name="queryId"
            label="Query"
            rules={[{ required: true, message: "Please, select the query" }]}
          >
            <QueryPicker />
          </Field>
        </Col>
      </Row>
      {selectedQuery ? (
        <ConditionBox fields={fields}>
          <ContentBuilder
            name="content"
            maxNesting={maxNesting}
            rules={[atLeastOne("At least one content block should be added")]}
          />
        </ConditionBox>
      ) : (
        <Alert message="Please, select query to be able to configure condition" />
      )}
    </>
  );
};

export const Condition = ({ maxNesting, onRemove }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const conditionType = Form.useWatch<"basic" | "query">(getAbsolutePath("type")) ?? "basic";
  return (
    <div className="condition">
      <HiddenField name="type" />
      <div className="condition__body">
        {conditionType === "basic" ? (
          <BasicConditionBox maxNesting={maxNesting} />
        ) : (
          <QueryConditionBox maxNesting={maxNesting} />
        )}
      </div>
      <IconButton icon={Remove} onClick={onRemove} className="condition__remove" />
    </div>
  );
};
