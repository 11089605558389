import type { ColumnsType } from "antd/lib/table/interface";
import type { EmailAlert } from "@/api/email-alerts/types";
import { DescriptionCell } from "@/components/common/DescriptionCell";
import { DuplicateEmailAlertButton } from "./DuplicateEmailAlertButton";

export const columns: ColumnsType<EmailAlert> = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Source Object",
    dataIndex: ["sourceObject", "label"],
  },
  {
    title: "Description",
    dataIndex: "description",
    render: (description) => <DescriptionCell description={description} />,
  },
  {
    title: "Duplicate",
    render: (_, alert) => <DuplicateEmailAlertButton alert={alert} />,
  },
];
