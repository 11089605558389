import type { EmailAlert } from "@/api/email-alerts/types";
import type { Status } from "@/utils";
import type { SalesforceObject } from "@/api/salesforce-objects/types";

export enum FlowActionType {
  SendEmailAlert = "send-email-alert",
  RunScript = "run-script",
}

export type FlowAction = {
  id: string;
  order: number;
  type: FlowActionType;
  scriptKey?: string;
  emailAlertId?: string;
  emailAlert?: EmailAlert;
};

export enum ChangeType {
  Create = "create",
  Update = "update",
}

export enum FlowEventType {
  ChangeEvent = "change-event",
  PlatformEvent = "platform-event",
}

export type FlowEvent = {
  name: string;
  label: string;
  entity: string;
  type: FlowEventType;
  deletedAt?: string;
  eventObject?: SalesforceObject;
};

export type Flow = {
  id: string;
  name: string;
  description?: string;
  status: Status;
  deactivationReason?: string;
  eventName?: string;
  event?: FlowEvent;
  changeType?: ChangeType;
  updateCriteria?: Record<string, unknown>;
  filter?: Record<string, unknown>;
  actions: FlowAction[];
};

export type CreateFlowDto = Omit<Flow, "id" | "alert" | "event">;
export type UpdateFlowDto = Pick<Flow, "id"> & Partial<CreateFlowDto>;

export type ImportPlatformEventParams = { name: string; refresh?: boolean };

export type Script = {
  key: string;
  label: string;
  sourceObject: string;
  details?: string;
  description?: string;
};

export type ScriptFilters = {
  sourceObject?: string;
};

export type ScriptDescriptionUpdateDto = {
  key: string;
  description?: string;
};
