import Icon from "@ant-design/icons";
import { Plus, Remove } from "../../Icons";
import { Button as AntdButton } from "antd";
import type { ButtonProps } from "@react-awesome-query-builder/ui";
import { IconButton } from "@/components/common/IconButton/IconButton";

const Button = ({ type, onClick, label, readonly }: ButtonProps) => {
  const hideLabelsFor = {
    addRuleGroup: true,
  };
  const btnLabel = hideLabelsFor[type] ? "" : label;

  const typeToClass = {
    addRule: "action action--ADD-RULE",
    addGroup: "action action--ADD-GROUP",
    delRule: "action action--DELETE", //?
    delGroup: "action action--DELETE",
    delRuleGroup: "action action--DELETE",
    addRuleGroup: "action action--ADD-GROUP",
  };

  if (type === "delRule" || type === "delGroup" || type === "delRuleGroup") {
    return (
      <IconButton
        onClick={onClick}
        disabled={readonly}
        key={type}
        className={typeToClass[type]}
        icon={Remove}
      />
    );
  }

  return (
    <AntdButton
      icon={<Icon component={Plus} />}
      className={typeToClass[type]}
      onClick={onClick}
      disabled={readonly}
    >
      {btnLabel}
    </AntdButton>
  );
};

export default Button;
