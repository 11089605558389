import type { FormItemProps } from "antd";
import { Form } from "antd";
import { joinFieldName } from "./utils";
import { useFieldPath } from "./hooks";

export const Field = ({ name, ...rest }: FormItemProps) => {
  const { localPath } = useFieldPath();
  return (
    <Form.Item
      colon={false}
      labelAlign="left"
      labelCol={{ span: rest.label ? 6 : 0 }}
      wrapperCol={{ span: rest.label ? 18 : 24 }}
      {...rest}
      name={joinFieldName(...localPath, name)}
    />
  );
};
