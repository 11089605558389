import { Box } from "../../../common/Box/Box";
import { Col, Divider, Form, Input, Row } from "antd";
import { FieldGroup } from "../../../common/Form/FieldGroup";
import { RecipientList } from "@/components/common/EmailMessageForm";
import { VariationVariables } from "@/components/common/VariationVariables";
import { getFieldMaxLengthRule } from "@/utils/validation";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import { Field } from "@/components/common/Form/Field";
import { useFieldPath } from "@/components/common/Form/hooks";
import { getEmptyArray } from "@/utils/empty";
import type { SalesforceQuery } from "@/api/reports/types";
import type { MultiSelectProps } from "@/components/common/MultiSelect/MultiSelect";
import { MultiSelect } from "@/components/common/MultiSelect/MultiSelect";

type Props = {
  disabled?: boolean;
};

const SourceQueryPicker = ({ value, onChange }: Pick<MultiSelectProps, "value" | "onChange">) => {
  const queries = Form.useWatch<SalesforceQuery[]>("queries") ?? getEmptyArray();

  const from = value?.length > 0 ? queries.find((query) => query.id === value[0])?.from : null;

  const options = queries
    .filter((entry) => !!entry.from)
    .map((entry, index) => ({
      value: entry.id,
      label: entry.name ?? `Query #${index + 1}`,
      disabled: from ? entry.from !== from : false,
    }));

  return (
    <MultiSelect
      placeholder="Select Queries"
      value={value}
      onChange={onChange}
      options={options}
      notFoundContent="No queries available"
    />
  );
};

export const Variation = ({ disabled }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const sourceQueryIds = Form.useWatch<string[]>(getAbsolutePath(["sourceQueries"])) ?? getEmptyArray();
  const queries = Form.useWatch<SalesforceQuery[]>(["queries"]) ?? getEmptyArray();

  const from = queries?.find((query) => sourceQueryIds.includes(query.id))?.from;

  return (
    <Box readOnly={disabled}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Field name="name" label="Name" rules={[getFieldMaxLengthRule("Variation name", 30)]}>
            <Input />
          </Field>
        </Col>
        <Col span={12}>
          <Field
            name="sourceQueries"
            label="Source Queries"
            rules={[{ required: true, message: "Please, select source queries" }]}
            tooltip="Only queries with same source object can be selected"
          >
            <SourceQueryPicker />
          </Field>
        </Col>
        {sourceQueryIds?.length > 0 ? (
          <Col span={24}>
            <FieldGroup prefix="query">
              <Field
                key={from}
                name="where"
                label="Query"
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "At least one of the rules should be configured",
                  },
                ]}
              >
                <SalesforceQueryBuilder from={from} />
              </Field>
            </FieldGroup>
          </Col>
        ) : null}
        <Col span={24}>
          <Form.Item label="To" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <RecipientList name={["email", "to"]} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="CC" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <RecipientList name={["email", "cc"]} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Variables</Divider>
      <VariationVariables />
    </Box>
  );
};
