import { Col, Form, Row } from "antd";
import { FullWidthButton } from "../../../common/FullWidthButton/FullWidthButton";
import { AssetNamingForm } from "./AssetNamingForm";
import { AssetType } from "../../../../utils";
import classNames from "classnames";
import { Plus } from "../../../common/Icons";

export const AssetNamingsForm = ({ disabled }) => {
  const usedTypes = (Form.useWatch("assetNamings") || []).map((naming) => naming.assetType);
  const availableAssetTypes = Object.values(AssetType).filter((type) => !usedTypes.includes(type));

  return (
    <div className={classNames(disabled && "form-disabled")}>
      <Form.List name="assetNamings">
        {(fields, { remove, add }) => (
          <Row gutter={[16, 16]}>
            {fields.map((field) => (
              <Col span={24} key={field.key}>
                <AssetNamingForm name={[field.name]} onRemove={() => remove(field.name)} />
              </Col>
            ))}
            {availableAssetTypes.map((type) => (
              <Col span={24} key={type}>
                <FullWidthButton
                  key={type}
                  onClick={() => add({ assetType: type, delimiter: "_", data: [] })}
                  label={`Add ${type} naming`}
                  icon={Plus}
                />
              </Col>
            ))}
          </Row>
        )}
      </Form.List>
    </div>
  );
};
