import React from "react";
import { columns } from "./columns";
import { DetailsTable } from "../DetailsTable/DetailsTable";
import { getDeliveryStatusColor, getDeliveryStatusLabel } from "../../../../utils";

export const Deliveries = ({ deliveries, counts }) => {
  return (
    <DetailsTable
      counts={counts}
      records={deliveries}
      columns={columns}
      getStatusColor={getDeliveryStatusColor}
      getStatusLabel={getDeliveryStatusLabel}
      title="Deliveries"
    />
  );
};
