import { useDownload } from "@/utils/useDownload";
import { downloadSpreadsheet } from "@/api/report-logs";
import { IconButton } from "@/components/common/IconButton/IconButton";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import type { ReportLog } from "@/api/report-logs/types";

type Props = {
  log: ReportLog;
};

export const DownloadSpreadsheetButton = ({ log }: Props) => {
  const { start: download, loading: downloading } = useDownload(() => downloadSpreadsheet(log.id), {
    message: "The spreadsheet is being downloaded",
    noContentMessage: "The are no records to include into the file",
  });

  return (
    <Tooltip title="Download Spreadsheet">
      <IconButton onClick={download} disabled={downloading}>
        {downloading ? <LoadingOutlined /> : <DownloadOutlined />}
      </IconButton>
    </Tooltip>
  );
};
