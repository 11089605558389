export const LogoColor = {
  Black: "black",
  White: "white",
  WarmGrey: "warm-grey",
};

export const LogoSize = {
  Large: "large",
  Small: "small",
};
