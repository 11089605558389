import type { ReportLog } from "@/api/report-logs/types";
import { ReportLogStatus } from "@/api/report-logs/types";
import type { FormItemProps } from "antd";
import { Alert, Col, Form, Row, Typography } from "antd";
import { CorrespondentTag } from "./CorrespondentTag";
import { RecipientList } from "./RecipientList";

const Item = (props: Pick<FormItemProps, "label" | "children">) => (
  <Form.Item {...props} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} />
);

type Props = {
  log: ReportLog;
};

export const ReportLogDetails = ({ log }: Props) => {
  if (log.status === ReportLogStatus.Failed) {
    return <Alert type="error" message={log.reason} />;
  }
  return (
    <Row gutter={[12, 12]}>
      <Col>
        <Item label="From">
          <CorrespondentTag correspondent={log.email.from} />
        </Item>
      </Col>
      <Col>
        <Item label="To">
          <RecipientList recipients={log.email.to} />
        </Item>
      </Col>
      {log.email.cc.length > 0 && (
        <Col>
          <Item label="CC">
            <RecipientList recipients={log.email.cc} />
          </Item>
        </Col>
      )}
      {log.email.bcc.length > 0 && (
        <Col>
          <Item label="BCC">
            <RecipientList recipients={log.email.bcc} />
          </Item>
        </Col>
      )}
      {log.email.subject && (
        <Col span={24}>
          <Item label="Subject">{log.email.subject}</Item>
        </Col>
      )}
      {log.email.body && (
        <Col span={24}>
          <Item label="Body">
            <Typography dangerouslySetInnerHTML={{ __html: log.email.body }} />
          </Item>
        </Col>
      )}
    </Row>
  );
};
