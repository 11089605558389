import { Select } from "antd";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "../../Icons";
import { useCampaigns } from "@/api/salesforce-api/hooks";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};

export const CampaignSelect = ({ value, onChange }: Props) => {
  const { isLoading, data: campaigns } = useCampaigns();
  return (
    <Select
      loading={isLoading}
      value={value}
      onChange={onChange}
      suffixIcon={<Icon component={DropdownArrow} />}
      options={campaigns.map((campaign) => ({ value: campaign.id, label: campaign.name }))}
      placeholder="Select campaign"
      showSearch
      filterOption={(inputValue, option) =>
        String(option.label).toLowerCase().includes(inputValue.toLowerCase())
      }
    />
  );
};
