import React from "react";

export type LayoutContextType = {
  isSecondary: boolean;
  setSecondary: () => void;
  setPrimary: () => void;
  isNavigateAllowed: boolean;
  preventNavigation: () => void;
  allowNavigation: () => void;
  setFullWidth: (value: boolean) => void;
};

export const LayoutContext = React.createContext<LayoutContextType>({
  isSecondary: false,
  setSecondary: () => {},
  setPrimary: () => {},
  isNavigateAllowed: true,
  preventNavigation: () => {},
  allowNavigation: () => {},
  setFullWidth: () => {},
});
