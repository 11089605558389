import { Color } from "@/utils/color";

export enum DeliveryStatus {
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Delivered = "delivered",
  Delayed = "delayed",
  AcknowledgementMissing = "acknowledgement_missing",
}

const labelLookup: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Started]: "Started",
  [DeliveryStatus.Succeeded]: "Succeeded",
  [DeliveryStatus.Failed]: "Failed",
  [DeliveryStatus.Delayed]: "Delayed",
  [DeliveryStatus.Delivered]: "Delivered",
  [DeliveryStatus.AcknowledgementMissing]: "Acknowledgement Missing",
};

export const getDeliveryStatusLabel = (status: DeliveryStatus) => labelLookup[status];

const colorLookup: Record<DeliveryStatus, Color> = {
  [DeliveryStatus.Started]: Color.Success,
  [DeliveryStatus.Succeeded]: Color.Success,
  [DeliveryStatus.Delivered]: Color.Accent,
  [DeliveryStatus.Failed]: Color.Failed,
  [DeliveryStatus.Delayed]: Color.Inactive,
  [DeliveryStatus.AcknowledgementMissing]: Color.Warning,
};

export const getDeliveryStatusColor = (status: DeliveryStatus) => colorLookup[status];
