import { Col, Form, Row } from "antd";
import React from "react";
import { Format } from "../../../utils";
import { OpenplayObjectType } from "@/types/common";
import PathSelect from "./PathSelect";
import PropTypes from "prop-types";
import { CONSTANT_MAPPING_PREFIX, EMPTY_VALUE_PLACEHOLDER } from "./utils";
import CSVColumnInput from "./CSVColumnInput";
import { isEmptyField } from "../../Feeds/utils";
import { IconButton } from "../IconButton/IconButton";
import { Remove } from "../Icons";
import { isNotDraft } from "../../../utils/validation";

const getExtra = (value) => {
  if (value?.[0] === EMPTY_VALUE_PLACEHOLDER) {
    return null;
  }
  if (value?.[0].startsWith(CONSTANT_MAPPING_PREFIX)) {
    return value[0].replace(CONSTANT_MAPPING_PREFIX, "Constant: ");
  }
  return value?.join("/");
};

const requiredIfNotEmptyField = (field, oppositeField) => (form) => ({
  required: isNotDraft(form) && !isEmptyField(form, ["mapping", "data", field.name, oppositeField]),
  message: "Field is mandatory.",
});

export default function ExtraMappingField({
  inputFormat,
  outputFormat,
  onRemove,
  field,
  defaultMappings,
  inputObjectType,
}) {
  const inputRuntimeVariables =
    inputFormat === Format.JSON && inputObjectType === OpenplayObjectType.Release
      ? [
          "current_date_time",
          "current_date_time_iso",
          "total_tracks_duration_iso",
          "sender_dpid",
          "sender_name",
          "message_thread_id",
          "message_id",
          "recipient_dpid",
          "recipient_name",
          "include_assets",
          "is_cover_available",
          "is_preorder_deal",
        ]
      : [];

  const targetFieldName = ["mapping", "data", field.name, "target"];
  const sourceFieldName = ["mapping", "data", field.name, "source"];

  const source = Form.useWatch(sourceFieldName);
  const target = Form.useWatch(targetFieldName);

  const overrideDefaults = defaultMappings.map(({ target }) => target);

  return (
    <Row className="table-row feed-extra-mapping__row" gutter={16}>
      <Col flex="auto">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={[field.name, "source"]}
              extra={getExtra(source)}
              validateTrigger="onChange"
              rules={[{ required: true, message: "Field is mandatory." }]}
              dependencies={[targetFieldName]}
              wrapperCol={{ span: 24 }}
            >
              <PathSelect
                format={inputFormat}
                objectType={inputObjectType}
                excludeArrays={outputFormat === Format.CSV}
                runtimeVariables={inputRuntimeVariables}
                canAddConstants
                fieldIndex={field.name}
                includeEmpty
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={[field.name, "target"]}
              extra={getExtra(target)}
              validateTrigger="onChange"
              rules={outputFormat === Format.CSV ? [] : [requiredIfNotEmptyField(field, "source")]}
              dependencies={[sourceFieldName]}
              wrapperCol={{ span: 24 }}
            >
              {outputFormat === Format.CSV ? (
                <CSVColumnInput />
              ) : (
                <PathSelect format={outputFormat} defaults={overrideDefaults} fieldIndex={field.name} />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col flex="none">
        <IconButton className="delete-button" icon={Remove} onClick={onRemove} />
      </Col>
    </Row>
  );
}

ExtraMappingField.propTypes = {
  inputFormat: PropTypes.oneOf(Object.values(Format)).isRequired,
  outputFormat: PropTypes.oneOf(Object.values(Format)).isRequired,
  onRemove: PropTypes.func.isRequired,
  data: PropTypes.object,
  field: PropTypes.object.isRequired,
};
