import type { PropsWithChildren } from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import Icon from "@ant-design/icons";
import { DragHandle, Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import "./SortableListItem.less";

type SortableListItemProps = PropsWithChildren<{
  onRemove?: () => void;
}>;

const Handle = SortableHandle(() => <Icon className="sortable-list-item__handle" component={DragHandle} />);

const SortableListItem = SortableElement<SortableListItemProps>(({ onRemove, children }) => (
  <div className="sortable-list-item">
    <Handle />
    <div className="sortable-list-item__content">{children}</div>
    {!!onRemove && <IconButton className="sortable-list-item__remove" icon={Remove} onClick={onRemove} />}
  </div>
));

export { SortableListItem };
