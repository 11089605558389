import { notification } from "antd";
import type { ArgsProps } from "antd/es/notification";

export const openFormNotification = (name: string) =>
  openNotification({
    description: `Changes for ${name} are saved successfully`,
  });

export type NotificationParams = Partial<Pick<ArgsProps, "message" | "description" | "type">>;

export const openNotification = ({
  message = "Notification",
  description = "",
  type = "success",
}: NotificationParams) => {
  notification.open({
    description,
    message,
    type,
    duration: 5,
    placement: "bottomRight",
  });
};
