import useSWR, { mutate } from "swr";
import {
  fetchSalesforceObject,
  fetchSalesforceObjectRelations,
  fetchSalesforceObjects,
} from "@/api/salesforce-objects/index";
import type { SalesforceObjectFilters } from "@/api/salesforce-objects/types";

const key = "salesforce-objects";

export const useSalesforceObjects = (filters: SalesforceObjectFilters = {}) =>
  useSWR([key, filters], ([_, filters]) => fetchSalesforceObjects(filters), {
    fallbackData: { data: [] },
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

export const useSalesforceObject = (name: string) => {
  const { data, isLoading, error } = useSWR(name ? [key, name] : null, ([, name]) => fetchSalesforceObject(name), {
    fallbackData: null,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { obj: data, isLoading, error };
};

export const useSalesforceObjectRelations = (name: string) =>
  useSWR([key, name, "relations"], ([, name]) => fetchSalesforceObjectRelations(name), { fallbackData: [] });

export const revalidateSalesforceObjects = () =>
  mutate((args) => (Array.isArray(args) && args.at(0) === key ? args : null));
