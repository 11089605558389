import { SpreadsheetForm } from "@/components/Spreadsheets/SpreadsheetForm";
import { useReportVariables } from "@/components/Reports/variables";

type Props = {
  readOnly: boolean;
};

export const ReportSpreadsheet = ({ readOnly }: Props) => {
  const variables = useReportVariables();
  return <SpreadsheetForm readOnly={readOnly} entity="report" variables={variables} />;
};
