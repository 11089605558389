import { IconButton } from "@/components/common/IconButton/IconButton";
import {
  Bold,
  BulletedList,
  Clear,
  HeadingOne,
  HeadingTwo,
  Italic,
  LinkBroken,
  OrderedList,
  Strike,
  Underline,
} from "../icons";
import "./Toolbar.less";
import { buttonClassName, getButtonClassName } from "./utils";
import { LinkButton } from "./LinkButton";
import type { Editor } from "@tiptap/react";
import { VariableButton } from "./VariableButton";
import type { Variables } from "@/components/common/RichTextEditor/Variables/types";

type Props = {
  editor: Editor;
  variables: Variables;
};

const Divider = () => <div className="rich-text-editor-toolbar__divider" />;

export const Toolbar = ({ editor, variables }: Props) => {
  return (
    <div className="rich-text-editor-toolbar">
      <IconButton
        icon={HeadingOne}
        onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
        disabled={!editor?.can().chain().focus().toggleHeading({ level: 1 }).run()}
        className={getButtonClassName(editor, "heading", { level: 1 })}
      />
      <IconButton
        icon={HeadingTwo}
        onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
        disabled={!editor?.can().chain().focus().toggleHeading({ level: 2 }).run()}
        className={getButtonClassName(editor, "heading", { level: 2 })}
      />
      <Divider />
      <IconButton
        icon={Bold}
        onClick={() => editor?.chain().focus().toggleBold().run()}
        disabled={!editor?.can().chain().focus().toggleBold().run()}
        className={getButtonClassName(editor, "bold")}
      />
      <IconButton
        icon={Italic}
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        disabled={!editor?.can().chain().focus().toggleItalic().run()}
        className={getButtonClassName(editor, "italic")}
      />
      <IconButton
        icon={Strike}
        onClick={() => editor?.chain().focus().toggleStrike().run()}
        disabled={!editor?.can().chain().focus().toggleStrike().run()}
        className={getButtonClassName(editor, "strike")}
      />
      <IconButton
        icon={Underline}
        onClick={() => editor?.chain().focus().toggleUnderline().run()}
        disabled={!editor?.can().chain().focus().toggleUnderline().run()}
        className={getButtonClassName(editor, "underline")}
      />
      <Divider />
      <IconButton
        icon={BulletedList}
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        className={getButtonClassName(editor, "bulletList")}
      />
      <IconButton
        icon={OrderedList}
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        className={getButtonClassName(editor, "orderedList")}
      />
      <Divider />
      <LinkButton editor={editor} variables={variables} />
      <IconButton
        icon={LinkBroken}
        onClick={() => editor?.chain().focus().unsetLink().run()}
        disabled={!editor?.isActive("link")}
        className={buttonClassName}
      />
      <Divider />
      <VariableButton variables={variables} editor={editor} />
      <IconButton
        icon={Clear}
        onClick={() => editor?.chain().focus().unsetAllMarks().clearNodes().run()}
        className={buttonClassName}
      />
    </div>
  );
};
