export const ddex_3_8 = {
  MessageHeader: {
    MessageThreadId: "string",
    MessageId: "string",
    MessageFileName: "string",
    MessageSender: {
      PartyId: {
        _Namespace: "string",
        _IsDPID: "false",
        _IsISNI: "true",
        __text: "string",
      },
      PartyName: {
        FullName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesBeforeKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        KeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesAfterKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        AbbreviatedName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        _LanguageAndScriptCode: "string",
      },
      TradingName: {
        _LanguageAndScriptCode: "string",
        __text: "string",
      },
      _LanguageAndScriptCode: "string",
    },
    SentOnBehalfOf: {
      PartyId: {
        _Namespace: "string",
        _IsDPID: "true",
        _IsISNI: "true",
        __text: "string",
      },
      PartyName: {
        FullName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesBeforeKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        KeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesAfterKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        AbbreviatedName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        _LanguageAndScriptCode: "string",
      },
      TradingName: {
        _LanguageAndScriptCode: "string",
        __text: "string",
      },
      _LanguageAndScriptCode: "string",
    },
    MessageRecipient: {
      PartyId: {
        _Namespace: "string",
        _IsDPID: "true",
        _IsISNI: "true",
        __text: "string",
      },
      PartyName: {
        FullName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesBeforeKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        KeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesAfterKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        AbbreviatedName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        _LanguageAndScriptCode: "string",
      },
      TradingName: {
        _LanguageAndScriptCode: "string",
        __text: "string",
      },
      _LanguageAndScriptCode: "string",
    },
    MessageCreatedDateTime: "2006-08-19T20:27:14+03:00",
    MessageAuditTrail: {
      MessageAuditTrailEvent: {
        MessagingPartyDescriptor: {
          PartyId: {
            _Namespace: "string",
            _IsDPID: "false",
            _IsISNI: "true",
            __text: "string",
          },
          PartyName: {
            FullName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            NamesBeforeKeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            KeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            NamesAfterKeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            AbbreviatedName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
          },
          TradingName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
        },
        DateTime: "2017-05-15T09:41:13",
      },
      _LanguageAndScriptCode: "string",
    },
    Comment: {
      _LanguageAndScriptCode: "string",
      __text: "string",
    },
    MessageControlType: "TestMessage",
    _LanguageAndScriptCode: "string",
  },
  UpdateIndicator: "UpdateMessage",
  IsBackfill: "false",
  CatalogTransfer: {
    CatalogTransferCompleted: "false",
    EffectiveTransferDate: {
      _IsApproximate: "false",
      _IsBefore: "true",
      _IsAfter: "true",
      _TerritoryCode: "anySimpleType",
      _LocationDescription: "string",
      _LanguageAndScriptCode: "string",
      __text: "string",
    },
    CatalogReleaseReferenceList: {
      CatalogReleaseReference: "string",
    },
    TerritoryCode: {
      _IdentifierType: "TIS",
      __text: "2123",
    },
    ExcludedTerritoryCode: {
      _IdentifierType: "TIS",
      __text: "686",
    },
    TransferringFrom: {
      PartyId: {
        _Namespace: "string",
        _IsDPID: "false",
        _IsISNI: "true",
        __text: "string",
      },
      PartyName: {
        FullName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesBeforeKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        KeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesAfterKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        AbbreviatedName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        _LanguageAndScriptCode: "string",
      },
    },
    TransferringTo: {
      PartyId: {
        _Namespace: "string",
        _IsDPID: "false",
        _IsISNI: "false",
        __text: "string",
      },
      PartyName: {
        FullName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        FullNameAsciiTranscribed: "string",
        FullNameIndexed: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesBeforeKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        KeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        NamesAfterKeyName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        AbbreviatedName: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        _LanguageAndScriptCode: "string",
      },
    },
  },
  WorkList: [
    {
      MusicalWork: {
        MusicalWorkId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "true",
        },
        MusicalWorkReference: "string",
        ReferenceTitle: {
          TitleText: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          SubTitle: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
        },
        RightsAgreementId: {
          MWLI: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
        },
        MusicalWorkContributor: {
          PartyId: {
            _Namespace: "string",
            _IsDPID: "false",
            _IsISNI: "true",
            __text: "string",
          },
          PartyName: {
            FullName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            FullNameAsciiTranscribed: "string",
            FullNameIndexed: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            NamesBeforeKeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            KeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            NamesAfterKeyName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            AbbreviatedName: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
          },
          MusicalWorkContributorRole: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Arranger",
          },
          SocietyAffiliation: {
            TerritoryCode: {
              _IdentifierType: "ISO",
              __text: "BY",
            },
            ExcludedTerritoryCode: {
              _IdentifierType: "TIS",
              __text: "SO",
            },
            MusicRightsSociety: {
              PartyId: {
                _Namespace: "string",
                _IsDPID: "false",
                _IsISNI: "true",
                __text: "string",
              },
              PartyName: {
                FullName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                FullNameAsciiTranscribed: "string",
                FullNameIndexed: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesBeforeKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                KeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesAfterKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                AbbreviatedName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
            },
          },
          _SequenceNumber: "100",
        },
        MusicalWorkType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "OriginalMusicalWork",
        },
        RightShare: {
          RightShareId: {
            MWLI: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          RightShareReference: "string",
          RightShareCreationReferenceList: {
            RightShareWorkReference: "string",
            RightShareResourceReference: "string",
            RightShareReleaseReference: "string",
          },
          TerritoryCode: {
            _IdentifierType: "TIS",
            __text: "192",
          },
          ExcludedTerritoryCode: {
            _IdentifierType: "TIS",
            __text: "XK",
          },
          RightsType: {
            _TerritoryCode: "anySimpleType",
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "MechanicalRight",
          },
          UseType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "DubForLivePerformance",
          },
          UserInterfaceType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "LocalStorageJukebox",
          },
          DistributionChannelType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "AsPerContract",
          },
          CarrierType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "SacdLpStereo",
          },
          CommercialModelType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "RightsClaimModel",
          },
          MusicalWorkRightsClaimType: "SocietyClaim",
          RightsController: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "true",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RightsAdministrator",
            RightShareUnknown: "false",
            RightSharePercentage: {
              _HasMaxValueOfOne: "true",
              __text: "1000.00",
            },
            RightsControllerType: "OriginalOwner",
            _SequenceNumber: "100",
          },
          ValidityPeriod: {
            StartDate: {
              _IsApproximate: "true",
              _IsBefore: "true",
              _IsAfter: "false",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            EndDate: {
              _IsApproximate: "true",
              _IsBefore: "true",
              _IsAfter: "true",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            StartDateTime: {
              _IsApproximate: "false",
              _IsBefore: "true",
              _IsAfter: "false",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "2009-07-28T22:14:45",
            },
            EndDateTime: {
              _IsApproximate: "true",
              _IsBefore: "false",
              _IsAfter: "true",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "2006-08-04T13:16:57",
            },
          },
          RightShareUnknown: "false",
          RightSharePercentage: {
            _HasMaxValueOfOne: "false",
            __text: "1000.00",
          },
          TariffReference: {
            _LanguageAndScriptCode: "string",
            _TariffSubReference: "string",
            __text: "string",
          },
          LicenseStatus: "Revoked",
          HasFirstLicenseRefusal: "false",
          _LanguageAndScriptCode: "string",
        },
        MusicalWorkDetailsByTerritory: {
          TerritoryCode: {
            _IdentifierType: "TIS",
            __text: "XK",
          },
          ExcludedTerritoryCode: {
            _IdentifierType: "ISO",
            __text: "188",
          },
          MusicalWorkContributor: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "false",
              _IsISNI: "true",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            MusicalWorkContributorRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Author",
            },
            SocietyAffiliation: {
              TerritoryCode: {
                _IdentifierType: "ISO",
                __text: "768",
              },
              ExcludedTerritoryCode: {
                _IdentifierType: "TIS",
                __text: "Worldwide",
              },
              MusicRightsSociety: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
              },
            },
            _SequenceNumber: "100",
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
        },
        _IsUpdated: "false",
        _LanguageAndScriptCode: "string",
      },
      _LanguageAndScriptCode: "string",
    },
  ],
  CueSheetList: [
    {
      CueSheet: {
        CueSheetId: {
          _Namespace: "string",
          __text: "string",
        },
        CueSheetReference: "string",
        CueSheetType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "SurrogateCueSheet",
        },
        Cue: {
          CueUseType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Theme",
          },
          CueThemeType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "UserDefined",
          },
          CueVocalType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Vocal",
          },
          IsDance: "false",
          CueVisualPerceptionType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Background",
          },
          CueOrigin: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "PreexistingMusic",
          },
          CueCreationReference: {
            CueWorkReference: "string",
            CueResourceReference: "string",
          },
          ReferencedCreationType: "MusicalWork",
          ReferencedCreationId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ISRC: "string",
            ISMN: "string",
            ISAN: "string",
            VISAN: "string",
            ISBN: "string",
            ISSN: "string",
            SICI: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          ReferencedCreationTitle: {
            TitleText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubTitle: {
              _LanguageAndScriptCode: "string",
              _SubTitleType: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _TitleType: "AbbreviatedDisplayTitle",
          },
          ReferencedCreationContributor: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "true",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            ResourceContributorRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "FilmEditor",
            },
            IsFeaturedArtist: "false",
            IsContractedArtist: "false",
            InstrumentType: "string",
            ArtistDelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Playback",
              },
              UserInterfaceType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "PhysicalMediaWriter",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2015-12-11T07:11:19",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2017-09-10T04:10:23+03:00",
                },
              },
              TerritoryOfRightsDelegation: {
                _IdentifierType: "ISO",
                __text: "736",
              },
              MembershipType: "WorldwideMember",
            },
            Sex: "Male",
            Nationality: "IdentifierType",
            DateAndPlaceOfBirth: {
              _IsApproximate: "false",
              _IsBefore: "false",
              _IsAfter: "true",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            DateAndPlaceOfDeath: {
              _IsApproximate: "true",
              _IsBefore: "false",
              _IsAfter: "false",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            PrimaryRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Choreographer",
            },
            Performance: {
              Territory: {
                _IdentifierType: "TIS",
                __text: "RHZW",
              },
              Date: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "true",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
            },
            PrimaryInstrumentType: "string",
            GoverningAgreementType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            ContactInformation: {
              EmailAddress: "string",
              PhoneNumber: "string",
              FaxNumber: "string",
            },
            TerritoryOfResidency: {
              _IdentifierType: "TIS",
              __text: "YE",
            },
            Citizenship: {
              _IdentifierType: "TIS",
              __text: "BN",
            },
            AdditionalRoles: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Contributor",
            },
            Genre: {
              GenreText: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              SubGenre: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            Membership: {
              Organization: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
              },
              MembershipType: "RegionalMember",
              StartDate: "string",
              EndDate: "string",
            },
            _SequenceNumber: "100",
          },
          ReferencedIndirectCreationContributor: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "true",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            MusicalWorkContributorRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "SubstitutedPublisher",
            },
            SocietyAffiliation: {
              TerritoryCode: {
                _IdentifierType: "ISO",
                __text: "Worldwide",
              },
              ExcludedTerritoryCode: {
                _IdentifierType: "ISO",
                __text: "XK",
              },
              MusicRightsSociety: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
              },
            },
            _SequenceNumber: "100",
          },
          ReferencedCreationCharacter: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "false",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            ResourceContributor: {
              PartyId: {
                _Namespace: "string",
                _IsDPID: "true",
                _IsISNI: "true",
                __text: "string",
              },
              PartyName: {
                FullName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                FullNameAsciiTranscribed: "string",
                FullNameIndexed: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesBeforeKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                KeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesAfterKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                AbbreviatedName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
              ResourceContributorRole: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Orchestra",
              },
              IsFeaturedArtist: "true",
              IsContractedArtist: "true",
              InstrumentType: "string",
              ArtistDelegatedUsageRights: {
                UseType: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "UseAsRingtune",
                },
                UserInterfaceType: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "UserDefined",
                },
                PeriodOfRightsDelegation: {
                  StartDate: {
                    _IsApproximate: "false",
                    _IsBefore: "false",
                    _IsAfter: "true",
                    _TerritoryCode: "anySimpleType",
                    _LocationDescription: "string",
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  EndDate: {
                    _IsApproximate: "false",
                    _IsBefore: "true",
                    _IsAfter: "false",
                    _TerritoryCode: "anySimpleType",
                    _LocationDescription: "string",
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  StartDateTime: {
                    _IsApproximate: "true",
                    _IsBefore: "false",
                    _IsAfter: "false",
                    _TerritoryCode: "anySimpleType",
                    _LocationDescription: "string",
                    _LanguageAndScriptCode: "string",
                    __text: "2010-10-29T20:09:18+03:00",
                  },
                  EndDateTime: {
                    _IsApproximate: "true",
                    _IsBefore: "true",
                    _IsAfter: "true",
                    _TerritoryCode: "anySimpleType",
                    _LocationDescription: "string",
                    _LanguageAndScriptCode: "string",
                    __text: "2010-01-13T09:44:35",
                  },
                },
                TerritoryOfRightsDelegation: {
                  _IdentifierType: "TIS",
                  __text: "776",
                },
                MembershipType: "RegionalMember",
              },
              Sex: "Female",
              Nationality: "IdentifierType",
              DateAndPlaceOfBirth: {
                _IsApproximate: "true",
                _IsBefore: "false",
                _IsAfter: "false",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              DateAndPlaceOfDeath: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "false",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              PrimaryRole: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Painter",
              },
              Performance: {
                Territory: {
                  _IdentifierType: "TIS",
                  __text: "Worldwide",
                },
                Date: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
              },
              PrimaryInstrumentType: "string",
              GoverningAgreementType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              ContactInformation: {
                EmailAddress: "string",
                PhoneNumber: "string",
                FaxNumber: "string",
              },
              TerritoryOfResidency: {
                _IdentifierType: "TIS",
                __text: "XK",
              },
              Citizenship: {
                _IdentifierType: "TIS",
                __text: "Worldwide",
              },
              AdditionalRoles: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "MainArtist",
              },
              Genre: {
                GenreText: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                SubGenre: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
              Membership: {
                Organization: {
                  PartyId: {
                    _Namespace: "string",
                    _IsDPID: "false",
                    _IsISNI: "false",
                    __text: "string",
                  },
                  PartyName: {
                    FullName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    FullNameAsciiTranscribed: "string",
                    FullNameIndexed: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesBeforeKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    KeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesAfterKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    AbbreviatedName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    _LanguageAndScriptCode: "string",
                  },
                },
                MembershipType: "RegionalMember",
                StartDate: "string",
                EndDate: "string",
              },
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          HasMusicalContent: "true",
          StartTime: "P6M7DT11H46M57S",
          Duration: "P3M9DT3H39M59S",
          EndTime: "PT21H54M2S",
          PLine: {
            Year: "2010+02:00",
            PLineCompany: "string",
            PLineText: "string",
            _LanguageAndScriptCode: "string",
            _PLineType: "OriginalPLine",
          },
          CLine: {
            Year: "2012+02:00",
            CLineCompany: "string",
            CLineText: "string",
            _LanguageAndScriptCode: "string",
          },
        },
      },
    },
  ],
  ResourceList: [
    {
      SoundRecording: [
        {
          SoundRecordingType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "NonMusicalWorkSoundRecording",
          },
          IsArtistRelated: "false",
          SoundRecordingId: {
            ISRC: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "false",
          },
          IndirectSoundRecordingId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "false",
          },
          ResourceReference: "string",
          ReferenceTitle: {
            TitleText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubTitle: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
          },
          InstrumentationDescription: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          IsMedley: "false",
          IsPotpourri: "false",
          IsInstrumental: "true",
          IsBackground: "true",
          IsHiddenResource: "false",
          IsBonusResource: "false",
          HasPreOrderFulfillment: "false",
          IsComputerGenerated: "true",
          IsRemastered: "false",
          NoSilenceBefore: "false",
          NoSilenceAfter: "true",
          PerformerInformationRequired: "false",
          LanguageOfPerformance: "ka",
          Duration: "P1M4DT10H41M3S",
          RightsAgreementId: {
            MWLI: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          SoundRecordingCollectionReferenceList: {
            NumberOfCollections: "100",
            SoundRecordingCollectionReference: {
              SequenceNumber: "100",
              SoundRecordingCollectionReference: "string",
              StartTime: "P10M9DT2H41M34S",
              Duration: "P4M6DT20H1M35S",
              EndTime: "P5M1DT6H19M27S",
              ReleaseResourceType: "PrimaryResource",
            },
          },
          ResourceMusicalWorkReferenceList: {
            ResourceMusicalWorkReference: {
              SequenceNumber: "100",
              DurationUsed: "P4M6DT11H49M30S",
              IsFragment: "true",
              ResourceMusicalWorkReference: "string",
            },
          },
          ResourceContainedResourceReferenceList: {
            ResourceContainedResourceReference: {
              ResourceContainedResourceReference: "string",
              DurationUsed: "P4M4DT23H34M55S",
              StartPoint: "1000.00",
              Purpose: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "FilmTrailerMusic",
              },
            },
          },
          CreationDate: {
            _IsApproximate: "true",
            _IsBefore: "true",
            _IsAfter: "false",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          MasteredDate: {
            _IsApproximate: "false",
            _IsBefore: "false",
            _IsAfter: "true",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          RemasteredDate: {
            _IsApproximate: "true",
            _IsBefore: "true",
            _IsAfter: "false",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          SoundRecordingDetailsByTerritory: [
            {
              TerritoryCode: {
                _IdentifierType: "TIS",
                __text: "XK",
              },
              ExcludedTerritoryCode: {
                _IdentifierType: "ISO",
                __text: "XK",
              },
              Title: {
                TitleText: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                SubTitle: {
                  _LanguageAndScriptCode: "string",
                  _SubTitleType: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
                _TitleType: "TitleAsPart",
              },
              DisplayArtist: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "false",
                  _IsISNI: "true",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                ArtistRole: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "Unknown",
                },
                Nationality: "IdentifierType",
                _SequenceNumber: "100",
              },
              DisplayConductor: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "false",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                ArtistRole: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "FeaturedArtist",
                },
                Nationality: "IdentifierType",
                _SequenceNumber: "100",
              },
              ResourceContributor: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "true",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                ResourceContributorRole: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "PlaybackSinger",
                },
                IsFeaturedArtist: "false",
                IsContractedArtist: "false",
                InstrumentType: "string",
                ArtistDelegatedUsageRights: {
                  UseType: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "PlayInPublic",
                  },
                  UserInterfaceType: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "Kiosk",
                  },
                  PeriodOfRightsDelegation: {
                    StartDate: {
                      _IsApproximate: "false",
                      _IsBefore: "true",
                      _IsAfter: "true",
                      _TerritoryCode: "anySimpleType",
                      _LocationDescription: "string",
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    EndDate: {
                      _IsApproximate: "false",
                      _IsBefore: "false",
                      _IsAfter: "false",
                      _TerritoryCode: "anySimpleType",
                      _LocationDescription: "string",
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    StartDateTime: {
                      _IsApproximate: "false",
                      _IsBefore: "false",
                      _IsAfter: "false",
                      _TerritoryCode: "anySimpleType",
                      _LocationDescription: "string",
                      _LanguageAndScriptCode: "string",
                      __text: "2005-02-11T04:09:44",
                    },
                    EndDateTime: {
                      _IsApproximate: "true",
                      _IsBefore: "false",
                      _IsAfter: "true",
                      _TerritoryCode: "anySimpleType",
                      _LocationDescription: "string",
                      _LanguageAndScriptCode: "string",
                      __text: "2008-09-11T17:38:02+03:00",
                    },
                  },
                  TerritoryOfRightsDelegation: {
                    _IdentifierType: "TIS",
                    __text: "MF",
                  },
                  MembershipType: "NationalMember",
                },
                Sex: "Unknown",
                Nationality: "IdentifierType",
                DateAndPlaceOfBirth: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                DateAndPlaceOfDeath: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                PrimaryRole: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "Photographer",
                },
                Performance: {
                  Territory: {
                    _IdentifierType: "ISO",
                    __text: "TH",
                  },
                  Date: {
                    _IsApproximate: "true",
                    _IsBefore: "false",
                    _IsAfter: "true",
                    _TerritoryCode: "anySimpleType",
                    _LocationDescription: "string",
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                },
                PrimaryInstrumentType: "string",
                GoverningAgreementType: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "UserDefined",
                },
                ContactInformation: {
                  EmailAddress: "string",
                  PhoneNumber: "string",
                  FaxNumber: "string",
                },
                TerritoryOfResidency: {
                  _IdentifierType: "TIS",
                  __text: "51",
                },
                Citizenship: {
                  _IdentifierType: "ISO",
                  __text: "AS",
                },
                AdditionalRoles: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "Translator",
                },
                Genre: {
                  GenreText: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  SubGenre: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                Membership: {
                  Organization: {
                    PartyId: {
                      _Namespace: "string",
                      _IsDPID: "true",
                      _IsISNI: "true",
                      __text: "string",
                    },
                    PartyName: {
                      FullName: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      FullNameAsciiTranscribed: "string",
                      FullNameIndexed: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      NamesBeforeKeyName: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      KeyName: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      NamesAfterKeyName: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      AbbreviatedName: {
                        _LanguageAndScriptCode: "string",
                        __text: "string",
                      },
                      _LanguageAndScriptCode: "string",
                    },
                  },
                  MembershipType: "RegionalMember",
                  StartDate: "string",
                  EndDate: "string",
                },
                _SequenceNumber: "100",
              },
              IndirectResourceContributor: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                IndirectResourceContributorRole: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "Composer",
                },
                Nationality: "IdentifierType",
                _SequenceNumber: "100",
              },
              RightsAgreementId: {
                MWLI: "string",
                ProprietaryId: {
                  _Namespace: "string",
                  __text: "string",
                },
              },
              DisplayArtistName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              LabelName: {
                _LanguageAndScriptCode: "string",
                _LabelNameType: "DisplayLabelName",
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "string",
              },
              RightsController: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "false",
                  _IsISNI: "true",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
                RightsControllerRole: "RightsController",
                RightShareUnknown: "true",
                RightSharePercentage: {
                  _HasMaxValueOfOne: "true",
                  __text: "1000.00",
                },
                RightsControllerType: "ExclusiveLicensee",
                TerritoryOfRegistration: {
                  _IdentifierType: "DeprecatedISO",
                  __text: "51",
                },
                StartDate: "string",
                EndDate: "string",
                _SequenceNumber: "100",
              },
              RemasteredDate: {
                _IsApproximate: "true",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              ResourceReleaseDate: {
                _IsApproximate: "false",
                _IsBefore: "false",
                _IsAfter: "true",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              OriginalResourceReleaseDate: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              PLine: {
                Year: "2012",
                PLineCompany: "string",
                PLineText: "string",
                _LanguageAndScriptCode: "string",
                _PLineType: "RemasteringPLine",
              },
              CourtesyLine: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              SequenceNumber: "100",
              HostSoundCarrier: {
                ReleaseId: {
                  GRid: "string",
                  ISRC: "string",
                  ICPN: {
                    _IsEan: "false",
                    __text: "string",
                  },
                  CatalogNumber: {
                    _Namespace: "string",
                    __text: "string",
                  },
                  ProprietaryId: {
                    _Namespace: "string",
                    __text: "string",
                  },
                  _IsReplaced: "true",
                },
                RightsAgreementId: {
                  MWLI: "string",
                  ProprietaryId: {
                    _Namespace: "string",
                    __text: "string",
                  },
                },
                Title: {
                  TitleText: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  SubTitle: {
                    _LanguageAndScriptCode: "string",
                    _SubTitleType: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                  _TitleType: "TitleAsPart",
                },
                DisplayArtist: {
                  PartyId: {
                    _Namespace: "string",
                    _IsDPID: "true",
                    _IsISNI: "true",
                    __text: "string",
                  },
                  PartyName: {
                    FullName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    FullNameAsciiTranscribed: "string",
                    FullNameIndexed: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesBeforeKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    KeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesAfterKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    AbbreviatedName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    _LanguageAndScriptCode: "string",
                  },
                  ArtistRole: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "Producer",
                  },
                  Nationality: "IdentifierType",
                  _SequenceNumber: "100",
                },
                AdministratingRecordCompany: {
                  PartyId: {
                    _Namespace: "string",
                    _IsDPID: "false",
                    _IsISNI: "false",
                    __text: "string",
                  },
                  PartyName: {
                    FullName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    FullNameAsciiTranscribed: "string",
                    FullNameIndexed: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesBeforeKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    KeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    NamesAfterKeyName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    AbbreviatedName: {
                      _LanguageAndScriptCode: "string",
                      __text: "string",
                    },
                    _LanguageAndScriptCode: "string",
                  },
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  _Role: "UserDefined",
                },
                TrackNumber: "string",
                VolumeNumberInSet: "string",
              },
              MarketingComment: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              Genre: {
                GenreText: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                SubGenre: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
              ParentalWarningType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Unknown",
              },
              AvRating: {
                RatingText: "string",
                RatingAgency: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "MTRCB",
                },
                RatingSchemeDescription: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
              },
              TechnicalSoundRecordingDetails: {
                TechnicalResourceDetailsReference: "string",
                DrmPlatformType: {
                  _Version: "string",
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "Unknown",
                },
                ContainerFormat: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "MP4",
                },
                AudioCodecType: {
                  _Version: "string",
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "MP3",
                },
                BitRate: {
                  _UnitOfMeasure: "bps",
                  __text: "1000.00",
                },
                NumberOfChannels: "100",
                SamplingRate: {
                  _UnitOfMeasure: "kHz",
                  __text: "1000.00",
                },
                BitsPerSample: "100",
                Duration: "P2DT4H19M35S",
                ResourceProcessingRequired: "false",
                UsableResourceDuration: "P7M9DT27M4S",
                IsPreview: "true",
                PreviewDetails: {
                  PartType: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  StartPoint: "1000.00",
                  EndPoint: "1000.00",
                  Duration: "P11M7DT7H44M4S",
                  TopLeftCorner: "1000.00",
                  BottomRightCorner: "1000.00",
                  ExpressionType: "Instructive",
                },
                FulfillmentDate: {
                  FulfillmentDate: "string",
                  ResourceReleaseReference: "string",
                },
                ConsumerFulfillmentDate: {
                  FulfillmentDate: "string",
                  ResourceReleaseReference: "string",
                },
                FileAvailabilityDescription: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                File: {
                  FileName: "string",
                  FilePath: "string",
                  URL: "string",
                  HashSum: {
                    HashSum: "string",
                    HashSumAlgorithmType: {
                      _Namespace: "string",
                      _UserDefinedValue: "string",
                      __text: "MD5",
                    },
                    HashSumDataType: "HexBinary",
                  },
                },
                Fingerprint: {
                  Fingerprint: "string",
                  FingerprintAlgorithmType: {
                    _Namespace: "string",
                    _UserDefinedValue: "string",
                    __text: "UserDefined",
                  },
                  FingerprintAlgorithmVersion: "string",
                  FingerprintAlgorithmParameter: "string",
                  FingerprintDataType: "Binary64",
                },
                _LanguageAndScriptCode: "string",
              },
              FulfillmentDate: {
                FulfillmentDate: "string",
                ResourceReleaseReference: "string",
              },
              Keywords: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              Synopsis: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
          ],
          TerritoryOfCommissioning: {
            _IdentifierType: "DeprecatedISO",
            __text: "YT",
          },
          NumberOfFeaturedArtists: "100",
          NumberOfNonFeaturedArtists: "100",
          NumberOfContractedArtists: "100",
          NumberOfNonContractedArtists: "100",
          _IsUpdated: "false",
          _LanguageAndScriptCode: "string",
        },
      ],
      MIDI: {
        MidiType: {
          _Namespace: "string",
          _UserDefinedValue: "string",
          __text: "MonophonicMidi",
        },
        IsArtistRelated: "false",
        MidiId: {
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "false",
        },
        IndirectMidiId: {
          ISWC: "string",
          OpusNumber: "string",
          ComposerCatalogNumber: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
          _IsReplaced: "false",
        },
        ResourceReference: "string",
        ReferenceTitle: {
          TitleText: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          SubTitle: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          _LanguageAndScriptCode: "string",
        },
        InstrumentationDescription: {
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        IsMedley: "true",
        IsPotpourri: "false",
        IsInstrumental: "false",
        IsBackground: "true",
        IsHiddenResource: "true",
        IsBonusResource: "true",
        IsComputerGenerated: "true",
        NoSilenceBefore: "true",
        NoSilenceAfter: "true",
        PerformerInformationRequired: "false",
        LanguageOfPerformance: "ko",
        Duration: "P1Y2M1DT13H43M14S",
        RightsAgreementId: {
          MWLI: "string",
          ProprietaryId: {
            _Namespace: "string",
            __text: "string",
          },
        },
        ResourceMusicalWorkReferenceList: {
          ResourceMusicalWorkReference: {
            SequenceNumber: "100",
            DurationUsed: "P10MT12H42M45S",
            IsFragment: "false",
            ResourceMusicalWorkReference: "string",
          },
        },
        ResourceContainedResourceReferenceList: {
          ResourceContainedResourceReference: {
            ResourceContainedResourceReference: "string",
            DurationUsed: "P8M5DT16H23M48S",
            StartPoint: "1000.00",
            Purpose: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "ChannelTrailerMusic",
            },
          },
        },
        CreationDate: {
          _IsApproximate: "false",
          _IsBefore: "false",
          _IsAfter: "true",
          _TerritoryCode: "anySimpleType",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        MasteredDate: {
          _IsApproximate: "true",
          _IsBefore: "false",
          _IsAfter: "true",
          _TerritoryCode: "anySimpleType",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        RemasteredDate: {
          _IsApproximate: "true",
          _IsBefore: "true",
          _IsAfter: "true",
          _TerritoryCode: "anySimpleType",
          _LocationDescription: "string",
          _LanguageAndScriptCode: "string",
          __text: "string",
        },
        MidiDetailsByTerritory: {
          TerritoryCode: {
            _IdentifierType: "ISO",
            __text: "US",
          },
          ExcludedTerritoryCode: {
            _IdentifierType: "ISO",
            __text: "Worldwide",
          },
          Title: {
            TitleText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubTitle: {
              _LanguageAndScriptCode: "string",
              _SubTitleType: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _TitleType: "UserDefined",
          },
          DisplayArtist: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "true",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            ArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Cartoonist",
            },
            Nationality: "IdentifierType",
            _SequenceNumber: "100",
          },
          ResourceContributor: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "false",
              _IsISNI: "false",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            ResourceContributorRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "GraphicArtist",
            },
            IsFeaturedArtist: "false",
            IsContractedArtist: "true",
            InstrumentType: "string",
            ArtistDelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UseAsKaraoke",
              },
              UserInterfaceType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "PersonalComputer",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2016-05-03T15:51:33",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "false",
                  _IsAfter: "false",
                  _TerritoryCode: "anySimpleType",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2005-01-23T02:34:43",
                },
              },
              TerritoryOfRightsDelegation: {
                _IdentifierType: "ISO",
                __text: "Worldwide",
              },
              MembershipType: "WorldwideMember",
            },
            Sex: "Male",
            Nationality: "IdentifierType",
            DateAndPlaceOfBirth: {
              _IsApproximate: "true",
              _IsBefore: "false",
              _IsAfter: "false",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            DateAndPlaceOfDeath: {
              _IsApproximate: "false",
              _IsBefore: "true",
              _IsAfter: "false",
              _TerritoryCode: "anySimpleType",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            PrimaryRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Dancer",
            },
            Performance: {
              Territory: {
                _IdentifierType: "TIS",
                __text: "XK",
              },
              Date: {
                _IsApproximate: "false",
                _IsBefore: "true",
                _IsAfter: "true",
                _TerritoryCode: "anySimpleType",
                _LocationDescription: "string",
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
            },
            PrimaryInstrumentType: "string",
            GoverningAgreementType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            ContactInformation: {
              EmailAddress: "string",
              PhoneNumber: "string",
              FaxNumber: "string",
            },
            TerritoryOfResidency: {
              _IdentifierType: "ISO",
              __text: "NQAQ",
            },
            Citizenship: {
              _IdentifierType: "ISO",
              __text: "Worldwide",
            },
            AdditionalRoles: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "Dancer",
            },
            Genre: {
              GenreText: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              SubGenre: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            Membership: {
              Organization: {
                PartyId: {
                  _Namespace: "string",
                  _IsDPID: "true",
                  _IsISNI: "false",
                  __text: "string",
                },
                PartyName: {
                  FullName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  FullNameAsciiTranscribed: "string",
                  FullNameIndexed: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesBeforeKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  KeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  NamesAfterKeyName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  AbbreviatedName: {
                    _LanguageAndScriptCode: "string",
                    __text: "string",
                  },
                  _LanguageAndScriptCode: "string",
                },
              },
              MembershipType: "RegionalMember",
              StartDate: "string",
              EndDate: "string",
            },
            _SequenceNumber: "100",
          },
          IndirectResourceContributor: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "false",
              _IsISNI: "false",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            IndirectResourceContributorRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "AssociatedPerformer",
            },
            Nationality: "IdentifierType",
            _SequenceNumber: "100",
          },
          RightsAgreementId: {
            MWLI: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          LabelName: {
            _LanguageAndScriptCode: "string",
            _LabelNameType: "UserDefined",
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "string",
          },
          RightsController: {
            PartyId: {
              _Namespace: "string",
              _IsDPID: "true",
              _IsISNI: "false",
              __text: "string",
            },
            PartyName: {
              FullName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesBeforeKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              KeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              NamesAfterKeyName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              AbbreviatedName: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RightsController",
            RightShareUnknown: "false",
            RightSharePercentage: {
              _HasMaxValueOfOne: "true",
              __text: "1000.00",
            },
            RightsControllerType: "ExclusiveLicensee",
            TerritoryOfRegistration: {
              _IdentifierType: "ISO",
              __text: "VI",
            },
            StartDate: "string",
            EndDate: "string",
            _SequenceNumber: "100",
          },
          RemasteredDate: {
            _IsApproximate: "true",
            _IsBefore: "false",
            _IsAfter: "false",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          ResourceReleaseDate: {
            _IsApproximate: "true",
            _IsBefore: "false",
            _IsAfter: "true",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          OriginalResourceReleaseDate: {
            _IsApproximate: "false",
            _IsBefore: "true",
            _IsAfter: "true",
            _TerritoryCode: "anySimpleType",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          CLine: {
            Year: "2017+02:00",
            CLineCompany: "string",
            CLineText: "string",
            _LanguageAndScriptCode: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          SequenceNumber: "100",
          HostSoundCarrier: {
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: {
                _IsEan: "false",
                __text: "string",
              },
              CatalogNumber: {
                _Namespace: "string",
                __text: "string",
              },
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
              _IsReplaced: "true",
            },
            RightsAgreementId: {
              MWLI: "string",
              ProprietaryId: {
                _Namespace: "string",
                __text: "string",
              },
            },
            Title: {
              TitleText: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              SubTitle: {
                _LanguageAndScriptCode: "string",
                _SubTitleType: "string",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _TitleType: "MisspelledTitle",
            },
            DisplayArtist: {
              PartyId: {
                _Namespace: "string",
                _IsDPID: "true",
                _IsISNI: "false",
                __text: "string",
              },
              PartyName: {
                FullName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                FullNameAsciiTranscribed: "string",
                FullNameIndexed: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesBeforeKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                KeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesAfterKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                AbbreviatedName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
              ArtistRole: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Producer",
              },
              Nationality: "IdentifierType",
              _SequenceNumber: "100",
            },
            AdministratingRecordCompany: {
              PartyId: {
                _Namespace: "string",
                _IsDPID: "false",
                _IsISNI: "false",
                __text: "string",
              },
              PartyName: {
                FullName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                FullNameAsciiTranscribed: "string",
                FullNameIndexed: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesBeforeKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                KeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                NamesAfterKeyName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                AbbreviatedName: {
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                _LanguageAndScriptCode: "string",
              },
              _Namespace: "string",
              _UserDefinedValue: "string",
              _Role: "RoyaltyAdministrator",
            },
            TrackNumber: "string",
            VolumeNumberInSet: "string",
          },
          MarketingComment: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          Genre: {
            GenreText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubGenre: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "Explicit",
          },
          FulfillmentDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
          },
          Keywords: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          Synopsis: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          TechnicalMidiDetails: {
            TechnicalResourceDetailsReference: "string",
            Duration: "P6M4DT19H3M43S",
            ResourceProcessingRequired: "true",
            UsableResourceDuration: "P1Y1DT22H48M",
            IsPreview: "true",
            PreviewDetails: {
              PartType: {
                _LanguageAndScriptCode: "string",
                __text: "string",
              },
              StartPoint: "1000.00",
              EndPoint: "1000.00",
              Duration: "P1Y1DT2H47M56S",
              TopLeftCorner: "1000.00",
              BottomRightCorner: "1000.00",
              ExpressionType: "Instructive",
            },
            FulfillmentDate: {
              FulfillmentDate: "string",
              ResourceReleaseReference: "string",
            },
            ConsumerFulfillmentDate: {
              FulfillmentDate: "string",
              ResourceReleaseReference: "string",
            },
            FileAvailabilityDescription: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            File: {
              FileName: "string",
              FilePath: "string",
              URL: "string",
              HashSum: {
                HashSum: "string",
                HashSumAlgorithmType: {
                  _Namespace: "string",
                  _UserDefinedValue: "string",
                  __text: "SHA1",
                },
                HashSumDataType: "HexBinary",
              },
            },
            NumberOfVoices: "100",
            SoundProcessorType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "MidiProcessor",
            },
            Fingerprint: {
              Fingerprint: "string",
              FingerprintAlgorithmType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              FingerprintAlgorithmVersion: "string",
              FingerprintAlgorithmParameter: "string",
              FingerprintDataType: "HexBinary",
            },
            _LanguageAndScriptCode: "string",
          },
          _LanguageAndScriptCode: "string",
        },
        _IsUpdated: "true",
        _LanguageAndScriptCode: "string",
      },
      Image: [
        {
          ResourceReference: "string",
          Type: { _Namespace: "string", _UserDefinedValue: "string", __text: "Poster" },
          ResourceId: { ProprietaryId: { _Namespace: "string", __text: "string" }, _IsReplaced: "true" },
          DisplayTitleText: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "MR",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "PH",
            _IsDefault: "true",
          },
          AdditionalTitle: {
            TitleText: "string",
            SubTitle: {
              _SequenceNumber: "100",
              _IsDisplayedInTitle: "false",
              _SubTitleType: "Location",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "SN",
            _TitleType: "UserDefined",
            _Namespace: "string",
            _UserDefinedValue: "string",
            _IsDefault: "true",
          },
          Contributor: {
            ContributorPartyReference: "string",
            Role: { _Namespace: "string", _UserDefinedValue: "string", __text: "Runner" },
            InstrumentType: { _Namespace: "string", _UserDefinedValue: "string", __text: "SleighBells" },
            HasMadeFeaturedContribution: "true",
            HasMadeContractedContribution: "true",
            DisplayCredits: {
              DisplayCreditText: "string",
              DisplayCreditParty: "string",
              NameUsedInDisplayCredit: "string",
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "524",
              _IsDefault: "false",
            },
            _SequenceNumber: "100",
          },
          CLine: {
            Year: "2014",
            CLineCompany: "string",
            CLineText: "string",
            _ApplicableTerritoryCode: "KR",
            _IsDefault: "false",
            _LanguageAndScriptCode: "string",
          },
          ParentalWarningType: {
            _Namespace: "string",
            _ApplicableTerritoryCode: "TN",
            _UserDefinedValue: "string",
            _IsDefault: "false",
            __text: "NotExplicit",
          },
          TechnicalDetails: {
            TechnicalResourceDetailsReference: "string",
            File: {
              URI: "string",
              HashSum: {
                Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "MDC2" },
                HashSumValue: "string",
                Version: "string",
                Parameter: "string",
                DataType: "HexBinary",
              },
              FileSize: "1000.00",
            },
            ImageCodecType: {
              _Version: "string",
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "GIF",
            },
            ImageHeight: { _UnitOfMeasure: "Inch", __text: "1000.00" },
            ImageWidth: { _UnitOfMeasure: "cm", __text: "1000.00" },
            AspectRatio: { _AspectRatioType: "PAR", __text: "1000.00" },
            ColorDepth: "100",
            ImageResolution: "100",
            BitDepth: "100",
            IsPreview: "false",
            PreviewDetails: {
              TopLeftCorner: "string",
              BottomRightCorner: "string",
              ExpressionType: "Instructive",
            },
            Fingerprint: {
              Algorithm: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "UserDefined",
              },
              Version: "string",
              Parameter: "string",
              File: {
                URI: "string",
                HashSum: {
                  Algorithm: { _Namespace: "string", _UserDefinedValue: "string", __text: "SHA-256" },
                  Version: "string",
                  Parameter: "string",
                  DataType: "Binary64",
                  HashSumValue: "string",
                },
                FileSize: "1000.00",
              },
              DataType: "HexBinary",
              FingerprintValue: "string",
            },
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "2125",
            _IsDefault: "true",
          },
          VersionType: { _Namespace: "string", _UserDefinedValue: "string", __text: "DemoVersion" },
          DisplayArtistName: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "YE",
            _IsDefault: "true",
            __text: "string",
          },
          DisplayArtist: {
            ArtistPartyReference: "string",
            DisplayArtistRole: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "FeaturedArtist",
            },
            ArtisticRole: { _Namespace: "string", _UserDefinedValue: "string", __text: "TapeOperator" },
            TitleDisplayInformation: {
              IsDisplayedInTitle: "false",
              Prefix: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
              _SequenceNumber: "100",
            },
            _SequenceNumber: "100",
          },
          ResourceRightsController: {
            PartyId: {
              ISNI: "string",
              DPID: "string",
              IpiNameNumber: "string",
              IPN: "string",
              CisacSocietyId: "string",
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            PartyName: {
              FullName: { _LanguageAndScriptCode: "string", __text: "string" },
              FullNameAsciiTranscribed: "string",
              FullNameIndexed: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesBeforeKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              KeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              NamesAfterKeyName: { _LanguageAndScriptCode: "string", __text: "string" },
              AbbreviatedName: { _LanguageAndScriptCode: "string", __text: "string" },
              _LanguageAndScriptCode: "string",
            },
            RightsControllerRole: "RoyaltyAdministrator",
            RightShareUnknown: "true",
            RightSharePercentage: { _HasMaxValueOfOne: "false", __text: "1000.00" },
            DelegatedUsageRights: {
              UseType: {
                _Namespace: "string",
                _UserDefinedValue: "string",
                __text: "Print",
              },
              PeriodOfRightsDelegation: {
                StartDate: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "2119",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                EndDate: {
                  _IsApproximate: "true",
                  _IsBefore: "false",
                  _IsAfter: "true",
                  _ApplicableTerritoryCode: "BJ",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "string",
                },
                StartDateTime: {
                  _IsApproximate: "true",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "KI",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2013-04-06T19:52:20",
                },
                EndDateTime: {
                  _IsApproximate: "false",
                  _IsBefore: "true",
                  _IsAfter: "true",
                  _TerritoryCode: "690",
                  _LocationDescription: "string",
                  _LanguageAndScriptCode: "string",
                  __text: "2008-06-15T09:48:24",
                },
              },
              TerritoryOfRightsDelegation: { _IdentifierType: "TIS", __text: "226" },
            },
            _SequenceNumber: "100",
          },
          WorkRightsController: {
            RightsControllerPartyReference: "string",
            RightsControllerRole: "Unknown",
            RightsControllerType: "OriginalOwner",
            RightShareUnknown: "false",
            RightSharePercentage: "1000.00",
            Territory: { _IdentifierType: "ISO", __text: "BE" },
            StartDate: "string",
            EndDate: "string",
          },
          CourtesyLine: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "AN",
            _IsDefault: "true",
            __text: "string",
          },
          CreationDate: {
            _IsApproximate: "true",
            _ApplicableTerritoryCode: "616",
            _LocationDescription: "string",
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          FirstPublicationDate: {
            FulfillmentDate: "string",
            ResourceReleaseReference: "string",
            _ApplicableTerritoryCode: "IO",
            _IsDefault: "false",
          },
          RelatedRelease: {
            ReleaseRelationshipType: {
              _Namespace: "string",
              _UserDefinedValue: "string",
              __text: "UserDefined",
            },
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            DisplayTitleText: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "288",
              _IsDefault: "true",
              __text: "string",
            },
            DisplayTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "true",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "174",
              _IsDefault: "true",
            },
            AdditionalTitle: {
              TitleText: "string",
              SubTitle: {
                _SequenceNumber: "100",
                _IsDisplayedInTitle: "false",
                _SubTitleType: "Version",
                __text: "string",
              },
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "GI",
              _TitleType: "AlternativeTitle",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _IsDefault: "false",
            },
            DisplayArtistName: {
              _LanguageAndScriptCode: "string",
              _ApplicableTerritoryCode: "2109",
              _IsDefault: "true",
              __text: "string",
            },
            ReleaseLabelReference: {
              _LanguageAndScriptCode: "string",
              _IsDefault: "false",
              _LabelType: "UserDefined",
              _Namespace: "string",
              _UserDefinedValue: "string",
              _ApplicableTerritoryCode: "CC",
              __text: "string",
            },
            ReleaseDate: {
              _IsApproximate: "false",
              _ApplicableTerritoryCode: "PY",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            OriginalReleaseDate: {
              _IsApproximate: "true",
              _ApplicableTerritoryCode: "RO",
              _LocationDescription: "string",
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
          },
          RelatedResource: {
            ResourceRelationshipType: "IsCoveredBy",
            ResourceRelatedResourceReference: "string",
            ReleaseId: {
              GRid: "string",
              ISRC: "string",
              ICPN: "string",
              CatalogNumber: { _Namespace: "string", __text: "string" },
              ProprietaryId: { _Namespace: "string", __text: "string" },
            },
            Timing: { StartPoint: "P1Y4M4DT11H55M24S", DurationUsed: "P10M9DT3H28M29S" },
          },
          ContainsHiddenContent: "false",
          Description: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "203",
            _IsDefault: "true",
            __text: "string",
          },
          Keywords: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "LR",
            _IsDefault: "false",
            __text: "string",
          },
          Synopsis: {
            _LanguageAndScriptCode: "string",
            _ApplicableTerritoryCode: "662",
            _IsDefault: "true",
            _IsShortSynopsis: "true",
            __text: "string",
          },
          ImageDetailsByTerritory: {
            TerritoryCode: "string",
            ParentalWarningType: "string",
            TechnicalImageDetails: {
              TechnicalResourceDetailsReference: "string",
              File: {
                FileName: "string",
                HashSum: {
                  HashSum: "string",
                  HashSumAlgorithmType: "string",
                },
              },
            },
          },
          _LanguageAndScriptCode: "string",
          _IsSupplemental: "true",
        },
      ],
      Video: [
        {
          VideoType: {
            _Namespace: "string",
            _UserDefinedValue: "string",
            __text: "NonMusicalWorkVideoChapter",
          },
          IsArtistRelated: "true",
          VideoId: {
            ISRC: "string",
            ISAN: "string",
            VISAN: "string",
            CatalogNumber: {
              _Namespace: "string",
              __text: "string",
            },
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            EIDR: "string",
            _IsReplaced: "true",
          },
          IndirectVideoId: {
            ISWC: "string",
            OpusNumber: "string",
            ComposerCatalogNumber: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
            _IsReplaced: "false",
          },
          ResourceReference: "string",
          VideoCueSheetReference: {
            VideoCueSheetReference: "string",
          },
          ReasonForCueSheetAbsence: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          ReferenceTitle: {
            TitleText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubTitle: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
          },
          Title: {
            TitleText: {
              _LanguageAndScriptCode: "string",
              __text: "string",
            },
            SubTitle: {
              _LanguageAndScriptCode: "string",
              _SubTitleType: "string",
              __text: "string",
            },
            _LanguageAndScriptCode: "string",
            _TitleType: "OriginalTitle",
          },
          InstrumentationDescription: {
            _LanguageAndScriptCode: "string",
            __text: "string",
          },
          IsMedley: "true",
          IsPotpourri: "true",
          IsInstrumental: "true",
          IsBackground: "false",
          IsHiddenResource: "false",
          IsBonusResource: "false",
          HasPreOrderFulfillment: "false",
          IsRemastered: "true",
          NoSilenceBefore: "false",
          NoSilenceAfter: "true",
          PerformerInformationRequired: "false",
          LanguageOfPerformance: "ve",
          LanguageOfDubbing: "tk",
          SubTitleLanguage: "tw",
          Duration: "P10MT9H56M7S",
          RightsAgreementId: {
            MWLI: "string",
            ProprietaryId: {
              _Namespace: "string",
              __text: "string",
            },
          },
          VideoCollectionReferenceList: {
            NumberOfCollections: "100",
            SoundRecordingCollectionReference: {
              SequenceNumber: "100",
              SoundRecordingCollectionReference: "string",
            },
          },
          VideoDetailsByTerritory: {
            TerritoryCode: {
              _IdentifierType: "ISO",
              __text: "SK",
            },
            ExcludedTerritoryCode: {
              _IdentifierType: "TIS",
              __text: "480",
            },
            _LanguageAndScriptCode: "string",
          },
          _IsUpdated: "true",
          _LanguageAndScriptCode: "string",
        },
      ],
      _LanguageAndScriptCode: "string",
    },
  ],
  ReleaseList: [
    {
      Release: {
        ReleaseId: { ICPN: "1", ISRC: "1", CatalogNumber: "string", GRid: "" },
        ReleaseReference: "Release57410",
        ReferenceTitle: {
          TitleText: "WITH",
        },
        ReleaseResourceReferenceList: {
          ReleaseResourceReference: {
            _ReleaseResourceType: "PrimaryResource",
            __text: "ATrack268647",
          },
        },
        ReleaseType: "Album",
        ReleaseDetailsByTerritory: [
          {
            TerritoryCode: "Worldwide",
            DisplayArtistName: "Sylvan Esso",
            LabelName: "Loma Vista Recordings",
            Title: {
              TitleText: "WITH",
              _TitleType: "FormalTitle",
            },
            DisplayArtist: {
              PartyName: {
                FullName: "string",
              },
              ArtistRole: "MainArtist",
              _SequenceNumber: "1",
            },
            ParentalWarningType: "NotExplicit",
            ResourceGroup: [
              {
                ResourceGroup: {
                  Title: {
                    TitleText: "Disc 1",
                    _TitleType: "GroupingTitle",
                  },
                  SequenceNumber: "1",
                  ResourceGroupContentItem: {
                    SequenceNumber: "1",
                    ResourceType: "SoundRecording",
                    ReleaseResourceReference: {
                      _ReleaseResourceType: "PrimaryResource",
                      __text: "string",
                    },
                  },
                },
                ResourceGroupContentItem: {
                  ResourceType: "Image",
                  SequenceNumber: "1",
                  ReleaseResourceReference: {
                    _ReleaseResourceType: "SecondaryResource",
                    __text: "ACover30255",
                  },
                },
              },
            ],
            Genre: {
              GenreText: "Alternative",
              SubGenre: "",
            },
            ReleaseDate: {
              _IsApproximate: "true",
              __text: "2020-04-24",
            },
          },
        ],
        PLine: {
          Year: "2020",
          PLineText: "℗ 2020Loma Vista Recordings.",
        },
        CLine: {
          Year: "2020",
          CLineText: "℗ 2020Loma Vista Recordings.",
        },
        GlobalOriginalReleaseDate: {
          _IsApproximate: "true",
          __text: "2020-04-24",
        },
        _IsMainRelease: "true",
      },
    },
  ],
};
