import { Field } from "@/components/common/Form/Field";
import { Select } from "antd";
import { toOptions } from "@/utils/toOptions";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import type { OpenplayObjectType } from "@/types/common";
import "./RowByField.less";
import { useOpenplayObject } from "@/api/openplay-objects/hooks";
import type { OpenplayObjectField } from "@/api/openplay-objects/types";
import { RestrictedField } from "@/components/common/RestrictedField";
import React from "react";

const getArrayOnlyPaths = (fields: OpenplayObjectField[], parentPath?: string): string[] => {
  return fields
    .filter((field) => (field.isArray || field.type === "object") && !field.hidden)
    .flatMap((field) => {
      const fieldName = field.isArray ? `${field.name}[]` : field.name;
      const fieldPath = parentPath ? `${parentPath}.${fieldName}` : fieldName;
      const paths: string[] = [];
      if (field.isArray) {
        paths.push(fieldPath);
      }
      if (field.type === "object") {
        paths.push(...getArrayOnlyPaths(field.children, fieldPath));
      }
      return paths;
    });
};

type RowByFieldProps = {
  openplayObject: OpenplayObjectType;
};

export const RowByField = ({ openplayObject }: RowByFieldProps) => {
  const { data: obj, error } = useOpenplayObject(openplayObject);
  const paths = getArrayOnlyPaths(obj?.root?.children ?? []);

  return (
    <Field name="rowBy" label="Row By" validateFirst initialValue="$">
      {error?.status === 403 ? (
        <RestrictedField message="You need 'Read - OpenPlay Object' permission to view this field" />
      ) : (
        <Select
          options={[{ value: "$", label: "--root--" }, ...toOptions(paths)]}
          allowClear
          suffixIcon={<Icon component={DropdownArrow} />}
          className="row-by-field"
          popupClassName="row-by-field__popup"
        />
      )}
    </Field>
  );
};
