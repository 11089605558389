import { HiddenField } from "@/components/common/HiddenField";
import { Field } from "@/components/common/Form/Field";
import { SalesforceQueryBuilder } from "@/components/common/SalesforceQuery/SalesforceQueryBuilder";
import { SalesforceObject } from "@/types/salesforce";
import { useFieldPath } from "@/components/common/Form/hooks";
import { CampaignFilterType, CampaignFilterValueSource } from "@/api/email-alerts/types";
import { Col, Form, Row, Select } from "antd";
import { LabelGroupPicker } from "@/components/common/LabelGroupPicker";
import { SalesforceObjectPathPicker } from "@/components/common/ObjectPathPicker/SalesforceObjectPathPicker";
import { DropdownArrow, Remove } from "@/components/common/Icons";
import { IconButton } from "@/components/common/IconButton/IconButton";
import "./CampaignFilter.less";
import Icon from "@ant-design/icons";
import { getFilterLabel } from "./utils";

type Props = {
  sourceObject?: string;
  onRemove: () => void;
};

export const CampaignFilter = ({ sourceObject, onRemove }: Props) => {
  const { getAbsolutePath } = useFieldPath();
  const type = Form.useWatch<CampaignFilterType>(getAbsolutePath("type"));
  const valueSource = Form.useWatch<CampaignFilterValueSource>(getAbsolutePath("source"));
  return (
    <div className="campaign-filter">
      <div className="campaign-filter__body">
        <HiddenField name="type" />
        {type === CampaignFilterType.Condition && (
          <Field
            name="condition"
            label={getFilterLabel(type)}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please, configure a condition" }]}
          >
            <SalesforceQueryBuilder from={SalesforceObject.CampaignMember} />
          </Field>
        )}
        {[
          CampaignFilterType.LabelGroup,
          CampaignFilterType.AdditionalLabelRecipients,
          CampaignFilterType.RestrictedLabelRecipients,
        ].includes(type) && (
          <Form.Item label={getFilterLabel(type)} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Row gutter={[12, 12]}>
              {sourceObject ? (
                <Col span={8}>
                  <Field name="source">
                    <Select
                      options={[
                        { value: CampaignFilterValueSource.Static, label: "Select Values" },
                        { value: CampaignFilterValueSource.Field, label: "Use Object Field" },
                      ]}
                      suffixIcon={<Icon component={DropdownArrow} />}
                    />
                  </Field>
                </Col>
              ) : (
                <HiddenField name="source" />
              )}
              {valueSource === CampaignFilterValueSource.Static && (
                <Col span={8}>
                  <Field
                    name="values"
                    rules={[{ required: true, message: "Please, select one or more label groups" }]}
                  >
                    <LabelGroupPicker placeholder="Select Label Groups" />
                  </Field>
                </Col>
              )}
              {valueSource === CampaignFilterValueSource.Field && (
                <Col span={8}>
                  <Field name="field" rules={[{ required: true, message: "Please, select an object field" }]}>
                    <SalesforceObjectPathPicker
                      obj={sourceObject}
                      includeFieldTypes={["string", "picklist"]}
                    />
                  </Field>
                </Col>
              )}
            </Row>
          </Form.Item>
        )}
      </div>
      <IconButton danger className="campaign-filter__remove" icon={Remove} onClick={onRemove} />
    </div>
  );
};
