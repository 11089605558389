import { Aggregation, VariableType } from "@/types/variables";
import { Form } from "antd";
import { useMemo } from "react";
import type { SalesforceQuery } from "@/api/reports/types";
import { useSalesforceObjects } from "@/api/salesforce-objects/hooks";
import { uniq } from "ramda";
import { getEmptyArray } from "@/utils/empty";
import type { ObjectField, SimpleField } from "@/utils/dynamic-fields/types";
import type { QueryVariables, Variables } from "@/components/common/RichTextEditor/Variables/types";
import { getFieldsFromSalesforceObject } from "@/utils/dynamic-fields";
import { capitalize } from "@/utils";

export const generalFields: SimpleField[] = [
  { type: "datetime", name: "current_date", label: "Current Date" },
];

export const useVariationVariables = (): ObjectField<VariableType.Variation, SimpleField> => {
  const variablesFromField = Form.useWatch<string[]>("variables") ?? getEmptyArray();
  return useMemo(
    () => ({
      type: "object",
      name: VariableType.Variation,
      label: "Variation",
      children: variablesFromField.map((name) => ({
        type: "string",
        name,
        label: name.split("_").map(capitalize).join(" "),
      })),
    }),
    [variablesFromField]
  );
};

export const useQueryVariables = (): QueryVariables => {
  const queries = Form.useWatch<SalesforceQuery[]>(["queries"]) ?? getEmptyArray();

  const salesforceObjectsNames = uniq(queries.map((query) => query.from).filter(Boolean));

  const {
    data: { data: salesforceObjects },
  } = useSalesforceObjects({ includeFields: true, names: salesforceObjectsNames });

  return useMemo(
    () => ({
      type: "object",
      name: VariableType.Query,
      label: "Query",
      children: queries.map((query, index) => {
        const salesforceObject = salesforceObjects.find((obj) => obj.name === query.from);
        return {
          type: "object",
          name: query.id,
          label: query.name?.trim() || `Query #${index + 1}`,
          children: [
            { type: "number", name: Aggregation.Total, label: "Total" },
            {
              type: "object",
              name: Aggregation.Sum,
              label: "Sum Of",
              children: getFieldsFromSalesforceObject(salesforceObject, ["int", "double", "currency"], [], 1),
            },
          ],
        };
      }),
    }),
    [queries, salesforceObjects]
  );
};

export const useReportVariables = (): Variables => {
  const variation = useVariationVariables();
  const query = useQueryVariables();
  return useMemo(() => {
    const variables: Variables = [
      { type: "object", name: VariableType.General, label: "General", children: generalFields },
    ];
    if (query.children.length > 0) {
      variables.push(query);
    }
    if (variation.children.length > 0) {
      variables.push(variation);
    }
    return variables;
  }, [query, variation]);
};
