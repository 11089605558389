import type { ReactNode } from "react";
import "./Summary.less";

type Item = {
  label: string;
  value: ReactNode;
};

type Props = {
  items: Item[];
};

export const Summary = ({ items }: Props) => (
  <ul className="summary">
    {items
      .filter((item) => !!item?.value)
      .map((item) => (
        <li className="summary__item">
          <span className="summary__label">{`${item.label}:`}</span>
          <span className="summary__value">{item.value}</span>
        </li>
      ))}
  </ul>
);
