import type { FieldProps } from "@react-awesome-query-builder/ui";
import { sortCascaderSearchResults } from "@/utils/autocomplete";
import { Cascader, Tooltip } from "antd";

const removePrefixPath = (selectedPath: string[], parentPath: string[]) => {
  if (!selectedPath) return selectedPath;
  let isPrefix = true;
  for (let i = 0; i < parentPath.length; i++) {
    const part = parentPath[i];
    if (selectedPath[i] !== undefined && part === selectedPath[i]) {
      //ok
    } else {
      isPrefix = false;
      break;
    }
  }
  return isPrefix ? selectedPath.slice(parentPath.length) : selectedPath;
};

export const FieldCascader = ({
  config,
  customProps,
  items,
  placeholder,
  selectedPath,
  selectedLabel,
  selectedOpts,
  selectedAltLabel,
  readonly,
  // @ts-expect-error
  parentField,
  setField,
}: FieldProps) => {
  const onChange = (keys: string[]) => {
    const parentPath = parentField ? parentField.split(".") : [];
    // @ts-expect-error
    setField([...parentPath, ...keys]);
  };

  let customProps2 = { ...customProps } as Record<string, unknown>;
  if (customProps2.showSearch) {
    customProps2.showSearch = { limit: 200, sort: sortCascaderSearchResults };
  }

  const { fieldSeparator } = config.settings;
  const parentFieldPath = parentField ? parentField.split(fieldSeparator) : [];
  const value = removePrefixPath(selectedPath, parentFieldPath);

  let res = (
    <Cascader
      fieldNames={{ label: "label", value: "key", children: "items" }}
      options={items}
      value={value}
      onChange={onChange}
      allowClear={false}
      placeholder={placeholder}
      size={config.settings.renderSize === "medium" ? "middle" : config.settings.renderSize}
      disabled={readonly}
      // @ts-expect-error
      showSearch={customProps.showSearch ? { limit: 200, sort: sortCascaderSearchResults } : false}
    />
  );

  let tooltipText = selectedOpts.tooltip || selectedAltLabel;
  if (tooltipText === selectedLabel) tooltipText = null;
  if (tooltipText) {
    res = <Tooltip title={tooltipText}>{res}</Tooltip>;
  }

  return res;
};
