import { Button, Col, Form, Input, Row } from "antd";
import Icon from "@ant-design/icons";
import "./ExtraMapping.less";
import classNames from "classnames";
import PropTypes from "prop-types";
import ExtraMappingField from "./ExtraMappingField";
import { Format, getFormatName, Status } from "@/utils";
import { Info, Plus } from "../Icons";
import { Box } from "../Box/Box";
import { ChangeTemplateButton } from "./ChangeTemplateButton";

export default function ExtraMapping({ disabled, fieldName, defaultMappings }) {
  return (
    <div className={classNames({ "form-disabled": disabled })}>
      <Row align="top">
        <Col span={24} className="filter-heading">
          <>
            <Icon component={Info} className="filter-heading__icon" />
            <div>
              <div>
                Mapping will be ignored in case when the mapping source selection has fewer square brackets
                (iteration markers) than the mapping target selection.
              </div>
              <div>
                For details please read the{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://concorddev.atlassian.net/wiki/spaces/DF/pages/305496067/JSON+to+XML+Converter"
                >
                  documentation
                </a>
                .
              </div>
            </div>
          </>
        </Col>
      </Row>
      <Form.Item hidden name={[fieldName, "id"]}>
        <Input />
      </Form.Item>
      <Form.Item
        noStyle
        dependencies={[
          [fieldName, "inputFormat"],
          [fieldName, "outputFormat"],
        ]}
      >
        {(form) => (
          <Form.List
            name={[fieldName, "data"]}
            rules={[
              {
                validator: (_, mappings) => {
                  const isCSV = form.getFieldValue([fieldName, "outputFormat"]) === Format.CSV;
                  const isDraft = form.getFieldValue("status") === Status.Draft;
                  if (isCSV && !isDraft) {
                    return (mappings ?? []).length === 0
                      ? Promise.reject("At least one mapping should be specified")
                      : Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <div className="feed-extra-mapping-form">
                <div className="feed-extra-mapping__headings-row">
                  <div className="feed-extra-mapping__headings-col">
                    <h2>{`Source: ${getFormatName(form.getFieldValue([fieldName, "inputFormat"]))}`}</h2>
                  </div>
                  <div className="feed-extra-mapping__headings-col">
                    <h2>{`Target: ${getFormatName(form.getFieldValue([fieldName, "outputFormat"]))}`}</h2>
                    <div style={{ marginLeft: "auto" }} className="flex-row">
                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => add()}
                        icon={<Icon component={Plus} />}
                      >
                        New Field
                      </Button>
                      <ChangeTemplateButton />
                    </div>
                  </div>
                </div>
                {fields.length > 0 && (
                  <Box title="Extra">
                    <Row gutter={[16, 16]}>
                      {fields.map((field) => (
                        <Col span={24}>
                          <ExtraMappingField
                            key={field.key}
                            field={field}
                            onRemove={() => remove(field.name)}
                            inputFormat={form.getFieldValue([fieldName, "inputFormat"])}
                            outputFormat={form.getFieldValue([fieldName, "outputFormat"])}
                            inputObjectType={form.getFieldValue("targetObject")}
                            defaultMappings={defaultMappings}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Box>
                )}
                <Form.ErrorList errors={errors} />
              </div>
            )}
          </Form.List>
        )}
      </Form.Item>
    </div>
  );
}

ExtraMapping.propTypes = {
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
