export enum SalesforceObject {
  Planner = "Planner__c",
  PlannerProject = "Planner_Project__c",
  ProductionRequest = "Production_Request__c",
  User = "User",
  Group = "Group",
  Organization = "Organization",
  Campaign = "Campaign",
  CampaignMember = "CampaignMember",
  Lead = "Lead",
  Contact = "Contact",
  RequestedRelease = "Requested_Release__c",
  ArtRequest = "Art_Request__c",
  ConcordBucks = "Concord_Bucks__c",
  AssociatedRelease = "Associated_Release__c",
}

export type Planner = {
  Id: string;
  UPC__c: string;
  Artist__c: string;
  Title__c: string;
  Version__c: string;
  Format__c: string;
  Format_Category__c: string;
  Label_Group__c: string;
  Project__c: string;
};

export type PlannerProject = {
  Id: string;
  A_R__c: string;
  Product_Manager__c: string;
  Project_Number__c: string;
  Master_Project_Number__c: string;
  Label_Group__c: string;
  Artist__c: string;
  Name: string;
  Sharepoint_Library_Created__c: string;
  Description__c: string;
};

export type RequestedRelease = {
  Id: string;
  UPC__c?: string;
  Artist__c: string;
  Title__c: string;
};

export type FieldType =
  | "id"
  | "boolean"
  | "string"
  | "textarea"
  | "datetime"
  | "date"
  | "email"
  | "url"
  | "int"
  | "double"
  | "currency"
  | "picklist"
  | "multipicklist"
  | "reference"
  | "percent"
  | "phone"
  | "address";
