import { Button } from "antd";
import { Tooltip } from "@/components/common/Tooltip";
import type { ComponentProps } from "react";

type ButtonProps = ComponentProps<typeof Button>;

type Props = {
  buttonType?: ButtonProps["type"];
  unavailable?: boolean;
  unavailableTooltip?: string;
} & Pick<ButtonProps, "onClick" | "loading" | "disabled">;

export const SaveAsTemplateButton = ({
  onClick,
  disabled,
  unavailable = false,
  unavailableTooltip,
  buttonType = "default",
  loading,
}: Props) => {
  return (
    <Tooltip title={unavailable ? unavailableTooltip : null}>
      <Button
        type={buttonType}
        htmlType="button"
        onClick={onClick}
        disabled={unavailable || disabled}
        loading={loading}
      >
        Save as Template
      </Button>
    </Tooltip>
  );
};
