import "./StatusCounter.less";

type Props = {
  status: string;
  count: number;
  getLabel: (status: string) => string;
  getColor: (status: string) => string;
};

export const StatusCounter = ({ status, count, getLabel, getColor }: Props) => (
  <div className="status-counter" style={{ color: getColor(status) }}>
    <div className="status-counter__count">
      <span>{count}</span>
    </div>
    <div className="status-counter__label">{getLabel(status)}</div>
  </div>
);
