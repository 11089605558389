import { mappingTemplatesToOptions } from "@/components/common/CommonMappings/utils";
import Icon from "@ant-design/icons";
import { DropdownArrow } from "@/components/common/Icons";
import { Select } from "antd";
import { mappingTemplates } from "@/utils";
import type { OpenplayObjectType, Operation } from "@/types/common";

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  operation: Operation;
  targetObject: OpenplayObjectType;
  disabledKeys?: string[];
};

export const MappingTemplatePicker = ({ value, onChange, operation, targetObject, disabledKeys }: Props) => {
  const availableTemplates = mappingTemplates
    .filter((template) => template.operation === operation && template.targetObjects.includes(targetObject))
    .map((template) => template.formats);

  return (
    <Select
      placeholder="Select template"
      options={mappingTemplatesToOptions(availableTemplates).map((option) => ({
        ...option,
        disabled: disabledKeys ? disabledKeys.includes(option.value) : false,
      }))}
      value={value}
      onChange={onChange}
      suffixIcon={<Icon component={DropdownArrow} />}
    />
  );
};
