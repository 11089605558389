import { Tooltip } from "antd";
import { BaseTag } from "@/components/common/BaseTag/BaseTag";
import type { Correspondent } from "@/api/report-logs/types";

type Props = {
  correspondent: Correspondent;
};

export const CorrespondentTag = ({ correspondent }: Props) => {
  const { address, name } =
    typeof correspondent === "string" ? { address: correspondent, name: null } : correspondent;
  return (
    <Tooltip key={address} title={name ? address : null}>
      <BaseTag>{name ?? address}</BaseTag>
    </Tooltip>
  );
};
