import { Alert } from "antd";
import React from "react";

export const DeliveryError = ({ delivery }) => (
  <Alert
    type="error"
    message={
      <div className="release-delivery-table__error">
        {delivery.reason.split("\n").map((line) => (
          <p>{line}</p>
        ))}
      </div>
    }
  />
);
